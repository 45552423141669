<template>
  <div class="confirmation-container">
    <v-icon large class="SuccessIcon">mdi-checkbox-marked-circle</v-icon>
    <span class="SuccessMsg">{{ message }}</span>
    <span v-if="userMail.length > 0" class="email-text">
      {{ userMail }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ConfirmationMessage",
  props: {
    message: {
      type: String,
      required: true,
      default: "",
    },
    userMail: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style scoped>
.SuccessIcon {
  color: #5eae55;
  padding: 5px;
}

.SuccessMsg {
  color: #6d6d6d;
  font-size: 14px;
  white-space: nowrap;
}

.email-text {
  font-weight: bold;
  color: black;
  font-size: 14px;
}

@media only screen and (min-width: 961px) {
  .confirmation-container {
    display: table;
    margin: auto;
    height: 40px;
    padding: 8px;
    white-space: nowrap;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }

  .confirmation-container:last-child {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .confirmation-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
