import OpeningHoursDto from "@/types/dto/eateries/openingHoursDto";
import Moment from "moment";
import { getValidOpeningDateRangesForDate } from "@/helpers/eateryOpeningTimeValidator";
import LocalTime from "@/types/time/localTime";
import { DateRange } from "@/types/time/dateRange";

function getAllValidDates(dateString: string, validDateRanges: DateRange[]) {
  const selectedDate = new Date(dateString);
  selectedDate.setHours(0, 0, 0, 0);
  const validDates: Date[] = [];

  for (let i = 0; i < 24 * 60; i++) {
    const currentDate = Moment(selectedDate)
      .add(i, "m")
      .toDate();

    const isValid = validDateRanges.some(dateRange =>
      dateRange.dateIsInsideRange(currentDate)
    );
    if (isValid) {
      validDates.push(currentDate);
    }
  }
  return validDates;
}

function getDisabledHours(validDates: Date[]) {
  const validHours = validDates.map(date => date.getHours());
  const uniqueValidHours = [...new Set(validHours)];
  const disabledHours: number[] = [];
  for (let i = 0; i < 24; i++) {
    const currentHourIsValid = uniqueValidHours.includes(i);
    if (!currentHourIsValid) disabledHours.push(i);
  }
  return disabledHours;
}

function getDisabledMinutes(validDates: Date[], selectedHour: number) {
  const validMinutes = validDates
    .filter(date => date.getHours() === selectedHour)
    .map(date => date.getMinutes());
  const uniqueValidMinutes = [...new Set(validMinutes)];
  const disabledMinutes: number[] = [];
  for (let i = 0; i < 60; i++) {
    const currentMinuteIsValid = uniqueValidMinutes.includes(i);
    if (!currentMinuteIsValid) disabledMinutes.push(i);
  }
  return disabledMinutes;
}

export function calculateDisabledTimeArrays(
  dateString: string,
  timeString: string,
  eateryOpeningHours: OpeningHoursDto[],
  openingTimeBufferInMinutes: number
) {
  const validDateRanges = getValidOpeningDateRangesForDate(
    new Date(dateString),
    eateryOpeningHours,
    openingTimeBufferInMinutes
  );

  const validDates = getAllValidDates(dateString, validDateRanges);

  const disabledHours = getDisabledHours(validDates);

  const selectedHour = Number(new LocalTime(timeString).hour);
  const disabledMinutes = getDisabledMinutes(validDates, selectedHour);

  return {
    hours: disabledHours,
    minutes: disabledMinutes
  };
}
