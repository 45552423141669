<template>
  <div>
    <PersonalInformationForm
      :wantFullName="nameRequired"
      :wantFirstname="false"
      :wantLastname="false"
      :wantEmail="emailRequired"
      :wantPhone="phoneRequired"
      :fullName="fullName"
      :email="email"
      :phone="phone"
      :personalInformationFormValid="personalValid"
      @fullName="fullName = $event"
      @email="email = $event"
      @phone="phone = $event"
      @personalInformationFormValid="personalValid = $event"
    />
    <template v-if="selectedEatingMode == 3">
      <AddressInformationForm
        :address="address"
        :postalCode="postalcode"
        :city="postalcity"
        :addressInformationFormValid="addressValid"
        @address="address = $event"
        @postalCode="postalcode = $event"
        @city="postalcity = $event"
        @addressInformationFormValid="addressValid = $event"
      />
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import codeBehind from "./UserInformation";
export default Vue.extend({
  name: "UserInformation",
  mixins: [codeBehind],
});
</script>
