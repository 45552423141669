<template>
  <FoodyCard>
    <!-- <div class="navBarContainer">
      <NavBar
        HeaderText="Handlekurv"
        :callBack="goBack"
        class="d-md-none d-lg-none"
      ></NavBar>
    </div> -->
    <EatingModeSwitch
      v-if="checkoutStage == 1"
      :currentEatery="CurrentEatery"
    ></EatingModeSwitch>
    <div class="shopping-cart-main">
      <Shopping-Cart />
    </div>

    <ErrorMsgBox :outlined="true" :selectedDate="selectedDate" :selectedTime="selectedTime"></ErrorMsgBox>

    <div class="shopping-cart-footer">
      <foody-button
        :btn-color="'primary'"
        :on-click="onClick"
        :parentclasses="['shopping-cart-button']"
        :disabled="buttonDisabled"
      >
        {{ buttonText ? buttonText : "Fortsett..." }}
      </foody-button>
    </div>
    <div v-if="$store.getters.dineInOrder">
      Din bordbestilling er registret velg maten du ønsker!
    </div>
  </FoodyCard>
</template>

<script lang="ts">
import ShoppingCart from "@/components/shopping-cart/ShoppingCart.vue";
import ErrorMsgBox from "@/components/common/ErrorMsgBox/ErrorMsgBox.vue";
import FoodyCard from "@/components/common/FoodyCard.vue";
import EatingModeSwitch from "@/components/eatery/EatingModeSwitch.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import { setAvailableEatingMode } from "@/types/dto/eateries/eateryDtoFunctions";
import { FoodyError } from "@/types/error/error";

export default {
  name: "Shoppingcartcontainer",
  components: {
    ShoppingCart,
    ErrorMsgBox,
    FoodyButton,
    FoodyCard,
    EatingModeSwitch,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: "",
    },
    buttonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    prevRoute: {
      type: String,
      required: false,
      default: null,
    },
    errorMsg: {
      type: Object as () => FoodyError,
      default: null,
    },
    checkoutStage: {
      type: Number,
      default: null,
    },
    selectedDate: {
      type: String,
      required: true,
    },
    selectedTime: {
      type: String,
      required: true,
    },
  },
  computed: {
    shoppingcart() {
      return this.$store.getters.cart;
    },
    shoppingCartSum() {
      let orderSum = 0;
      this.shoppingcart.forEach(function (cartItem: any) {
        if (cartItem.nrOfItems > 1) {
          orderSum += cartItem.totalPrice * cartItem.nrOfItems;
        } else {
          orderSum += cartItem.totalPrice;
        }
      });
      return orderSum;
    },
    AnyShoppingCartItem() {
      const items = this.$store.getters.cart;
      if (items && items.length > 0) return true;

      return false;
    },
    DineInOnlyItemInCart() {
      const items = this.$store.getters.cart;
      const dineInOnlyItemInCart = items.some(
        (item: { dineInOnly: boolean }) => item.dineInOnly === true
      );
      if (
        !this.TableObject &&
        this.CurrentEatingMode !== 1 &&
        dineInOnlyItemInCart
      )
        return true;

      return false;
    },
    CurrentEatery() {
      return this.$store.getters.eateryInfo;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    CurrentEatingMode() {
      return (
        this.$store.getters.selectedEatingMode ??
        setAvailableEatingMode(this.CurrentEatery)
      );
    },
  },
  watch: {
    shoppingCart: function () {
      this.ErrorMessage;
    },
  },
};
</script>
<style scoped>
.errorMsg {
  font-size: 0.9em;
  color: rgba(218, 139, 129);
  text-align: center;
}
</style>
