<template>
  <div>
    <Dashboard />
    <!-- This snack bar should be its own component, and used only one place. Use it here in App.vue and not in MainContent.vue -->
    <v-dialog
      bottom
      :value="updateExists"
      :timeout="-1"
      persistent
      max-width="500px"
      min-height="300px"
    >
      <FoodyCard>
        <v-card-title style="justify-content: space-around">
          <b>Ny versjon tilgjengelig</b>
        </v-card-title>
        <v-card-text style="text-align: center">
          Trykk her for å oppdatere til siste versjon:
          <div style="padding-top: 20px">
            <FoodyButton :btn-color="'primary'" :on-click="refreshApp">
              Oppdatér
            </FoodyButton>
          </div>
        </v-card-text>
      </FoodyCard>
    </v-dialog>
  </div>
</template>
<script>
import Dashboard from "@/views/Dashboard";
import FoodyCard from "@/components/common/FoodyCard.vue";
import FoodyButton from "@/components/common/FoodyButton";
import "./styles/vuestyles.css";
import update from "./mixins/update";
import DisableTabDirective from "./directives/disableTabOnClass";

export default {
  name: "App",
  components: {
    Dashboard,
    FoodyCard,
    FoodyButton,
  },
  directives: { disableTabOnClass: DisableTabDirective },
  mixins: [update],

  async mounted() {
    const lastSelectedEatery = this.$store.getters.lastSelectedEatery;
    if (lastSelectedEatery) {
      await this.$store.dispatch("setEateryName", lastSelectedEatery);
      await this.$store.dispatch("getEateryInfo", lastSelectedEatery);
      await this.$store.dispatch("setLastSelectedEatery", lastSelectedEatery);
    }
  },
};
</script>
