import Inputfield from "@/components/inputFields/Inputfield.vue";
import TextAreaField from "@/components/inputFields/TextAreaField.vue";
//import { email } from "vuelidate/lib/validators";
const codeBehind = {
  components: { Inputfield, TextAreaField },
  data() {
    return {
      nameValid: false,
      emailValid: false,
      phoneValid: false,
      messageValid: false,
      error: [] as string[],
    };
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "",
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
    phone: {
      type: String || null,
      required: false,
      default: null || "",
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    wantName: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantEmail: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantPhone: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
    nameIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    emailIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    phoneIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    messageIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    partnerContactFormValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    nameInput: {
      get() {
        return this.name;
      },
      set(val: string) {
        this.$emit("name", val);
      },
    },
    emailInput: {
      get() {
        return this.email;
      },
      set(val: string) {
        this.$emit("email", val);
      },
    },
    phoneInput: {
      get() {
        return this.phone;
      },
      set(val: string) {
        this.$emit("phone", val);
      },
    },
    messageInput: {
      get() {
        return this.message;
      },
      set(val: string) {
        this.$emit("message", val);
      },
    },
    nameChecker() {
      if (this.wantName) return this.nameValid;
      return true;
    },
    emailChecker() {
      if (this.wantEmail) return this.emailValid;
      return true;
    },
    phoneChecker() {
      if (this.phoneInput !== null && this.phoneInput.length > 0)
        return this.phoneValid;
      return true;
    },
    messageChecker() {
      if (this.wantMessage) return this.messageValid;
      return true;
    },
    formValid(): boolean {
      return (
        this.nameChecker &&
        this.emailChecker &&
        this.messageChecker &&
        this.phoneChecker
      );
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [vm.formValid],
      () => {
        this.$emit("partnerContactFormValid", this.formValid);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
};
export default codeBehind;
