<template>
  <div v-if="isLoading" class="loader"></div>
</template>
<script>
export default {
  name: "Loader",

  props: {
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #980a90; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
