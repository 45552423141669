const codebehindProps = {
  props: {
    fullName: {
      type: String,
      required: false,
      default: "",
    },
    firstname: {
      type: String,
      required: false,
      default: "",
    },
    lastname: {
      type: String,
      required: false,
      default: "",
    },
    email: {
      type: String,
      required: false,
      default: "",
    },
    phone: {
      type: String,
      required: false,
      default: "",
    },
    wantFullName: {
      type: Boolean,
      required: false,
      default: false,
    },
    wantFirstname: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantLastname: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantEmail: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantPhone: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullNameIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    firstnameIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    lastnameIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    emailIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    phoneIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    personalInformationFormValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
export default codebehindProps;
