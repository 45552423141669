<template>
  <section class="partner-catchlines-section">
    <div class="partner-catchlines">
      <div class="partner-catchline">
        <v-icon x-large class="catchlineicon">mdi-check-circle</v-icon>
        <div>Markedets rimligste løsning.</div>
      </div>
      <div class="partner-catchline">
        <v-icon x-large class="catchlineicon">mdi-check-circle</v-icon>
        <div>Raskt og enkelt å komme i gang.</div>
      </div>
      <div class="partner-catchline">
        <v-icon x-large class="catchlineicon">mdi-check-circle</v-icon>
        <div>Ingen oppsigelsestid.</div>
      </div>
      <div class="partner-catchline">
        <v-icon x-large class="catchlineicon">mdi-check-circle</v-icon>
        <div>Vi setter opp menyen for deg.</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
.partner-catchlines-section {
  background-color: var(--partner-dark-background-color);
  padding: 2rem 0;
  color: white;
}
.partner-catchlines {
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.partner-catchline {
  display: flex;
  align-items: center;
}
.catchlineicon {
  margin: 0 1rem;
  color: var(--foody-shopping-cart-primary-color);
}
@media only screen and (max-width: 1200px) {
  .partner-catchlines {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .partner-catchline > * {
    text-align: center;
  }
  .partner-catchline:not(:last-child) {
    margin-bottom: 2em;
  }
  .catchlineicon {
    margin: 0px 0px 0.5rem 0px;
  }
  .partner-catchline {
    display: flex;
    flex-direction: column;
  }
}
</style>
