<template>
  <div v-if="addArray">
    <div v-for="(error, i) in addArray" v-bind="$props" :key="i">
      <v-alert
        v-if="error.isActive"
        dense
        :outlined="outlined"
        :type="error.severity"
      >
        {{ error.message }}
      </v-alert>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import codebehind from "./ErrorMsgBox";

export default Vue.extend({
  name: "ErrorMsgBox",
  mixins: [codebehind],
});
</script>

<style scoped></style>
