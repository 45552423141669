<template>
  <div class="orderOptionContainer">
    <v-radio-group
      v-model="selectedOrderingOption"
      class="orderOptionRadioGroup"
      :items="OrderingOptions"
      row
      @change="saveSelectedOption"
    >
      <v-radio
        v-for="option in OrderingOptions"
        :key="option"
        :label="getEatingModeLabel(option)"
        :value="option"
      >
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { setAvailableEatingMode } from "@/types/dto/eateries/eateryDtoFunctions";
import { getValidEatingModesForCurrentMenu } from "@/helpers/currentMenuHelper";
import { EatingMode } from "@/types/enums";

export default {
  props: {
    eateryInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedOrderingOption:
        this.$store.getters.selectedEatingMode ??
        setAvailableEatingMode(this.CurrentEatery),
    };
  },
  computed: {
    OrderingOptions() {
      return getValidEatingModesForCurrentMenu();
    },
    showOrderTypeDropdown() {
      const showDropdown = !this.$store.getters.tableObject;
      return showDropdown;
    },
    CurrentEatingMode() {
      return (
        this.$store.getters.selectedEatingMode ??
        setAvailableEatingMode(this.CurrentEatery)
      );
    },

    CurrentEatery() {
      return this.$store.getters.eateryInfo;
    },
    currentMenu() {
      return this.$store.getters.menu;
    },
    menus() {
      return this.$store.getters.menus;
    },
  },
  mounted() {
    const orderingOptions = this.OrderingOptions;
    if (!orderingOptions) return;
    if (!this.CurrentEatingMode) {
      this.selectedOrderingOption = orderingOptions[0].id;
      this.saveSelectedOption();
    }
  },
  methods: {
    saveSelectedOption() {
      this.$store.dispatch("setEatingMode", this.selectedOrderingOption);
      this.$store.dispatch("updateErrorStatus");
    },
    getEatingModeLabel(eatingMode) {
      switch (eatingMode) {
        case EatingMode.DineIn:
          return "Spise inne";
        case EatingMode.Delivery:
          return "Levering";
        case EatingMode.TakeAway:
          return "Hent selv";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.orderOptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.orderOptionRadioGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 16px;
}
</style>
<style>
/* Unscoped because it's targeting child-component elements */
.orderOptionRadioGroup > div > div > div > div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}
.orderOptionRadioGroup > div > div > div > div > label {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 15px;
}
.orderOptionRadioGroup > div > div > div > div i:before {
  font-size: 40px;
}
</style>
