<template>
  <div v-if="!fp.multiSelect">
    <FoodOptionRadio :foodProp="fp" />
  </div>
  <div v-else-if="fp.multiSelect">
    <FoodOptionMulti :foodProp="fp" />
  </div>
</template>
<script>
import FoodOptionRadio from "@/components/menues/menu-items/menu-item-properties/FoodOptionRadio.vue";
import FoodOptionMulti from "@/components/menues/menu-items/menu-item-properties/FoodOptionMulti.vue";
export default {
  name: "FoodPropertySingle",
  components: {
    FoodOptionRadio: FoodOptionRadio,
    FoodOptionMulti: FoodOptionMulti,
  },
  props: {
    fp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
