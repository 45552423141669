<template>
  <v-card :class="computedClasses">
    <slot>Content</slot>
  </v-card>
</template>

<script>
export default {
  name: "FoodyCard",
  props: {
    parentclasses: {
      type: Array,
      required: false,
    },
  },
  computed: {
    computedClasses() {
      const childclasses = ["pa-4"];
      const parentC = this.parentclasses
        ? [...this.parentclasses, ...childclasses]
        : childclasses;
      return parentC;
    },
  },
};
</script>
