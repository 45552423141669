import FoodyCard from "@/components/common/FoodyCard.vue";
const codeBehind = {
  components: {
    FoodyCard,
  },
  data() {
    return {
      selected: ["Necessary"],
    };
  },
  async mounted() {
    const cookie = await this.$store.dispatch("getGDPRCookie");
    if (!cookie) {
      const GDPRCookie = { Necessary: true, Analytical: false };
      this.$store.dispatch("setGDPRCookie", GDPRCookie);
    }
    if (cookie && cookie.Analytical === true) {
      this.selected.push("Analytic");
    }
    this.$watch(
      (vm: any) => [vm.selected],
      () => {
        const GDPRCookie = { Necessary: true, Analytical: false };
        if (this.selected.includes("Analytic")) {
          GDPRCookie.Analytical = true;
        }
        this.$store.dispatch("setGDPRCookie", GDPRCookie);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
};
export default codeBehind;
