<template>
  <v-app>
    <MainContent @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />
    <Sidebar
      ref="drawer"
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    />
    <gdprCookiePopup></gdprCookiePopup>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import MainContent from "@/components/common/MainContent.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import gdprCookiePopup from "@/components/gdpr/gdprCookiePopup.vue";
import "../styles/vuestyles.css";
export default Vue.extend({
  components: {
    MainContent,
    Sidebar,
    gdprCookiePopup,
  },
});
</script>
<style>
/* Modifying directly to snackbar class, since normal class will not work */
.v-snack__wrapper {
  margin-bottom: 10px !important;
}
.v-application--wrap {
  width: 100vw;
}
</style>
