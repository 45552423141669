<template>
  <v-row cols="12" lg="6" xs="12">
    <v-col style="margin-top: 19px">
      <TimePicker
        ref="timepicker"
        :time="timeInput"
        :date="dateInput"
        :eating-mode="currentEatingModeInput"
        :reservation="reservation"
        :openingTimeBufferInMinutes="openingTimeBufferInMinutes"
        @time="timeInput = $event"
        @timeValid="validTimeInput = $event"
        @invalidReasons="invalidReasonsInput = $event"
      ></TimePicker>
    </v-col>
    <v-col>
      <DatePicker
        :date-prop="dateInput"
        @dateProp="dateInput = $event"
      ></DatePicker>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import codeBehind from "./TimeAndDateSelector";
export default Vue.extend({
  name: "TimeAndDateSelector",
  mixins: [codeBehind],
});
</script>
