<template>
  <div class="partner-prices">
    <div class="prices-list">
      <span class="header">Prisliste</span>
      <div class="prices-list-item">
        <div>
          <span class="price-item">QR-bestilling til café og bar</span>
          <p>inkluderer drikke og cafemeny</p>
        </div>
        <span class="price">kr 700/mnd</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">QR-bestilling til restaurant</span>
          <p>inkluderer mat, drikke og cafemeny</p>
        </div>
        <span class="price">kr 1100/mnd</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">QR-bestilling til hotellbar/café</span>
          <p>inkluderer drikke og cafémeny</p>
        </div>
        <span class="price">kr 700/mnd</span>
      </div>
      <div class="prices-list-item">
        <div>
          <span class="price-item"> - Roomservice</span>
        </div>
        <span class="price">kr 10 pr rom/mnd</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">Webshop på getfood.no</span>
          <p>
            Inkluderer egen utsalgsprofil på getfood.no for
            takeaway-bestillinger
          </p>
        </div>
        <span class="price">5% av omsetningen på getfood.no</span>
      </div>
      <div class="prices-list-item">
        <div>
          <span class="price-item"> - Bordreservasjon</span>
          <p>Bordreservasjonsfunksjon på getfood.no</p>
        </div>
        <span class="price">kr 200/mnd</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">Menyoppsett</span>
          <p>getfood setter opp menyen for deg</p>
        </div>
        <span class="price">kr 1000</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">Ordrehåndteringssystem</span>
          <p>System for felles håndtering av alle ordre</p>
        </div>
        <span class="price">inkludert</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">Smittesporing</span>
        </div>
        <span class="price">inkludert</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.header {
  font-family: "Poppins";
  font-size: 1.7em;
  font-weight: bold;
  color: #000000;
  border-left: solid 5px #9f0073;
  grid-column: 1 / -1;
  margin: 10px 0 20px;
}

.prices-list {
  margin: 0 auto;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-gap: 3px 20px;
  max-width: 650px;
}
.prices-list hr {
  grid-column: 1 / -1;
}
.prices-list-item {
  display: contents;
}
.price-item {
  font-weight: bold;
  font-size: 0.8em;
}

p {
  font-weight: normal;
  font-style: italic;
  color: #828282;
  margin: 0;
  font-size: 0.8em;
  text-align: left;
}
.price {
  text-align: right;
  font-weight: bold;
  font-size: 0.8em;
}

hr {
  margin: 20px 0;
}
</style>
