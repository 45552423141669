<template>
  <div v-bind="$props">
    <v-alert dense :outlined="outlined" :type="type">
      {{ message }}
    </v-alert>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "ErrorComponent",
  props: {
    message: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "warning",
    },
  },
});
</script>
