import Vue from "vue";

function disableTab(root: HTMLElement, className: string) {
  const elements = Array.from(
    root.querySelectorAll(className)
  ) as HTMLElement[];
  elements.forEach((x) => x.setAttribute("tabIndex", "-1"));
}

const DisableTabDirective = Vue.directive("disableTabOnClass", {
  inserted: (el, binding) => {
    if (Array.isArray(binding.value)) {
      binding.value.forEach((className) => disableTab(el, className));
    } else {
      disableTab(el, binding.value);
    }
  },
});

export default DisableTabDirective;
