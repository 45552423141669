<template>
  <v-container fluid>
    <v-row class="name-row">
      <inputfield
        v-if="wantFirstname"
        :input="firstnameInput"
        input-type="name"
        field-label="Fornavn"
        :input-valid="firstnameValid"
        @input="firstnameInput = $event"
        @inputValid="firstnameValid = $event"
      ></inputfield>
      <inputfield
        v-if="wantLastname"
        :input="lastnameInput"
        input-type="name"
        field-label="Etternavn"
        :input-valid="lastnameValid"
        @input="lastnameInput = $event"
        @inputValid="lastnameValid = $event"
      ></inputfield>
    </v-row>
    <v-row>
      <inputfield
        v-if="wantFullName"
        :input="fullNameInput"
        input-type="name"
        field-label="Navn"
        :input-valid="fullNameValid"
        @input="fullNameInput = $event"
        @inputValid="fullNameValid = $event"
      >
      </inputfield>
    </v-row>
    <v-row>
      <inputfield
        v-if="wantEmail"
        autoCompleteType="username"
        :input="emailInput"
        input-type="email"
        field-label="E-post"
        :input-valid="emailValid"
        @input="emailInput = $event"
        @inputValid="emailValid = $event"
      ></inputfield>
    </v-row>
    <v-row class="phone-row">
      <phone-input
        v-if="wantPhone"
        :phone="phoneInput"
        @phone="phoneInput = $event"
        @phoneNumberPrefixOutput="phoneNumberPrefixOutput = $event"
        @phoneNumberValid="phoneValid = $event"
      ></phone-input>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import Codebehind from "@/components/forms/personal/PersonalInformationForm";
import Inputfield from "@/components/inputFields/Inputfield.vue";
import Vue from "vue";
export default Vue.extend({
  name: "PersonalInformationForm",
  components: { Inputfield },
  mixins: [Codebehind],
});
</script>

<style scoped>
@media only screen and (max-width: 496px) {
  .name-row {
    grid-gap: 0;
  }
}
@media only screen and (min-width: 497px) {
  .name-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
  }
  .fullname-and-email {
    grid-gap: 2em;
  }
}

@media (max-width: 769px) {
  .fullname-and-email {
    padding-right: 1em;
  }
  .phone-row {
    padding-right: 1em;
  }
}
</style>
