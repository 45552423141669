export class DateRange {
  private _from: Date;
  private _to: Date;

  constructor(from: Date, to: Date) {
    if (!from) throw new Error(`From date must be defined (From: ${from})`);
    if (!to) throw new Error(`To date must be defined (To: ${to})`);

    if (from > to)
      throw Error(`From (From: ${from}) must be before to (To: ${to})`);
    this._from = from;
    this._to = to;
  }

  get from() {
    return this._from;
  }

  get to() {
    return this._to;
  }

  dateIsInsideRange(date: Date) {
    return date >= this._from && date <= this._to;
  }
}
