import { MutationTree } from "vuex";
import EateryState from "./state";

export const mutations: MutationTree<EateryState> = {
  SET_EATERY_NAME(state, eateryName: string) {
    state.eateryName = eateryName;
  },
  UPDATE_EATERY_LOGO(state, companyLogo: string) {
    state.eateryLogo = companyLogo;
  },
  UPDATE_EATERY_INFO(state, eateryInfo) {
    state.eateryInfo = eateryInfo;
  },
  UPDATE_EATERIES(state, eateries) {
    state.eateries = eateries;
  },
};
