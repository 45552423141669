<template>
  <div>
    <div style="margin-bottom: 20px" class="options-head">
      <h3>Velg {{ foodProp.name }}</h3>
    </div>
    <v-checkbox
      v-for="option in foodProp.foodPropertyOptions"
      :key="option.id"
      v-model="selOptions"
      hide-details="auto"
      class="ma-0 pa-0 foody-checkbox-flex"
      :value="option.id"
      @change="updateSelectedOptions"
    >
      <template slot="label">
        <span>{{ option.name }}</span>
        <span v-if="option.price" class="foody-options-price"
          >+ {{ +option.price / 100 }} NOK</span
        >
      </template>
    </v-checkbox>
  </div>
</template>
<script>
export default {
  name: "Foodoptionmulti",
  props: {
    foodProp: Object,
  },
  data() {
    return {
      selOptions: this.foodProp.foodPropertyOptions
        .filter((o) => o.selected)
        .map((x) => x.id),
    };
  },
  methods: {
    style2(length) {
      return "height:" + 100 / length + "%;";
    },
    updateSelectedOptions(optionIds) {
      const selectedElements = [];
      for (
        let index = 0;
        index < this.foodProp.foodPropertyOptions.length;
        index++
      ) {
        const element = this.foodProp.foodPropertyOptions[index];
        element.selected = optionIds.some((id) => id === element.id);
        if (element.selected) {
          selectedElements.push(element);
        }
      }
    },
  },
};
</script>
