<template>
  <v-container fluid>
    <v-row>
      <inputfield
        :input="passwordInput"
        input-type="password"
        field-label="Passord"
        :input-valid="passwordValid"
        :external-errors="error"
        autoCompleteType="current-password"
        @input="passwordInput = $event"
        @inputValid="passwordValid = $event"
      ></inputfield>
    </v-row>
    <v-row>
      <inputfield
        v-if="wantRepeatPassword"
        :input="repeatPasswordInput"
        input-type="password"
        field-label="Gjenta passord"
        :input-valid="repeatPasswordValid"
        :external-errors="error"
        @input="repeatPasswordInput = $event"
        @inputValid="repeatPasswordValid = $event"
      ></inputfield>
    </v-row>
  </v-container>
</template>
<script>
import Codebehind from "@/components/forms/password/PasswordForm";
import Vue from "vue";
export default Vue.extend({
  name: "PasswordForm",
  mixins: [Codebehind],
});
</script>
