import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import CityState from "./state";
import RootState from "@/types/state/rootState";

export const state: CityState = {
  cities: [],
};

//TODO: Properly namespace
const namespaced = false;

export const citiesModule: Module<CityState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
