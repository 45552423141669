<template>
  <v-card>
    <div style="text-align: center; padding-top: 20px">
      <img :src="currentEatery.bannerUrl" class="eatery-logo" />
      <h3 style="margin: auto">Din bordreservasjon er registrert!</h3>
    </div>
    <div class="content">
      <label>Navn</label>
      <span>{{ tableReservation.name }}</span>
      <label>Telefon</label>
      <span>{{ tableReservation.Phone }}</span>
      <label>Email</label>
      <span>{{ tableReservation.Email }}</span>
      <label>Antall personer</label>
      <span>{{ tableReservation.NrOfPeople }}</span>
      <label>Reservasjonstid</label>
      <span>{{ formattedReservationDateTime }}</span>
      <label>Melding</label>
      <span>{{ tableReservation.Request }}</span>
    </div>
    <p>{{ reservationInfo }}</p>
  </v-card>
</template>

<script>
import Vue from "vue";
import codeBehind from "./TableReservationSuccessPage.ts";
export default Vue.extend({
  name: "TableReservationSuccessPage",
  mixins: [codeBehind],
});
</script>

<style scoped>
.banner-img {
  max-height: 100px;
  max-width: 60%;
}
.eatery-logo {
  max-height: 150px;
  width: auto;
  max-width: 250px;
  border-radius: 20px;
}

label {
  text-align: left;
  font-weight: bold;
  padding-right: 25px;
  font-size: 1rem;
}
span {
  font-size: 1rem;
}
.content {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 35px;
  grid-gap: 10px;
  margin: 0;
  max-width: 500px;
  margin-top: 50px;
  padding-bottom: 50px;
}
p {
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
@media only screen and (min-width: 601px) {
  .content {
    grid-template-columns: 200px 1fr;
    margin: 0 auto;
    margin-top: 50px;
  }
  label {
    text-align: right;
  }
}
</style>
