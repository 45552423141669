import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import AddressInformationForm from "@/components/forms/address/AddressInformationForm.vue";
import UserInformationObject from "@/types/checkout/userInformation";
import { PropType } from "vue";

const codebehind = {
  components: {
    PersonalInformationForm: PersonalInformationForm,
    AddressInformationForm: AddressInformationForm,
  },
  data() {
    return {
      fullName: "",
      address: "",
      postalcode: "",
      postalcity: "",
      phone: "",
      email: "",
    };
  },
  props: {
    userInformationObject: {
      type: Object as PropType<UserInformationObject>,
      required: true,
    },
    selectedEatingMode: {
      type: Number,
      required: true,
    },
    personalInformationFormValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    addressInformationFormValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    emailRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    phoneRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    nameRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.getUser();
    this.$watch(
      (vm: any) => [
        vm.fullName,
        vm.email,
        vm.phone,
        vm.address,
        vm.postalcity,
        vm.postalcode,
      ],
      () => {
        if (this.loggedInUser) {
          this.getUser();
        }
        const userObject = this.userInformationObject as UserInformationObject;
        userObject.fullName = this.fullName;
        userObject.phone = this.phone;
        userObject.email = this.email;
        userObject.address = this.address;
        userObject.postalcity = this.postalcity;
        userObject.postalcode = this.postalcode;
        this.$emit("userInformationObject", userObject);
      },
      {
        immediate: true, // run immediately
      }
    );
  },
  computed: {
    personalValid: {
      get() {
        return this.personalInformationFormValid;
      },
      set(val: string) {
        this.$emit("personalInformationFormValid", val);
      },
    },
    addressValid: {
      get() {
        return this.addressInformationFormValid;
      },
      set(val: string) {
        this.$emit("addressInformationFormValid", val);
      },
    },
  },
  methods: {
    async getUser() {
      const user = this.$store.getters.loggedInUser;
      if (user) {
        (this.fullName = user.name ?? ""), (this.phone = user.phone ?? "");
        this.email = user.email ?? "";
        if (user && user.address) {
          this.address = user.address.streetAddress ?? "";
          this.postalcode = user.address.postalCode ?? "";
          this.postalcity = user.address.postalArea ?? "";
        }
      } else if (this.$store.getters.dineInOrder) {
        const dineInOrder = this.$store.getters.dineInOrder;
        (this.fullName = user.name ?? ""),
          (this.phone = dineInOrder.Phone ?? "");
        this.email = dineInOrder.Email ?? "";
        if (dineInOrder.Address) {
          this.address = dineInOrder.Address.StreetAddress ?? "";
          this.postalcode = dineInOrder.Address.PostalCode ?? "";
          this.postalcity = dineInOrder.Address.PostalArea ?? "";
        }
      } else if (this.$store.getters.TableObject) {
        const table = this.$store.getters.TableObject;
        this.tableId = table.tableId;
        this.fullName = user.name ?? "";
      }
      this.personalValid;
    },
  },
};
export default codebehind;
