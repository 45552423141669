<template>
  <FoodyCard :parentclasses="['card-view']">
    <v-card-title>Ordrehistorikk</v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
      @update:options="optionsUpdated()"
    >
      <template #[`item.orderDate`]="{ item }">
        <span v-bind="item">{{ item.orderDate }}</span>
      </template>
      <template #[`item.totalPrice`]="{ item }">
        <span v-bind="item">{{ item.totalPrice / 100 }},-</span>
      </template>
      <template #[`item.dailyId`]="{ item }">
        <span v-bind="item">{{ item.dailyId }}</span>
      </template>
      <template #[`item.receipt`]="{ item }">
        <div v-bind="item">
          <div v-if="item.error" style="color: red">
            {{ item.errorMessage }}
          </div>
          <v-btn
            color="blue-grey"
            :disabled="item.downloading"
            x-small
            class="ma-2 white--text"
            @click="downloadReceipt(item)"
          >
            <span v-if="!item.downloading">Last ned kvittering</span>
            <span v-if="item.downloading">Laster ned...</span>
            <v-progress-circular
              v-if="item.downloading"
              :size="15"
              :width="2"
              style="margin-left: 20px; margin-right: 10px"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </FoodyCard>
</template>

<script>
import moment from "moment";
import FoodyCard from "@/components/common/FoodyCard.vue";
export default {
  name: "UserOrdersHistory",
  components: {
    FoodyCard,
  },
  data: function () {
    return {
      orders: [],
      totalItems: 0,
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      headers: [
        { text: "Restaurant", value: "eateryName", sortable: false },
        { text: "Dato", value: "orderDate", sortable: true },
        { text: "Daily Id", value: "dailyId", sortable: false },
        { text: "Beløp", value: "totalPrice", sortable: false },
        { text: "Handlinger", value: "receipt", sortable: false },
      ],
    };
  },
  methods: {
    getDateTime: function (dateTime) {
      dateTime = dateTime.replace("kl. ", "");
      if (dateTime) {
        return moment(dateTime).format("HH:mm MM/DD/YYYY");
      }
    },
    optionsUpdated: function () {
      this.getOrders();
    },
    async getOrders() {
      this.loading = true;
      const page = this.options.page;
      const rowsPerPage = this.options.itemsPerPage;
      const sortBy = this.options.sortBy;
      const sortByDirection = this.options.sortDesc;
      const sort = {};
      for (let i = 0; i < sortBy.length; i++) {
        sort[sortBy[i]] = sortByDirection[i];
      }
      const res = await this.$store.dispatch("getUserOrders", {
        pageNumber: page,
        pageSize: rowsPerPage,
        sort: sort,
      });
      const ordersData = res.data;
      this.totalItems = res.totalRecords;
      ordersData.forEach((item) => (item.downloading = false));
      this.orders = ordersData;
      this.loading = false;
    },
    async downloadReceipt(item) {
      item.error = false;
      item.downloading = true;
      const res = await this.$store.dispatch(
        "getOrderRecieptPdf",
        item.accessGUID
      );

      if (res instanceof Error) {
        item.error = true;
        item.downloading = false;
        item.errorMessage = "Beklager noe gikk feil, prøv igjen!";
      } else {
        const blob = new Blob([res], { type: "application/pdf" });
        const link = document.createElement("a");
        link.id = "link";
        link.target = "_self";
        link.href = URL.createObjectURL(blob);
        link.download = "order_" + item.accessGUID;
        link.click();
        item.downloading = false;
      }
    },
  },
};
</script>

<style>
h1,
h2,
h3 {
  margin-left: 15px;
}
p {
  margin: 0px;
}
</style>
