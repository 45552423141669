<template>
  <v-img
    :src="foodyLogo"
    alt="Foody logo"
    style="cursor: pointer"
    height="100%"
    max-width="100px"
    contain
    position="left"
    @click="navHome"
  />
</template>
<script>
import logo from "../../assets/img/getfood_logo.png";
export default {
  name: "FoodyLogo",
  props: {},
  data() {
    return {};
  },
  computed: {
    foodyLogo() {
      return logo;
    },
    navigationUrl() {
      if (this.$store.getters.tableObject)
        return `/${this.$store.getters.tableObject.eateryName}`;
      return "/";
    },
  },
  methods: {
    navHome: function () {
      const path = this.navigationUrl;
      if (this.$route.path !== path) this.$router.push(path);
    },
  },
};
</script>
