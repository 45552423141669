<template>
  <div class="main-content">
    <div class="spinner-container">
      <Loader :isLoading="isLoading" />
    </div>
    <div class="card-container">
      <div class="eateries-container" style="margin-top: 20px">
        <EateryCardMinimal
          v-for="c in getEateries"
          :key="c.id"
          :eatery-item="c"
          :queryParam="queryParams"
        />
      </div>
    </div>
    <div class="full-width-exception">
      <PartnerFooter></PartnerFooter>
    </div>
  </div>
</template>

<script>
import EateryCardMinimal from "@/components/eatery/EateryCardMinimal.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import FoodcourtApi from "@/util/api/foodcourtApi";

export default {
  components: {
    EateryCardMinimal,
    PartnerFooter,
  },

  data() {
    return {
      foodcourtEateries: [],
      isLoading: true,
      queryParams: window.location.search.substr(1),
    };
  },
  metaInfo() {
    return {
      meta: [],
    };
  },
  computed: {
    tableObject() {
      return this.$store.getters.tableObject;
    },
    getEateries() {
      if (!this.foodcourtEateries.eateries) return [];

      const eateries = [...this.foodcourtEateries.eateries]
        .sort((a, b) => b.visibilityLevel - a.visibilityLevel)
        .filter((e) => e.visibilityLevel > 0);

      if (this.tableObject)
        return eateries.filter((e) => e.dineIn && e.hasQrModule);

      return eateries.filter(
        (e) => (e.dineIn && !e.dineInIsQrOnly) || e.takeAway || e.delivery
      );
    },
  },
  async mounted() {
    const foodcourtParameter = this.$route.params.foodcourtName;
    await FoodcourtApi.getFoodcourtEateries(foodcourtParameter, null).then(
      (res) => (this.foodcourtEateries = res.data)
    );
    this.isLoading = false;
  },
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  color: #222222;
}
.eateries-container {
  display: grid;
  grid-gap: 30px;
}
.main-content {
  margin: 0 auto;
}
@media (min-width: 426px) {
  .card-container {
    padding: 50px 3vw;
  }

  .eateries-container {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}
.margin-20px-top {
  margin-top: 20px;
}
</style>
