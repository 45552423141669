//import shoppingCartErrorsDto from "@/types/dto/shoppingCartErrors/shoppingCartErrorsDto";
import RootState from "@/types/state/rootState";
import { GetterTree } from "vuex";
import ErrorState from "./state";

export const getters: GetterTree<ErrorState, RootState> = {
  isErrorNoCartItem(state): boolean {
    return state.noCartItem;
  },
  isErrorBelowMinimumDeliverySum(state): boolean {
    return state.belowMinimumDeliverySum;
  },
  isErrorEatingModeClosed(state): boolean {
    return state.eatingModeClosed;
  },
  isErrorNotAvailableForDelivery(state): boolean {
    return state.notAvailableForDelivery;
  },
  isErrorNotAvailableForTakeAway(state): boolean {
    return state.notAvailableForTakeAway;
  },
  isErrorQrDineInClosed(state): boolean {
    return state.qrDineInIsClosed;
  },
};
