import Vue from "vue";
import VModal from "vue-js-modal";
import vuetify from "@/plugins/vuetify";
import VueCookies from "vue-cookies";
import Moment from "moment";
import "moment/dist/locale/nb";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import { allowRouterPushRedirect } from "./util/allowRouterPushRedirect";

Moment.locale("nb");
Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(VueMeta);

allowRouterPushRedirect();

new Vue({
  router,
  store,
  vuetify,
  VModal,
  Moment,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (h: any) => h(App),
}).$mount("#app");
