<template>
  <VuePhoneNumberInput
    v-model="phoneInput"
    default-country-code="NO"
    :translations="translation"
    @update="validatePhone"
  />
</template>
<script>
import Vue from "vue";
import Codebehind from "./PhoneInput";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default Vue.extend({
  name: "PhoneInput",
  components: {
    VuePhoneNumberInput,
  },
  mixins: [Codebehind],
});
</script>

<style scoped></style>
