<template>
  <div class="form-with-delete">
    <PersonalInformationForm
      :want-email="false"
      :firstname="person.firstname"
      :lastname="person.lastname"
      :phone="person.phone"
      @firstname="person.firstname = $event"
      @lastname="person.lastname = $event"
      @phone="person.phone = $event"
    />
    <FoodyButton :on-click="deleteForm" btn-color="primary"
      ><v-icon>mdi-delete-outline</v-icon></FoodyButton
    >
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";

export default Vue.extend({
  name: "PersonalInformationFormWithDelete",
  components: {
    PersonalInformationForm,
    FoodyButton,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
    index: { type: Number },
  },
  methods: {
    deleteForm() {
      this.$emit("person", this);
    },
  },
});
</script>

<style scoped>
.form-with-delete {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-with-delete > button {
  margin-left: 15px;
}
</style>
