import RootState from "@/types/state/rootState";

const State: RootState = {
  menus: [],
  lastNavigationPage: null,
  menu: null,
  lastMenuId: null,
  activeMenuitem: null,
  cart: [],
  loggedInUser: null,
  loading: false,
  lastSelectedEatery: null,
  selectedFoodPropOptions: [],
  userProfile: null,
  paymentOrder: null,
  dineInOrder: null,
  paymentId: null,
  checkoutKey: null,
  tableReservationObject: null,
  showShoppingCart: true,
  snackbarText: "",
  showSnackbar: false,
  tableObject: null,
  deliveryObject: null,
  resetToken: null,
  deliveryOption: null,
  messageToKitchen: "",
  token: null,
  refreshToken: null,
  GDPRCookie: null,
  lockedToEatery: null,
};

export default State;
