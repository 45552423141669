import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import EateryState from "./state";
import RootState from "@/types/state/rootState";

export const state: EateryState = {
  eateryName: null,
  eateryLogo: null,
  eateryInfo: null,
  eateries: [],
};

//TODO: Properly namespace
const namespaced = false;

export const eateryModule: Module<EateryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
