import vueAlertType from "./vueAlertType";

export enum messageType {
	noCartItems = 1,
	belowMinimumDeliverySum = 2,
    eatingModeClosed = 3,
    notAvailabelForTakeaway = 4,
    notAvailabelForDelivery = 5, 
    qrDineInIsClosed = 6,
};

export function getSeverity(type: messageType) : vueAlertType {
    switch(type) {
        case messageType.noCartItems: 
        case messageType.belowMinimumDeliverySum: 
        case messageType.eatingModeClosed:
        case messageType.qrDineInIsClosed:
            return vueAlertType.info
        case messageType.notAvailabelForDelivery:
        case messageType.notAvailabelForTakeaway:
            return vueAlertType.error
        default: 
            throw Error("Invalid message type");
    }
    
}