var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"full-width-exception"},[_c('v-card',{attrs:{"flat":""}},[_c('Banner',{attrs:{"eateryInfo":_vm.eateryInfo,"showNavigation":true}})],1)],1),(_vm.menu)?_c('v-card',[_c('div',{staticClass:"sticky-categories"},[(_vm.activeTab)?_c('MenuCategoryTabs',{attrs:{"menu-categories":_vm.menuCategories,"active-tab":_vm.activeTab,"menu":_vm.menu},on:{"activeTabUpdated":_vm.setActiveTab}}):_vm._e(),_c('div',{staticClass:"foody-category-tabs"},[_c('v-tabs',{attrs:{"background-color":"white","color":"#222222","grow":false},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.filteredGroupedMenuItems.filter((gmi) => {
              return !gmi.$ref;
            })),function(g){return _c('v-tab',{key:g.header,on:{"click":function($event){return _vm.scrollToSubMenu(g.header)}}},[_c('span',[_vm._v(_vm._s(g.header))])])}),1)],1)],1),(
        _vm.eateryInfo &&
        _vm.eateryInfo.bannerUrl != null &&
        _vm.eateryInfo.aboutMessage != null
      )?_c('v-card',{attrs:{"outlined":""}}):_vm._e(),_c('div',{class:_vm.getCssClassFoodyMenu(),attrs:{"id":"menu-menues-container"}},[_c('GroupedMenuItems',{key:_vm.activeTab,attrs:{"groupedMenuItems":_vm.filteredGroupedMenuItems,"menuViewMode":_vm.menu.menuViewMode,"hasOnlyQrAndDineIn":_vm.hasOnlyQrAndDineIn,"observer":_vm.observer}}),(!_vm.hasOnlyQrAndDineIn || _vm.TableObject)?_c('div',{staticClass:"shopping-cart-container"},[_c('ShoppingCartContainer',{attrs:{"on-click":_vm.TableObject ? _vm.fastCheckout : _vm.checkout,"button-disabled":!_vm.ShoppingCartValid,"button-text":_vm.TableObject
              ? _vm.shoppingCartButtonTextForTable
              : _vm.shoppingCartButtonText,"selectedDate":_vm.date,"selectedTime":_vm.time}})],1):_vm._e(),(_vm.hasOnlyQrAndDineIn && !_vm.TableObject && _vm.hasReservationEnabled)?_c('div',{staticClass:"stick-button-container"},[_c('FoodyButton',{attrs:{"btn-color":'primary',"parentclasses":['sticky-button'],"onClick":_vm.goToEaterieReservation}},[_vm._v(" RESERVER BORD ")])],1):_vm._e()],1),_c('MobileShoppingCart',{attrs:{"on-click":_vm.TableObject ? _vm.fastCheckoutCondencedShoppingCart : _vm.checkout,"button-text":_vm.mobileShoppingCartButtonText}})],1):_vm._e(),_c('PaymentDialog',{ref:"PaymentDialog",attrs:{"userInformationObject":_vm.userInformation,"deliverynote":_vm.deliverynote,"tableId":_vm.tableId,"selectedTime":_vm.time,"selectedDate":_vm.date}}),_c('div',[(_vm.eateryInfo.seoDescription)?_c('EaterySeoDescription',{staticClass:"seo-description",attrs:{"description":_vm.eateryInfo.seoDescription}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }