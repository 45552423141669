import Moment from "moment";
import FoodyCard from "@/components/common/FoodyCard.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import ShoppingCartContainer from "@/components/shopping-cart/ShoppingCartContainer.vue";
import LoginBottomSheet from "@/components/forms/LoginBottomSheet.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import ErrorMsgBox from "@/components/common/ErrorMsgBox/ErrorMsgBox.vue";
import { EatingMode } from "@/types/enums/eatingMode";
import timeHelper from "@/helpers/timeHelper";
import {
  getEatingModeDto,
  getPausedUntilUtcDate,
  checkIfEatingModeClosed,
} from "@/types/dto/eateries/eateryDtoFunctions";
import EatingModeSwitch from "@/components/eatery/EatingModeSwitch.vue";
import UserInformation from "./UserInformation/UserInformation.vue";
import UserInformationObject from "@/types/checkout/userInformation";
import TimeRadioSelector from "./TimeAndDate/TimeRadioSelector/TimeRadioSelector.vue";
import TimeAndDateSelector from "./TimeAndDate/TimeAndDateSelector/TimeAndDateSelector.vue";
import PaymentDialog from "./PaymentDialog/PaymentDialog.vue";
import { getMininumDeliveryPrice } from "@/types/dto/eateries/eateryDtoFunctions";
import NavBar from "@/components/common/NavBar.vue";
import {
  dateAndTimeAreWithinHoursForCurrentEatingMode,
  getFirstAvailableOpeningTimeForCurrentEatingMode,
} from "@/helpers/currentEateryHelper";

const codebehind = {
  components: {
    ShoppingCartContainer,
    FoodyCard,
    ErrorComponent,
    EatingModeSwitch,
    UserInformation,
    TimeRadioSelector,
    TimeAndDateSelector,
    PaymentDialog,
    FoodyButton,
    NavBar,
    ErrorMsgBox,
    LoginBottomSheet,
  },
  data() {
    return {
      showSheet: false,
      date: Moment().format("YYYY-MM-DD"),
      time: Moment().format("HH:mm"),
      paymentId: null as string,
      userInformation: {} as UserInformationObject,
      deliverynote: "",
      eatingMode: null as EatingMode,
      timeOption: "1",
      timeUpdaterTimeoutId: null as string | null,
      tableId: "",
      personalInformationFormValid: false,
      addressInformationFormValid: false,
      validTime: false,
      dateAndTimeValid: false,
      everythingIsValid: false,
      shoppingCartIsValid: true,
      checkoutStage: 1,
      isMobile: true,
    };
  },
  props: {
    prevRoute: {
      type: String,
      required: false,
    },
  },
  updated() {
    this.eatingMode = this.$store.getters.selectedEatingMode;
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    clearTimeout(this.timeUpdaterTimeoutId);
  },
  mounted() {
    this.timeUpdaterTimeoutId = setInterval(this.updateTime, 1000);
    this.onResize();
    this.eatingMode = this.$store.getters.selectedEatingMode;

    this.setUpDateAndTime();

    // show stage 2 if logged in from Checkout.
    if (this.$route.query.stage && this.isMobile) {
      this.navToOrderConfirmation();
    }

    if (this.$route.query.paymentId) {
      const checkoutKey = this.$store.getters.checkoutKey;
      this.$router.push(
        `/${this.$store.getters.eateryName}/success/?paymentid=${this.$route.query.paymentId}&checkoutKey=${checkoutKey}`
      );
    }
    this.validateDateAndtime();

    //Setup watchers
    this.$watch(
      (vm: any) => [
        vm.currentEatingMode,
        vm.MinimumDeliveryPriceValid,
        vm.DeliveryMinimumPrice,
      ],
      () => {
        this.validateShoppingCart();
      },
      {
        immediate: true, // run immediately
      }
    );

    this.$watch(
      (vm: any) => [
        vm.personalInformationFormValid,
        vm.addressInformationFormValid,
        vm.dateAndTimeValid,
        vm.time,
        vm.validTime,
        vm.loggedInUser,
      ],
      () => {
        if (this.TableObject) {
          this.everythingIsValid = true;
          return;
        }

        if (this.currentEatingMode !== EatingMode.Delivery) {
          this.addressInformationFormValid = true;
        }
        let valid;
        //Implicit else
        if (this.disableTimePicker()) {
          valid =
            this.personalInformationFormValid &&
            this.dateAndTimeValid &&
            this.addressInformationFormValid;
        } else {
          valid =
            this.personalInformationFormValid &&
            this.addressInformationFormValid;
        }

        if (this.validTime) {
          this.validateDateAndtime();
        }
        if (this.eatingModeOnPause) {
          valid = false;
        }
        this.everythingIsValid = valid;
      },
      {
        immediate: true, // run immediately
      }
    );
  },
  watch: {
    loggedInUser: function () {
      if (this.loggedInUser) {
        this.$refs.userInformationRef.getUser();
      }
    },
    currentEatery: function () {
      this.validateDateAndtime();
    },
    time: function () {
      this.validateDateAndtime();
    },
    date: function () {
      const timeIsValid = dateAndTimeAreWithinHoursForCurrentEatingMode(
        this.date,
        this.time
      );
      if (!timeIsValid) {
        const date = new Date(this.date);
        const nextOpeningTime =
          getFirstAvailableOpeningTimeForCurrentEatingMode(date);
        this.date = Moment(nextOpeningTime).format("YYYY-MM-DD");
        this.time = Moment(nextOpeningTime).format("HH:mm");
      }
      this.validateDateAndtime();
    },
  },
  computed: {
    eatingModeDto() {
      return getEatingModeDto(this.currentEatery, this.eatingMode);
    },
    isQrAndClosed() {
      return this.TableObject && checkIfEatingModeClosed(this.currentEatery);
    },

    currentEateryName() {
      return this.$store.getters.eateryName;
    },
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },
    isDineInAndClosed() {
      return (
        this.currentEatingMode === EatingMode.DineIn &&
        checkIfEatingModeClosed(this.currentEatery)
      );
    },
    OrderErrorMsg() {
      if (this.eatingModeOnPause) {
        return {
          message: "Spisestedet har satt bestilling på pause.",
          type: "info",
        };
      }
      if (this.isDineInAndClosed) {
        const nextOpeningDate: Date =
          getFirstAvailableOpeningTimeForCurrentEatingMode();
        const nextOpeningDateMoment = Moment(nextOpeningDate);
        return {
          message: `Spisestedet er stengt. Neste åpningstid er ${nextOpeningDateMoment.format(
            "dddd"
          )} kl. ${nextOpeningDateMoment.format("HH:mm")} `,
          type: "error",
        };
      }
      if (!this.dateAndTimeValid) {
        return { message: "Valgt tidspunkt er ugyldig.", type: "error" };
      }
    },
    DeliveryMinimumPrice() {
      return getMininumDeliveryPrice(this.currentEatery);
    },
    MinimumDeliveryPriceValid() {
      if (this.shoppingCartSum() > this.DeliveryMinimumPrice) return true;
      return false;
    },
    ShoppingCart() {
      return this.$store.getters.cart;
    },
    eatingModeOnPause() {
      // TODO : Sjekk ut nå / 1 time / egendefinert (date-time stuff...)

      const splitTime = this.time.split(":");
      const selectedDate = Moment(this.date);
      selectedDate.add(Number.parseInt(splitTime[0]), "hours");
      selectedDate.add(Number.parseInt(splitTime[1]), "minutes");
      selectedDate.add(Number.parseInt(splitTime[2]), "seconds");

      const pausedUntilDate = Moment(getPausedUntilUtcDate(this.currentEatery));
      if (selectedDate.isBefore(pausedUntilDate)) {
        return true;
      }
      return false;
    },
    DineInOnlyItemInCart() {
      const items = this.$store.getters.cart;

      if (!items) return false;

      const dineInOnlyItemInCart = items.some(
        (item: { dineInOnly: boolean }) => item.dineInOnly === true
      );

      return this.currentEatingMode !== 1 && dineInOnlyItemInCart;
    },
    currentEatingMode() {
      if (this.$store.getters.selectedEatingMode)
        return this.$store.getters.selectedEatingMode;
      return EatingMode.TakeAway;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    PaymentId() {
      return this.$store.getters.paymentId;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    validTimeInput: {
      get() {
        return this.validTime;
      },
      set(val: string) {
        this.validTime = val;
      },
    },
    setDate: {
      get() {
        return this.date;
      },
      set(val: string) {
        this.date = val;
      },
    },
    setTime: {
      get() {
        return this.time;
      },
      set(val: string) {
        this.time = val;
      },
    },
  },
  methods: {
    disableTimePicker() {
      if (
        (this.currentEatingMode === 1 &&
          this.currentEatery.dineIn.disableTimePicker) ||
        (this.currentEatingMode === 2 &&
          this.currentEatery.takeAway.disableTimePicker)
      ) {
        return false;
      }
      return true;
    },
    showLoginSheet() {
      this.showSheet = true;
    },
    prettyTimeString(timeString: string): string {
      return timeHelper.prettyHoursAndMinutes(timeString);
    },
    showStage2() {
      if (this.checkoutStage == 2 || !this.isMobile) return true;
    },

    goShoppingCart() {
      this.checkoutStage = 1;
    },
    // Check the selected time at periodic intervals and update it to the lowest possible time if it is earlier
    updateTime() {
      const selectedTime = timeHelper.getDateFromTimeString(
        this.time,
        this.date
      );
      const firstPossibleOrderTime =
        getFirstAvailableOpeningTimeForCurrentEatingMode();
      if (selectedTime < firstPossibleOrderTime) {
        this.time = timeHelper.getTimeStringFromDate(firstPossibleOrderTime);
      }
    },
    goBack() {
      if (this.TableObject) {
        this.$router.push(`/${this.$store.getters.tableObject.eateryName}`);
      } else {
        if (this.$store.getters.lastNavigationPage !== "/") {
          this.$router.back();
        } else {
          this.$router.push(`/${this.$store.getters.eateryName}`);
        }
      }
    },

    navToOrderConfirmation() {
      this.checkoutStage = 2;
    },
    onResize() {
      if (window.innerWidth > 960) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    shoppingCartSum() {
      let orderSum = 0;
      this.ShoppingCart.forEach(function (cartItem: any) {
        if (cartItem.nrOfItems > 1) {
          orderSum += cartItem.totalPrice * cartItem.nrOfItems;
        } else {
          orderSum += cartItem.totalPrice;
        }
      });
      return orderSum;
    },
    getTableNr() {
      return this.$store.getters.tableObject.TableId;
    },
    validateShoppingCart() {
      if (this.currentEatingMode == EatingMode.Delivery) {
        if (this.DeliveryMinimumPrice > 0) {
          this.shoppingCartIsValid =
            this.DeliveryMinimumPrice > 0 && this.MinimumDeliveryPriceValid;
          return;
        }
      } else {
        this.shoppingCartIsValid = true;
      }
    },
    validateDateAndtime() {
      this.dateAndTimeValid = dateAndTimeAreWithinHoursForCurrentEatingMode(
        this.date,
        this.time
      );
    },
    setUpDateAndTime() {
      const openingDateTime =
        getFirstAvailableOpeningTimeForCurrentEatingMode();

      openingDateTime.setMinutes(openingDateTime.getMinutes());

      this.date = Moment(openingDateTime).format("yyyy-MM-DD");
      this.time = Moment(openingDateTime).format("HH:mm");
    },
    async submit() {
      if (
        this.everythingIsValid &&
        !this.DineInOnlyItemInCart &&
        !this.isQrAndClosed
      ) {
        this.$refs.PaymentDialog.pay();
      }
    },
  },
};
export default codebehind;
