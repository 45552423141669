<template>
  <v-container fluid>
    <v-row>
      <inputfield
        :input="addressInput"
        input-type="street"
        field-label="Adresse"
        :input-valid="streetAddressValid"
        @input="addressInput = $event"
        @inputValid="streetAddressValid = $event"
      ></inputfield>
    </v-row>
    <v-row>
      <inputfield
        :input="postalCodeInput"
        input-type="postalCode"
        field-label="Postnummer"
        :input-valid="postalCodeValid"
        @input="postalCodeInput = $event"
        @inputValid="postalCodeValid = $event"
      ></inputfield>
      <v-spacer />
      <inputfield
        :input="cityInput"
        input-type="postalArea"
        field-label="Sted"
        :input-valid="postalAreaValid"
        @input="cityInput = $event"
        @inputValid="postalAreaValid = $event"
      ></inputfield>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import Codebehind from "@/components/forms/address/AddressInformationForm";
import Vue from "vue";
export default Vue.extend({
  name: "AddressInformationForm",
  mixins: [Codebehind],
});
</script>
