<template>
  <div v-if="customerMessage" class="alert-container">
    <v-card elevation="0" color="#FFFFFF" class="rounded-card">
      <v-icon large color="#777777" class="alert-icon"
        >mdi-information-outline</v-icon
      >
      <span class="alert-text">{{ customerMessage }}</span>
    </v-card>
  </div>
</template>

<script lang="ts">
import { getCustomerInfoText } from "@/types/dto/eateries/eateryDtoFunctions";
export default {
  computed: {
    TableObject() {
      return this.$store.getters.tableObject;
    },
    CurrentEatery() {
      return this.$store.getters.eateryInfo;
    },
    CurrentEatingMode() {
      return this.$store.getters.selectedEatingMode;
    },
    customerMessage() {
      const customerInfoText = getCustomerInfoText(this.CurrentEatery);
      const eatery = this.CurrentEatery;
      if (
        eatery.dineInIsQrOnly &&
        !eatery.takeAway &&
        !eatery.delivery &&
        !this.TableObject
      )
        return "Dette er kun menyvisning";
      return customerInfoText;
    },
  },
};
</script>

<style scoped>
.alert-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.alert-icon:before {
  padding: 7px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.alert-icon {
  align-self: center;
  height: 50px;
  width: 50px;
}

.alert-text {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-left: 8px;
}
.rounded-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  min-height: 65px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 50px;
  padding: 0px 10px;
  margin: 0 10px;
  place-content: center;
}
</style>
