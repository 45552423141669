<template>
  <div>
    <foody-button
      :btn-color="'primary'"
      :outlined="true"
      :on-click="onClickRedirect"
    >
      Registrere deg?
    </foody-button>
  </div>
</template>

<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
export default {
  name: "WantToRegister",
  components: { FoodyButton: FoodyButton },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    onClickRedirect() {
      this.$router.push("/register/?orderId=" + this.orderId);
    },
  },
};
</script>
