import UserInformationObject from "@/types/checkout/userInformation";
import { PropType } from "vue";

const codebehindProps = {
  props: {
    userInformationObject: {
      type: Object as PropType<UserInformationObject>,
      required: true,
    },
    deliverynote: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    selectedTime: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
      required: true,
    },
  },
};
export default codebehindProps;
