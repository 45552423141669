<template>
  <div @keyup.enter="doRegister">
    <FeedbackCard v-if="showFeedbackCard">
      <!-- <FeedbackCard> -->
      <template #header> Bruker opprettet! </template>
      <template #content>
        Du vil straks motta bekreftelses e-post, som krever godkjenning.
        <FoodyButton
          :btn-color="'primary'"
          class="margin-top-2rem"
          :on-click="navigateBack"
        >
          Tilbake
        </FoodyButton>
      </template>
    </FeedbackCard>
    <FoodyCard
      v-if="!showFeedbackCard"
      :parentclasses="['customerRegistration-view']"
    >
      <v-card-text>
        <v-card-title class="heading font-weight-bold" style="font-size: 30px">
          Registrer deg
        </v-card-title>
        <form
          v-disable-tab-on-class="['.mdi-close', '.mdi-eye-off']"
          style="padding: 0 16px"
          @submit.prevent="doRegister"
        >
          <v-row style="padding: 0 16px">
            <PersonalInformationForm
              :firstname="form.firstname"
              :lastname="form.lastname"
              :email="form.email"
              :phone="form.phone"
              :personal-information-form-valid="personalInformationFormValid"
              @firstname="form.firstname = $event"
              @lastname="form.lastname = $event"
              @email="form.email = $event"
              @phone="form.phone = $event"
              @personalInformationFormValid="
                personalInformationFormValid = $event
              "
            ></PersonalInformationForm>
            <PasswordForm
              :password="form.password"
              :repeat-password="form.repeatPassword"
              :want-repeat-password="true"
              :password-valid="passwordValid"
              @password="form.password = $event"
              @repeatPassword="form.repeatPassword = $event"
              @passwordValid="passwordValid = $event"
            ></PasswordForm>
            <AddressInformationForm
              :address="form.address"
              :postal-code="form.postalcode"
              :city="form.city"
              :address-information-form-valid="addressInformationFormValid"
              @address="form.address = $event"
              @postalCode="form.postalcode = $event"
              @city="form.city = $event"
              @addressInformationFormValid="
                addressInformationFormValid = $event
              "
            />
            <v-checkbox v-model="termsApproved">
              <template #label>
                <div>
                  Jeg godtar
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <a target="_blank" href="/terms" @click.stop v-on="on">
                        vilkår og betingelser.
                      </a>
                    </template>
                    Åpner i nytt vindu
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <ErrorComponent
            v-if="registrationError !== ''"
            :message="registrationError"
          />

          <FoodyButton
            :btn-color="'primary'"
            :on-click="doRegister"
            :parentclasses="['btn btn-default', 'pull-right']"
            :disabled="
              !(
                personalInformationFormValid &&
                addressInformationFormValid &&
                passwordValid &&
                termsApproved
              )
            "
            >Registrér deg</FoodyButton
          >
        </form>
      </v-card-text>
    </FoodyCard>
  </div>
</template>
<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
import FoodyCard from "@/components/common/FoodyCard.vue";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import AddressInformationForm from "@/components/forms/address/AddressInformationForm.vue";
import PasswordForm from "@/components/forms/password/PasswordForm.vue";
import FeedbackCard from "@/components/common/FeedbackCard.vue";
import ErrorComponent from "@/components/common/ErrorComponent";

export default {
  name: "CustomerRegistration",
  components: {
    FoodyButton: FoodyButton,
    FoodyCard: FoodyCard,
    PersonalInformationForm: PersonalInformationForm,
    AddressInformationForm: AddressInformationForm,
    PasswordForm: PasswordForm,
    FeedbackCard: FeedbackCard,
    ErrorComponent: ErrorComponent,
  },
  data() {
    const registrationForm = Object.freeze({
      // TODO: reset this to empty when done
      firstname: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      postalcode: "",
      city: "",
      password: "",
      repeatPassword: "",
    });
    return {
      form: Object.assign({}, registrationForm),
      showFeedbackCard: false,
      registrationError: "",
      orderId: "",
      personalInformationFormValid: false,
      addressInformationFormValid: false,
      passwordValid: false,
      termsApproved: false,
    };
  },
  watch: {
    addressInformationFormValid: function () {
      this.addressInformationFormValid;
    },
  },
  created() {
    // TODO: When comming from success page, that user want to register.
    // Let user register, then populate the created user with the order that he had made earlier.
    // Need to check if email used is not already registered, then check if order is not done already.
    // Maybe only be added to new user if paymentstatus is "reserved" only?
    // Send info that was used in order to pre fill fields ? If so need to be done from Success page or WantToRegister model.
  },
  methods: {
    setFeedbackCardVisibility(value) {
      this.showFeedbackCard = value;
    },
    doRegister() {
      if (
        !(
          this.personalInformationFormValid &&
          this.addressInformationFormValid &&
          this.passwordValid &&
          this.termsApproved
        )
      ) {
        return;
      }

      this.loading = true;
      let orderGuid = null;
      if (this.$route.query.orderId) orderGuid = this.$route.query.orderId;
      const newUser = {
        Name: `${this.form.firstname} ${this.form.lastname}`,
        Email: this.form.email,
        PhoneNumber: this.form.phone,
        Password: this.form.password,
        RepeatPassword: this.form.repeatPassword,
        Address: {
          StreetAddress: this.form.address,
          PostalCode: this.form.postalcode,
          PostalArea: this.form.city,
        },
      };
      const registerObj = {
        newUserInfo: newUser,
        guid: orderGuid,
      };
      this.$store
        .dispatch("registerUser", registerObj)
        .then(() => this.setFeedbackCardVisibility(true))
        .catch((error) => {
          this.registrationError = error.response.data;
        });
    },
    navigateBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.margin-top-2rem {
  margin-top: 2rem;
}
</style>
