import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import UserOptionState from "./state";
import RootState from "@/types/state/rootState";

export const state: UserOptionState = {
  selectedEatingMode: null,
  selectedTip: null
};

//TODO: Properly namespace
const namespaced = false;

export const userOptionsModule: Module<UserOptionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
