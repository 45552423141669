<template>
  <div style="margin: 0 auto; padding: 15px">
    <!-- <PartnerNavBack class="pad-left-40" variant="black"></PartnerNavBack> -->
    <PartnerSchema
      :name="form.name"
      :email="form.email"
      :phone="form.phone"
      :message="form.message"
      :partner-contact-form-valid="partnerContactFormValid"
      @name="form.name = $event"
      @email="form.email = $event"
      @phone="form.phone = $event"
      @message="form.message = $event"
      @partnerContactFormValid="partnerContactFormValid = $event"
    ></PartnerSchema>
  </div>
</template>

<script>
import PartnerSchema from "@/components/partner/PartnerSchema.vue";
//import PartnerNavBack from "@/components/partner/PartnerNavBack.vue";
export default {
  components: {
    PartnerSchema: PartnerSchema,
  },
  data() {
    const registrationForm = Object.freeze({
      // TODO: reset this to empty when done
      name: "",
      phone: "",
      email: "",
      message: "",
    });
    return {
      form: Object.assign({}, registrationForm),
      showFeedbackCard: false,
      partnerContactFormValid: false,
    };
  },
};
</script>
<style scoped></style>
