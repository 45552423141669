import CustomerLogin from "@/views/CustomerLogin.vue";
import CustomerRegistration from "@/views/CustomerRegistration.vue";
import UserOrdersHistory from "@/views/UserOrdersHistory.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import { ResetTokenGuard } from "@/router/guards/user-guards";
import { RouteConfig } from "vue-router";

const UserRoutes: RouteConfig[] = [
  { path: "/login", component: CustomerLogin },
  { path: "/userorderhistory", component: UserOrdersHistory },
  { path: "/register", component: CustomerRegistration },
  { path: "/forgot", component: ForgotPassword },
  {
    path: "/reset",
    beforeEnter: (to, from, next) => ResetTokenGuard(to, from, next),
    component: ResetPassword,
  },
];

export default UserRoutes;
