<template>
  <section class="partner-schema">
    <div v-if="!messageSuccess">
      <v-form>
        <v-container class="form-container">
          <h2 class="contact-header">
            Lurer du på noe?
            <span style="color: #980a90">Ta kontakt for mer informasjon</span>
          </h2>
          <v-row class="bottom-space-15px">
            <inputfield
              v-if="wantName"
              :input="nameInput"
              input-type="name"
              field-label="Navn"
              :input-valid="nameValid"
              @input="nameInput = $event"
              @inputValid="nameValid = $event"
            >
            </inputfield>
          </v-row>
          <v-row class="bottom-space-15px">
            <inputfield
              v-if="wantEmail"
              :input="emailInput"
              input-type="email"
              field-label="E-post"
              :input-valid="emailValid"
              @input="emailInput = $event"
              @inputValid="emailValid = $event"
            >
            </inputfield>
          </v-row>
          <v-row class="bottom-space-15px">
            <phone-input
              v-if="wantPhone"
              :phone="phoneInput"
              @phone="phoneInput = $event"
              @phoneNumberPrefixOutput="phoneNumberPrefixOutput = $event"
              @phoneNumberValid="phoneValid = $event"
            ></phone-input>
          </v-row>
          <v-row class="bottom-space-15px">
            <text-area-field
              v-if="wantMessage"
              label="Melding"
              :input="messageInput"
              :input-valid="messageValid"
              @input="messageInput = $event"
              @inputValid="messageValid = $event"
            >
            </text-area-field>
          </v-row>
          <v-row>
            <FoodyButton
              :parentclasses="['button-width']"
              title="Ta kontakt"
              btn-color="primary"
              :on-click="sendRequestMessage"
              :disabled="!partnerContactFormValid"
            >
              Ta kontakt
            </FoodyButton>
          </v-row>
        </v-container>
        <div class="call-us-container">
          <span class="call-us"> eller ring oss på </span>
          <div class="phone-wrapper">
            <img
              class="phone-icon"
              src="../../assets/img/phone-icon.svg"
              alt="phone"
            />
            <span class="phonenr"> 95203432 </span>
          </div>
        </div>
      </v-form>
    </div>
    <PartnerRequestMessageSuccessPage v-if="messageSuccess" />
  </section>
</template>

<script>
import PartnerRequestMessageSuccessPage from "@/components/partner/PartnerRequestMessageSuccessPage.vue";
import Inputfield from "../inputFields/Inputfield.vue";
import TextAreaField from "../inputFields/TextAreaField.vue";
import phoneInput from "../inputFields/PhoneInput.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import Vue from "vue";
import Codebehind from "../../views/PartnerContactForm";
export default Vue.extend({
  name: "PartnerContactForm",

  components: {
    PartnerRequestMessageSuccessPage: PartnerRequestMessageSuccessPage,
    Inputfield,
    phoneInput,
    FoodyButton,
    TextAreaField,
  },
  mixins: [Codebehind],
  data() {
    return {
      messageSuccess: null,
    };
  },

  methods: {
    async sendRequestMessage() {
      const msgObject = {
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Message: this.message,
      };
      const requestRes = await this.$store.dispatch(
        "sendRequestMessage",
        msgObject
      );
      if (requestRes) {
        this.messageSuccess = true;
      } else {
        // TODO : Logic for displaying error..
        this.messageSuccess = false;
      }
    },
  },
});
</script>

<style scoped>
v-row {
  margin-bottom: 15px;
}

.bottom-space-15px {
  margin-bottom: 15px;
}
.partner-schema {
  margin: 0 auto;
  text-align: center;
  background-color: white;
}
.call-us {
  font-size: 1.8em;
  font-weight: bold;
  width: 100%;
}
.phonenr {
  font-size: 2.5em;
  font-weight: medium;
  width: 100%;
  margin-left: 1rem;
}
.call-us-container {
  padding: 4em 0;
  background-color: #f5f2f4;
  justify-content: center;
}
.phone-icon {
  max-height: 1.5em;
  border: 1px solid black;
  border-radius: 100%;
  padding: 0.25em;
  margin: auto 0;
}
.purple {
  color: var(--foody-shopping-cart-primary-color);
}
.container {
  width: 100%;
  max-width: 100%;
}
.form-container {
  width: 1140px;
  padding: 8em 0;
}
.form-container .contact-header {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1200px) {
  .form-container {
    width: 80%;
  }
}
</style>
