import ShoppingCartContainer from "@/components/shopping-cart/ShoppingCartContainer.vue";
import MobileShoppingCart from "@/components/shopping-cart/MobileShoppingCart.vue";
import Banner from "@/components/menues/Banner.vue";
import MenuCategoryTabs from "@/components/MenuCategoryTabs.vue";
import GroupedMenuItems from "@/components/menues/GroupedMenuItems.vue";
import { EatingMode } from "@/types/enums/eatingMode";
import {
  GroupedCategoryEnum,
  MenuItemCatEnum,
  MenuItemSingleEnum,
} from "@/types/enums/menuItemCategories";
import MenuItemGrouping from "@/types/dto/menues/menuItemGrouping";
import EateryDto from "@/types/dto/eateries/eateryDto";
import Menu from "@/types/dto/menues/menu";
import MenuItem from "@/types/dto/menues/menuItem";
import PaymentDialog from "./checkout/PaymentDialog/PaymentDialog.vue";
import UserInformation from "./checkout/UserInformation/UserInformation.vue";
import UserInformationObject from "@/types/checkout/userInformation";
import Moment from "moment";
import {
  checkIfEatingModeClosed,
  getMininumDeliveryPrice,
} from "@/types/dto/eateries/eateryDtoFunctions";
import timeHelper from "@/helpers/timeHelper";
import FoodyButton from "@/components/common/FoodyButton.vue";

const codeBehind = {
  name: "MenuView",
  components: {
    FoodyButton,
    PaymentDialog,
    UserInformation,
    ShoppingCartContainer,
    MobileShoppingCart,
    Banner,
    GroupedMenuItems,
    MenuCategoryTabs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userInformation: {} as UserInformationObject,
      deliverynote: "",
      tableId: "",
      time: "",
      date: Moment().format("YYYY-MM-DD"),
      selectedTab: MenuItemCatEnum,
      modalData: {
        item: 0,
      },
      nextHasBeenClicked: false,
      dialog: false,
      activeTab: MenuItemCatEnum.Food,
      observer: IntersectionObserver,
    };
  },
  created() {
    this.observer = new IntersectionObserver(this.onGroupedMenuItemsObserved, {
      root: document.querySelector("#menu-menues-container"),
      rootMargin: "-40% 0% -20% 0%",
      threshold: 0,
    });
    const isQr =
      this.$route.query.source === "qrcode" ||
      !!this.$store.getters.tableObject?.eateryName;
    this.$store.dispatch("setEatingModeBasedOnCurrentEateryAndMenu", isQr);
  },
  beforeDestroy() {
    this.observer.disconnect();
    this.$store.dispatch("clearMenu");
  },
  computed: {
    hasOnlyQrAndDineIn(): boolean {
      const eatery = this.currentEatery;
      return eatery.dineInIsQrOnly && !eatery.takeAway && !eatery.delivery;
    },
    hasReservationEnabled(): boolean {
      const eatery = this.currentEatery;
      return eatery.tableReservationEnabled;
    },
    DineInOnlyItemInCart() {
      const items = this.$store.getters.cart;
      const dineInOnlyItemInCart = items.some(
        (item: { dineInOnly: boolean }) => item.dineInOnly === true
      );
      if (
        !this.TableObject &&
        this.CurrentEatingMode !== 1 &&
        dineInOnlyItemInCart
      )
        return true;

      return false;
    },
    shoppingcart() {
      return this.$store.getters.cart;
    },
    shoppingCartSum() {
      let orderSum = 0;
      this.shoppingcart.forEach(function (cartItem: any) {
        if (cartItem.nrOfItems > 1) {
          orderSum += cartItem.totalPrice * cartItem.nrOfItems;
        } else {
          orderSum += cartItem.totalPrice;
        }
      });
      return orderSum;
    },
    filteredGroupedMenuItems(): Array<MenuItemGrouping> {
      let groupedMenuItems = this.groupedMenuItems as Array<MenuItemGrouping>;
      groupedMenuItems = this.filterbyTab(groupedMenuItems, this.activeTab);
      return groupedMenuItems;
    },
    eateryInfo(): EateryDto {
      return this.$store.getters.eateryInfo;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    menu(): Menu {
      return this.$store.getters.menu;
    },
    groupedMenuItems() {
      return this.menu.groupedMenuItems;
    },
    CurrentEatingMode() {
      return this.$store.getters.selectedEatingMode ?? EatingMode.TakeAway;
    },
    shoppingCartButtonText() {
      return "NESTE";
    },
    shoppingCartButtonTextForTable() {
      const items = this.$store.getters.cart;
      const drinkItems = items.filter(
        (item: any) => item && item.foodItemCategory === 2
      );
      return drinkItems.length > 0 ||
        this.nextHasBeenClicked ||
        !this.drinkMenuItemsAvailable()
        ? "TIL BETALING"
        : "NESTE";
    },
    mobileShoppingCartButtonText() {
      let returnString = "";
      const items = this.$store.getters.cart;
      const drinkItems = items.filter(
        (item: any) => item && item.foodItemCategory === 2
      );
      if (
        drinkItems.length > 0 ||
        this.nextHasBeenClicked ||
        !this.drinkMenuItemsAvailable()
      ) {
        returnString = "HANDLEKURV";
      } else {
        returnString = "NESTE";
      }
      return returnString;
    },
    menuCategories() {
      return this.getMenuCategories();
    },
    currentEateryName() {
      return this.$store.getters.eateryName;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    ShoppingCartValid() {
      const items = this.$store.getters.cart;
      const isEatingModeClosed = checkIfEatingModeClosed(this.eateryInfo);
      let showButton = false;

      const dineInOnlyItemInCart = items.some(
        (item: { dineInOnly: boolean }) => item.dineInOnly === true
      );

      if (items && items.length > 0) showButton = true;

      //If current eating mode is 'Take Away / Delivery', and Cart har some 'Dine in Only' items then 'Checkout' button is disabled and a message is shown.
      //This condition implies that we are not in 'Table Reservation' mode.
      if (
        !this.TableObject &&
        this.CurrentEatingMode !== 1 &&
        dineInOnlyItemInCart
      )
        showButton = false;
      if (
        this.CurrentEatingMode === EatingMode.Delivery &&
        !this.minimumDeliveryPriceValid()
      ) {
        showButton = false;
      }
      if (this.TableObject && isEatingModeClosed) {
        showButton = false;
      }
      return showButton;
    },
  },
  mounted() {
    //Setup watchers
    this.$watch(
      (vm: any) => [vm.activeTab],
      () => {
        // default scroll to top when active-tab is changed.
        window.scrollTo(0, 0);
        const firstMenuItem = [
          ...(this.filteredGroupedMenuItems as Array<MenuItemGrouping>),
        ].shift();
        this.selectedTab = firstMenuItem.header;
      }
    );
  },
  methods: {
    onGroupedMenuItemsObserved(entries: any) {
      entries.map(({ target, isIntersecting }: any) => {
        if (isIntersecting) {
          const currentHeader = target.getAttribute("id");
          this.selectedTab = this.getCurrentGroupMenuItemIndex(currentHeader);
        }
      });
    },
    getCurrentGroupMenuItemIndex(header: string): number {
      const indexOfGroupMenuItem = this.filteredGroupedMenuItems.findIndex(
        (object: MenuItemGrouping) => {
          return object.header === header;
        }
      );
      if (typeof indexOfGroupMenuItem === "number") return indexOfGroupMenuItem;
    },
    getCssClassFoodyMenu(): string {
      if (this.hasOnlyQrAndDineIn && !this.TableObject) {
        return "foody-grid-menu-dine-in-qr-only";
      }
      return "foody-grid-menu";
    },

    goToEaterieReservation: function () {
      this.$router.push("/" + this.currentEatery.urlName + "/bord");
    },
    drinkMenuItemsAvailable() {
      const menus = this.groupedMenuItems as Array<MenuItemGrouping>;
      const drink = menus.find((x) => this.menuGroupingHasDrinks(x));
      if (drink) return true;

      return false;
    },
    getMenuCategories() {
      const gmis = this.groupedMenuItems as Array<MenuItemGrouping>;
      const output = [] as number[];

      gmis.forEach((g) => {
        g.menuItems.forEach((m) => {
          if (output.includes(m.foodItemCategory)) return;
          output.push(m.foodItemCategory);
        });
      });
      return output;
    },
    prettyTimeString(timeString: string): string {
      return timeHelper.prettyHoursAndMinutes(timeString);
    },
    minimumDeliveryPriceValid() {
      const minDeliveryPrice = getMininumDeliveryPrice(this.currentEatery);
      if (this.shoppingCartSum < minDeliveryPrice) return false;
      return true;
    },
    setActiveTab(tabEnum: MenuItemCatEnum) {
      this.activeTab = tabEnum;

      // default scroll to top when active-tab is changed.
      window.scrollTo(0, 0);
      const firstMenuItem = [
        ...(this.filteredGroupedMenuItems as Array<MenuItemGrouping>),
      ].shift();
      this.selectedTab = firstMenuItem.header;
    },

    getActiveCategoryByMenuData(
      foodItems: Array<MenuItemGrouping>,
      drinkItems: Array<MenuItemGrouping>,
      addonItems: Array<MenuItemGrouping>
    ) {
      if (foodItems.length > 0) return MenuItemCatEnum.Food;
      else if (drinkItems.length > 0) return MenuItemCatEnum.Drinks;
      else if (addonItems.length > 0) return MenuItemCatEnum.Addons;
      else return MenuItemCatEnum.Food;
    },
    filterbyTab(menuItems: Array<MenuItemGrouping>, tabEnum: MenuItemCatEnum) {
      switch (tabEnum) {
        case MenuItemCatEnum.Food:
          return this.filterFoodItems(menuItems);
        case MenuItemCatEnum.Drinks:
          return this.filterDrinkItems(menuItems);
        case MenuItemCatEnum.Addons:
          return this.filterAddonItems(menuItems);
        default:
          return this.filterFoodItems(menuItems);
      }
    },
    filterAddonItems(
      menuItems: Array<MenuItemGrouping>
    ): Array<MenuItemGrouping> {
      return menuItems.filter(
        (x) =>
          x.menuItems.filter(
            (y) => y.foodItemCategory === MenuItemSingleEnum.Addon
          ).length > 0
      );
    },
    filterFoodItems(
      menuItems: Array<MenuItemGrouping>
    ): Array<MenuItemGrouping> {
      return menuItems.filter(
        (x) =>
          x.menuItems.filter(
            (y) =>
              y.foodItemCategory === MenuItemSingleEnum.Food ||
              y.foodItemCategory === MenuItemSingleEnum.SideDish ||
              y.foodItemCategory === MenuItemSingleEnum.Dessert
          ).length > 0
      );
    },
    filterDrinkItems(
      menuItems: Array<MenuItemGrouping>
    ): Array<MenuItemGrouping> {
      return menuItems.filter(
        (x) =>
          x.menuItems.filter(
            (y) => y.foodItemCategory === GroupedCategoryEnum.Drinks
          ).length > 0
      );
    },
    menuGroupingHasDrinks(menuGroups: MenuItemGrouping) {
      return (
        menuGroups &&
        menuGroups.menuItems &&
        menuGroups.menuItems.filter(
          (y) => y.foodItemCategory === GroupedCategoryEnum.Drinks
        ).length > 0
      );
    },
    scrollToSubMenu: function (targetId: string) {
      document
        .getElementById(targetId)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    beforeOpen(event: any) {
      this.modalData.item = event.params.item;
    },
    navigateToDrinks() {
      const items = this.$store.getters.cart;
      const itemCheck = items.length > 0;
      if (itemCheck) {
        const drinks = items.filter(
          (item: MenuItem) =>
            item && item.foodItemCategory === GroupedCategoryEnum.Drinks
        );
        if (!this.nextHasBeenClicked && drinks.length < 1) {
          const menus = this.groupedMenuItems as Array<MenuItemGrouping>;
          const drink = menus.find((x) => this.menuGroupingHasDrinks(x));
          if (drink) {
            // set active tab to "Drinks"
            this.activeTab = MenuItemCatEnum.Drinks;
            this.$nextTick(() => {
              this.selectedTab = menus.indexOf(drink);
              this.scrollToSubMenu(drink.header);
              this.nextHasBeenClicked = true;
            });
            return true;
          }
        }
      }
      return false;
    },
    fastCheckout() {
      if (!this.navigateToDrinks()) this.$refs.PaymentDialog.pay();
    },
    fastCheckoutCondencedShoppingCart() {
      if (!this.navigateToDrinks())
        this.$router.push("/" + this.currentEateryName + "/checkout");
    },
    checkout() {
      const items = this.$store.getters.cart;
      const tableReservation = this.$store.getters.dineInOrder;
      const itemCheck = items.length > 0;
      const tableCheck = tableReservation;

      if (!itemCheck && tableCheck) {
        this.dialog = true;
      }
      // if (window.innerWidth < 960){
      //   this.$router.push("/" + this.currentEateryName + "/checkout");
      // }
      else if (itemCheck) {
        const drinks = items.filter(
          (item: MenuItem) =>
            item && item.foodItemCategory === GroupedCategoryEnum.Drinks
        );

        if (
          !this.nextHasBeenClicked &&
          drinks.length < 1 &&
          this.drinkMenuItemsAvailable() &&
          this.activeTab != MenuItemCatEnum.Drinks
        ) {
          const menus = this.groupedMenuItems as Array<MenuItemGrouping>;
          const drink = menus.find((x) => this.menuGroupingHasDrinks(x));

          if (drink) {
            // set active tab to "Drinks"
            this.activeTab = MenuItemCatEnum.Drinks;
            //this.activeMenuItems = this.getDrinkItems(this.menu);
            // future test : SubMenus should be of type drinks.
            this.$nextTick(() => {
              this.selectedTab = menus.indexOf(drink);
              this.scrollToSubMenu(drink.header);
            });
          }
          this.nextHasBeenClicked = true;
        } else {
          this.$router.push("/" + this.currentEateryName + "/checkout");
        }
      }
    },
  },
};

export default codeBehind;
