const SHOPPPING_CART_LOCAL_STORAGE_ID = "shopping_cart";
const TABLE_RESERVATION_ID = "bordreservasjon";
const TABLE_ID = "bord";

function getShoppingCartLocalStorageId(state) {
  return state.companyName + "_" + SHOPPPING_CART_LOCAL_STORAGE_ID;
}
function checkFoodOptions(x, foodItem) {
  if (foodItem.choices) {
    const arr1 = foodItem.choices
      .map((t) => t.id)
      .concat()
      .sort();
    const arr2 = x.choices
      .map((t) => t.id)
      .concat()
      .sort();

    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }

    return true;
  }
}

function checkVariants(x, cartItem) {
  if (
    !cartItem.variant ||
    (cartItem.variant && cartItem.variant.length === 0)
  ) {
    return true;
  }
  return x.variant.id === cartItem.variant.id;
}
function findCartItemIndex(state, cartItem) {
  const item = state.cart.findIndex(
    (x) =>
      x.menuItemId === cartItem.menuItemId &&
      checkFoodOptions(x, cartItem) &&
      checkVariants(x, cartItem)
  );
  return item;
}
function getCurrentCartItem(state, cartItem) {
  const item = state.cart[findCartItemIndex(state, cartItem)];
  return item;
}

function getSelectedFoodProperties(item) {
  return item.foodProperties.filter((x) =>
    x.foodPropertyOptions.some(
      (foodPropertyOption) => foodPropertyOption && foodPropertyOption.selected
    )
  );
}
function getSelectedFoodVariant(item) {
  return item.menuItemVariants.find((x) => x.selected);
}
function checkIfDineInObject(item) {
  return (
    item && item.name && item.name.toLowerCase().includes(TABLE_RESERVATION_ID)
  );
}
function checkIfTableObject(item) {
  return item && item.name && item.name.toLowerCase().includes(TABLE_ID);
}
function getSelectedFoodPropertiesOptions(foodProperties) {
  if (foodProperties && foodProperties.length > 0) {
    const res = foodProperties.map((x) => {
      const options = x.foodPropertyOptions.filter((option) => {
        if (option.selected) {
          return option;
        }
      });
      return options;
    });
    return res.flat();
  } else return [];
}
function getOptionsSum(options) {
  let sum = 0;
  for (let index = 0; index < options.length; index++) {
    const option = options[index];
    sum += option.price;
  }
  return sum;
}
function getCartItem(item) {
  if (checkIfDineInObject(item)) return item;

  const selectedFoodItemVariant = getSelectedFoodVariant(item);
  const selectedFoodProps = getSelectedFoodProperties(item);
  const selectedOptions = getSelectedFoodPropertiesOptions(selectedFoodProps);
  const optionsSum = getOptionsSum(selectedOptions);

  return {
    menuItemId: item.menuItemId,
    foodItemCategory: item.foodItemCategory,
    name: item.name,
    choices: selectedOptions,
    variant: selectedFoodItemVariant,
    nrOfItems: 1,
    dineInOnly: item.dineInOnly,
    allowCustomerMessage: item.allowCustomerMessage,
    totalPrice:
      (selectedFoodItemVariant
        ? selectedFoodItemVariant.price
        : item.singlePrice) + optionsSum,
  };
}

export {
  getShoppingCartLocalStorageId,
  checkIfDineInObject,
  checkIfTableObject,
  findCartItemIndex,
  getCartItem,
  getCurrentCartItem,
};
