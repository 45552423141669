import { render, staticRenderFns } from "./PartnerAlphabox.vue?vue&type=template&id=5de9121b&scoped=true"
import script from "./PartnerAlphabox.vue?vue&type=script&lang=js"
export * from "./PartnerAlphabox.vue?vue&type=script&lang=js"
import style0 from "./PartnerAlphabox.vue?vue&type=style&index=0&id=5de9121b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5de9121b",
  null
  
)

export default component.exports