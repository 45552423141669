<template>
  <div>
    <v-list v-if="loggedInUser" dense>
      <v-list-item
        v-for="item in loggedInItems"
        :key="item.title"
        link
        target="_blank"
        @click="handleClick(item.action)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-list-item
        v-for="item in loggedOutItems"
        :key="item.title"
        link
        @click="handleClick(item.action)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense class="sidebar-footer">
      <v-list-item v-if="!loggedInUser" link @click="register">
        <v-list-item-icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Registrer deg</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!loggedInUser" link @click="login">
        <v-list-item-icon>
          <v-icon>mdi-login-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logg inn</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="loggedInUser" link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logg ut</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <LoginBottomSheet
      :showSheet="showSheet"
      @sheetUpdated="(val) => (showSheet = val)"
    />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import LoginBottomSheet from "@/components/forms/LoginBottomSheet.vue";

const clickActions: { [key: string]: string } = {
  profile: "profile",
  orderHistory: "orderHistory",
  allergy: "allergy",
  termsAndConditions: "termsAndConditions",
  contactInfo: "contactInfo",
};

export default Vue.extend({
  components: { LoginBottomSheet },
  data: () => ({
    showSheet: false,
    loggedInItems: [
      {
        title: "Min Profil",
        icon: "mdi-account",
        action: clickActions.profile,
      },
      {
        title: "Ordrehistorikk",
        icon: "mdi-receipt",
        action: clickActions.orderHistory,
      },
    ],
    loggedOutItems: [
      {
        title: "Vilkår og betingelser",
        icon: "mdi-file-document",
        action: clickActions.termsAndConditions,
      },
      {
        title: "Kontaktinfo",
        icon: "mdi-information",
        action: clickActions.contactInfo,
      },
      {
        title: "Allergener",
        icon: "mdi-alert-circle",
        action: clickActions.allergy,
      },
      {
        title: "Cookie Policy",
        icon: "mdi-information",
        action: clickActions.cookie,
      },
    ],
  }),
  computed: {
    loggedInUser(): Record<string, any> {
      return this.$store.getters.loggedInUser;
    },
  },
  methods: {
    showLoginSheet() {
      this.showSheet = true;
    },
    handleClick(action: string): void {
      switch (action) {
        case clickActions.profile:
          this.toggleShowProfile();
          break;
        case clickActions.orderHistory:
          this.orderHistory();
          break;
        case clickActions.allergy:
          this.allergy();
          break;
        case clickActions.termsAndConditions:
          this.termsAndConditions();
          break;
        case clickActions.contactInfo:
          this.contactInfo();
          break;
        case clickActions.cookie:
          this.cookiePolicy();
          break;
        default:
          break;
      }
    },
    openLinkInNewTab(path: string): void {
      if (this.$route.path !== path) {
        const newRoute = this.$router.resolve({ path: path });
        window.open(newRoute.href, "_blank");
      }
    },
    redirect(path: string): void {
      if (this.$route.path !== path) this.$router.push(path);
    },
    logout(): void {
      this.$store.dispatch("logOutUser");
    },
    login(): void {
      this.showLoginSheet();
    },
    register(): void {
      this.redirect(`/register`);
    },
    toggleShowProfile(): void {
      this.$emit("toggleShowProfile");
    },
    orderHistory(): void {
      this.redirect(`/userorderhistory`);
    },
    allergy(): void {
      this.openLinkInNewTab(`/allergy`);
    },
    termsAndConditions(): void {
      this.openLinkInNewTab(`/terms`);
    },
    contactInfo(): void {
      this.openLinkInNewTab(`/getfoodcontact`);
    },
    cookiePolicy(): void {
      this.openLinkInNewTab(`/cookiepolicy`);
    },
  },
});
</script>
<style scoped>
.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
