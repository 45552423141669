<template>
  <div v-if="item.description" class="foodcard-text">
    <span>
      {{ getDescriptionText() }}
      <a v-if="displayTruncatedDescription()" @click="showMore">vis mer</a>
    </span>
  </div>
</template>
<script>
import { MenuViewMode } from "@/types/enums/MenuViewMode";

const TRUNCATED_DESCRIPTION_MAX_LENGTH = 150;

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    menuViewMode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showFullDescription: false,
    };
  },
  methods: {
    getDescriptionText() {
      if (this.displayTruncatedDescription())
        return (
          this.item.description.slice(0, TRUNCATED_DESCRIPTION_MAX_LENGTH) +
          ".."
        );
      return this.item.description;
    },
    displayTruncatedDescription() {
      if (this.showFullDescription) return false;
      if (this.menuViewMode === MenuViewMode.Full) return false;
      return this.item.description?.length > TRUNCATED_DESCRIPTION_MAX_LENGTH;
    },
    showMore() {
      this.showFullDescription = true;
    },
  },
};
</script>
<style scoped>
.foodcard-text {
  font-size: 12pt;
  color: var(--primary-text);
}

.show-more {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 50%;
}
</style>
