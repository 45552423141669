<template>
  <div class="catchlines-list">
    <div class="catchline">
      <v-icon x-large color="black" class="catchlineicon"
        >mdi-check-circle</v-icon
      >
      <span>Markedets rimligste løsning.</span>
    </div>
    <div class="catchline">
      <v-icon x-large color="black" class="catchlineicon"
        >mdi-check-circle</v-icon
      >
      <span>Raskt og enkelt å komme i gang.</span>
    </div>
    <div class="catchline">
      <v-icon x-large color="black" class="catchlineicon"
        >mdi-check-circle</v-icon
      >
      <span>Ingen oppsigelsestid.</span>
    </div>
    <div class="catchline">
      <v-icon x-large color="black" class="catchlineicon"
        >mdi-check-circle</v-icon
      >
      <span>Vi setter opp menyen for deg.</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.catchlines-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 50px;
  justify-items: left;
  margin: 0 auto;
  width: 650px;
  width: max-content;
}
.catchline span {
  font-size: 1.3em;
}
.catchlineicon {
  margin-top: -4px;
  margin-right: 10px;
  color: #000000;
}
@media only screen and (max-width: 1200px) {
  .catchlines-list {
    grid-template-columns: 1fr;
  }
}
</style>
