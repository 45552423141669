<template>
  <div class="not-found-page">
    <h1>Adressen er ikke gyldig</h1>
    <FoodyButton
      justify="center"
      align="center"
      :btn-color="'primary'"
      :onClick="goBack"
      >Tilbake</FoodyButton
    >
  </div>
</template>

<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
export default {
  name: "NotFound",
  components: {
    FoodyButton,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>
<style scoped>
.not-found-page {
  text-align: center;
  padding-top: 4rem;
}
.not-found-page button {
  margin-top: 1rem;
}
</style>
