<template>
  <section>
    <div class="partner-footer">
      <div class="footer-content">
        <a target="_blank" href="https://www.facebook.com/getfood.no">
          <img
            class="partner-social-links"
            src="../../assets/img/logo_facebook.png"
            alt="Facebook"
            style="margin-right: 20px"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/getfood.no/">
          <img
            class="partner-social-links"
            src="../../assets/img/logo_instagram.png"
            alt="Instagram"
          />
        </a>
        <div class="footer-companyinfo">
          <span>FOODY AS</span>
          <span>Torggata 11, 0181 Oslo</span>
        </div>
        <div class="footer-bottom">Opphavsrett 2020</div>
      </div>
    </div>
    <div class="getfood-subfooter">
      <div class="subfooter-content">
        <img src="../../assets/img/getfood_partnerlogo.png" alt="Foody logo" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
.getfood-subfooter {
  background-color: #ffffff;
  width: 100%;
  height: 200px;
  padding: 40px;
}
.subfooter-content {
  margin: 0 auto;
  text-align: center;
}
.partner-footer {
  background-color: var(--partner-dark-background-color);
  padding: 4rem;
  border-top: 1px solid var(--partner-gray-border-color);
}
.footer-content {
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
}
.footer-companyinfo {
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer-companyinfo span {
  display: block;
}
</style>
