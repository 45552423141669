
export default class LocalTime {
	hour: Number;
	minute: Number;
	second: Number;

	/*=====Initialization=====*/
	public constructor(timeString: string) {
		const splitString = timeString.split(":");
		this.hour = parseInt(splitString[0]);
		this.minute = parseInt(splitString[1]);
		this.second = parseInt(splitString[2]);
		if (!this.second)
			this.second = 0;
	}

	/*=====Comparisons=====*/
	public isBefore(other: LocalTime): boolean {
		if (this.hour < other.hour)
			return true;
		if (this.hour > other.hour)
			return false;

		if (this.minute < other.minute)
			return true;
		if (this.minute > other.minute)
			return false;

		if (this.second < other.second)
			return true;
		if (this.second > other.second)
			return false;
		return false;
	}

	public isAfter(other: LocalTime): boolean {
		if (this.hour > other.hour)
			return true;
		if (this.hour < other.hour)
			return false;

		if (this.minute > other.minute)
			return true;
		if (this.minute < other.minute)
			return false;

		if (this.second > other.second)
			return true;
		if (this.second < other.second)
			return false;
		return false;
	}

	public isSame(other: LocalTime): boolean {
		return this.hour == other.hour && this.minute == other.minute && this.second == other.second;
	}

	/*=====Other methods=====*/
	public copy(): LocalTime {
		return new LocalTime(this.toString());
	}

	public toString(): string {
		return `${this.hour}:${this.minute}:${this.second}`;
	}

	/*=====Static factory metods=====*/
	public static min(): LocalTime {
		return new LocalTime("00:00");
	}

	public static max(): LocalTime {
		return new LocalTime("23:59:59");
	}
}