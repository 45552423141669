<template>
  <FoodyCard :parentclasses="['card-view']">
    <v-card-title>Allergener</v-card-title>
    <v-spacer></v-spacer>
    <v-card-subtitle class="terms-subtitle">P - Peanøtter</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">N - Nøtter</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">L - Lupin</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">G - Gluten</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">E - Egg</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">M - Melk</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">B - Bløtdyr</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">F - Fisk</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">SK - Skalldyr</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">SL - Selleri</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">SN - Sennep</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">SE - Sesam</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle">SO - Soya</v-card-subtitle>
    <v-card-subtitle class="terms-subtitle"
      >SV - Svoveldioksid sulfitt</v-card-subtitle
    >
  </FoodyCard>
</template>

<script>
import FoodyCard from "@/components/common/FoodyCard.vue";

export default {
  components: {
    FoodyCard
  }
};
</script>
