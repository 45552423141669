<template>
  <div>
    <div class="full-width-exception">
      <v-card flat>
        <Banner :eateryInfo="eateryInfo" :showNavigation="true" />
      </v-card>
    </div>
    <v-card v-if="menu">
      <div class="sticky-categories">
        <MenuCategoryTabs
          v-if="activeTab"
          :menu-categories="menuCategories"
          :active-tab="activeTab"
          :menu="menu"
          @activeTabUpdated="setActiveTab"
        ></MenuCategoryTabs>
        <div class="foody-category-tabs">
          <v-tabs
            v-model="selectedTab"
            background-color="white"
            color="#222222"
            :grow="false"
          >
            <v-tab
              v-for="g in filteredGroupedMenuItems.filter((gmi) => {
                return !gmi.$ref;
              })"
              :key="g.header"
              @click="scrollToSubMenu(g.header)"
            >
              <span>{{ g.header }}</span>
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <v-card
        v-if="
          eateryInfo &&
          eateryInfo.bannerUrl != null &&
          eateryInfo.aboutMessage != null
        "
        outlined
      >
      </v-card>
      <div id="menu-menues-container" :class="getCssClassFoodyMenu()">
        <GroupedMenuItems
          :key="activeTab"
          :groupedMenuItems="filteredGroupedMenuItems"
          :menuViewMode="menu.menuViewMode"
          :hasOnlyQrAndDineIn="hasOnlyQrAndDineIn"
          :observer="observer"
        />

        <div
          v-if="!hasOnlyQrAndDineIn || TableObject"
          class="shopping-cart-container"
        >
          <ShoppingCartContainer
            :on-click="TableObject ? fastCheckout : checkout"
            :button-disabled="!ShoppingCartValid"
            :button-text="
              TableObject
                ? shoppingCartButtonTextForTable
                : shoppingCartButtonText
            "
            :selectedDate="date"
            :selectedTime="time"
          />
        </div>
        <div
          v-if="hasOnlyQrAndDineIn && !TableObject && hasReservationEnabled"
          class="stick-button-container"
        >
          <FoodyButton
            :btn-color="'primary'"
            :parentclasses="['sticky-button']"
            :onClick="goToEaterieReservation"
          >
            RESERVER BORD
          </FoodyButton>
        </div>
      </div>
      <MobileShoppingCart
        :on-click="TableObject ? fastCheckoutCondencedShoppingCart : checkout"
        :button-text="mobileShoppingCartButtonText"
      />
    </v-card>
    <PaymentDialog
      ref="PaymentDialog"
      :userInformationObject="userInformation"
      :deliverynote="deliverynote"
      :tableId="tableId"
      :selectedTime="time"
      :selectedDate="date"
    ></PaymentDialog>
    <div>
      <EaterySeoDescription
        v-if="eateryInfo.seoDescription"
        class="seo-description"
        :description="eateryInfo.seoDescription"
      >
      </EaterySeoDescription>
    </div>
  </div>
</template>
<script>
import EaterySeoDescription from "@/components/common/EaterySeoDescription.vue";
import Vue from "vue";
import codeBehind from "./Menu.ts";
export default Vue.extend({
    name: "Menu",
    mixins: [codeBehind],
    components: { EaterySeoDescription }
});
</script>

<style scoped>

.seo-description {
  margin: 1rem 0;
}
.stick-button-container {
  display: flex;
  width: 60%;
  padding: 1em;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  justify-self: center;
}
.sticky-button {
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .stick-button-container {
    display: flex;
    width: 100%;
    padding: 1em;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    justify-self: center;
  }
  .sticky-button {
    width: 100%;
  }
}
</style>
