<template>
  <div>
    <NavBar HeaderText="Ordrebekreftelse" :showNavbarButton="false"></NavBar>
    <div v-if="isLoading">
      <Loader class="spinner" :isLoading="true" />
    </div>
    <FoodyCard v-if="!isLoading" :parentclasses="['success-view-card']">
      <section v-if="order">
        <Receipt :order="order"></Receipt>
        <v-divider></v-divider>
        <div class="success-margin-top">
          <div v-if="order.orderGuid" class="want-recipt-div">
            <div v-if="order.orderGuid" class="want-recipt-child">
              <WantRecipt
                :order="order"
                :is-recipt-sendt="sendtRecipt"
                :user="getUser"
                @isReciptSendt="sendtRecipt = $event"
              />
            </div>
          </div>
          <div class="flex-column">
            <p v-if="!getUser" class="italic-text align-self-start">
              Opprett konto på getFOOD for enda enklere bestilling
            </p>
            <div class="foody-flex-row foody-space-betweeen">
              <WantToRegister
                v-if="order && !getUser && order.orderGuid"
                :order-id="order.orderGuid"
              ></WantToRegister>
              <span>
                <FoodyButton
                  :btnColor="'primary'"
                  :outlined="true"
                  :on-click="orderMore"
                  >Bestill mer</FoodyButton
                >
              </span>
            </div>
          </div>
        </div>
      </section>
    </FoodyCard>
    <FoodyCard
      v-if="!isSuccess && !isLoading"
      :parentclasses="['success-view-card']"
    >
      <h3>
        En feil har oppstått. Vennligst kontakt kelner/spisestedet for avklaring
        om din ordre er blitt registrert!
      </h3>
    </FoodyCard>
  </div>
</template>

<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
import WantToRegister from "@/components/WantToRegister.vue";
import WantRecipt from "@/components/WantRecipt.vue";
import Receipt from "@/components/Receipt.vue";
import NavBar from "@/components/common/NavBar.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import Loader from "@/components/common/Loader.vue";

export default {
  components: {
    FoodyCard,
    WantToRegister,
    Receipt,
    WantRecipt,
    NavBar,
    FoodyButton,
    Loader,
  },
  data() {
    return {
      paymentId: null,
      retrivedPaymentId: null,
      done: false,
      order: null,
      sendtRecipt: false,
      isSuccess: false,
      isLoading: true,
    };
  },
  computed: {
    eateryName() {
      return this.$store.getters.eateryName;
    },
    TableObject() {
      return this.$store.getters.tableObject;
    },
    getUser() {
      const user = this.$store.getters.loggedInUser;
      return user;
    },
    orderGuid() {
      return this.order.orderGuid;
    },
  },
  async created() {
    const paramPaymentId = this.$route.query.paymentid;
    this.paymentId = paramPaymentId;
    if (this.isLoading) {
      await this.paymentComplete();
      this.isLoading = !this.isLoading;
    }

    this.$store.dispatch("clearLocalShoppingCart");
    this.$store.dispatch("clearShoppingCart");
    this.$store.dispatch("clearTableObject");
  },
  methods: {
    goBack() {
      if (this.prevRoute && this.prevRoute.path === "/") {
        const route = this.$route;
        if (route) {
          const params = route.params;
          if (params.cname) {
            const eateryName = params.cname;
            const newRoute = `/${eateryName}`;

            if (this.$route.path !== newRoute) this.$router.push(newRoute);
          }
        }
      }
      if (this.prevRoute && this.prevRoute.path) {
        this.$router.push(this.prevRoute.path);
      }
    },
    async paymentComplete() {
      try {
        const result = await this.$store.dispatch(
          "updateOrderAsReserved",
          this.paymentId
        );
        if (result) {
          this.order = result.data;
          this.isSuccess = result.status === 200 ? true : false;
          if (!this.isSuccess) {
            this.$router.push(`/${this.eateryName}/orderfailed`);
          }
        }
      } catch (error) {
        this.isSuccess = false;
        this.$router.push(`/${this.eateryName}/orderfailed`);
      }
    },
    orderMore() {
      const redirectUrl = JSON.parse(localStorage.getItem("startUrl"));
      if (redirectUrl.path) this.$router.push(redirectUrl.path.fullPath);
    },
  },
};
</script>
<style scoped>
.want-recipt-div {
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  margin: 20px 0px 30px 0px;
}

.want-recipt-child {
  margin-right: 30%;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.italic-text {
  font-size: 12px;
  color: grey;
}

.spinner {
  margin: 0 auto;
  margin-top: 1em;
}

@media screen and (max-width: 700px) {
  .success-margin-top {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .want-recipt-child {
    align-self: center;
    margin-right: 0;
  }

  .foody-flex-row {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .align-self-start {
    width: 90vw;
  }
}
</style>
