<template>
  <v-card
    class="mx-auto foody-card-search-result"
    @click="goToCompany(eateryItem.urlName)"
  >
    <div class="d-flex flex-no-wrap">
      <div class="underbildeicon">
        <v-avatar class="ma-3" size="100" rounded>
          <v-img contain :src="eateryLogo" />
        </v-avatar>
        <div class="icon_bilde v-card__actions">
          <EateryEatingModeTooltip :eateryItem="eateryItem" />
        </div>
      </div>
      <div class="bigboks">
        <v-card-title
          style="word-break: break-word"
          font-size="18"
          v-text="eateryItem.displayName"
        ></v-card-title>

        <v-card-subtitle
          font-size="12"
          v-text="eateryItem.aboutMessage"
        ></v-card-subtitle>

        <v-card-actions class="bunninfo">
          <div v-if="distance !== null" class="underboks">
            <v-icon x-small>mdi-circle</v-icon>
            <span style="padding: 5px" v-text="distance"> </span>
          </div>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import ImgNotFound from "../../assets/img/image-not-found.png";
import { EatingMode } from "@/types/enums/eatingMode";
import EateryEatingModeTooltip from "@/components/eatery/EateryEatingModeTooltip.vue";

export default {
  components: { EateryEatingModeTooltip },
  props: {
    i: {
      type: Number,
      required: true,
    },
    eateryItem: {
      type: Object,
      required: true,
    },
    preferredEatingMode: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    hasDineIn() {
      return this.eateryItem.dineIn !== null;
    },
    hasDelivery() {
      return this.eateryItem.delivery !== null;
    },
    hasTakeAway() {
      return this.eateryItem.takeAway !== null;
    },
    estimateInfo(): { icon: string; text: string; type: string } {
      // Get estimation type depending on filter and available data from backend
      let eatingMode,
        type = "";
      // If there is a preferred eating mode set (from MatFilter)
      // And it is available in the returned data from API
      if (
        this.preferredEatingMode &&
        this.hasEatingMode(this.preferredEatingMode)
      ) {
        // Use the preferred eating mode
        eatingMode = this.preferredEatingMode;
      } else if (this.hasDelivery) {
        // If delivery is available, use it as default
        eatingMode = EatingMode.Delivery;
      } else if (this.hasTakeAway) {
        // If takeaway is availble, use it as fallback
        eatingMode = EatingMode.TakeAway;
      } else if (this.hasDineIn) {
        // If only dine-in available, use it
        eatingMode = EatingMode.DineIn;
      }
      const minutes = this.eateryItem?.dineIn?.orderReadyEstimateMinutes;
      switch (eatingMode) {
        case EatingMode.Delivery:
          type = "Levering";
          break;
        case EatingMode.TakeAway:
          type = "Takeaway";
          break;
        case EatingMode.DineIn:
          type = "Dine-in";
          break;
      }
      return {
        icon: this.getEatingModeIcon(eatingMode),
        text: minutes ? `~${minutes} min` : type, // If no estimation, show the eating mode type
        type,
      };
    },
    distance() {
      if (this.eateryItem.distanceMeters === null) return null;
      const distanceKm = (this.eateryItem.distanceMeters / 1000).toFixed(1);
      if (+distanceKm < 1) {
        return `${+distanceKm * 1000} m`;
      }
      return `${distanceKm} km`;
    },
    eateryLogo() {
      if (this.eateryItem.logoUrl) {
        return this.eateryItem.logoUrl;
      } else {
        return ImgNotFound;
        //image-not-found.png
      }
    },
  },
  methods: {
    getEatingModeIcon(mode: EatingMode): string {
      switch (mode) {
        case EatingMode.Delivery:
          return "mdi-bike-fast";
        case EatingMode.TakeAway:
          return "mdi-run-fast";
        case EatingMode.DineIn:
          return "mdi-door";
      }
    },
    hasEatingMode(mode: EatingMode): EatingMode {
      if (mode === EatingMode.DineIn) {
        return this.hasDineIn;
      }
      if (mode === EatingMode.TakeAway) {
        return this.hasTakeAway;
      }
      if (mode === EatingMode.Delivery) {
        return this.hasDelivery;
      }
      return EatingMode.Invalid;
    },
    goToCompany: function (eateryUrlName: string) {
      this.$router.push("/" + eateryUrlName);
    },
  },
};
</script>

<style scoped>
.foody-card-search-result {
  box-shadow: 0px 6px 30px rgb(0 0 0 / 8%) !important;
  min-width: 236px;
  width: 100%;
}

/* Make sure the icons and information box is always on the bottom */
.foody-card-search-result > div {
  height: 100%;
}

.underbildeicon {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.icon_bilde {
  flex: 1;
  align-items: flex-end;
}

.dollarcont {
  width: 50px;
  display: inline-block;
}

.bunninfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: nowrap;
  font-size: 12px;
}
.bigboks {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@media (min-width: 426px) {
  .foody-card-search-result {
    padding: 15px 0;
  }
}
</style>
