import { Route } from "vue-router";
import store from "../../store/index";

async function getLockedRestaurant() {
  return store.state.lockedToEatery;
}

function shouldPerformRedirect(url: string, lockedToRestaurant: string) {
  const whiteList = [
    (url: string) => url.endsWith("/login"),
    (url: string) => url.endsWith("/register"),
    (url: string) => url.endsWith("/forgot"),
    (url: string) => url.includes(lockedToRestaurant),
  ];

  if (!lockedToRestaurant) {
    return false;
  }

  if (whiteList.some((checkUrlOnWhitelistRule) => checkUrlOnWhitelistRule(url))) {
    return false;
  }

  return true;
}

export async function restaurantGuard(
  to: Route,
  _: Route | string,
  next: Function
) {
  const lockedToRestaurant = await getLockedRestaurant();
  if (shouldPerformRedirect(to.fullPath, lockedToRestaurant)) {
    return next(`/${lockedToRestaurant}`);
  }
  return next();
}
