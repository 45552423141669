<template>
  <FoodyButton
    v-if="!hasOnlyQrAndDineIn || showPropsButton() || tableObjectIsTruthy"
    :btn-color="'primary'"
    :parentclasses="['btn-full-width']"
    :on-click="handleClick"
    :disabled="currentItemIsOutOfStock"
  >
    <span v-if="currentItemIsOutOfStock">UTSOLGT</span>
    <span v-else-if="!showPropsButton()">LEGG TIL</span>
    <span v-else-if="hasOnlyQrAndDineIn">
      <v-icon left dense class="list-icon" style="font-size: 17px"
        >mdi-list-status</v-icon
      >
      VIS VALG</span
    >
    <span v-else>
      <v-icon left dense class="list-icon" style="font-size: 17px"
        >mdi-list-status</v-icon
      >
      LEGG TIL
    </span>
  </FoodyButton>
</template>
<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
import { itemOutOfStock } from "@/helpers/cartHelper.ts";
import { MenuViewMode } from "@/types/enums/MenuViewMode";

export default {
  components: {
    FoodyButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    addItemToCart: {
      type: Function,
      required: true,
    },
    showMenuItemBottomSheet: {
      type: Function,
      required: true,
    },
    hasOnlyQrAndDineIn: {
      type: Boolean,
      required: true,
    },
    tableObjectIsTruthy: {
      type: Boolean,
      required: true,
    },
    menuViewMode: {
      type: Number,
      required: true,
    },
    menuItemVariantCount: {
      type: Number,
      required: true,
    },
    menuItemPropertiesCount: {
      type: Number,
      required: true,
    },
    menuItemPropertiesOptionsCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentItemIsOutOfStock() {
      return itemOutOfStock(this.item);
    },
  },
  methods: {
    handleClick() {
      if (this.showPropsButton()) this.showMenuItemBottomSheet();
      else this.addItemToCart();
    },
    showPropsButton() {
      if (this.menuViewMode === MenuViewMode.Full) return false;
      const hasVariants = this.menuItemVariantCount;
      const hasMoreThenThreePropsOption =
        this.menuItemPropertiesCount >= 1 &&
        this.menuItemPropertiesOptionsCount > 3;
      const propsCount = this.menuItemPropertiesCount;

      if (this.menuViewMode === MenuViewMode.MaxCompact) {
        if (!hasVariants && propsCount === 0) return false;
        return true;
      }
      if (this.menuViewMode === MenuViewMode.Compressed) {
        if (hasVariants && propsCount) {
          return true;
        }
        if (hasVariants > 3) {
          return true;
        }
        if (propsCount > 1) {
          return true;
        }
        if (hasMoreThenThreePropsOption) {
          return true;
        }
        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped>
.list-icon {
  color: var(--foody-shopping-cart-primary-color);
  margin-bottom: 3px;
  position: relative;
  left: 5px;
}
</style>
