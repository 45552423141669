<template>
  <div id="dibs-complete-checkout" />
</template>

<script>
export default {
  name: "NetsCheckout",

  props: {
    paymentId: {
      type: [String, null],
      required: true,
    },
    checkoutKey: {
      type: [String, null],
      required: true,
    },
    paymentComplete: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      checkoutOptions: {
        checkoutKey: this.checkoutKey, //[Required] Test or Live GUID with dashes
        paymentId: this.paymentId,
        containerId: "dibs-complete-checkout", //[optional] defaultValue: dibs-checkout-content
      },
    };
  },
  mounted() {
    this.checkPaymentId();
  },
  updated() {
    this.checkPaymentId();
  },
  methods: {
    checkPaymentId: function () {
      if (this.paymentId) {
        this.startCheckout();
      }
    },
    startCheckout: function () {
      //eslint-disable-next-line
      const checkout = new Dibs.Checkout(this.checkoutOptions);
      checkout.on("payment-completed", (response) => {
        this.paymentComplete(response);
        checkout.cleanup();
      });
    },
  },
};
</script>
