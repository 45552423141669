import Inputfield from "@/components/inputFields/Inputfield.vue";
const codeBehind = {
  components: { Inputfield },
  data() {
    return {
      show1: false,
      show2: false,
      passwordValid: false,
      repeatPasswordValid: false,
      error: [] as string[],
    };
  },
  props: {
    password: {
      type: String,
      required: true,
      default: "",
    },
    repeatPassword: {
      type: String,
      required: false,
      default: "",
    },
    wantRepeatPassword: {
      type: Boolean,
      required: false,
      default: false,
    },
    passwordFormValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    passwordInput: {
      get() {
        return this.password;
      },
      set(val: string) {
        this.$emit("password", val);
      },
    },
    repeatPasswordInput: {
      get() {
        return this.repeatPassword;
      },
      set(val: string) {
        this.$emit("repeatPassword", val);
      },
    },
    formValid(): boolean {
      if (!this.wantRepeatPassword) return this.passwordValid;
      else {
        return this.passwordValid && this.repeatPasswordValid;
      }
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [
        vm.formValid,
        vm.password,
        vm.repeatPassword,
        vm.passwordValid,
        vm.repeatPasswordValid,
        vm.wantRepeatPassword,
      ],
      () => {
        this.error = [];
        let passwordEqual = false;
        if (!this.wantRepeatPassword) passwordEqual = true;
        if (
          this.wantRepeatPassword &&
          ((this.passwordValid &&
            this.repeatPassword &&
            this.repeatPassword.length > 8) ||
            (this.repeatPasswordValid &&
              this.password &&
              this.password.length > 8)) &&
          !(this.password === this.repeatPassword)
        ) {
          this.error.push("Passordene er ikke like");
        } else {
          passwordEqual = true;
        }
        this.$emit("passwordValid", this.formValid && passwordEqual);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
};
export default codeBehind;
