<template>
  <v-navigation-drawer
    v-model="drawer"
    class="app--drawer"
    fixed
    temporary
    style="z-index: 100; width: 310px"
  >
    <v-list-item v-if="loggedInUser" class="px-2" style="height: 64px">
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/lego/6.jpg" />
      </v-list-item-avatar>

      <v-list-item-title>{{ loggedInUser.name }}</v-list-item-title>

      <v-btn icon @click.stop="$emit('toggle-drawer')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item v-else style="height: 64px; justify-content: flex-end">
      <v-btn icon @click.stop="$emit('toggle-drawer')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider />
    <UserProfile
      v-if="showProfile"
      @toggleShowProfile="toggleShowProfile($event)"
    />
    <SidebarItems v-else @toggleShowProfile="toggleShowProfile($event)" />
  </v-navigation-drawer>
</template>
<script lang="ts">
import Vue from "vue";
import SidebarItems from "./SidebarItems.vue";
import UserProfile from "./UserProfile.vue";
export default Vue.extend({
  components: {
    SidebarItems,
    UserProfile,
  },
  data: () => ({
    drawer: false,
    showProfile: false,
  }),
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },
  },
  methods: {
    toggleShowProfile() {
      this.showProfile = !this.showProfile;
    },
  },
});
</script>
