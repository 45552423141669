<template>
  <v-card>
    <div class="description">{{ description }}</div>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "EaterySeoDescription",
  props: {
    description: {
      type: String,
      default: ''
    }
  }
});
</script>

<style>
.description {
  padding: 1rem;
  }

</style>