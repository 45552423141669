<template>
  <section>
    <div class="partner-features-wrapper">
      <div class="partner-features">
        <div
          v-for="{ title, body, icon } in partnerFeaturesWithIcons"
          :key="title"
          class="partner-features-content"
        >
          <v-img class="partner-features-icon" :src="icon" />
          <div class="partner-features-text">
            <h3 slot="title">
              {{ title }}
            </h3>
            <span>
              {{ body }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import partnerFeaturesWithIcons from "./PartnerFeaturesWithIcons";

export default {
  data() {
    return {
      partnerFeaturesWithIcons,
    };
  },
};
</script>
<style>
.partner-features-wrapper {
  padding: 4em 0;
  background-color: white;
}
.partner-features {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.partner-features-content {
  width: 20%;
}
.partner-features-icon {
  height: 56px;
  width: 56px;
  margin-bottom: 1rem;
}
h3 {
  margin: unset;
}

@media only screen and (max-width: 1200px) {
  .partner-features {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
  }
  .partner-features-content {
    margin: 0 auto 1rem auto;
    text-align: center;
    width: 75%;
  }
  .partner-features-icon {
    margin: 0 auto 1rem auto;
  }
}
</style>
