import { FoodProperty } from "@/types/dto/menues/FoodProperty";
import { FoodPropertyOption } from "@/types/dto/menues/FoodPropertyOption";
import MenuItem from "@/types/dto/menues/menuItem";
import { MenuItemVariant } from "@/types/dto/menues/MenuItemVariant";

export function calculatePrice(
  menuItem: MenuItem,
  selectedFoodPropertiesOptions: FoodPropertyOption[],
  selectedVariant?: MenuItemVariant
) {
  const basePrice = selectedVariant?.price ?? menuItem.singlePrice;

  const optionsPrice = selectedFoodPropertiesOptions.reduce(
    (previous, current) => previous + current.price,
    0
  );

  return basePrice + optionsPrice;
}

export function getFoodPropertyOptionsCount(menuItem: MenuItem) {
  if (!menuItem?.foodProperties) return 0;

  return menuItem.foodProperties.reduce(
    (previous, current) =>
      previous + (current?.foodPropertyOptions?.length ?? 0),
    0
  );
}

function getFoodPropertyOptionDefaultSelectedValue(
  foodProperty: FoodProperty,
  foodPropertyOptionIndex: number
) {
  return foodProperty.multiSelect ? false : foodPropertyOptionIndex === 0;
}

export function resetFoodPropertyOptions(menuItem: MenuItem) {
  menuItem.foodProperties = menuItem.foodProperties.map((foodProperty) => ({
    ...foodProperty,
    foodPropertyOptions: foodProperty.foodPropertyOptions.map(
      (foodPropertyOption, index) => ({
        ...foodPropertyOption,
        selected: getFoodPropertyOptionDefaultSelectedValue(
          foodProperty,
          index
        ),
      })
    ),
  }));
}

export function resetMenuItemVariants(menuItem: MenuItem) {
  menuItem.menuItemVariants = menuItem.menuItemVariants.map(
    (menuItemVariant) => ({
      ...menuItemVariant,
      selected: menuItemVariant.isDefault,
    })
  );
}
