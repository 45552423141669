<template>
  <div v-if="foodProperties">
    <FoodPropertySingle v-if="showSingle" :fp="foodProperties[0]" />
    <FoodPropertiesMulti v-if="showMulti" :foodProperties="foodProperties" />
  </div>
</template>
<script>
import FoodPropertySingle from "@/components/menues/menu-items/menu-item-properties/FoodPropertySingle.vue";
import FoodPropertiesMulti from "@/components/menues/menu-items/menu-item-properties/FoodPropertiesMulti.vue";

export default {
  components: {
    FoodPropertySingle: FoodPropertySingle,
    FoodPropertiesMulti: FoodPropertiesMulti,
  },
  props: {
    foodProperties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    showSingle() {
      return this.foodProperties.length === 1;
    },
    showMulti() {
      return this.foodProperties.length > 1;
    },
  },
  methods: {
    updateSingleFoodOptionSummary(option) {
      this.fpSummarySingle = option.name;
    },
  },
};
</script>
<style></style>
