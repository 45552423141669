import RootState from "@/types/state/rootState";
import { ActionTree } from "vuex";
import EateryState from "./state";
import { EatingMode } from "@/types/enums/eatingMode";
import { getValidEatingModesForCurrentMenu } from "@/helpers/currentMenuHelper";
import { getCurrentEatingMode } from "@/helpers/currentEateryHelper";

export const actions: ActionTree<EateryState, RootState> = {
  setEatingMode(context, eatingMode: EatingMode) {
    context.commit("SET_EATING_MODE", eatingMode);
  },
  setSelectedTip(context, selectedTip: number) {
    context.commit("SET_SELECTED_TIP", selectedTip);
  },
  setEatingModeBasedOnCurrentEateryAndMenu(context, isQr: boolean) {
    const validEatingModes = getValidEatingModesForCurrentMenu(isQr);
    const eatingModeFromStore = getCurrentEatingMode();

    if (eatingModeFromStore && validEatingModes.includes(eatingModeFromStore)) {
      // Valid eating mode already selected
      return;
    }

    if (validEatingModes.length === 0) {
      throw new Error("No valid eating modes for current menu");
    }

    context.commit("SET_EATING_MODE", validEatingModes[0]);
  },
};
