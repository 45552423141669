import FoodyButton from "@/components/common/FoodyButton.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";
import PasswordWithVerifyPassword from "@/components/forms/password/PasswordWithVerifyPassword.vue";
import PhoneInput from "@/components/inputFields/PhoneInput.vue";
import Inputfield from "@/components/inputFields/Inputfield.vue";
import CodebehindData from "@/components/sidebar/UserProfile.data";

const codebehind = {
  mixins: [CodebehindData],
  components: {
    FoodyButton,
    PhoneInput,
    ErrorComponent,
    PasswordWithVerifyPassword,
    Inputfield,
  },
  created() {
    this.getUser();
  },
  computed: {
    fieldsValid() {
      return (
        this.firstNameValid &&
        this.lastNameValid &&
        //this.emailValid && // TODO: Uncoment this when email is going to be reactivated
        this.phoneValid &&
        this.streetAddressValid &&
        this.postalCodeValid &&
        this.postalAreaValid
      );
    },
    passwordsValid() {
      const valid = this.bothPasswordsValid && this.oldPasswordValid;
      return valid;
    },
  },
  methods: {
    handleClick() {
      this.$emit("toggleShowProfile");
    },
    async cancelUserInfoEdit() {
      this.editUserInfoActive = false;
      await this.getUser();
    },
    clearPasswordField() {
      this.oldPassword = "";
      this.newPassword = "";
      this.newRepeatPassword = "";
    },
    cancelPasswordEdit() {
      this.editUserPasswordActive = false;
      this.clearPasswordField();
    },
    setName(name: string) {
      const arr = name?.split(" ");
      if (arr?.length >= 2) {
        this.firstName = arr.slice(0, arr.length - 1).join(" ");
        this.lastName = arr[arr.length - 1].toString();
      } else if (arr?.length === 1) {
        this.firstName = arr[0]?.toString();
      }
    },
    async getUser() {
      const user = this.$store.getters.loggedInUser;

      if (user) {
        this.setName(user.name);
        this.phone = user.phone ?? "";
        this.email = user.email ?? "";
        if (user && user.address) {
          this.streetAddress = user.address.streetAddress ?? "";
          this.postalCode = user.address.postalCode ?? "";
          this.postalArea = user.address.postalArea ?? "";
        }
      }
    },

    async saveProfile() {
      if(!this.editUserInfoActive) { return; }
      await this.editProfile();
    },

    async editProfile() {
      if (!this.editUserInfoActive) {
        this.editUserInfoActive = true;
      } else {
        if(!this.fieldsValid) { return; }
        const user = {
          name: this.firstName + " " + this.lastName,
          phoneNumber: this.phone,
          email: this.email,
          Address: {
            streetAddress: this.streetAddress,
            postalCode: this.postalCode,
            postalArea: this.postalArea,
          },
        };
        try {
          this.isLoading = true;
          const res = await this.$store.dispatch("updateUserProfile", user);
          this.errorMessage = "";
          if (res) {
            await this.$store.dispatch("getUserInfo");
            await this.$store.dispatch("getUserAddress");

            this.isLoading = false;
          }
        } catch (error) {
          this.isLoading = false;
          this.errorMessage = "Noe gikk feil med lagring prøv igjen!";
        }
        this.editUserInfoActive = false;
      }
    },
    async editPassword() {
      if (!this.editUserPasswordActive) {
        this.editUserPasswordActive = true;
      } else {
        if(!this.passwordsValid) {
          return;
        }
        const userPasswordInfo = {
          CurrentPassword: this.oldPassword,
          NewPassword: this.newPassword,
          RepeatNewPassword: this.newRepeatPassword,
        };
        try {
          this.isLoading = true;
          const res = await this.$store.dispatch(
            "updateUserPassoword",
            userPasswordInfo
          );
          if (res) {
            this.isLoading = false;
            this.editUserPasswordActive = false;
            this.clearPasswordField();
          }
          this.errorMessage = "";
        } catch (error) {
          this.isLoading = false;
          this.errorMessage =
            "Noe gikk feil, vi fikk ikke byttet passord prøv igjen!";
        }
      }
    },
  },
};
export default codebehind;
