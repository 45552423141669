import CityDto from "@/types/dto/cities/cityDto";
import RootState from "@/types/state/rootState";
import { GetterTree } from "vuex";
import CityState from "./state";

export const getters: GetterTree<CityState, RootState> = {
  allCities(state): CityDto[] {
    return state.cities;
  },
};
