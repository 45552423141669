<template>
  <div>
    <div class="v-label theme--light" style="font-weight: bold">
      Velg når du vil ha maten:
    </div>
    <v-radio-group v-model="timeOptionInput" column>
      <v-radio
        :disabled="eatingModeClosed"
        :label="currentEatingMode == 3 ? 'Snarest' : ' Snarest'"
        value="1"
      />
      <v-radio label="Velg tid" value="2" />
    </v-radio-group>
  </div>
</template>
<script>
import Vue from "vue";
import codeBehind from "./TimeRadioSelector";

export default Vue.extend({
  name: "TimeRadioSelector",
  mixins: [codeBehind],
});
</script>
