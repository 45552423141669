import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";

//import routes
import UserRoutes from "@/router/user-routes";
import OrderRoutes from "@/router/order-routes";
import FoodCourtRoutes from "@/router/foodcourt-routes";

//import views
//import UnderDevelopment from "@/views/UnderDevelopment.vue"; // Uncomment when needed again
import Main from "@/views/Main/Main.vue";
import Terms from "@/views/Terms.vue";
import CookiePolicy from "@/views/cookiePolicy/CookiePolicy.vue";
import Allergies from "@/views/Allergies.vue";
import GetFoodContact from "@/views/GetFoodContact.vue";
import NotFound from "@/views/NotFound.vue";
import Search from "@/views/Search.vue";

// import partnerpages
import Partner from "@/views/Partner.vue";
import PartnerContact from "@/views/PartnerContact.vue";
import PartnerSignUp from "@/views/PartnerSignUp.vue";
import PartnerEateries from "@/views/PartnerEateries.vue";
import PartnerChains from "@/views/PartnerChains.vue";
import PartnerHotels from "@/views/PartnerHotels.vue";
import PartnerCafeBar from "@/views/PartnerCafeBar.vue";
import PartnerPrices from "@/views/PartnerPrices.vue";
import PartnerQuestions from "@/views/PartnerQuestions.vue";
import { restaurantGuard } from "./guards/restaurant-guard";

Vue.use(VueRouter);

const baseRoutes: RouteConfig[] = [
  {
    path: "/",
    component: Main,
    beforeEnter: (to, from, next) => {
      resetTable();
      next();
    },
  },
  {
    path: "/iframe/:restaurant",
    beforeEnter: (to, from, next) => {
      lockEatery(to.params.restaurant);
      next("/");
    },
  },
  { path: "/search/:term?", component: Search },
  { path: "/notfound", component: NotFound },
  { path: "/getfoodcontact", component: GetFoodContact },
  { path: "/terms", component: Terms },
  { path: "/cookiepolicy", component: CookiePolicy },
  { path: "/allergy", component: Allergies },
  { path: "/partner", component: Partner },
  { path: "/partner/contact", component: PartnerContact },
  { path: "/partner/signup", component: PartnerSignUp },
  { path: "/partner/spisesteder", component: PartnerEateries },
  { path: "/partner/kjeder", component: PartnerChains },
  { path: "/partner/hotell", component: PartnerHotels },
  { path: "/partner/cafebar", component: PartnerCafeBar },
  { path: "/partner/prisliste", component: PartnerPrices },
  { path: "/partner/faq", component: PartnerQuestions },
];

const routes = baseRoutes
  .concat(FoodCourtRoutes)
  .concat(UserRoutes)
  .concat(OrderRoutes)
  .concat([{ path: "*", component: NotFound }]);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to?.hash) {
      return {
        selector: to.hash,
      };
    } else if (to?.path.includes("/checkout")) {
      // Making sure checkout allways will be at top. even when back and forward navigation (savedPosition) is used
      return { x: 0, y: 0 };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function resetTable() {
  store.dispatch("clearTableObject");
}

function lockEatery(eateryId: string) {
  store.dispatch("lockEatery", eateryId);
}

router.beforeEach((to, from, next) => {
  restaurantGuard(to, from, next);
});

router.afterEach((to, from) => {
  store.dispatch("updateLastNavigationPage", from.fullPath);
});

export default router;
