<template>
  <section class="partner-intro">
    <div class="partner-intro-content">
      <div class="header">
        <span>
          Nå får du samlet all mobil bestilling i ett system. <br />
          Vi har tilpassede løsninger for alle typer serveringssteder.
        </span>
      </div>
      <div class="partner-phone-mobile-wrapper">
        <div class="partner-phone-mobile">
          <img src="../../assets/img/partner-phone-mockup.svg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
.partner-intro {
  padding: 20px 0 70px 0;
  background-color: white;
  width: 100%;
}

.partner-intro-content {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}

.partner-intro-content > div {
  max-width: 1140px;
}

.partner-phone-mobile {
  position: absolute;
  top: -360px;
}

.partner-intro-content > .header {
  max-width: 80vw;
}

.partner-intro-content span {
  font-size: 1.1em;
}

@media only screen and (max-width: 1200px) {
  .partner-intro {
    padding: 30px;
  }
  .partner-intro-content {
    flex-direction: column;
  }
  .partner-phone-mobile {
    display: block;
    position: inherit;
    max-width: 100%;
  }
  .partner-intro-content > .header,
  .partner-phone-mobile img {
    max-width: 100%;
  }
}
</style>
