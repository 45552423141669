<template>
  <v-tabs
    v-model="selectedTab"
    fixed-tabs
    background-color="#222222"
    dark
    class="tabs foody-main-category-tab"
    @change="setActiveTab"
  >
    <v-tab
      v-for="cat in foodCategories"
      :id="cat.id"
      :key="cat.id"
      :href="'#' + cat.id"
      active-class="active-tab"
      class="tab"
      ripple
    >
      {{ cat.name }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import {
  GroupedCategoryEnum,
  MenuItemCatEnum,
  MenuItemSingleEnum,
} from "@/types/enums/menuItemCategories";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop({ required: true }) private menuCategories: Array<GroupedCategoryEnum>;
  @Prop({ required: true }) private activeTab: MenuItemCatEnum;

  @Watch("activeTab", { immediate: true }) onActiveTabChanged() {
    this.selectedTab = this.activeTab;
  }
  // data
  private selectedTab: string = MenuItemCatEnum.Food;

  // computed
  get foodCategories() {
    const foodCategoriesArray: {
      name: string;
      id: MenuItemCatEnum;
    }[] = [];
    if (this.menuCategories.length === 1) {
      foodCategoriesArray.push(
        this.addSingleFoodCategory(this.menuCategories[0])
      );
    } else {
      this.menuCategories.forEach((elm) => {
        switch (elm) {
          case MenuItemSingleEnum.Dessert as any as GroupedCategoryEnum:
          case MenuItemSingleEnum.SideDish as any as GroupedCategoryEnum:
          case MenuItemSingleEnum.Food as any: {
            if (foodCategoriesArray.find((el) => el.id == MenuItemCatEnum.Food))
              break;
            const cat = { name: "Mat", id: MenuItemCatEnum.Food };
            foodCategoriesArray.push(cat);
            break;
          }
          case GroupedCategoryEnum.Drinks: {
            const cat = { name: "Drikke", id: MenuItemCatEnum.Drinks };
            foodCategoriesArray.push(cat);
            break;
          }
          case GroupedCategoryEnum.Addons: {
            const cat = { name: "Tillegg", id: MenuItemCatEnum.Addons };
            foodCategoriesArray.push(cat);
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    return foodCategoriesArray;
  }
  // methods
  public addSingleFoodCategory(foodCat: number) {
    switch (foodCat) {
      case GroupedCategoryEnum.Food: {
        return { name: "Mat", id: MenuItemCatEnum.Food };
      }
      case GroupedCategoryEnum.Drinks: {
        return { name: "Drikke", id: MenuItemCatEnum.Drinks };
      }
      case GroupedCategoryEnum.Addons: {
        return { name: "Tillegg", id: MenuItemCatEnum.Addons };
      }
      default: {
        return { name: "Mat", id: MenuItemCatEnum.Food };
      }
    }
  }
  public setActiveTab(e: any) {
    let selectedTabEnum = null;
    switch (e) {
      case "1": {
        selectedTabEnum = MenuItemCatEnum.Food;
        break;
      }
      case "2": {
        selectedTabEnum = MenuItemCatEnum.Drinks;
        break;
      }
      case "3": {
        selectedTabEnum = MenuItemCatEnum.Addons;
        break;
      }
      default: {
        selectedTabEnum = MenuItemCatEnum.Food;
      }
    }
    this.$emit("activeTabUpdated", selectedTabEnum);
  }
}
</script>

<style scoped>
.active-tab {
  background-color: #ffffff;
  color: var(--primary-color);
}
.tab {
  font-size: 1.2em;
}
.tab.v-tab {
  color: #ffffff !important;
  font-weight: 600;
}
.tab.v-tab.active-tab {
  color: #222222 !important;
  font-weight: 600;
}
.tabs {
  border-top: 1px solid #eaeaea;
}
.tab:hover {
  background-color: #ffffff !important;
  color: #222222 !important;
}
</style>
