//view imports
import Menu from "@/views/Menu.vue";
import Menus from "@/views/Menus.vue";
import Checkout from "@/views/checkout/Checkout.vue";
import Success from "@/views/Success.vue";
import OrderFailed from "@/views/OrderFailed.vue";
import Smittesporing from "@/views/Smittesporing.vue";
import TableReservation from "@/views/TableReservation.vue";
import TableReservationSuccessPage from "@/views/TableReservationSuccessPage.vue";
import {
  MenusRouteGuard,
  MenuRouteGuard,
  CheckoutRouteGuard,
  SuccessRouteGuard,
  SmittesporingRouteGuard,
  TableReservationRouteGuard,
} from "@/router/guards/order-guards";
import { RouteConfig } from "vue-router";

const OrderRoutes: RouteConfig[] = [
  {
    path: "/:cname?",
    component: Menus,
    props: true,
    beforeEnter: (to, from, next) => MenusRouteGuard(to, from, next),
  },
  {
    path: "/:cname/smittesporing",
    component: Smittesporing,
    beforeEnter: (to, from, next) => SmittesporingRouteGuard(to, from, next),
  },
  {
    path: "/:cname/menu/:id",
    beforeEnter: (to, from, next) => MenuRouteGuard(to, from, next),
    component: Menu,
    props: true,
  },
  {
    path: "/:cname/checkout",
    component: Checkout,
    props: true,
    beforeEnter: (to, from, next) => CheckoutRouteGuard(to, from, next),
  },
  {
    path: "/:cname/checkout/:stage",
    component: Checkout,
    props: true,
    beforeEnter: (to, from, next) => CheckoutRouteGuard(to, from, next),
  },
  {
    path: "/:cname/success",
    component: Success,
    beforeEnter: (to, from, next) => SuccessRouteGuard(to, from, next),
    props: (route) => ({ id: route.query.paymentid }),
  },
  { path: "/:cname/orderfailed", component: OrderFailed },
  {
    path: "/:cname/bord",
    component: TableReservation,
    beforeEnter: (to, from, next) => TableReservationRouteGuard(to, from, next),
  },
  {
    path: "/:cname/bordreservasjon",
    component: TableReservationSuccessPage,
  },
];

export default OrderRoutes;
