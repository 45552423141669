<template>
  <div>
    <div
      v-for="g in groupedMenuItems"
      :key="g.id"
      style="padding-bottom: 1.2em"
    >
      <h2 :id="g.header" class="header-sub-title" style="font-size: 1.5em">
        {{ g.header }}
      </h2>
      <p
        :id="g.info"
        style="
          font-size: 1em;
          padding-bottom: 0.7em;
          color: var(--primary-text);
        "
      >
        {{ g.info }}
      </p>
      <FoodItem
        v-for="item in getFiltered(g.menuItems)"
        :key="item.id"
        :item="item"
        :menuViewMode="menuViewMode"
        :hasOnlyQrAndDineIn="hasOnlyQrAndDineIn"
      />
    </div>
  </div>
</template>
<script lang="ts">
import FoodItem from "@/components/menues/menu-items/FoodItem/FoodItem.vue";
import MenuItem from "@/types/dto/menues/menuItem";
import { EatingMode } from "@/types/enums/eatingMode";
import { setAvailableEatingMode } from "@/types/dto/eateries/eateryDtoFunctions";
import { MenuViewMode } from "@/types/enums/MenuViewMode";

export default {
  name: "GroupedMenuItems",
  components: {
    FoodItem: FoodItem,
  },
  props: {
    groupedMenuItems: {
      type: Array,
      required: true,
    },
    menuViewMode: {
      type: Number,
      required: true,
      default: MenuViewMode.Compressed,
    },
    hasOnlyQrAndDineIn: {
      type: Boolean,
      required: false,
      default: false,
    },
    observer: {
      type: IntersectionObserver,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    CurrentEatingMode() {
      return (
        this.$store.getters.selectedEatingMode ??
        setAvailableEatingMode(this.CurrentEatery)
      );
    },

    CurrentEatery() {
      return this.$store.getters.eateryInfo;
    },
  },
  mounted() {
    this.setTargetsOnObserver();
  },
  updated() {
    this.setTargetsOnObserver();
  },
  methods: {
    setTargetsOnObserver() {
      const targets = document.querySelectorAll(".header-sub-title");
      targets.forEach((target: any) => {
        this.observer.observe(target);
      });
    },
    getFiltered(groupedMenuItems: Array<MenuItem>) {
      const table = this.$store.getters.tableObject;
      if (!table && this.CurrentEatingMode !== EatingMode.DineIn) {
        groupedMenuItems = groupedMenuItems.filter(
          (menuItem) => menuItem.dineInOnly !== true
        );
      }
      return groupedMenuItems;
    },
  },
};
</script>
<style scoped>
h1 {
  color: var(--primary-color);
  margin-left: 8px;
}
</style>
