<template>
  <div style="margin: 0 auto; padding: 15px">
    <PartnerNavBack class="pad-left-40" variant="black"></PartnerNavBack>
    <PartnerFAQ></PartnerFAQ>
  </div>
</template>

<script>
import PartnerFAQ from "@/components/partner/PartnerFAQ.vue";
import PartnerNavBack from "@/components/partner/PartnerNavBack.vue";
export default {
  components: {
    PartnerFAQ: PartnerFAQ,
    PartnerNavBack: PartnerNavBack,
  },
};
</script>
<style scoped></style>
