<template>
  <div>
    <div class="center-text">
      <span>Vil du gi tips?</span>
    </div>
    <div class="foody-flex-responsive foody-flex-align-center">
      <v-chip-group
        v-model="selectedTip"
        active-class="primary"
        mandatory
        @change="setSelectedTip"
      >
        <v-chip class="ma-2 tip-chip"> 0% </v-chip>
        <v-chip class="ma-2 tip-chip"> 5% </v-chip>
        <v-chip class="ma-2 tip-chip"> 10% </v-chip>
        <v-chip class="ma-2 tip-chip"> 15% </v-chip>
        <v-chip class="my-2 ml-1 tip-chip"> annet </v-chip>
      </v-chip-group>
    </div>
    <div v-if="selectedTip === 4">
      <v-text-field
        v-model="manualTip"
        label="Regular"
        solo
        class="tip-input"
        placeholder="velg tipsbeløp selv"
        @keydown="preventNonNumeric"
        @blur="setManualTip"
      ></v-text-field>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import store from "../../store/index";
export default Vue.extend({
  name: "Tip",
  data() {
    return {
      manualTip: 0,
      selectedTip: store.getters.selectedTip ?? 0,
    };
  },
  methods: {
    preventNonNumeric(event: any) {
      const theEvent = event || window.event;
      const key = theEvent.keyCode || theEvent.which;
      // Don't validate the input if below arrow, delete and backspace keys were pressed
      if (
        key == 37 ||
        key == 38 ||
        key == 39 ||
        key == 40 ||
        key == 8 ||
        key == 46
      ) {
        // Left / Up / Right / Down Arrow, Backspace, Delete keys
        return;
      }
      const char = String.fromCharCode(event.keyCode);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    setSelectedTip() {
      if (this.selectedTip == 4) {
        this.$store.dispatch("setSelectedTip", parseInt(this.manualTip) * 100);
      } else {
        this.$store.dispatch("setSelectedTip", this.selectedTip);
      }
    },
    setManualTip() {
      this.$store.dispatch("setSelectedTip", parseInt(this.manualTip) * 100);
    },
  },
});
</script>
<style scoped>
.tip-chip {
  border: 1px solid #6d6d6d;
  color: #6d6d6d;
}
.theme--light.v-chip:not(.v-chip--active) {
  background-color: white;
}
.tip-input {
  border-radius: 100px;
}
.center-text {
  text-align: center;
}
::v-deep .v-slide-group__next {
  display: none;
}
.v-application .ma-2 {
  margin: 7px !important;
}
</style>
