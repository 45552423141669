<template>
  <div>
    <NavBar HeaderText="En feil oppsto" :callBack="goBack"></NavBar>
    <FoodyCard :parentclasses="['success-view-card']">
      <section>
        <h4>Det har oppstått en feil...</h4>
        <v-divider style="margin: 20px 0"></v-divider>
        <span
          >En feil oppsto ved opprettelse av din bestilling. <br />
          Vennligst ta kontakt med spisestedet for å avklare om bestillingen er
          registrert eller ikke.</span
        >
      </section>
    </FoodyCard>
  </div>
</template>

<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
import NavBar from "@/components/common/NavBar.vue";

export default {
  components: {
    FoodyCard,
    NavBar,
  },
  computed: {
    companyName() {
      return this.$store.getters.eateryName;
    },
  },
  methods: {
    goBack() {
      if (this.prevRoute && this.prevRoute.path === "/") {
        const route = this.$route;
        if (route) {
          const params = route.params;
          if (params.cname) {
            const companyName = params.cname;
            const newRoute = `/${companyName}`;

            if (this.$route.path !== newRoute) this.$router.push(newRoute);
          }
        }
      }
      if (this.prevRoute && this.prevRoute.path) {
        this.$router.push(this.prevRoute.path);
      }
    },
  },
};
</script>
