<template>
  <div class="search-content">
    <div class="searchcard-container">
      <GeoSearchField
        type="store"
        :onSearch="onSearch"
        :searchTerm="searchTerm"
      />
      <div class="searchheader">
        <h2 class="searchheader__title">Søkeresultater</h2>
        <div class="searchheader__filter container">
          <MatFilter :eateries="eateries" @filter-changed="filterChanged" />
        </div>
      </div>
      <div style="margin-top: 20px">
        <v-progress-circular
          v-show="isLoading"
          class="search-spinner"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
        <p
          v-if="!isLoading && (!items || items.length === 0)"
          class="foody-search-notfound"
        >
          Ingen restauranter funnet i nærheten
        </p>
        <div v-if="!isLoading" class="eateries-container">
          <EaterySearchCard
            v-for="(item, i) in items"
            :key="item.eateryId"
            :i="i"
            :eatery-item="item"
            :preferred-eating-mode="selectedEatingMode"
          />
        </div>
        <div v-if="getQrEateries.length > 0" style="margin-top: 3em">
          <div class="centerheader">Ut og spise</div>
          <div class="eateries-container">
            <EaterySearchCard
              v-for="(item, i) in getQrEateries"
              :key="item.eateryId"
              :i="i"
              :eatery-item="item"
              :preferred-eating-mode="selectedEatingMode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeoSearchField from "@/components/common/GeoSearchField.vue";
import MatFilter from "@/components/MatFilter.vue";
import EaterySearchCard from "@/components/eatery/EaterySearchCard.vue";

export default {
  components: {
    GeoSearchField,
    MatFilter,
    EaterySearchCard,
  },
  data: () => {
    return {
      isLoading: true,
      activeFilters: [],
      eateries: null,
      selectedEatingMode: null,
    };
  },
  computed: {
    searchTerm() {
      return this.$route.params.term || "";
    },
    items() {
      const eateries = [...this.$store.getters.eateries];
      eateries.sort(function (a, b) {
        return b.visibilityLevel - a.visibilityLevel;
      });
      const onlyVisibleEateries = eateries.filter(
        (x) =>
          (x.visibilityLevel != 1 && !x.dineInIsQrOnly) ||
          x.takeAway ||
          x.delivery
      );
      return onlyVisibleEateries;
    },
    getQrEateries() {
      const eateries = [...this.$store.getters.eateries];
      eateries.sort(function (a, b) {
        return b.visibilityLevel - a.visibilityLevel;
      });
      const onlyQrModulEnabled = eateries.filter(
        (x) =>
          x.visibilityLevel != 1 &&
          x.dineInIsQrOnly &&
          !x.takeAway &&
          !x.delivery
      );
      return onlyQrModulEnabled;
    },
  },
  async created() {
    this.onSearch(this.searchTerm);
  },
  methods: {
    onSearch: async function onSearch(searchText) {
      this.isLoading = true;
      // Send a query with new filters
      await this.$store.dispatch("getEateriesByQuery", {
        addressString: searchText,
        ...this.activeFilters,
      });
      // Update URL with new search term
      if (
        searchText !== null &&
        this.$route.fullPath !== `/search/${searchText}`
      ) {
        this.$router.replace(`/search/${searchText}`);
      }
      // Get static eatery data (for use in filtering)
      // This is so the available kitchenTypes won't change based on filtering
      this.eateries = Object.assign({}, this.$store.getters.eateries);
      this.isLoading = false;
    },
    filterChanged: async function filterChanged(activeFilters) {
      this.isLoading = true;
      // Update filter data
      this.activeFilters = activeFilters;
      // Send a query with new filters
      await this.$store.dispatch("getEateriesByQuery", {
        addressString: this.searchTerm,
        ...this.activeFilters,
      });
      // Update the selected eating mode, so we can send it to eatery item
      this.selectedEatingMode =
        this.activeFilters && this.activeFilters["EatingMode"]
          ? parseInt(this.activeFilters["EatingMode"], 10)
          : 0;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.centerheader {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}

.border-line {
  border-bottom: 4px solid;
  border-color: #e2e7ed;
  margin: 10px 0px 10px 0px;
}

@media (min-width: 426px) {
  .foody-search-notfound {
    padding: 15px 0;
  }
}

.eateries-container {
  display: grid;
  grid-gap: 30px;
}

.searchheader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  font-weight: bold;
  width: 100%;
}

.searchheader__title {
  flex: 1;
  font-size: 28px;
  font-weight: 700;
  margin-left: 0;
}

.searchheader__filter {
  flex: 1;
  justify-content: flex-end;
}

/* 
  We want to specify the maximum numbers of columns
  repeat() will create using auto-fit
  To do this, we use variables.
  https://stackoverflow.com/a/54907809
*/

.eateries-container {
  /*
      If variables are not supported, we use the full width
    */
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  /* 
      Show a column with 2 360px rows if possible
      Else, go to next column
    */
  grid-template-columns: repeat(
    var(--repeat, auto-fit),
    minmax(calc(360px * 1), 1fr)
  );
}

/* By default, auto-fit columns */
.eateries-container {
  --repeat: auto-fit;
}
/* If we can fit two eatery containers, set repeat to 2 rows */
@media (min-width: calc(360px * 2)) {
  .eateries-container {
    --repeat: 2;
  }
}
@media (min-width: 960px) {
  .searchcard-container {
    padding: 50px 10vw;
  }
}
</style>
