<template>
  <div class="mt-4 mb-4" style="max-width: 600px; margin: 0 auto">
    <v-card>
      <div class="banner-container">
        <v-img :src="currentEatery.bannerUrl" class="eatery-logo">
          <div class="banner-header">
            <div class="nav-back"></div>
            <div class="company-header">
              <h1>{{ currentEatery.displayName }}</h1>
              <div
                v-if="currentEatery.kitchenTypes"
                class="kitchentype-container"
              >
                <span
                  v-for="ktype in currentEatery.kitchenTypes"
                  :key="ktype.id"
                >
                  {{ ktype.name }}
                </span>
              </div>
              <div class="address-text">
                <v-icon color="white">mdi-map-marker</v-icon>
                {{ currentEatery.address.streetAddress }},
                {{ currentEatery.address.postalCode }}
                {{ currentEatery.address.postalArea }}
              </div>
            </div>
            <div
              class="nav-info clickable"
              @click.stop="showEateryInfoModal = true"
            >
              <v-icon class="nav-info-icon" x-large
                >mdi-information-outline</v-icon
              >
            </div>
          </div>
        </v-img>
      </div>
      <EateryInfoModal
        v-model="showEateryInfoModal"
        :eateryInfo="currentEatery"
      />
      <v-card-text style="overflow: hidden">
        <v-container style="padding: 0px">
          <v-card flat>
            <v-form ref="form" @submit.prevent="submit">
              <v-container
                style="bottom-margin: 0px; align-items: center"
                fluid
              >
                <!-- number of people  -->
                <v-row cols="12" lg="6" xs="12" align="center" justify="center">
                  <div class="button-group-date-clock-container">
                    <v-col
                      cols="12"
                      lg="6"
                      md="7"
                      sm="12"
                      xs="12"
                      align-self="center"
                    >
                      <p class="choose-persons-amount-label">
                        Velg antall personer
                      </p>
                      <v-btn-toggle
                        v-model="form.numberOfPeople"
                        color="#9f0073"
                        background-color="#fff"
                      >
                        <template v-for="n in 10">
                          <v-btn
                            :key="n"
                            class="toggle-buttons"
                            elevation="3"
                            :value="n"
                          >
                            {{ n === 10 ? `${n}+` : n }}
                          </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-col>

                    <!-- Information container -->
                    <div v-if="form.numberOfPeople === 10">
                      <v-alert
                        border="top"
                        colored-border
                        color="#9f0073"
                        type="info"
                        elevation="2"
                      >
                        {{ currentEateryName }} tar kontakt for å bekrefte
                        reservasjonen
                      </v-alert>
                    </div>

                    <!-- time and date selector -->
                    <v-col>
                      <TimeAndDateSelector
                        :date="date"
                        :time="time"
                        :currentEatingMode="currentEatingMode"
                        :validTime="validTime"
                        :openingTimeBufferInMinutes="orderReadyEstimateMinutes"
                        :reservation="true"
                        @time="setTime = $event"
                        @date="setDate = $event"
                        @validTime="validTimeInput = $event"
                        @invalidReasons="invalidReasonsInput = $event"
                      >
                      </TimeAndDateSelector>
                    </v-col>
                  </div>
                </v-row>

                <!-- personal information - fullname, phone and email -->
                <v-col cols="12" sm="12" md="12" lg="12">
                  <PersonalInformationForm
                    v-disable-tab-on-class="['.mdi-close']"
                    :wantFullName="true"
                    :wantFirstname="false"
                    :wantLastname="false"
                    :fullName="form.name"
                    :email="form.email"
                    :phone="form.phone"
                    :personal-information-form-valid="
                      personalInformationFormValid
                    "
                    @fullName="form.name = $event"
                    @email="form.email = $event"
                    @phone="form.phone = $event"
                    @personalInformationFormValid="
                      personalInformationFormValid = $event
                    "
                  />
                </v-col>
                <v-row
                  class="justify-center alert-box"
                  cols="12"
                  lg="6"
                  xs="12"
                >
                  <ErrorComponent
                    v-if="!validTime"
                    :message="getInvalidReasonString"
                  />
                </v-row>
                <v-col cols="12" class="pt-7 pb-0">
                  <v-textarea
                    v-model="form.request"
                    outlined
                    auto-grow
                    label="Melding til spisestedet"
                  ></v-textarea>
                </v-col>
                <v-row cols="12" align="center" justify="center">
                  <v-card-actions>
                    <FoodyButton
                      :parentclasses="[]"
                      :btn-color="'primary'"
                      :on-click="submit"
                      :disabled="!formIsValid"
                    >
                      Send hendvendelse
                    </FoodyButton>
                  </v-card-actions>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-container>
      </v-card-text>
      <div v-if="showError" style="margin: 20px">
        <ErrorMsgBox
          v-if="showError"
          :error="{
            message:
              'En feil oppsto ved sending av reservasjonen. Prøv igjen eller ta kontakt med spisestedet.',
            type: 'error',
          }"
          :selectedDate="date"
          :selectedTime="time"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import codeBehind from "./TableReservation.ts";
export default Vue.extend({
  name: "TableReservation",
  mixins: [codeBehind],
});
</script>

<style scoped>
.v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  height: 57px !important;
}
.v-time-picker-title {
  height: 57px !important;
}

::v-deep .fullname-and-email {
  padding-right: 0em;
}

.alert-box {
  margin-top: 16px;
  white-space: pre-line;
}

::v-deep .phone-row {
  padding-right: 0em;
}

.kitchentype-container {
  display: block;
  margin-top: -5px;
}
.kitchentype-container span {
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px 5px black;
}

.banner-bg {
  max-height: 400px;
  background-position: top center;
}
.banner-header {
  display: flex;
}
.banner-header .nav-back {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-width: 50px;
}
.nav-back-icon {
  color: white;
}
.address-text {
  padding-top: 10px;
  color: white;
}
.banner-header .company-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 5px black;
}
.banner-header .company-header h1 {
  margin-left: 0;
  font-size: 2em;
  color: #ffffff;
}
.banner-header .kitchentype-container span {
  padding: 0 10px;
  border-right: 2px solid white;
}
.banner-header .kitchentype-container span:last-of-type {
  border-right: none;
}
.banner-header .nav-info {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 50px;
  align-items: flex-start;
}
.nav-info-icon {
  padding-top: 5px;
  padding-right: 25px;
  color: white;
}
.banner-container {
  flex: 1 0 0px;
  text-align: center;
  margin-top: 1px;
  padding-top: 1px;
}
.banner-content {
  display: flex;
  flex-direction: row;
}
.banner-dropdown {
  margin-left: 100px;
  margin-right: 100px;
}
.banner-img {
  max-height: 150px;
  width: auto;
  max-width: 250px;
  border-radius: 20px;
}
.banner-text {
  flex-grow: 1;
  vertical-align: text-top;
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 15px;
}
.banner-text span {
  font-size: 1.4em;
  line-height: 1.5em;
}

.button-group-date-clock-container {
  margin-top: 10px;
}

.choose-persons-amount-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}

@media only screen and (max-width: 520px) {
  .banner-img {
    max-height: 100px;
    max-width: 60%;
  }
  .toggle-buttons {
    min-width: 30px !important;
  }
  .nav-info-icon {
    padding-right: 10px;
  }
  .banner-container {
    text-align: center;
    margin-top: 1px;
    padding-top: 1px;
  }
  .banner-header {
    margin-top: 0;
  }
  .banner-header .company-header h3 {
    font-size: 2em;
  }
  .banner-content {
    display: flex;
    flex-direction: column;
  }
  .banner-text {
    flex-grow: 1;
    vertical-align: text-top;
    font-size: 0.8em;
  }
  .banner-img {
    max-height: 100px;
    max-width: 60%;
  }
  .banner-text span {
    font-size: 1.1em;
    line-height: 1.2em;
  }
}

@media only screen and (max-width: 376px) {
  .toggle-buttons {
    min-width: 35px !important;
    width: 35px !important;
  }
}

@media only screen and (max-width: 321px) {
  .toggle-buttons {
    min-width: 30px !important;
    width: 30px !important;
  }
}
</style>
