<template>
  <section class="partner-faq">
    <h2>Ofte stilte spørsmål</h2>
    <div class="faq-list">
      <v-expansion-panels
        v-for="(columns, index) in splitFaq"
        :key="index"
        class="accordion"
      >
        <v-expansion-panel
          v-for="{ title, body, linkText, redirectUrl } in columns"
          :key="title"
          class="collapse"
        >
          <v-expansion-panel-header
            class="collapse-header"
            icon-color="primary"
            hide-actions
          >
            <template #default="{ open }">
              {{ title }}
              <v-icon v-if="!open" class="icon" color="primary">$plus</v-icon>
              <v-icon v-if="open" class="icon" color="primary">$minus</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="content">
            {{ body }}
            <a v-if="redirectUrl" @click="redirectTo(redirectUrl)">
              {{ linkText }}
            </a>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </section>
</template>

<script>
import partnerFaq from "./PartnerFaq.ts";
import { splitArray } from "@/util/arrayOperations";
export default {
  computed: {
    splitFaq() {
      return splitArray(partnerFaq, partnerFaq.length / 2);
    },
  },
  methods: {
    redirectTo(redirectUrl) {
      this.$router.push("/partner/" + redirectUrl);
    },
  },
};
</script>

<style scoped>
.partner-faq {
  background-color: var(--partner-dark-background-color);
  color: white;
  padding: 4rem 0;
  text-align: center;
}
.partner-faq > * {
  max-width: 1140px;
  margin: 0 auto 0 auto;
}
.partner-faq .collapse {
  background-color: var(--partner-dark-background-color);
  color: white;
  border-bottom: 1px solid var(--partner-gray-border-color);
  border-radius: unset;
}
.partner-faq .collapse::before {
  box-shadow: none;
}
.partner-faq .collapse .content {
  text-align: left;
  font-size: 1rem;
}
.faq-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;
  padding: 4em 0;
}
.faq-list .accordion {
  display: block;
}
.icon {
  justify-content: flex-end;
}

@media only screen and (max-width: 750px) {
  .faq-list {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
}
</style>
