<template>
  <div>
    <Loader />
    <div class="full-width-exception eating-mode-switch-container">
      <v-card v-if="eateryInfo">
        <!-- <img :src="eateryInfo.bannerUrl" class="banner-img" /> -->
        <!-- <Banner :eatery-info="eateryInfo" /> -->
        <EatingModeSwitch :currentEatery="currentEatery" />
      </v-card>
    </div>
    <!-- <v-container>
      <v-card*
        max-width="1300"
        v-if="showTableReservationBar"
        max-width="1300"
      >
        <TableReservationInit></TableReservationInit>
      </v-card>
    </v-container> -->
    <div v-if="menus">
      <div class="foody-menus-container">
        <MenuSmall
          v-for="item in filterMenuesBasedOnEatingModeAndOpeningHours"
          :key="item.id"
          :item="item"
        />
      </div>
    </div>
    <EaterySeoDescription
      v-if="currentEatery.seoDescription"
      class="eatery-seo-description"
      :description="currentEatery.seoDescription"
    ></EaterySeoDescription>
  </div>
</template>

<script>
import Loader from "@/components/common/Loader.vue";
import MenuSmall from "@/components/menues/MenuSmall";
import EatingModeSwitch from "@/components/eatery/EatingModeSwitch.vue";
import Moment from "moment";
import { timeIsValidForDate } from "@/helpers/eateryOpeningTimeValidator";
import { currentEatingModeIsOpenNow } from "@/helpers/currentEateryHelper";
import EaterySeoDescription from "@/components/common/EaterySeoDescription.vue";

export default {
  components: {
    Loader: Loader,
    MenuSmall: MenuSmall,
    EatingModeSwitch: EatingModeSwitch,
    EaterySeoDescription
},
  props: {
    cname: {
      type: String,
    },
    buttonText: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    hasTableObject() {
      return this.$store.getters.tableObject;
    },
    currentEatingMode() {
      return this.$store.getters.selectedEatingMode;
    },
    currentEateryName() {
      return this.$store.getters.eateryName;
    },
    eateryInfo() {
      return this.$store.getters.eateryInfo;
    },
    menus() {
      const menus = this.$store.getters.menus;
      return menus;
    },

    showTableReservationBar() {
      const showBar = !(
        this.$store.getters.tableObject?.TableId != null &&
        this.$store.getters.tableObject.TableId > 0
      );
      return showBar;
    },
    filterMenuesBasedOnEatingModeAndOpeningHours() {
      const activeMenues = [];
      if (this.hasTableObject) {
        this.menus.map((menu) => {
          if (menu.allowDineIn && this.isCurrentMenuOpen(menu)) {
            activeMenues.push(menu);
          }
        });
        return activeMenues;
      }
      this.menus.map((menu) => {
        if (this.currentEatingMode === 1 && menu.allowDineIn == true) {
          activeMenues.push(menu);
        }
        if (this.currentEatingMode === 2 && menu.allowTakeAway == true) {
          activeMenues.push(menu);
        }
        if (this.currentEatingMode === 3 && menu.allowDelivery == true) {
          activeMenues.push(menu);
        }
      });
      return activeMenues;
    },
  },
  created() {
    if (this.hasTableObject) {
      if (this.filterMenuesBasedOnEatingModeAndOpeningHours.length == 1) {
        this.goToMenu(this.filterMenuesBasedOnEatingModeAndOpeningHours[0]);
      }
    }
    const isQr =
      this.$route.query.source === "qrcode" ||
      !!this.$store.getters.tableObject?.eateryName;
    this.$store.dispatch("setEatingModeBasedOnCurrentEateryAndMenu", isQr);
  },
  methods: {
    isCurrentMenuOpen(menu) {
      const eatingModeIsOpenNow = currentEatingModeIsOpenNow();
      const menuHasOpeningHours = !!menu.openingHours;

      if (!menuHasOpeningHours) return eatingModeIsOpenNow;

      const menuIsOpenNow = timeIsValidForDate(
        Moment().format("HH:mm"),
        Moment().format("YYYY-MM-DD"),
        menu.openingHours,
        0
      );
      return menuIsOpenNow && eatingModeIsOpenNow;
    },
    goToMenu: function (item) {
      this.$router.push("/" + this.currentEateryName + "/menu/" + item.id);
    },
  },
};
</script>

<style scoped>

.eatery-seo-description {
  margin: 1rem 3rem;
}
.eating-mode-switch-container {
  margin-top: 60px;
}

@media only screen and (max-width: 600px) {
  .eating-mode-switch-container {
    margin-top: 0px;
  }
}
</style>
