import Moment from "moment";
import {
  getEatingModeDto,
  getPausedUntilUtcDate,
} from "@/types/dto/eateries/eateryDtoFunctions";
import OpeningHoursDto from "@/types/dto/eateries/openingHoursDto";
import timeHelper from "@/helpers/timeHelper";
import { calculateDisabledTimeArrays } from "./disabledTimesCalculator";
import { validateDateTime } from "@/helpers/currentEateryHelper";

const codeBehind = {
  name: "TimePicker",
  data() {
    return {
      show: false,
      styleNormal: {
        borderBottom: "1px solid #949494",
      },
      styleError: {
        borderBottom: "1px solid #fb8c00",
      },
      validTime: true,
      disabledHoursArray: Array<number>(),
      disabledMinutesArray: Array<number>(),
      disabled: true,
    };
  },
  props: {
    time: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    eatingMode: {
      type: Number,
      required: true,
    },
    reservation: {
      type: Boolean,
      required: true,
    },
    openingTimeBufferInMinutes: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  mounted() {
    //Generate disabled time arrays and validate time
    this.generateDisabledArrays();
    this.validateTime();

    //Set up complex watchers
    this.$watch(
      (vm: any) => [vm.date, vm.time, vm.eatingMode],
      () => {
        this.generateDisabledArrays();
      }
    );
    this.$watch(
      (vm: any) => [vm.eatingMode, vm.time],
      () => {
        this.validateTime();
      }
    );
  },
  computed: {
    currentOpeningHours(): OpeningHoursDto[] {
      const currentEatery = this.$store.getters.eateryInfo;
      return getEatingModeDto(currentEatery, this.eatingMode).openingHours;
    },
    currentPausedUntilDate(): Date | undefined {
      const currentEatery = this.$store.getters.eateryInfo;
      return getPausedUntilUtcDate(currentEatery);
    },
    timeBinding: {
      get() {
        return timeHelper.getDateTimeFromDateAndTimeStrings(
          this.date,
          this.time
        );
      },
      set(val: Date) {
        const time = Moment(val);
        this.$emit("time", time.format("HH:mm"));
        this.generateDisabledArrays();
        this.validateTime();
      },
    },
  },
  methods: {
    validateTime(): boolean {
      const validationMessages = validateDateTime(
        this.date,
        this.time,
        this.reservation
      );
      const isValid = validationMessages.length === 0;
      this.$emit("invalidReasons", validationMessages);
      this.$emit("timeValid", isValid);
      this.validTime = isValid;
      return isValid;
    },
    reset() {
      this.time = "";
    },
    generateDisabledArrays() {
      const { hours, minutes } = calculateDisabledTimeArrays(
        this.date,
        this.time,
        this.currentOpeningHours,
        this.openingTimeBufferInMinutes
      );

      this.disabledHoursArray = hours;
      this.disabledMinutesArray = minutes;
      this.disabled = hours.length >= 24;
    },
    onClickEditTime() {
      this.show = !this.disabled;
    },
  },
};
export default codeBehind;
