import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import VuexPersist from "vuex-persist";

import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import RootState from "../types/state/rootState";
import { eateryModule } from "./eateries";
import { userOptionsModule } from "./userOptions";
import { shoppingCartErrorModule } from "./shoppingCartErrors";
import { citiesModule } from "./cities";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    lastSelectedEatery: state.lastSelectedEatery,
    lastMenuId: state.lastMenuId,
    loggedInUser: state.loggedInUser,
    cart: state.cart,
    dineInOrder: state.dineInOrder,
    tableObject: state.tableObject,
    token: state.token,
    refreshToken: state.refreshToken,
  }),
});
const storeOptions: StoreOptions<RootState> = {
  plugins: [vuexLocalStorage.plugin],
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    eateryModule,
    userOptionsModule,
    shoppingCartErrorModule,
    citiesModule,
  },
};

export default new Store<RootState>(storeOptions);
