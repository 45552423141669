import DatePicker from "@/components/common/datePicker/DatePicker.vue";
import TimePicker from "@/components/common/timePicker/TimePicker.vue";
const codebehind = {
  components: { DatePicker, TimePicker },
  props: {
    date: {
      type: String,
      required: true,
    },
    currentEatingMode: {
      type: Number,
      required: true,
    },
    // If true, check if reservation is at least 45 minutes before closing time
    reservation: {
      type: Boolean,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    validTime: {
      type: Boolean,
      required: true,
      default: false,
    },
    invalidReasons: {
      type: Array<string>,
      required: false,
      default: () => [] as string[],
    },
    openingTimeBufferInMinutes: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    dateInput: {
      get() {
        return this.date;
      },
      set(val: string) {
        this.$emit("date", val);
      },
    },
    currentEatingModeInput: {
      get() {
        return this.currentEatingMode;
      },
    },
    timeInput: {
      get() {
        return this.time;
      },
      set(val: string) {
        this.$emit("time", val);
      },
    },
    validTimeInput: {
      get() {
        return this.validTime;
      },
      set(val: string) {
        this.$emit("validTime", val);
      },
    },
    invalidReasonsInput: {
      get() {
        return this.invalidReasons;
      },
      set(val: string[]) {
        this.$emit("invalidReasons", val);
      },
    },
  },
};
export default codebehind;
