<template>
  <v-dialog v-model="show" width="500">
    <FoodyCard>
      <v-card-title> Inneholder følgende allergener: </v-card-title>
      <v-card-text v-if="allergies.length === 0">
        Dette produktet har ingen kjente allergener
      </v-card-text>
      <v-card-text v-if="allergies.length > 0">
        <ul v-for="allergy in allergies" :key="allergy">
          <li class="capitalLetter">
            {{ allergy }}
          </li>
        </ul>
      </v-card-text>
      <v-card-text> {{ item.allergensFreeText }} </v-card-text>
    </FoodyCard>
  </v-dialog>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
import {
  sortAllergensBetweenGroupedAndUngrouped,
  createAllergyList,
} from "./utils";
export default {
  name: "AllergyList",
  components: { FoodyCard },
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasAllergens() {
      return this.item && this.item.length > 0;
    },
    allergies() {
      const collection = sortAllergensBetweenGroupedAndUngrouped(
        this.item.allergens
      );
      const allergies = createAllergyList(collection);
      return allergies;
    },
  },
  methods: {},
};
</script>
<style scoped>

.capitalLetter {
  text-transform: capitalize;
}

</style>