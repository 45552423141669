<template>
  <div class="foodcard-properties-box">
    <div
      v-for="fp in foodProperties"
      :key="fp.id"
      style="width: 100%"
      class="food-item-option"
    >
      <FoodOptionRadio v-if="!fp.multiSelect" :foodProp="fp" />
      <FoodOptionMulti v-if="fp.multiSelect" :foodProp="fp" />
    </div>
  </div>
</template>
<script>
import FoodOptionRadio from "@/components/menues/menu-items/menu-item-properties/FoodOptionRadio.vue";
import FoodOptionMulti from "@/components/menues/menu-items/menu-item-properties/FoodOptionMulti.vue";
export default {
  name: "FoodPropertiesMulti",
  components: {
    FoodOptionRadio,
    FoodOptionMulti,
  },
  props: {
    foodProperties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
