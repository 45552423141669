<template>
  <v-container fluid>
    <FoodyCard :parentclasses="['customerRegistration-view']">
      <v-row>
        <v-col cols="12" sm="12">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                <slot name="header" />
              </h3>
              <div>
                <slot name="content" />
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="12">
          <v-card-actions>
            <slot name="actions" />
          </v-card-actions>
        </v-col>
      </v-row>
    </FoodyCard>
  </v-container>
</template>

<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
export default {
  components: {
    FoodyCard: FoodyCard,
  },
  props: {
    header: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
    actions: {
      type: String,
      required: false,
    },
  },
};
</script>
