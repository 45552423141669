<template>
  <div class="main-content">
    <div
      class="search-container full-width-exception"
      :style="{
        backgroundColor: '#111',
        backgroundImage:
          'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' +
          require('../../../src/assets/img/mainGetFood.png') +
          ')',
      }"
    >
      <div class="search-content">
        <div class="searchheader">Finn restauranter nær deg</div>
        <GeoSearchField :onSearch="search" :isFrontpage="true" />
      </div>
    </div>

    <CitiesSection />
    <div class="border-line"></div>

    <EateriesWithOnlineOrderingSection />
    <div class="border-line"></div>

    <EateriesWithoutOnlineOrderingSection />
    <div class="border-line"></div>

    <PartnerSection />

    <div class="full-width-exception">
      <PartnerFooter></PartnerFooter>
    </div>
  </div>
</template>

<script>
import GeoSearchField from "@/components/common/GeoSearchField";
import CitiesSection from "./CitiesSection.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import EateriesWithOnlineOrderingSection from "./EateriesWithOnlineOrderingSection.vue";
import EateriesWithoutOnlineOrderingSection from "./EateriesWithoutOnlineOrderingSection.vue";
import PartnerSection from "./PartnerSection.vue";

export default {
  components: {
    PartnerFooter,
    CitiesSection,
    GeoSearchField,
    EateriesWithOnlineOrderingSection,
    EateriesWithoutOnlineOrderingSection,
    PartnerSection,
  },
  methods: {
    search: function (searchText) {
      this.$router.push(`/search/${encodeURIComponent(searchText)}`);
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  color: #222222;
}

.border-line {
  border-bottom: 4px solid;
  border-color: #f5f8fa;
  margin: 10px 0px 10px 0px;
}

.search-container {
  min-height: 30vh;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.searchheader {
  font-size: 2.8125rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
}

.main-content {
  margin: 0 auto;
}

@media (min-width: 426px) {
  .search-content {
    position: relative;
    padding: 50px 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
