import {
  checkIfEatingModeClosed,
  getMininumDeliveryPrice,
  setAvailableEatingMode,
} from "@/types/dto/eateries/eateryDtoFunctions";
import RootState from "@/types/state/rootState";
import { ActionTree } from "vuex";
import ShoppingCartErrorState from "./state";
import store from "../../store/index";
import { EatingMode } from "@/types/enums/eatingMode";

function shoppingCartSum(cartList: any) {
  const cart = cartList;
  let orderSum = 0;
  cart.forEach(function (cartItem: any) {
    if (cartItem.nrOfItems > 1) {
      orderSum += cartItem.totalPrice * cartItem.nrOfItems;
    } else {
      orderSum += cartItem.totalPrice;
    }
  });
  return orderSum;
}

async function DineInOnlyItemInCart(): Promise<boolean> {
  const eateryInfo = store.getters.eateryInfo;
  const currentEatingMode =
    store.getters.selectedEatingMode ??
    (await setAvailableEatingMode(eateryInfo));
  const dineInOnlyItemInCart = store.getters.cart.some(
    (item: { dineInOnly: boolean }) => item.dineInOnly === true
  );

  return currentEatingMode !== EatingMode.DineIn && dineInOnlyItemInCart;
}

export const actions: ActionTree<ShoppingCartErrorState, RootState> = {
  async updateErrorStatus(context: any): Promise<void> {
    const cart = context.getters.cart;
    const eateryInfo = context.getters.eateryInfo;
    const tableobject = context.getters.tableObject;
    const minDeliveryPrice = await getMininumDeliveryPrice(eateryInfo);
    const isEatingModeClosed =
      checkIfEatingModeClosed(eateryInfo) && !tableobject;
    const isQrDineInClosed = checkIfEatingModeClosed(eateryInfo) && tableobject;
    const currentEatingMode =
      store.getters.selectedEatingMode ??
      (await setAvailableEatingMode(eateryInfo));
    const isEatingModeDelivery = currentEatingMode === EatingMode.Delivery;
    const isEatingModeTakeaway = currentEatingMode === EatingMode.TakeAway;
    const dineInOnlyInCart = await DineInOnlyItemInCart();

    context.commit("SET_NO_CART_ITEM", cart.length <= 0);
    context.commit("SET_EATING_MODE_CLOSED", isEatingModeClosed);
    context.commit(
      "SET_BELOW_MINIMUM_DELIVERY_SUM",
      shoppingCartSum(cart) < minDeliveryPrice && isEatingModeDelivery
    );
    context.commit(
      "SET_NOT_AVAILABLE_FOR_DELIVERY",
      dineInOnlyInCart && isEatingModeDelivery
    );
    context.commit(
      "SET_NOT_AVAILABLE_FOR_TAKEAWAY",
      dineInOnlyInCart && isEatingModeTakeaway
    );
    context.commit("SET_QR_DINE_IN_IS_CLOSED", isQrDineInClosed);
  },
};
