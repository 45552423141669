import { PartnerFaq } from "src/types/partner/partner";

const partnerFaq: Array<PartnerFaq> = [
  {
    title: "Hva er prisen?",
    body: "Her finner du mer informasjon om våre priser og ulike moduler:",
    redirectUrl: "prisliste",
    linkText: "Prisliste",
  },
  {
    title: "Må man ha bordreservasjon?",
    body: `Nei, det må man ikke. Bordreservasjoner er en modul som du selv
      bestemmer om du vil ha med.`,
  },
  {
    title: "Lager dere QR skiltene?",
    body: `Dere får tilgang i løsningen deres fra getFOOD til å generere QR
      koder. I utgangspunktet så designer vi ikke f.eks. bordskilter,
      men oppfordrer dere til å bruke det byrået som dere allerede
      bruker. Skulle dere trenge hjelp for å komme fort i gang, så
      hjelper vi dere selvfølgelig mer en gjerne.`,
  },
  {
    title: "Finnes det også en løsning for Bar eller Pub?",
    body: `Ja, det gjør det. Vi til byr QR kode for bestilling og betaling
      for mat og drikke og løsningen vår passer perfekt for bar, pub og
      også cafe. Her finner dere mer informasjon om dette:`,
    redirectUrl: "cafebar",
    linkText: "Bar og Pub",
  },
  {
    title: "Kan vi bruke løsningen i vår Cafe?",
    body: `Vi til byr QR kode for bestilling og betaling for mat og drikke og
      løsningen vår passer perfekt for en cafe. Her finner dere mer
      informasjon om dette:`,
    redirectUrl: "cafebar",
    linkText: "Cafe",
  },
  {
    title: "Vi er en større gruppering, finnes det egne ordninger for oss?",
    body: `Ja, det har vi. Vi har egne priser og avtaler for større
      grupperinger, Foodcourt, Kjøpesenteret, Kjeder og
      innkjøpssamarbeid. Se her for mer info:`,
    redirectUrl: "kjeder",
    linkText: "Grupperinger",
  },
  {
    title: "Har dere smittesporing?",
    body: `For alle våre kunder ligger det mulighet for å bruke
      smittesporing. Vi ser det som en del av vårt samfunnsansvar og
      koster 0,- vi er glade for å kunne være med å bidra i en vanskelig
      og utfordrende situasjon som vi er i.`,
  },
  {
    title: "Må man kjøpe alle modulene?",
    body: `Nei, du velger selv hvilken modul som passer best for deg og ditt
      serveringssted. Her er en oversikt over alle de ulike modulene.`,
    redirectUrl: "prisliste",
    linkText: "Prisliste",
  },
  {
    title: "Hva menes det med at menyen vår også legges i en nettportal?",
    body: `Hvis du benytter deg av vår bestillings og betalingsløsning vil vi
      vi automatisk legge til din bedrift i vår nettportal getfood.no Du
      vil også få din egen url link som du kan benytte det av i dine
      sosiale medier kanal.`,
  },
  {
    title: "Har dere støtte for Take-Away?",
    body: `Ja, det har vi. Med vår løsning vil ditt spisested få en helt egen
      meny i vår portal. Her kan kunder bestille og betale den maten de
      vil ha og komme og hente på et ønsket tidspunkt. Priser og
      oversikt over modulene finner du her.`,
    redirectUrl: "spisesteder",
    linkText: "Spisesteder",
  },
  {
    title: "Hva slags moduler har dere?",
    body: "Her finner du oversikten over alle de modulene vi har:",
    redirectUrl: "prisliste",
    linkText: "Moduler",
  },
  {
    title: "Legger dere inn menyen for oss?",
    body: `Det er klart vi gjør, hvis dere ønsker det. Vi legger inn hele din
      meny slik at du kan raskt komme i gang med løsningen. Det koster
      1000,-`,
  },
  {
    title: "Vi driver hotell, passer det for oss?",
    body: `Ja, løsningen passer perfekt f.eks. til roomservice. Nå blir
      roomservicen lett tilgjengelig for gjestene og bestillingen kan gå
      rett til de riktige personene for en hurtig bestilling. I tillegg
      kan du benytte denne løsningen i hotellets cafeteria og bar. Du
      finner mer info her:`,
    redirectUrl: "hotell",
    linkText: "Hotell",
  },
  {
    title: "Hva betyr webshop for Take Away?",
    body: `Med webshop mener vi den digitale menyen på vår portal som kundene
      kan bestille fra. Menyen er da tilgjengelig for kundene til å
      bestille dine matretter via denne menyen, og hente på ønsket
      tidspunkt.`,
  },
  {
    title: "Kan kunden bestille og betale via QR koden?",
    body: `Ja, kundene kan bestille og betale helt uten å laste ned egen app.
      QR koden scannes lett med telefonen og gjestene får tilgang til
      menyen.`,
  },
];

export default partnerFaq;
