import Inputfield from "@/components/inputFields/Inputfield.vue";
import PhoneInput from "@/components/inputFields/PhoneInput.vue";
import props from "./PersonalInformationForm.props";
const codebehind = {
  components: {
    PhoneInput,
    Inputfield,
  },
  mixins: [props],
  data() {
    return {
      fullNameValid: false,
      firstnameValid: false,
      lastnameValid: false,
      emailValid: false,
      phoneValid: false,
      phoneNumberPrefixOutput: "",
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },
    fullNameInput: {
      get() {
        return this.fullName;
      },
      set(val: string) {
        this.$emit("fullName", val);
      },
    },
    firstnameInput: {
      get() {
        return this.firstname;
      },
      set(val: string) {
        this.$emit("firstname", val);
      },
    },
    lastnameInput: {
      get() {
        return this.lastname;
      },
      set(val: string) {
        this.$emit("lastname", val);
      },
    },
    emailInput: {
      get() {
        return this.email;
      },
      set(val: string) {
        this.$emit("email", val);
      },
    },
    phoneInput: {
      get() {
        return this.phone;
      },
      set(val: string) {
        this.$emit("phone", val);
      },
    },
    fullNameChecker() {
      if (this.wantFullName) return this.fullNameValid;
      return true;
    },
    firstnameChecker() {
      if (this.wantFirstname) return this.firstnameValid;
      return true;
    },
    lastnameChecker() {
      if (this.wantLastname) return this.lastnameValid;
      return true;
    },
    emailChecker() {
      if (this.wantEmail) return this.emailValid;
      return true;
    },
    phoneChecker() {
      if (this.wantPhone) return this.phoneValid;
      return true;
    },

    formValid(): boolean {
      return (
        this.fullNameChecker &&
        this.firstnameChecker &&
        this.lastnameChecker &&
        this.emailChecker &&
        this.phoneChecker
      );
    },
  },
  mounted() {
    if (this.loggedInUser) this.phoneValid = true;
    this.$watch(
      (vm: any) => [vm.loggedInUser],
      () => {
        if (this.loggedInUser) {
          this.phoneValid = true;
        }
      }
    );
    this.$watch(
      (vm: any) => [vm.formValid],
      () => {
        this.$emit("personalInformationFormValid", this.formValid);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  methods: {
    clear() {
      this.$v.$reset();
    },
  },
};
export default codebehind;
