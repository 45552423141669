import Moment from "moment";
import { currentEatingModeIsOpenNow } from "@/helpers/currentEateryHelper";
const codebehind = {
  data() {
    return {
      eatingModeClosed: false
    };
  },
  props: {
    currentEatingMode: {
      type: Number,
      required: true
    },
    selectedTime: {
      type: String,
      required: true
    },
    validateDateAndtime: {
      type: Function,
      required: true
    },
    timeOption: {
      type: String,
      required: true
    },
    currentEatery: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if (currentEatingModeIsOpenNow()) {
      this.eatingModeClosed = false;
    } else {
      this.eatingModeClosed = true;
      this.timeOptionInput = "2";
    }
  },
  computed: {
    selectedTimeInput: {
      get() {
        return this.selectedTime;
      },
      set(val: string) {
        this.$emit("selectedTime", val);
        this.validateDateAndtime;
      }
    },
    timeOptionInput: {
      get() {
        return this.timeOption;
      },
      set(val: string) {
        this.updateTimeOption(val);
        this.$emit("timeOption", val);
      }
    }
  },
  methods: {
    updateTimeOption(timeOptionInput: string) {
      const date = (Moment() as any)._d;
      let time;
      if (timeOptionInput === "1") {
        time = date.getHours() + ":" + date.getMinutes();
        this.selectedTimeInput = time;
      } else {
        this.selectedTimeInput = date.getHours() + ":" + date.getMinutes();
      }
      this.selectedDate = Moment().format("YYYY-MM-DD");
    }
  }
};
export default codebehind;
