<template>
  <div>
    <div v-if="order">
      <div class="flexbox-column.pos">
        <v-card-title v-if="order" class="card-title">
          Takk for din bestilling!
        </v-card-title>

        <v-card-subtitle class="text-center">
          <div>Orgnr: {{ `${companyInfo.companyOrgNr}` }}</div>
          <div>
            Ordre tid: <time>{{ order.orderDateTime }}</time>
          </div>
          <div v-if="isNotDineIn" class="order-ready-text">
            {{ doneText }}: <time>{{ order.completionDateTime }}</time>
          </div>
        </v-card-subtitle>
      </div>
      <v-divider class="receipt-divider" />
      <div v-if="$store.getters.eateryInfo.hasInfectionTrackingEnabled">
        <div class="infection-tracking-div">
          <h3>Hjelp oss med å spore smitte</h3>
          <FoodyButton
            :on-click="redirectToInfectionTracking"
            :btn-color="'primary'"
            :outlined="true"
            >Registrer ditt besøk</FoodyButton
          >
        </div>
        <v-divider class="receipt-divider" />
      </div>
      <h3
        v-if="order.items && order.items.length > 0"
        class="margin-bottom-10px margin-top-10px"
      >
        Ordre {{ order.dailyId }}
      </h3>
      <v-list v-if="order.items && order.items.length > 0" subheader dense>
        <v-simple-table>
          <template v-for="item in order.items">
            <section
              :key="item.name"
              class="recipe-list-item-box border-bottom"
            >
              <div>
                <tr class="flexbox-row order-items">
                  <td class="font-weight-bold">
                    <v-list-item-title v-text="item.count + ' x '" />
                  </td>
                  <td class="font-weight-bold">
                    <v-list-item-title v-text="item.name" />
                  </td>
                  <td class="item-end font-weight-bold">
                    <v-list-item-title
                      v-text="
                        (item.totalCost / 100).toFixed(2) + ' ' + order.currency
                      "
                    />
                  </td>
                </tr>
              </div>
              <tr
                v-for="(choice, index) in item.choices"
                :key="item.name + choice.name + index"
              >
                <td>
                  <v-list-item-subtitle>
                    {{ choice.name }}
                  </v-list-item-subtitle>
                </td>
              </tr>
            </section>
          </template>
        </v-simple-table>

        <v-simple-table class="recipe-item-box">
          <!-- Sum  -->
          <div class="flexbox-column">
            <tr class="flexbox-row margin-bottom-10px">
              <td>
                <v-list-item-subtitle> Sum</v-list-item-subtitle>
              </td>
              <td class="item-end">
                <v-list-item-subtitle>
                  {{
                    `${(order.totalCostItemsOnly / 100).toFixed(2)} ${
                      order.currency
                    }`
                  }}
                </v-list-item-subtitle>
              </td>
            </tr>
            <!-- mva -->
            <tr class="flexbox-row margin-bottom-10px">
              <td>
                <v-list-item-subtitle
                  >{{ `Herav mva ${order.vatPercentage} %` }}
                </v-list-item-subtitle>
              </td>
              <td class="item-end">
                <v-list-item-subtitle>
                  {{ `${(order.vatCost / 100).toFixed(2)} ${order.currency}` }}
                </v-list-item-subtitle>
              </td>
            </tr>
            <!-- Tips -->
            <tr class="flexbox-row margin-bottom-10px">
              <td>
                <v-list-item-subtitle v-text="`Tips`" />
              </td>
              <td class="item-end">
                <v-list-item-subtitle
                  >{{ `${tipsAmountInKr} ${order.currency}` }}
                </v-list-item-subtitle>
              </td>
            </tr>
          </div>
        </v-simple-table>
        <v-divider class="receipt-divider" />
        <v-simple-table>
          <!-- Total pris -->
          <tr class="total-amount-text-box">
            <td>
              <v-list-item-title class="font-weight-bold" v-text="'Total'" />
            </td>
            <td class="align-text-end">
              <v-list-item-title class="font-weight-bold">
                {{ `${(order.totalCost / 100).toFixed(2)} ${order.currency}` }}
              </v-list-item-title>
            </td>
          </tr>
        </v-simple-table>
      </v-list>

      <section
        v-if="order.tableReservation && order.tableReservation.nrOfPeople > 0"
      >
        <h4>
          Bordbestilling til kl: (<time>{{
            order.tableReservation.reservationDateTimeString
          }}</time
          >)
        </h4>
        <div>For antall personer: {{ order.tableReservation.nrOfPeople }}</div>
        <div v-if="order.tableReservation.request">
          kommentar: {{ order.tableReservation.request }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
import { EatingMode } from "@/types/enums/eatingMode";

export default {
  components: {
    FoodyButton,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    companyInfo() {
      return this.$store.getters.eateryInfo;
    },
    tipsAmountInKr() {
      const tips =
        this.order.extraItems.filter((item) => item.description === "Tips")[0]
          ?.cost ?? 0;
      return (tips / 100).toFixed(2);
    },
    doneText() {
      switch (this.order.orderType) {
        case EatingMode.TakeAway:
          return "Forventet klart til henting";
        case EatingMode.Delivery:
          return "Forventet levert";
        case EatingMode.DineIn:
          return "Ordre ferdig";
        default:
          return "Ordre ferdig";
      }
    },

    isNotDineIn() {
      if (this.order.orderType !== EatingMode.DineIn) {
        return true;
      }
      return false;
    },
  },
  methods: {
    redirectToInfectionTracking() {
      const path = `/${this.$store.getters.eateryName}/smittesporing`;
      if (this.$route.path !== path) this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.v-list-item__subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Poppins";
  font-size: 0.825rem;
}
.v-list-item__title {
  font-family: "Poppins";
  font-size: 0.825rem;
}
.receipt-divider {
  margin-bottom: 15px;
}
.font-weight-bold {
  font-weight: bold;
}
.sub-header-padding {
  padding: 0 16px;
}
.card-title {
  font-size: 28px;
  justify-content: center;
}

.align-text-end {
  text-align: end;
}

.text-center {
  text-align: center;
}

.recipe-item-box {
  margin-top: 10px;
}

.recipe-list-item-box {
  margin-bottom: 10px;
}

.infection-tracking-button {
  margin-top: 10px;
}
.flexbox-column {
  display: flex;
  flex-flow: column;
  column-gap: 10px;
}

.flexbox-column.pos {
  justify-content: center;
  text-align: center;
}

.flexbox-row {
  display: flex;
  flex-flow: row;
}

.order-items {
  gap: 10px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-top-10px {
  margin-top: 10px;
}

.gap-column-10px {
  column-gap: 10px;
}

.item-end {
  margin-left: auto;
}

.infection-tracking-div {
  display: flex;
  flex-flow: column wrap;
  flex-direction: column;
  row-gap: 5px;
  align-content: center;
  margin: 10px 0 10px 0;
}

.border-bottom {
  border-bottom: 1px solid rgba(165, 162, 162, 0.2);
}

.order-ready-text {
  color: #9f0073;
  font-weight: bolder;
}

.total-amount-text-box {
  margin-bottom: 40px;
}

@media screen and (max-width: 960px) {
}
</style>
