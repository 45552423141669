<template>
  <section class="devices">
    <v-btn x-large color="primary" dark> Bli kunde nå </v-btn>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
.devices {
  background-image: url("../../assets/img/bg_devices.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 800px;
  z-index: -1;
}
</style>
