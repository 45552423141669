<template>
  <div class="prices">
    <span class="header"> Priser </span>
    <div class="prices-list">
      <div class="prices-list-item">
        <span class="single-price-desc">
          <span
            >Ta kontakt for priser og tilbud for kjeder, grupper og
            innkjøpssamarbeid.</span
          >
          <br /><br />
          <span>Ta en titt på vår </span><a @click="navToPrices">prisliste</a
          ><span> for veiledende priser for frittstående spisesteder</span
          >.<br /><br />
          <span>Du kan kontakte oss ved å fylle ut </span
          ><a @click="navToContact">dette skjemaet.</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navToPrices() {
      this.$router.push("/partner/prisliste");
    },
    navToContact() {
      this.$router.push("/partner/contact");
    },
  },
};
</script>

<style scoped>
.price {
  text-align: right;
}
p {
  font-weight: normal;
  font-style: italic;
  color: #828282;
  margin: 0;
  font-size: 0.8em;
  text-align: left;
}
.prices {
  background-color: #ffffff;
}
.prices .header {
  font-size: 1.7em;
  font-weight: bold;
  color: #000000;
  width: 100%;
}
.single-price-desc {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.prices-list {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 3px 20px;
  font-size: 1.2em;
}
.prices-list hr {
  grid-column: 1 / -1;
}

.prices-list-item {
  display: contents;
  text-align: start;
}
@media only screen and (max-width: 1200px) {
}
</style>
