import Router from "vue-router";

export const allowRouterPushRedirect = () => {
  const originalPush = Router.prototype.push;
  Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => {
      if (Router.isNavigationFailure(err)) {
        // resolve err
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };
};
