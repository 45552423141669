import moment from "moment";

export default {
  lastNavigationPage: (state) => state.lastNavigationPage,
  menus: (state) => state.menus,
  menu: (state) => state.menu,
  lastMenuId: (state) => state.lastMenuId,
  cart: (state) => state.cart,
  isLoading: (state) => state.loading,
  paymentId: (state) => state.paymentId,
  selectedFoodPropOptions: (state) => state.selectedFoodPropOptions,
  lastSelectedEatery: (state) => state.lastSelectedEatery,
  paymentOrder: (state) => state.paymentOrder,
  dineInOrder: (state) => state.dineInOrder,
  tableReservationObject: (state) => state.tableReservationObject,
  submittedOrder: (state) => state.submittedOrder,
  showShoppingCart: (state) => state.showShoppingCart,
  snackbarText: (state) => state.snackbarText,
  showSnackbar: (state) => state.showSnackbar,
  tableObject: (state) => {
    const now = moment(new Date());
    const tableExpiry = state.tableObject
      ? moment(state.tableObject.expiry)
      : null;

    if (tableExpiry && now.isAfter(tableExpiry)) {
      return null;
    }

    return state.tableObject;
  },
  deliveryObject: (state) => state.deliveryObject,
  deliveryOption: (state) => state.deliveryOption,
  messageToKitchen: (state) => state.messageToKitchen,
  //Auth
  loggedInUser: (state) => state.loggedInUser,
  resetToken: (state) => state.resetToken,
  refreshToken: (state) => state.refreshToken,
  token: (state) => state.token,
  //GDPR token
  GDPRCookie: (state) => state.GDPRCookie,
};
