<template>
  <div class="partner-schema">
    <div v-if="!messageSuccess">
      <span class="header"> Beskriv kort virksomheten i meldingsfeltet. </span>
      <v-form>
        <v-container>
          <v-row>
            <v-text-field v-model="name" label="Navn" placeholder="Navn" solo>
            </v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="email"
              label="E-post"
              placeholder="E-post"
              solo
            >
            </v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              v-model="phone"
              label="Mobil"
              placeholder="Mobil"
              solo
            >
            </v-text-field>
          </v-row>
          <v-row>
            <v-textarea
              v-model="message"
              label="Melding"
              placeholder="Melding"
              solo
            >
            </v-textarea>
          </v-row>
          <v-row>
            <v-btn
              style="width: 100%"
              x-large
              color="secondary"
              dark
              @click="sendRequestMessage"
            >
              Ta kontakt
            </v-btn>
          </v-row>
          <v-row>
            <span class="call-us"> Vi tar kontakt så fort som mulig! </span>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <PartnerRequestMessageSuccessPage
      v-if="messageSuccess"
    ></PartnerRequestMessageSuccessPage>
  </div>
</template>

<script>
import PartnerRequestMessageSuccessPage from "@/components/partner/PartnerRequestMessageSuccessPage.vue";
export default {
  components: {
    PartnerRequestMessageSuccessPage: PartnerRequestMessageSuccessPage,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      messageSuccess: false,
    };
  },
  methods: {
    async sendRequestMessage() {
      const msgObject = {
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Message: this.message,
      };
      const requestRes = await this.$store.dispatch(
        "sendRequestMessage",
        msgObject
      );
      if (requestRes) {
        this.messageSuccess = true;
      } else {
        // TODO : Logic for displaying error..
        this.messageSuccess = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Poppins";
}
.partner-schema {
  margin: 0 auto;
  text-align: center;
  padding: 0 10px;
  margin-top: 40px;
  max-width: 850px;
}
.call-us {
  font-size: 1.8em;
  font-weight: bold;
  width: 100%;
  margin-top: 30px;
}
.phonenr {
  font-size: 2.5em;
  font-weight: medium;
  width: 100%;
  margin-top: 20px;
}
</style>
