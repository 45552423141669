import Inputfield from "@/components/inputFields/Inputfield.vue";
import codebehindProps from "@/components/forms/address/AddressInformationForm.props";
const codebehind = {
  components: { Inputfield },
  mixins: [codebehindProps],
  data() {
    return {
      streetAddressValid: false,
      postalCodeValid: false,
      postalAreaValid: false,
    };
  },
  computed: {
    addressInput: {
      get() {
        return this.address;
      },
      set(val: string) {
        this.$emit("address", val);
      },
    },
    postalCodeInput: {
      get() {
        return this.postalCode;
      },
      set(val: string) {
        this.$emit("postalCode", val);
      },
    },
    cityInput: {
      get() {
        return this.city;
      },
      set(val: string) {
        this.$emit("city", val);
      },
    },
    streetAddressChecker() {
      if (this.wantAddress) return this.streetAddressValid;
      return true;
    },
    postalCodeChecker() {
      if (this.wantPostalCode) return this.postalCodeValid;
      return true;
    },
    postalAreaChecker() {
      if (this.wantCity) return this.postalAreaValid;
      return true;
    },
    formValid(): boolean {
      return (
        this.streetAddressChecker &&
        this.postalCodeChecker &&
        this.postalAreaChecker
      );
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [vm.formValid],
      () => {
        this.$emit("addressInformationFormValid", this.formValid);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
};
export default codebehind;
