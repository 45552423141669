<template>
  <FoodyCard :parentclasses="['card-view']">
    <header>Kontaktinfo - get|FOOD</header>
    <v-card-text>
      <v-container
        class="mb-6"
        style="border: none !important; padding-left: 0; padding-right: 0"
      >
        <v-row no-gutters>
          <v-col :cols="5"><div class="pt-2 pb-2">Orgnr:</div></v-col>
          <v-col :cols="7"><div class="pt-2 pb-2">925085588</div> </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col :cols="5"><div class="pt-2 pb-2">Firmanavn:</div></v-col>
          <v-col :cols="7"><div class="pt-2 pb-2">Foody AS</div></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col :cols="5"> <div class="pt-2 pb-2">Besøksadresse:</div></v-col>
          <v-col :cols="7">
            <div class="pt-2 pb-2">Torggata 11, 0181 Oslo</div></v-col
          >
        </v-row>
        <v-row no-gutters>
          <v-col :cols="5"><div class="pt-2 pb-2">Mail:</div></v-col>
          <v-col :cols="7"
            ><div class="pt-2 pb-2">
              <a href="mailto:hello@getfood.no">hello@getfood.no</a>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col :cols="5"> <div class="pt-2 pb-2">Ring get|FOOD:</div></v-col>
          <v-col :cols="7"
            ><div class="pt-2 pb-2">
              <a href="tel:95203432">944 51 155</a>
            </div></v-col
          >
        </v-row>
      </v-container>
    </v-card-text>
  </FoodyCard>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
export default {
  components: {
    FoodyCard,
  },
};
</script>
