import { PartnerFeaturesWithIcons } from "src/types/partner/partner";
import BurgerPhoneIcon from "../../assets/img/Mask-Group-9-1.svg";
import BurgerDesktopIcon from "../../assets/img/Mask-Group-10-1.svg";
import BurgerTabletIcon from "../../assets/img/Mask-Group-11-2.svg";
import BurgerReceiptIcon from "../../assets/img/Mask-Group-12-1.svg";

const partnerFeaturesWithIcons: Array<PartnerFeaturesWithIcons> = [
  {
    title: "Mobil bestilling",
    icon: BurgerPhoneIcon,
    body: "QR, take-away og bordreservasjon.",
  },
  {
    title: "Salgsportal",
    body: "Egen utsalgsportal på getfood.no",
    icon: BurgerDesktopIcon,
  },
  {
    title: "Ingen registrering",
    body: "Gjester bestiller uten å måtte laste ned en app.",
    icon: BurgerTabletIcon,
  },
  {
    title: "Forhindrer smitte",
    body: "Kontaktløs betaling og gratis smittesporing.",
    icon: BurgerReceiptIcon,
  },
];

export default partnerFeaturesWithIcons;
