<template>
  <v-tooltip v-model="showTooltip" bottom>
    <template #activator="{ attrs }">
      <div class="dashboard-icon">
        <v-avatar
          v-if="ShoppingCartCount"
          size="15"
          small
          color="purple"
          class="shopping-cart-icon-counter"
        >
          {{ ShoppingCartCount }}
        </v-avatar>
        <v-btn icon v-bind="attrs" @click="OpenShoppingCart">
          <v-icon color="black" medium> mdi-cart </v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ toolTipText }}</span>
  </v-tooltip>
</template>
<script>
import { isMobile } from "@/helpers/deviceHelper";

export default {
  name: "ShoppingCartIcon",
  data() {
    return {
      showTooltip: false,
      toolTipText: null,
    };
  },
  computed: {
    ShoppingCartCount() {
      const items = this.$store.getters.cart;
      if (!items) return null;

      let sum = items.reduce((prev, cur) => prev + cur.nrOfItems, 0);
      if (this.$store.getters.tableObject) sum += 1;
      return sum;
    },
  },
  methods: {
    ShowToolTip(text) {
      if (this.showTooltip === true) return;
      this.toolTipText = text;
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
        setTimeout(() => {
          this.toolTipText = null;
        }, 1000);
      }, 5000);
    },

    OpenShoppingCart() {
      const eateryName = this.$store.getters.lastSelectedEatery;
      if (eateryName) {
        const cart = this.$store.getters.cart;
        if ((cart && cart.length > 0) || this.$store.getters.tableObject) {
          if (isMobile()) {
            this.OpenShoppingCartMobile(eateryName);
          } else {
            this.OpenShoppingCartStationary(eateryName);
          }
        } else {
          this.ShowToolTip(
            "Handlekurven er tom. Velg varer fra en restaurant for å bestille"
          );
          return;
        }
      } else {
        this.ShowToolTip(
          "Handlekurven er tom. Velg varer fra en restaurant for å bestille"
        );
      }
    },

    OpenShoppingCartMobile(eatery) {
      const checkoutRoute = `/${eatery}/checkout`;
      // Don't go to new route if it's the same route
      if (this.$router.currentRoute.path !== checkoutRoute)
        this.$router.push(checkoutRoute);
    },
    OpenShoppingCartStationary(eatery) {
      const lastMenu = this.$store.getters.lastMenuId;
      const checkoutRoute = `/${eatery}/checkout`;

      // If on checkout-page, go back to current eatery's current menu
      if (this.$router.currentRoute.path.includes("checkout")) {
        this.$router.push(
          lastMenu ? `/${eatery}/menu/${lastMenu}` : `/${eatery}`
        );
        return;
      }

      // If on eatery, go to checkout
      if (this.$router.currentRoute.path.includes(eatery)) {
        if (this.$router.currentRoute.path !== checkoutRoute)
          this.$router.push(checkoutRoute);
        return;
      }

      // Otherwise go back to current eatery's current menu;
      if (this.$router.currentRoute.path !== checkoutRoute)
        this.$router.push(
          lastMenu ? `/${eatery}/menu/${lastMenu}` : `/${eatery}`
        );
    },
  },
};
</script>
<style scoped>
.dashboard-icon {
  visibility: visible !important;
  position: relative;
}
</style>
