<template>
  <div :class="[getContainerCssClassName(), this.estimationOnly ? 'addedTopMargin' : '']">
    <ClosedSection v-if="!isOpenNow" :nextOpeningDate="nextOpeningDate" :currentEatingMode="currentEatingMode" />
    <OpenSection v-else-if="currentEatingModeDto?.orderReadyEstimateMinutes" 
      :currentEatingMode="currentEatingMode"
      :orderReadyEstimateMinutes="currentEatingModeDto.orderReadyEstimateMinutes" />
  </div>
</template>

<script lang="ts">
import ClosedSection from "./ClosedSection.vue";
import OpenSection from "./OpenSection.vue";
import codeBehind from "./DeliveryOptionInfo";

export default {
  mixins: [codeBehind],
  components: { ClosedSection, OpenSection }
};
</script>
<style scoped>
.container-for-table-reservation {
  width: max-content;
  margin: 0 auto 10px;
  text-align: center;
}

.addedTopMargin {
  margin-top: 5rem !important;
}

.container-for-ordering {
  width: max-content;
  margin: 0 auto 10px;
  text-align: center;
}

@media (min-width: 767px) {
  .container-for-table-reservation {
    padding: 5px 15px 15px 15px;
  }
}
</style>
