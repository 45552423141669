<template>
  <div>
    <v-tooltip v-if="eateryItem.dineIn" bottom>
      <template #activator="{ on, attrs }">
        <v-icon v-bind="attrs" color="#9F0073" v-on="on"
          >{{ eateryItem.dineIn.isQrOnly ? "mdi-qrcode" : "mdi-door" }}
        </v-icon>
      </template>
      <span>{{
        eateryItem.dineIn.isQrOnly ? "QR-Bestilling" : "Dine-in"
      }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="eateryItem.delivery"
          v-bind="attrs"
          style="padding-left: 8px"
          color="#9F0073"
          v-on="on"
          >mdi-bike-fast</v-icon
        >
      </template>
      <span>Levering</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="eateryItem.takeAway"
          v-bind="attrs"
          style="padding-left: 8px"
          color="#9F0073"
          v-on="on"
          >mdi-run-fast</v-icon
        >
      </template>
      <span>Takeaway</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    eateryItem: {
      type: Object,
      required: true,
    },
  },
};
</script>
