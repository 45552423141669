<template>
  <v-card class="foody-card-minimal-static-height" :ripple="false">
    <v-card-title class="company-card-title" v-text="eateryItem.displayName">
    </v-card-title>
    <v-card-subtitle class="company-card-subtitle">
      <span>
        {{ eateryAboutMessage }}
      </span>
    </v-card-subtitle>

    <div class="img-container">
      <v-img class="eatery-img" contain :src="eateryLogo"></v-img>
    </div>

    <div
      v-if="eateryItem.tableReservationEnabled"
      class="foody-button-reservation"
    >
      <FoodyButton
        v-if="eateryItem.tableReservationEnabled"
        :parentclasses="['card-button']"
        :fullSize="false"
        :btnColor="'primary'"
        :outlined="true"
        :onClick="goToEaterieReservation"
        >RESERVER</FoodyButton
      >
    </div>

    <div
      v-if="
        eateryItem.delivery ||
        eateryItem.takeAway ||
        (eateryItem.dineIn && !eateryItem.dineIn.isQrOnly)
      "
      class="tooltip-container"
    >
      <EateryEatingModeTooltip :eateryItem="eateryItem" />
    </div>

    <div class="foody-button-menu">
      <FoodyButton
        :parentclasses="['card-button']"
        :btnColor="'primary'"
        :fullSize="false"
        :onClick="goToEaterieMenu"
        >{{ buttonText() }}</FoodyButton
      >
    </div>
  </v-card>
</template>
<script lang="ts">
import ImgNotFound from "../../assets/img/image-not-found.png";
import { remainingTime, toString } from "@/common/timeCalculations";
import FoodyButton from "../common/FoodyButton.vue";
import EateryEatingModeTooltip from "@/components/eatery/EateryEatingModeTooltip.vue";
export default {
  components: { FoodyButton, EateryEatingModeTooltip },
  props: {
    eateryItem: {
      type: Object,
      required: true,
    },
    queryParam: {
      type: String,
    },
  },
  data() {
    return {
      eateryAboutMessageLength: 70,
    };
  },
  computed: {
    fullUrl() {
      let url = "/" + this.eateryItem.urlName;
      if (this.queryParam) url += "?" + this.queryParam;
      return url;
    },
    isOnlyDineInQr() {
      const x = this.eateryItem;
      return x.dineInIsQrOnly && !x.takeAway && !x.delivery;
    },
    eateryLogo() {
      if (this.eateryItem.logoUrl) {
        return this.eateryItem.logoUrl;
      } else {
        return ImgNotFound;
      }
    },
    eateryAboutMessage() {
      const aboutMessage = this.eateryItem.aboutMessage;
      if (aboutMessage) {
        const isAboutMessageToLong =
          aboutMessage.length > this.eateryAboutMessageLength;
        const aboutMessageShortend = `${this.eateryItem.aboutMessage.slice(
          0,
          this.eateryAboutMessageLength
        )}...`;
        return isAboutMessageToLong ? aboutMessageShortend : aboutMessage;
      }
      return "";
    },
  },
  methods: {
    buttonText() {
      return this.isOnlyDineInQr ? "SE MENY" : "BESTILL";
    },
    roundHalf(num: number): number {
      return Math.round(num * 2) / 2;
    },
    calculateTime(num: number): string {
      const remaining = remainingTime(num);
      return toString(remaining);
    },
    prettyTimeString(timeString: string): string {
      const result = timeString.split(":");
      return result[0] + ":" + result[1];
    },
    goToEaterieReservation: function () {
      this.$router.push("/" + this.eateryItem.urlName + "/bord");
    },
    goToEaterieMenu: function () {
      this.$router.push(this.fullUrl);
    },
  },
};
</script>
<style scoped>
.foody-card-minimal-static-height {
  padding: 15px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08) !important;
  display: grid;
  grid-gap: 7px;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "title title image"
    "text text image"
    "reserve order icons";
  min-height: 150px;
  height: 100%;
  cursor: pointer;
}

.foody-card-minimal-static-height:before {
  background: none;
}
.foody-card-minimal-static-height .img-container {
  grid-area: image;
  justify-self: end;
  align-self: center;
}
.eatery-img {
  max-height: 85px;
  max-width: 100px;
}
.company-card-title {
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  grid-area: title;
  word-break: normal;
}
.company-card-subtitle {
  overflow: hidden;
  max-height: 70px;
  padding: 0;
  grid-area: text;
}
.foody-button-menu {
  grid-area: order;
}
.foody-button-reservation {
  grid-area: reserve;
}
.tooltip-container {
  grid-area: icons;
  justify-self: end;
  align-self: center;
}
.card-button {
  font-size: 0.8em;
}

@media (max-width: 321px) {
  .card-button {
    font-size: 1.5em;
  }
}

@media (max-width: 426px) {
  .foody-card-minimal-static-height {
    padding: 10px;
  }

  .card-button {
    font-size: 0.6em;
  }
}
</style>
