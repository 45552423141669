import { PartnerBenefits } from "src/types/partner/partner";
import benefits1Img from "../../assets/img/benefits_1.png";
import benefits4Img from "../../assets/img/benefits_4.png";
import benefits5Img from "../../assets/img/benefits_5.png";

const partnerBenefits: Array<PartnerBenefits> = [
  {
    title: `getFood skaper den <span class="purple-text">gode kundeopplevelsen</span>`,
    body: `Med en helhetlig tjeneste for mobil bestilling og betaling av mat og drikke, gjør getFOOD hverdagen enklere for spisesteder og ikke minst – kunden.`,
    imageUrl: benefits4Img,
  },
  {
    title: `Du får <span class="purple-text">høyere omsetning</span> og <span class="purple-text">lavere kostnader</span>`,
    body: `Når kunden kan bestille når de vil, bestiller de gjerne mer. I tillegg kan du enkelt bemanne mer effektivt med getFOOD-systemet.`,
    imageUrl: benefits5Img,
    bgColor: "white",
    textRight: true,
  },
  {
    title: `Full kontroll med alt <span class="purple-text">i ett system</span>`,
    body: `QR, takeaway og bordreservasjon håndteres enkelt i et og samme system. Smittesporing spanderer vi også.`,
    imageUrl: benefits1Img,
  },
];

export default partnerBenefits;
