<template>
  <v-container fluid>
    <v-row>
      <PersonalInformationForm
        :want-firstname="false"
        :want-lastname="false"
        :want-phone="false"
        :email="emailInput"
        :personal-information-form-valid="personalInformationFormValid"
        @email="emailInput = $event"
        @personalInformationFormValid="personalInformationFormValid = $event"
      />
    </v-row>
    <v-row>
      <PasswordForm
        :password="passwordInput"
        :password-valid="passwordValid"
        @password="passwordInput = $event"
        @passwordValid="passwordValid = $event"
      ></PasswordForm>
    </v-row>
  </v-container>
</template>
<script>
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import PasswordForm from "@/components/forms/password/PasswordForm.vue";

export default {
  name: "LoginForm",
  components: {
    PersonalInformationForm: PersonalInformationForm,
    PasswordForm: PasswordForm,
  },
  props: {
    email: {
      type: String,
      required: true,
      default: "",
    },
    password: {
      type: String,
      required: true,
      default: "",
    },
    loginFormValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      personalInformationFormValid: false,
      passwordValid: false,
    };
  },
  computed: {
    emailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.$emit("email", val);
      },
    },
    passwordInput: {
      get() {
        return this.password;
      },
      set(val) {
        this.$emit("password", val);
      },
    },
  },
  mounted() {
    this.$watch(
      (vm) => [vm.personalInformationFormValid, vm.passwordValid],
      () => {
        const checked = this.personalInformationFormValid && this.passwordValid;
        this.$emit("loginFormValid", checked);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },

  methods: {
    clear() {
      this.$v.$reset();
    },
  },
};
</script>
