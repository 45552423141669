<template>
  <div>
    <div class="main-content-app-height" />
    <v-app-bar flat app class="main-content-app-height" color="white">
      <v-app-bar-nav-icon
        class="dashboard-icon"
        color="black"
        @click.stop="$emit('toggle-drawer')"
      />
      <div>
        <FoodyLogo />
      </div>

      <ShoppingCartIcon
        :style="
          ShowShoppingCartIcon ? 'visibility:visible' : 'visibility:hidden'
        "
      />
    </v-app-bar>
    <div>
      <transition name="slide-fade">
        <main>
          <router-view />
        </main>
      </transition>
      <v-snackbar
        v-model="ShowSnackbar"
        :timeout="timeout"
        style="padding-bottom: 65px"
        >{{ SnackbarText }}</v-snackbar
      >
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import FoodyLogo from "../common/FoodyLogo.vue";
import ShoppingCartIcon from "../shopping-cart/ShoppingCartIcon.vue";

export default Vue.extend({
  components: {
    FoodyLogo,
    ShoppingCartIcon,
  },
  data(): Record<string, any> {
    return {
      timeout: 2000,
    };
  },
  computed: {
    ShowShoppingCartIcon() {
      if (this.$route) {
        const params = this.$route.params;
        if (params && params.cname) {
          return true;
        }
      }

      return false;
    },
    ShowSnackbar: {
      get() {
        return this.$store.getters.showSnackbar;
      },
      set(newShowSnackbar) {
        return newShowSnackbar;
      },
    },
    SnackbarText() {
      return this.$store.getters.snackbarText;
    },
  },
});
</script>
<style>
/* Modifying directly to snackbar class, since normal class will not work */
.v-snack__wrapper {
  margin-bottom: 100px !important;
}
.main-content-app-height {
  max-width: 100vw;
  height: 64px;
}
@media only screen and (max-width: 960px) {
  .main-content-app-height {
    height: 64px;
  }
}
</style>
