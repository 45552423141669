import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./action";
import { mutations } from "./mutations";
import ShoppingCartErrorState from "./state";
import RootState from "@/types/state/rootState";

export const state: ShoppingCartErrorState = {
  noCartItem: false,
  belowMinimumDeliverySum: false,
  eatingModeClosed: false,
  notAvailableForDelivery: false,
  notAvailableForTakeAway: false,
  qrDineInIsClosed: false,
};

//TODO: Properly namespace
const namespaced = false;

export const shoppingCartErrorModule: Module<
  ShoppingCartErrorState,
  RootState
> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
