<template>
  <section class="reviews">
    <div class="reviews_list">
      <div class="review_item">
        <div class="img-container">
          <img src="../../assets/img/review_sushimi.png" />
        </div>
        <p>"Salget økte med en gang!"</p>
        <div class="review_footer">
          <span>Eirik Sundal</span>
          <span>Daglig leder, Café Bergen og Sushimi.</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
section {
  background-color: white;
}
.reviews_list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 20px;
}
.review_item {
  margin-top: 4rem;
  text-align: center;
}
.review_item p {
  margin-top: 20px;
  font-style: italic;
  font-size: 1.5em;
  font-family: "Poppins";
}
.review_item .img-container {
  height: 150px;
}
.review_item .img-container img {
  height: 150px;
}
.review_item .review_footer span:first-of-type {
  font-size: 1em;
  font-weight: bold;
  display: block;
}
.review_item .review_footer span:last-of-type {
  font-size: 1em;
}

@media only screen and (max-width: 1200px) {
  .reviews_list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px 20px;
  }
}
</style>
