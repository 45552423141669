<template>
  <v-card v-if="item" class="foody-menu-item-card" @click="goToMenu(item)">
    <v-img
      cover
      :src="item.blobUrlPhoto ? item.blobUrlPhoto : defaultImg"
      height="200px"
    />
    <v-card-title>
      {{ item.name }}
    </v-card-title>

    <v-card-subtitle style="text-align: left">
      {{ item.description }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
import ImgNotFound from "../../assets/img/image-not-found.png";

export default {
  name: "MenuSmall",
  props: ["item"],
  data() {
    return {
      defaultImg: ImgNotFound,
    };
  },
  computed: {
    currentEatery() {
      return this.$store.getters.eateryName;
    },
  },
  methods: {
    goToMenu: function (item) {
      this.$router.push("/" + this.currentEatery + "/menu/" + item.id);
    },
  },
};
</script>

<style scoped>
::v-deep.foody-menu-item-card .v-card__subtitle,
::v-deep.foody-menu-item-card .v-card__subtitle > * {
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
