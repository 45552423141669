<template>
  <div class="foody-flex-row">
    <v-btn icon @click="onClickMinus">
      <v-icon medium color="black"> mdi-minus-circle-outline </v-icon>
    </v-btn>
    <input
      type="number"
      placeholder="1"
      :value="counter"
      class="shopping-cart-stepper-input"
      readonly
    />

    <v-btn icon :disabled="!canAddMore" @click="onClickPlus">
      <v-icon medium color="black"> mdi-plus-circle-outline </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ShoppingCartStepper",
  props: {
    onClickMinus: {
      type: Function,
      required: true,
    },
    onClickPlus: {
      type: Function,
      required: true,
    },
    counter: {
      type: Number,
      required: true,
    },
    canAddMore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
};
</script>
