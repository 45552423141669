const codebehindProps = {
  props: {
    address: {
      type: String,
      required: false,
    },
    postalCode: {
      type: String,
      required: false,
    },
    city: {
      type: String,
      required: false,
    },
    wantAddress: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantPostalCode: {
      type: Boolean,
      required: false,
      default: true,
    },
    wantCity: {
      type: Boolean,
      required: false,
      default: true,
    },
    addressIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    postalCodeIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    cityIsRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    addressInformationFormValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
export default codebehindProps;
