function extractOtherErrors (response: any) {
  if(typeof(response) === 'object') {
    return `Ordren ble ikke godkjent. Feilkode: ${response.status ?? 'ingen kode'}. Melding: ${response.statusText ?? 'ingen melding'}`
  }

  if(typeof(response) === 'string') {
    return response;
  }
}

export default {
  async getOrderErrorCodeAsync(response: any): Promise<string> {
    try {
      
      if(!response?.data?.errors || response.data.errors.length === 0) {
        return extractOtherErrors(response);
      }

      const errorDesc = response.data.errors[0].description;
      const errorEnd = errorDesc.indexOf("]");
      const errorCode = errorDesc.substr(1, errorEnd - 1);

      switch (errorCode) {
        case "OV-1":
          return "Spisestedet ble ikke funnet.";
        case "OV-2":
          return "Spisestedet støtter ikke denne spisemodusen.";
        case "OV-2b":
          return "En rett er fra en meny som ikke støtter denne spisemodusen.";
        case "OV-3":
          return "Ordren inneholder ugyldige retter.";
        case "OV-4":
          return "Spisestedet holder stengt.";
        case "OV-4b":
          return "En rett er fra en meny som ikke er gyldig for dette klokkeslettet.";
        case "OV-5":
          return "Spisestedet har satt spisemodusen på pause.";
        case "OV-6":
          return "Spisestedet tar ikke imot tips.";
        case "OV-7":
          return "Totalsummen på ordren er for lav til å kunne leveres";
        case "OV-8":
          return "Dine-in er kun tillat med QR-kode";
        case "OV-9": {
          let soldOutItems = errorDesc.substr(
            response.data.lastIndexOf("["),
            response.data.lastIndexOf("]")
          );
          soldOutItems = soldOutItems.slice(1, -1);
          return soldOutItems
            ? `${soldOutItems} er dessverre utsolgt.`
            : "Matretter er dessverre utsolgt.";
        }
        case "OV-10":
          return "QR ordre kan ikke bestilles fram i tid.";
        default: {
          return "Ordren ble ikke godkjent.\n" + errorCode ?? 'Ukjent feil';
        }
      }
    } catch (e) {
      return `Ordren ble ikke godkjent. Melding: ${e.toString()}`;
    }
  },
  responseSuccess(response: any): boolean {
    return response.status === 200 ? true : false;
  },
};
