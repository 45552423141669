<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doSearch">
    <v-container>
      <v-row
        justify="center"
        style="margin-left: -8px; margin-right: -8px"
        align="center"
      >
        <v-col sm="6">
          <v-text-field
            :rules="searchRules"
            :value="shownSearchTerm"
            aria-label="Hvor er du?"
            label="Hvor er du?"
            solo
            clearable
            name="search-input"
            required
            hide-details
            validate-on-blur
            @input="(value) => (searchTermValue = value)"
            @click:clear="onClearClicked"
          >
            <v-icon
              slot="append"
              color="primary"
              aria-label="Finn min plassering"
              title="Finn min plassering"
              @click="locateMe"
            >
              mdi-map-marker
              <span>Finn min plassering</span>
            </v-icon>
          </v-text-field>
        </v-col>
        <FoodyButton
          :tile="false"
          justify="center"
          align="center"
          type="submit"
          :parentclasses="buttonClasses"
          :btn-color="'primary'"
          :onClick="doSearch"
          >SØK</FoodyButton
        >
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FoodyButton from "@/components/common/FoodyButton.vue";

import locationHelper from "@/helpers/locationHelper";

export default {
  name: "GeoSearchField",
  components: {
    FoodyButton,
  },
  props: {
    onSearch: {
      type: Function,
      required: false,
      default: null,
    },
    searchTerm: {
      type: String,
      required: false,
      default: "",
    },
    isFrontpage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      buttonClasses: ["v-size--large", "search-btn"],
      valid: false,
      searchRules: [
        (v) =>
          !this.isFrontpage ||
          !!v ||
          "Fyll inn adresse eller velg plassering-ikonet",
      ],
      location: null,
      searchTermValue: this.searchTerm,
      gettingLocation: false,
      errorStr: null,
    };
  },
  computed: {
    // Show "Min lokasjon"-text if coordinates, else show search term value
    shownSearchTerm() {
      return this.isLocation ? "Min lokasjon" : this.searchTermValue;
    },
    // If search term value contains coordiantes
    isLocation() {
      return locationHelper.isCoords(this.searchTermValue || this.searchTerm);
    },
  },
  methods: {
    onClearClicked() {
      this.$refs.form.reset();
      if (!this.isFrontpage) {
        this.doSearchQuery(this.searchTermValue || "");
      }
    },
    doSearchQuery: function (searchText) {
      this.onSearch(searchText);
    },
    doSearch(e) {
      e.preventDefault();
      // If form can be validated (search term not empty)
      if (this.$refs.form.validate()) {
        if (!this.gettingLocation) {
          this.doSearchQuery(this.searchTermValue);
        }
        this.$refs.form.resetValidation();
      }
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
        this.searchTermValue = `${this.location.coords.latitude}, ${this.location.coords.longitude}`;
      } catch (e) {
        this.gettingLocation = false;
        this.errorStr = e.message;
      }
      this.doSearchQuery(this.searchTermValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application .error--text {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.search-btn {
  border-radius: 4px;
}
</style>

<style lang="scss">
.v-application
  .v-text-field.v-text-field--solo.error--text
  > .v-input__control
  > .v-input__slot {
  border: 2px solid !important;
  border-radius: inherit !important;
}
.v-application .error--text .v-text-field__slot input::placeholder,
.v-application .error--text .v-text-field__slot > .v-label {
  color: #ff5252 !important;
}
</style>
