<template>
  <FoodyCard :parentclasses="['card-view']">
    <v-card-text>
      <v-card-title style="font-weight: bold"> Cookie policy </v-card-title>
      <v-card-text>
        For at din opplevelse hos getFOOD skal være best mulig, bruker vi
        cookies på våre sider. Noen av disse slettes automatisk etter ditt
        besøk, mens andre blir lagret og brukes blant annet til å kjenne igjen
        din nettleser til neste besøk. Du kan konfigurere og velge hvilke
        cookies som aksepteres i nettleseren din. Ved å ikke akseptere cookies
        kan enkelte funksjoner på getFOOD være begrenset eller ikke virke som
        normalt.
      </v-card-text>
      <v-card-text>
        Vi har delt cookies vi bruker inn i to kategorier; nødvendige og
        analytiske.
      </v-card-text>
      <v-card-text
        >Nødvendige cookies brukes for at vår løsning skal fungere som normalt.
        Slike cookies gjør det mulig å navigere seg rundt på siden og benytte
        seg av våre tjenester. Uten disse kan ikke funksjonaliteten garanteres.
        Dette inkluderer også at ditt samtykke til cookies blir lagret hos oss.
      </v-card-text>
      <v-card-text>
        Analytiske cookies hjelper oss å forbedre tjenesten ved å hente
        analysere informasjonen vi henter fra trafikk på nettsiden. De kan også
        brukes til å sende skreddersydd markedsføring og måle effektiviteten av
        våre reklamekampanjer. Denne kategorien inkluderer tredjeparts-cookies.
      </v-card-text>
      <v-card-text>
        Du kan lese mer om våre samarbeidspartnere i vår
        <router-link to="/terms">personvernserklæring</router-link>.
      </v-card-text>
      <div class="d-flex">
        <v-checkbox
          v-model="selected"
          disabled
          label="Nødvendige"
          value="Necessary"
        ></v-checkbox>
        <v-checkbox
          v-model="selected"
          label="Analytiske"
          value="Analytic"
          style="margin-left: 10px"
        ></v-checkbox>
      </div>
    </v-card-text>
  </FoodyCard>
</template>
<script>
import Vue from "vue";
import FoodyCard from "@/components/common/FoodyCard.vue";
import Codebehind from "./CookiePolicy";
export default Vue.extend({
  components: {
    FoodyCard,
  },
  mixins: [Codebehind],
});
</script>
