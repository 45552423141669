import FoodyButton from "@/components/common/FoodyButton.vue";
const codeBehind = {
  components: { FoodyButton },
  data() {
    return {
      GDPRsnackbar: false,
    };
  },
  computed: {
    cookieChange(): boolean {
      const cookie = this.$store.getters.GDPRCookie;
      if (!cookie) return true;
      return false;
    },
  },
  async mounted() {
    // Don't show the popup if the page is embedded in an iframe
    if(window.location !== window.parent.location) {
      return;
    }
    await this.checkIfGDPRAccepted();
    this.$watch(
      (vm: any) => [vm.cookieChange, vm.GDPRsnackbar],
      () => {
        this.GDPRsnackbar = this.cookieChange;
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  methods: {
    async acceptGDPR() {
      const GDPRCookie = {
        Necessary: true,
        Analytical: true,
      };
      await this.$store.dispatch("setGDPRCookie", GDPRCookie);
      this.GDPRsnackbar = false;
    },
    async checkIfGDPRAccepted() {
      await this.$store.dispatch("getGDPRCookie");
    },
  },
};
export default codeBehind;
