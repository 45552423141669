import { functionsApi as api, functionsBaseURL, getConfig } from "../api";

export default {
  /* Getters */
  async getFoodcourtEateries(query: string, token: string): Promise<any[]> {
    const endpointUrl = functionsBaseURL + "/api/foodcourts/" + query;
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
};
