<template>
  <div>
    <div class="d-flex" @click.stop="show = !disabled">
      <v-icon class="time-icon">mdi-clock-time-four-outline</v-icon>
      <span class="time-display" :class="{ disabled: disabled }">{{
        time
      }}</span>
    </div>
    <v-dialog v-model="show" width="500">
      <FoodyCard style="min-height: 600px">
        <v-card-title>
          <b>Velg tid</b>
        </v-card-title>
        <v-card-text>
          <timeselector
            v-model="timeBinding"
            format="HH:mm"
            :disable="{
              h: disabledHoursArray,
              m: disabledMinutesArray,
              s: null
            }"
            :value="timeBinding"
            class="borderAdjustment"
            :style="[validTime ? styleNormal : styleError]"
            @cleared="reset"
          />
        </v-card-text>
        <div class="bottom-container">
          <v-divider />
          <v-card-actions style="justify-content: center">
            <v-btn large text color="primary" @click.stop="show = false">
              Bekreft
            </v-btn>
          </v-card-actions>
        </div>
      </FoodyCard>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import codeBehind from "./TimePicker";
import Timeselector from "vue-timeselector";
import FoodyCard from "@/components/common/FoodyCard.vue";
export default Vue.extend({
  name: "TimePicker",
  components: {
    Timeselector,
    FoodyCard,
  },
  mixins: [codeBehind],
});
</script>
<style scoped>
.time-display {
  border-bottom: 2px solid #9f0073;
  width: 100%;
  margin-bottom: 6px;
}
.time-display.disabled {
  color: lightgray;
  border-bottom-color: transparent;
}
.time-icon {
  padding-right: 7px;
  padding-bottom: 12px;
}
.bottom-container {
  position: absolute;
  bottom: 10%;
  width: 90%;
}
/* For minor adjusment to the border to be like vuetify */
::v-deep .borderAdjustment {
  padding-bottom: 3px;
}
::v-deep .vtimeselector input,
::v-deep .vtimeselector .vtimeselector__clear__ico {
  display: none;
}
::v-deep .timeselector__box__item--is-selected {
  background: #9f0073;
  color: #ffffff;
  border-radius: 20px;
}
::v-deep .vtimeselector__box--is-closed {
  display: flex;
  min-height: 400px;
}
::v-deep .timeselector__box__item--is-disabled {
  display: none;
}
::v-deep .vtimeselector__input {
  font-size: 24px;
  padding-bottom: 5px;
}
::v-deep .vtimeselector__box__item {
  height: 50px;
  font-size: 20px;
  line-height: 1.6;
}
::v-deep .vtimeselector__box__head {
  font-size: 16px;
  border-bottom: 1px solid var(---primary-color);
}
::v-deep .vtimeselector__box__list--hours {
  padding-left: 0 !important;
}
</style>
