<template>
  <FoodyCard class="smittesporing-container">
    <form
      v-if="!isLoading"
      v-disable-tab-on-class="['.mdi-close', '.mdi-eye-off']"
    >
      <PersonalInformationForm
        :want-email="false"
        :firstname="firstName"
        :lastname="lastName"
        :phone="phoneNr"
        @firstname="firstName = $event"
        @lastname="lastName = $event"
        @phone="phoneNr = $event"
      />
      <component
        :is="component"
        v-for="(visitor, index) in visitors"
        :key="index"
        :index="index"
        :want-email="false"
        :person="visitor"
        @person="removeVisitor"
      />
      <section class="smittesporing-btn-group">
        <FoodyButton :on-click="addVisitors" btn-color="primary">+</FoodyButton>
        <FoodyButton
          :on-click="registerVisitors"
          btn-color="primary"
          :disabled="!isFormValid"
          >Registrer</FoodyButton
        >
      </section>
    </form>
    <v-progress-circular
      v-show="isLoading"
      class="smittesporing-spinner"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
    <ErrorComponent
      v-if="statusMessage != ''"
      class="smittesporing-error-component"
      :message="statusMessage"
      :type="statusType"
    />
  </FoodyCard>
</template>

<script lang="ts">
import Vue from "vue";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import FoodyCard from "@/components/common/FoodyCard.vue";
import PersonalInformationFormWithDelete from "@/components/forms/personal/PersonalInformationFormWithDelete.vue";
import ErrorComponent from "@/components/common/ErrorComponent.vue";

interface Visitor {
  firstname: string;
  lastname: string;
  phone: string;
}

export default Vue.extend({
  name: "Smittesporing",
  components: {
    PersonalInformationForm,
    FoodyButton,
    FoodyCard,
    PersonalInformationFormWithDelete,
    ErrorComponent,
  },
  data(): object {
    return {
      firstName: null as string,
      lastName: null as string,
      phoneNr: null as string,
      email: null as string,
      visitors: [] as Visitor[],
      component: "PersonalInformationFormWithDelete",
      isLoading: false,
      statusMessage: "" as string,
      statusType: "" as string,
    };
  },
  computed: {
    isFormValid(): boolean {
      let formIsValid: boolean;
      formIsValid =
        this.firstName &&
        this.lastName &&
        this.validPhoneNumberLength(this.phoneNr)
          ? true
          : false;
      if (this.visitors.length > 0) {
        formIsValid =
          formIsValid &&
          this.visitors.every(
            (visitor: Visitor) =>
              visitor.firstname &&
              visitor.lastname &&
              this.validPhoneNumberLength(visitor.phone)
          )
            ? true
            : false;
      }
      return formIsValid;
    },
    getUserFromState() {
      const user = this.$store.getters.loggedInUser;
      const name = user?.name ?? "";
      this.setName(name);
      this.setPhoneNumber(user);
      return user;
    },
  },
  created() {
    this.getUserFromState;
  },
  methods: {
    setName(name: string) {
      const arr = name?.split(" ");
      if (arr?.length >= 2) {
        this.firstName = arr.slice(0, arr.length - 1).join(" ");
        this.lastName = arr[arr.length - 1].toString();
      } else if (arr?.length === 1) {
        this.firstName = arr[0]?.toString();
      }
    },
    setPhoneNumber(user: any) {
      this.phoneNr = user?.phone ?? "";
    },
    addVisitors(): void {
      const initVisitor: Visitor = {
        firstname: null as string,
        lastname: null as string,
        phone: null as string,
      };
      this.visitors.push(initVisitor);
    },
    removeVisitor(objToDelete: any): void {
      this.visitors.splice(objToDelete.index, 1);
    },
    validPhoneNumberLength(number: string): boolean {
      if (number) {
        return number.toString().length > 7;
      }
      return false;
    },
    async registerVisitors(): Promise<void> {
      const visitorsArr: Visitor[] = [
        {
          firstname: this.firstName,
          lastname: this.lastName,
          phone: this.phoneNr,
        },
      ];
      if (this.visitors.length > 0) {
        this.visitors.forEach((visitor: Visitor) => {
          visitorsArr.push(visitor);
        });
      }

      this.isLoading = true;
      this.statusMessage = "";

      try {
        await this.$store.dispatch("addInfectionTracking", visitorsArr);
        this.statusMessage = "Smittesporing er registrert";
        this.statusType = "success";
        setTimeout(
          () =>
            this.$router.push({ path: `/${this.$store.getters.eateryName}` }),
          2000
        );
      } catch {
        this.statusMessage = "Noe gikk galt, vennligst prøv igjen";
        this.statusType = "warning";
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
