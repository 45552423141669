<template>
  <v-card
    class="mx-auto foody-cities-card"
    style="border-radius: 20px"
    @click="goToCity(cityItem.searchString)"
  >
    <div class="d-flex flex-no-wrap flex-column">
      <div class="Img-card">
        <v-img
          contain
          style="border-top-left-radius: 20px; border-top-right-radius: 20px"
          max-height="300"
          max-width="700"
          :src="cityImage"
        />
      </div>
      <div class="title mx-auto">
        <v-card-title class="text-h5">{{ cityItem.name }}</v-card-title>
      </div>
    </div>
  </v-card>
</template>
<script>
import CityIcon from "../assets/img/city.svg";
export default {
  props: {
    cityItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cityImage() {
      if (this.cityItem.imageUrl) {
        return this.cityItem.imageUrl;
      } else {
        return CityIcon;
      }
    },
  },
  methods: {
    goToCity: function (cityName) {
      this.$router.push(`/search/${cityName}`);
    },
  },
};
</script>
<style>
.foody-cities-card :hover {
  color: #9f0073;
}

.Img-card .v-image__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.foody-cities-card:hover .Img-card .v-image__image {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.foody-cities-card {
  box-shadow: 0px 6px 30px rgb(0 0 0 / 8%) !important;
  min-width: 275px;
  width: 25%;
}

.Img-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.foody-cities-card .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  flex-wrap: nowrap;
  font-size: 12px;
}

/* Make sure the icons and information box is always on the bottom */
.foody-cities-card > div {
  height: 100%;
}
</style>
