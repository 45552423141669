<template>
  <div class="main-content">
    <PartnerHead />
    <PartnerIntro />
    <PartnerFeatures />
    <PartnerTypes />
    <PartnerBenefits />
    <PartnerFeaturesWithIcons />
    <PartnerCatchlines />
    <PartnerReviews />
    <PartnerDevices style="display: none" />
    <PartnerContact />
    <PartnerFAQ />
    <PartnerFooter />
  </div>
</template>

<script>
import PartnerHead from "@/components/partner/PartnerHead.vue";
import PartnerIntro from "@/components/partner/PartnerIntro.vue";
import PartnerTypes from "@/components/partner/PartnerTypes.vue";
import PartnerBenefits from "@/components/partner/PartnerBenefits.vue";
import PartnerFeaturesWithIcons from "@/components/partner/PartnerFeaturesWithIcons.vue";
import PartnerCatchlines from "@/components/partner/PartnerCatchlines.vue";
import PartnerFeatures from "@/components/partner/PartnerFeatures.vue";
import PartnerReviews from "@/components/partner/PartnerReviews.vue";
import PartnerDevices from "@/components/partner/PartnerDevices.vue";
import PartnerFAQ from "@/components/partner/PartnerFAQ.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import PartnerContact from "@/views/PartnerContact.vue";

export default {
  name: "Partner",
  components: {
    PartnerHead: PartnerHead,
    PartnerIntro: PartnerIntro,
    PartnerTypes: PartnerTypes,
    PartnerBenefits: PartnerBenefits,
    PartnerFeaturesWithIcons: PartnerFeaturesWithIcons,
    PartnerCatchlines: PartnerCatchlines,
    PartnerFeatures: PartnerFeatures,
    PartnerReviews: PartnerReviews,
    PartnerDevices: PartnerDevices,
    PartnerContact: PartnerContact,
    PartnerFAQ: PartnerFAQ,
    PartnerFooter: PartnerFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins";
}
h2 {
  font-size: 2.5rem;
  margin: unset;
}
.mainwrapper {
  max-width: 100%;
}

.pad-left-40 {
  padding-left: 40px;
}
.partner-buttons {
  display: grid;
  grid-gap: 20px 50px;
  grid-template-columns: 1fr;
  margin: 50px auto;
  max-width: 500px;
}

.partner-buttons button {
  max-width: 200px;
  justify-self: center;
}

.main-content {
  height: 100%;
  width: 100%;
  background-color: white;
}

.partner-type .header,
.partner-schema .header,
.partner-faq .header {
  font-family: "Poppins";
  font-size: 2.5em;
  font-weight: bold;
  color: #000;
  padding-left: 5px;
}
.partner-head > .partner-content,
.partner-intro-content {
  margin: 0 auto;
  width: max-content;
  text-align: center;
}

.partner-type-buttons button .v-btn__content,
.partner-buttons button .v-btn__content {
  color: #ffffff;
  text-transform: capitalize;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .partner-head > .partner-content,
  .partner-intro-content {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .partner-buttons {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1201px) {
  .partner-buttons {
    grid-template-columns: 1fr 1fr;
  }
  .mainwrapper {
    margin: 0 auto;
    max-width: 1250px;
  }
}
@media only screen and (max-width: 1200px) {
  h2 {
    font-size: 2em;
  }
}
</style>
