<template>
  <div class="prices">
    <span class="header"> Priser </span>
    <div class="prices-list">
      <div class="prices-list-item">
        <div>
          <span class="price-item">Kontaktløs bestilling med QR kode</span>
          <p>inkluderer mat, drikke og cafemeny</p>
        </div>
        <span class="price">kr 1100/mnd</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">Webshop for take-away</span>
          <p>
            Inkluderer egen utsalgsprofil på getfood.no for
            takeaway-bestillinger
          </p>
        </div>
        <span class="price">5%</span>
      </div>
      <hr />
      <div class="prices-list-item">
        <div>
          <span class="price-item">+ Bordreservasjon</span>
        </div>
        <span class="price">kr 200/mnd</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.price {
  text-align: right;
}
p {
  font-weight: normal;
  font-style: italic;
  color: #828282;
  margin: 0;
  font-size: 0.8em;
  text-align: left;
}
.prices {
  background-color: #ffffff;
}
.prices .header {
  font-size: 1.7em;
  font-weight: bold;
  color: #000000;
  width: 100%;
}
.prices-list {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 3px 20px;
  font-size: 1.2em;
}
.prices-list hr {
  grid-column: 1 / -1;
}

.prices-list-item {
  display: contents;
  text-align: start;
}
@media only screen and (max-width: 1200px) {
}

.prices-list-item {
  display: contents;
}
@media only screen and (max-width: 1200px) {
}
</style>
