var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"search-container full-width-exception",style:({
      backgroundColor: '#111',
      backgroundImage:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' +
        require('../../../src/assets/img/mainGetFood.png') +
        ')',
    })},[_c('div',{staticClass:"search-content"},[_c('div',{staticClass:"searchheader"},[_vm._v("Finn restauranter nær deg")]),_c('GeoSearchField',{attrs:{"onSearch":_vm.search,"isFrontpage":true}})],1)]),_c('CitiesSection'),_c('div',{staticClass:"border-line"}),_c('EateriesWithOnlineOrderingSection'),_c('div',{staticClass:"border-line"}),_c('EateriesWithoutOnlineOrderingSection'),_c('div',{staticClass:"border-line"}),_c('PartnerSection'),_c('div',{staticClass:"full-width-exception"},[_c('PartnerFooter')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }