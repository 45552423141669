import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const codebehind = {
  mixins: [validationMixin],
  validations() {
    switch (this.inputType) {
      case "postalCode":
        return {
          input: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(4),
            positiveNumber: function (value: string): boolean {
              const isNumber = parseInt(value);
              if (!Number.isNaN(isNumber) && isNumber > 0) {
                return true;
              } else return false;
            },
          },
        };
      case "email":
        return {
          input: { required, email: email },
        };
      case "password":
        return {
          input: {
            required,
            minLength: minLength(8),
            valid: function (value: string): boolean {
              const containsUppercase = /[A-Z]/.test(value);
              const containsLowercase = /[a-z]/.test(value);
              const containsNumber = /[0-9]/.test(value);
              return containsUppercase && containsLowercase && containsNumber;
            },
          },
        };
      default:
        return {
          input: { required },
        };
    }
  },
  data() {
    return {
      show: false,
    };
  },
  props: {
    input: {
      type: String,
      required: false,
    },
    fieldLabel: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputValid: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputType: {
      type: String,
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    externalErrors: {
      type: Array,
      required: false,
    },
    autoCompleteType: {
      type: String,
      required: false,
      default: "on",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.input;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },
    inputFieldType(): string {
      if (
        this.inputType === "name" ||
        this.inputType === "street" ||
        this.inputType === "postalArea" ||
        (this.inputType === "password" && this.show)
      ) {
        return "text";
      } else if (this.inputType === "postalCode") {
        return "number";
      } else if (this.inputType === "email") {
        return "email";
      } else {
        return "password";
      }
    },

    validateRules(): string[] {
      const errors: string[] = [];
      if (
        !this.$v.input.required &&
        this.isRequired &&
        !(this.inputType === "postalCode")
      ) {
        errors.push("Dette feltet er påkrevd");
      }
      // Email
      if (this.inputType === "email") {
        if (!this.$v.input.email) errors.push("Må være en gyldig epost");
      }
      //  Password
      else if (this.inputType === "password") {
        if (!this.$v.input.minLength) {
          errors.push("Minst 8 karakterer, tall, små og store bokstaver");
        } else if (!this.$v.input.valid) {
          errors.push(
            "Ikke gyldig passord, det må inneholde tall, små og store bokstaver"
          );
        }
      }
      // Postal Code
      else if (this.inputType === "postalCode") {
        if (!this.$v.input.required && this.isRequired) {
          errors.push("Dette feltet er påkrevd og kun positive tall er tillat");
        } else if (!this.$v.input.positiveNumber) {
          errors.push("Kun positive tall er tillat");
        } else if (!this.$v.input.minLength) {
          errors.push("Minimum 4 tegn");
        } else if (!this.$v.input.maxLength) {
          errors.push("Maximum 4 tegn");
        }
      }
      return errors;
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [vm.validateRules],
      () => {
        const checked = this.validateRules.length === 0;
        this.$emit("inputValid", checked);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },

  methods: {
    clear() {
      this.$v.$reset();
    },
  },
};

export default codebehind;
