import Moment from "moment";
import EateryDto from "./eateryDto";
import EatingModeDto from "./eatingModeDto";
import { EatingMode } from "@/types/enums/eatingMode";
import OpeningHoursDto from "./openingHoursDto";
import store from "../../../store/index";
import DeliveryDto from "./deliveryDto";
import TakeawayDto from "./takeawayDto";
import timeHelper from "@/helpers/timeHelper";

export function getEatingModeDto(
  eateryDto: EateryDto,
  eatingMode: EatingMode
): EatingModeDto {
  if (!eateryDto) throw "Missing eateryDto!";
  switch (eatingMode) {
    case EatingMode.DineIn:
      return eateryDto.dineIn;
    case EatingMode.Delivery:
      return eateryDto.delivery;
    case EatingMode.TakeAway:
      return eateryDto.takeAway;
    default:
      throw "Invalid eating mode!";
  }
}

export function checkIfEatingModeClosed(eateryDto: EateryDto): Boolean {
  setAvailableEatingMode(eateryDto);
  const eatingMode = store.getters.selectedEatingMode ?? EatingMode.TakeAway;
  const eatingModeData = getEatingModeDto(eateryDto, eatingMode);
  return !eatingModeData.openNow;
}

export function eatingModeClosed() {
  return store.getters.eatingModeClosed;
}

function addMomentTimestampsToOpeningHours(openingHours: OpeningHoursDto) {
  const from = Moment()
    .startOf("week")
    .add(openingHours.dayId, "days")
    .set("hours", openingHours.opens.substring(0, 2))
    .set("minutes", openingHours.opens.substring(3));

  const openingTimeIsBeforeClosingTime = Moment(
    openingHours.opens,
    "hh:mm"
  ).isBefore(Moment(openingHours.closing, "hh:mm"));
  const closingDay = openingTimeIsBeforeClosingTime
    ? openingHours.dayId
    : openingHours.dayId + 1;
  const to = Moment()
    .startOf("week")
    .add(closingDay, "days")
    .set("hours", openingHours.closing.substring(0, 2))
    .set("minutes", openingHours.closing.substring(3));

  return {
    ...openingHours,
    from,
    to,
  };
}

function pausedDateNullOrPast(pausedUntilUtc: Date): Boolean {
  const now = Moment.utc(new Date()).local();
  const pausedUntilLocal = pausedUntilUtc
    ? Moment.utc(pausedUntilUtc).local()
    : null;
  const result = pausedUntilLocal > now ? false : true;
  return result;
}

export function getPausedUntilUtcDate(eateryDto: EateryDto): Date {
  setAvailableEatingMode(eateryDto);
  const eatingMode = store.getters.selectedEatingMode ?? EatingMode.TakeAway;
  if (eatingMode == EatingMode.TakeAway) {
    const eatingModeData = getEatingModeDto(
      eateryDto,
      eatingMode
    ) as TakeawayDto;
    if (pausedDateNullOrPast(eatingModeData.pausedUntilUtc)) return null;
    return timeHelper.convertUtcToLocal(eatingModeData.pausedUntilUtc);
  } else if (eatingMode == EatingMode.Delivery) {
    const eatingModeData = getEatingModeDto(
      eateryDto,
      eatingMode
    ) as DeliveryDto;
    if (pausedDateNullOrPast(eatingModeData.pausedUntilUtc)) return null;
    return timeHelper.convertUtcToLocal(eatingModeData.pausedUntilUtc);
  }
  return null;
}

export function getPausedUntilUtcMsg(eateryDto: EateryDto): String {
  setAvailableEatingMode(eateryDto);
  const eatingMode = store.getters.selectedEatingMode ?? EatingMode.TakeAway;
  if (eatingMode == EatingMode.TakeAway) {
    const eatingModeData = getEatingModeDto(
      eateryDto,
      eatingMode
    ) as TakeawayDto;
    if (pausedDateNullOrPast(eatingModeData.pausedUntilUtc)) return null;
    const localTime = Moment(
      timeHelper.convertUtcToLocal(eatingModeData.pausedUntilUtc)
    ).format("HH:mm");
    return (
      "Hent selv er utilgjengelig frem til " +
      localTime +
      " , men du kan forhåndsbestille."
    );
  } else if (eatingMode == EatingMode.Delivery) {
    const eatingModeData = getEatingModeDto(
      eateryDto,
      eatingMode
    ) as DeliveryDto;
    if (pausedDateNullOrPast(eatingModeData.pausedUntilUtc)) return null;
    const localTime = Moment(
      timeHelper.convertUtcToLocal(eatingModeData.pausedUntilUtc)
    ).format("HH:mm");
    return (
      "Utkjøring er utilgjengelig frem til " +
      localTime +
      " , men du kan forhåndsbestille."
    );
  }
  return null;
}

export async function setAvailableEatingMode(eateryDto: EateryDto) {
  const selectedEatingMode = store.getters.selectedEatingMode;
  // If the user has already selected an eating mode and the new restaurant has it, use it. No further action required.
  if (selectedEatingMode && !!getEatingModeDto(eateryDto, selectedEatingMode)) {
    return;
  }
  const table = store.getters.tableObject;
  if (table && eateryDto.dineIn) {
    await store.dispatch("setEatingMode", EatingMode.DineIn);
    return;
  }

  if (eateryDto.takeAway) {
    await store.dispatch("setEatingMode", EatingMode.TakeAway);
  } else if (eateryDto.dineIn) {
    await store.dispatch("setEatingMode", EatingMode.DineIn);
  } else if (eateryDto.delivery) {
    await store.dispatch("setEatingMode", EatingMode.Delivery);
  } else {
    // bubble error... (we need a custom error component...)
  }
}

export function getEatingModeClosedMessage(): String {
  let returnMsg = "";
  const eatingMode = store.getters.selectedEatingMode ?? EatingMode.TakeAway;
  switch (eatingMode) {
    case EatingMode.TakeAway: {
      returnMsg = "Take-away er nå stengt, men du kan forhåndsbestille.";
      break;
    }
    case EatingMode.DineIn: {
      returnMsg = "Restauranten er nå stengt.";
      break;
    }
    case EatingMode.Delivery: {
      returnMsg = "Utkjøring av mat er nå stengt, men du kan forhåndsbestille.";
      break;
    }
    default: {
      break;
    }
  }
  return returnMsg;
}

function getNextAvailableOpeningDay(
  openingHours: Array<OpeningHoursDto>,
  currentWeekDay: number
): OpeningHoursDto {
  const sortedOpeningHours = [...openingHours]
    .filter((oh) => oh)
    .sort((a, b) => (a.dayId > b.dayId ? 1 : -1))
    .map(addMomentTimestampsToOpeningHours);

  for (let i = 0; i < sortedOpeningHours.length; i++) {
    const currentOpeningHours = sortedOpeningHours[i];
    const currentDateTime = Moment()
      .startOf("week")
      .add(currentWeekDay, "days");
    if (
      currentDateTime.isBetween(
        currentOpeningHours.from,
        currentOpeningHours.to
      )
    ) {
      return currentOpeningHours;
    }

    if (currentDateTime.isAfter(currentOpeningHours.to)) continue;

    return currentOpeningHours;
  }

  // Next opening day is not found, this can happen if next opening day is next week
  return sortedOpeningHours[0];
}

export function getTipTotal(tipOption: number, orderSum: number): number {
  const round = (orderSum: number) => Math.round(orderSum * 100) / 100;
  // From 4 and up is manual tip
  switch (tipOption) {
    case 0:
      return 0;
    case 1:
      return round(orderSum * 0.05);
    case 2:
      return round(orderSum * 0.1);
    case 3:
      return round(orderSum * 0.15);
    case 4:
      return store.getters.selectedTip ?? 0;
    default:
      return store.getters.selectedTip ?? 0;
  }
}
export function getCustomerInfoText(eateryDto: EateryDto): string {
  const eatingMode = store.getters.selectedEatingMode;
  const eatingModeData = getEatingModeDto(eateryDto, eatingMode);
  if (!eatingModeData) return "";
  return eatingModeData.customerInfoText ?? "";
}

export function getDeliveryProps(eateryDto: EateryDto): DeliveryDto {
  const eatingMode = store.getters.selectedEatingMode;
  if (eatingMode != EatingMode.Delivery) return null;
  const eatingModeData = getEatingModeDto(eateryDto, eatingMode) as DeliveryDto;
  if (eatingModeData.deliveryPrice || eatingModeData.freeDeliveryFromPrice) {
    return eatingModeData;
  }
  return null;
}

export function showExtraDeliveryInfo(eateryDto: EateryDto): boolean {
  const eatingMode = store.getters.selectedEatingMode;
  return (
    eatingMode == EatingMode.Delivery &&
    eateryDto.delivery.openNow &&
    eateryDto.delivery.deliveryPrice > 0
  );
}

export function getMininumDeliveryPrice(eateryDto: EateryDto): number {
  const eatingMode = store.getters.selectedEatingMode;
  if (eatingMode != EatingMode.Delivery) return null;
  const eatingModeData = getEatingModeDto(eateryDto, eatingMode) as DeliveryDto;
  if (eatingModeData.minimumPrice && eatingModeData.minimumPrice > 0) {
    return eatingModeData.minimumPrice;
  }
  return 0;
}

export async function deliveryMessage(eateryDto: EateryDto): Promise<string> {
  await setAvailableEatingMode(eateryDto);
  const eatingMode = store.getters.selectedEatingMode;
  if (eatingMode != EatingMode.Delivery) return null;
  const eatingModeData = getEatingModeDto(eateryDto, eatingMode) as DeliveryDto;
  let returnString = "";
  if (eatingModeData.deliveryPrice && eatingModeData.deliveryPrice > 0) {
    returnString =
      "Pris for levering kr. " + eatingModeData.deliveryPrice / 100 + ",-";
  }
  if (
    eatingModeData.freeDeliveryFromPrice &&
    eatingModeData.freeDeliveryFromPrice > 0
  ) {
    returnString +=
      "\n" +
      "Gratis levering over kr. " +
      eatingModeData.freeDeliveryFromPrice / 100 +
      ",-";
  }
  if (eatingModeData.minimumPrice && eatingModeData.minimumPrice > 0) {
    returnString +=
      "\n" +
      "Minstebeløp for bestilling kr. " +
      eatingModeData.minimumPrice / 100 +
      ",-";
  }
  return returnString;
}
