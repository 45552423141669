<template>
  <v-btn
    v-if="tile"
    :outlined="outlined"
    :disabled="disabled"
    :color="btnColor"
    :class="computedClasses"
    :type="type"
    tile
    @click.prevent="onClick"
  >
    <slot>Button</slot>
  </v-btn>
  <v-btn
    v-else
    rounded
    :outlined="outlined"
    :disabled="disabled"
    :color="btnColor"
    :class="computedClasses"
    :type="type"
    @click.prevent="onClick"
  >
    <slot>Button</slot>
  </v-btn>
</template>

<script>
export default {
  name: "FoodyButton",
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    onClick: {
      type: Function,
      required: true,
    },
    parentclasses: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    btnColor: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    tile: {
      type: Boolean,
      required: false,
    },
    fullSize: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    computedClasses() {
      //const childclasses = ["foody-button", "pointer"];
      const parentC = this.parentclasses;
      // ? [...this.parentclasses, ...childclasses]
      // : childclasses;

      return parentC;
    },
    color() {
      // const childclasses = ["foody-button", "pointer"];
      const parentC = this.parentColor;
      // ? [...this.parentclasses, ...childclasses]
      // : childclasses;
      return parentC;
    },
  },
};
</script>
