import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#9F0073", // this allows for setting color on all that is marked primary.. .. and so on.
        secondary: "#565656",
        accent: "#2185B1",
        error: "#b71c1c",
      },
    },
  },
};

export default new Vuetify(opts);
