<template>
  <div>
    <span>{{ estimationLabel }}</span>
    &nbsp;
    <span style="font-weight: bold">{{ orderReadyEstimateMinutes }}&nbsp; min</span>
  </div>
</template>
<script>
  
import { EatingMode } from "@/types/enums/eatingMode";

export default{
  props: {
    orderReadyEstimateMinutes: {
      type: Number,
      required: true,
    },
    currentEatingMode: {
      type: Number,
      required: true,
    },
  },
  computed: {
    estimationLabel(){
      switch (this.currentEatingMode) {
        case EatingMode.DineIn:
          return "";
        case EatingMode.TakeAway:
          return "Est. ventetid ";
        case EatingMode.Delivery:
          return "Est. leveringstid ";
        default:
          return "";
      }
    },
  }
}
</script>
<style scoped>
  span {
    font-size: 1rem;
  }
</style>