<template>
  <div>
    <div class="alphabox">
      <div class="header">
        <span>
          {{ headerText }}
        </span>
        <div v-if="showFeaturesCheck === 'true'" class="features">
          <span>QR</span>
          <span>Takeaway</span>
          <span>Bordreservasjon</span>
          <span>Smittesporing</span>
        </div>
      </div>
    </div>
    <div class="partner-buttons">
      <v-btn x-large color="primary" dark @click="navToSignUp">
        Bli kunde nå
      </v-btn>
      <v-btn x-large color="secondary" dark @click="navToContact">
        Ta kontakt
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    header: {
      type: String,
    },
    showFeatures: {
      type: String,
    },
  },
  data: function () {
    return {
      headerText: this.header,
      showFeaturesCheck: this.showFeatures,
    };
  },
  methods: {
    navToContact() {
      this.$router.push("/partner/contact");
    },
    navToSignUp() {
      this.$router.push("/partner/signup");
    },
  },
};
</script>
<style scoped>
.alphabox {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  min-height: 100px;
  margin-top: 180px;
  padding: 15px;
}

.alphabox .header {
  color: #ffffff;
  font-size: 1.7em;
  font-weight: medium;
  text-align: center;
}

.features {
  margin: 25px 25px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.features span {
  color: #ffffff;
  padding-right: 32px;
  padding-left: 32px;
}

.features span:first-child {
  padding-left: 0;
}

.features span:last-child {
  border-right: none;
}

@media only screen and (max-width: 600px) {
  .partner-eateries-bg-head {
    font-size: 0.8em;
  }

  .features {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .alphabox {
    min-height: 100px;
    margin-top: 180px;
  }
  .alphabox .header {
    font-size: 1.3em;
  }
  .features {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1201px) {
  .features span:not(:first-child) {
    border-left: 1px solid white;
  }
}
</style>
