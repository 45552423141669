<template>
  <div>
    <div class="navbar_container">
      <v-btn v-if="showNavbarButton" icon class="navbar_navbtn" @click="goBack">
        <v-icon class="navbar_icon" color="white" large>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <div style="text-align: center; width: 100%">
        <span class="navbar_header">
          {{ HeaderText }}
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop({ required: true }) private HeaderText: string;
  @Prop({ required: false }) private callBack: Function;
  @Prop({ default: true }) showNavbarButton: boolean;

  public goBack() {
    if (this.callBack) {
      this.callBack();
    }
  }
}
</script>
<style scoped lang="css">
.navbar_container {
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
}
.navbar_navbtn {
  display: block;
}
.navbar_icon {
  color: #fff;
}
.navbar_header {
  color: #fff;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: bold;
  line-height: 2.2em;
  margin-left: -36px;
}
.navbar_bottom_spacer {
  height: 25px;
}
</style>
