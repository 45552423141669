export default {
  data() {
    return {
      registration: null,
      updateExists: false,
    };
  },
  created() {
    // Lsiten for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    //Short circuit if user not logged in
    if (!this.$store.getters.loggedInUser) return;

    const rawToken = this.$store.getters.token;
    if (!rawToken) this.$store.dispatch("logOutUser");
    else {
      const base64Token = rawToken.split(".")[1];
      const loginToken = JSON.parse(atob(base64Token));
      const milliSecIn12Hours = 12 * 60 * 60 * 1000;
      const expiryTimeCheck = new Date().getTime() + milliSecIn12Hours;
      if (loginToken.exp < expiryTimeCheck)
        //Update store
        this.$store.dispatch("refreshTokens");
    }
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
