<template>
  <div style="margin: 0 auto; padding: 15px">
    <PartnerNavBack class="pad-left-40" variant="black"></PartnerNavBack>
    <PartnerSchemaSignUp></PartnerSchemaSignUp>
  </div>
</template>

<script>
import PartnerSchemaSignUp from "@/components/partner/PartnerSchemaSignUp.vue";
import PartnerNavBack from "@/components/partner/PartnerNavBack.vue";
export default {
  components: {
    PartnerSchemaSignUp: PartnerSchemaSignUp,
    PartnerNavBack: PartnerNavBack,
  },
};
</script>
<style scoped></style>
