<template>
  <div class="alert-container">
    <v-card elevation="0" :color="bgColor" class="rounded-card">
      <v-icon large :color="iconColor" class="alert-icon">{{ icon }}</v-icon>
      <span class="alert-text">{{ msg }}</span>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop({ required: true }) private msg: string;
  @Prop({ required: true }) private icon: string;
  @Prop({ required: true }) private iconColor: string;
  @Prop({ required: true }) private bgColor: string;
}
</script>

<style scoped>
.alert-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.alert-icon:before {
  padding: 7px;
  background: rgba(218, 139, 129, 0.2);
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
.alert-icon {
  align-self: center;
  height: 50px;
  width: 50px;
}
.alert-text {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-left: 8px;
}
.rounded-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) !important;
  min-height: 65px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 50px;
  padding: 0px 10px;
  margin: 0 10px 15px 10px;
}
/* .company-msg-text {
  padding: 20px 0;
  display: block;
  color: #f44336;
  margin-left: 10px;
}
.msg-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
} */
</style>
