<template>
  <div>
    <inputfield
      :input="passwordInput"
      input-type="password"
      field-label="Nytt passord"
      :input-valid="newPasswordValid"
      :input-disabled="editActive"
      @input="passwordInput = $event"
      @inputValid="newPasswordValid = $event"
    ></inputfield>
    <inputfield
      :input="repeatPasswordInput"
      input-type="password"
      field-label="Gjenta nytt passord"
      :input-valid="newRepeatPasswordValid"
      :input-disabled="editActive"
      @input="repeatPasswordInput = $event"
      @inputValid="newRepeatPasswordValid = $event"
    ></inputfield>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Inputfield from "@/components/inputFields/Inputfield.vue";

export default Vue.extend({
  name: "PasswordWithValidation",
  components: {
    Inputfield,
  },
  props: {
    password: {
      type: String,
      required: true,
      default: "",
    },
    repeatPassword: {
      type: String,
      required: false,
      default: "",
    },
    bothPasswordsValid: {
      type: Boolean,
      required: true,
      default: false,
    },
    editActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      newPasswordValid: false,
      newRepeatPasswordValid: false,
    };
  },
  computed: {
    passwordInput: {
      get() {
        return this.password;
      },
      set(val) {
        this.$emit("password", val);
      },
    },
    repeatPasswordInput: {
      get() {
        return this.repeatPassword;
      },
      set(val) {
        this.$emit("repeatPassword", val);
      },
    },

    checkpasswordsSimmilar() {
      return this.password === this.repeatPassword;
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [
        vm.newPasswordValid,
        vm.newRepeatPasswordValid,
        vm.checkpasswordsSimmilar,
      ],
      () => {
        const valid =
          this.newPasswordValid &&
          this.newRepeatPasswordValid &&
          this.checkpasswordsSimmilar;
        this.$emit("bothPasswordsValid", valid);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  methods: {},
});
</script>
