<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="checkoutCard">
      <v-container>
        <div class="foody-flex-row foody-flex-center">
          <div v-if="error">
            <ErrorComponent v-if="error" :message="errorMessage" />
            <v-btn @click="showDebug = true">HELP</v-btn>
            <div v-if="showDebug">
              Vi ber deg om å kopiere innholdet i tekstfeltet under og sende det til admin@getfood.no
              <textarea v-model="ErrorOrderFields" rows="10" style="width: 100%"></textarea>
            </div>
          </div>
        </div>
        <NetsCheckout
          v-if="!error"
          :key="paymentId"
          :checkout-key="checkoutKey"
          :payment-id="paymentId"
          :payment-complete="paymentComplete"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="closeDialog()">
            Avbryt betaling
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import codeBehind from "./PaymentDialog";
export default Vue.extend({
  name: "PaymentDialog",
  mixins: [codeBehind],
});
</script>
