<template>
  <div>
    <div class="profile-heading">
      <v-btn icon @click.stop="handleClick">
        <v-icon style="color: #000" class="chevron-back">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <h3>Profil</h3>
    </div>
    <div class="profile-info" @keyup.enter="() => saveProfile()">
      <v-list-item two-line>
        <v-list-item-content>
          <inputfield
            :input="firstName"
            input-type="name"
            field-label="Fornavn"
            :input-valid="firstNameValid"
            :input-disabled="!editUserInfoActive"
            @input="firstName = $event"
            @inputValid="firstNameValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <inputfield
            :input="lastName"
            input-type="name"
            field-label="Etternavn"
            :input-valid="lastNameValid"
            :input-disabled="!editUserInfoActive"
            @input="lastName = $event"
            @inputValid="lastNameValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <!-- Change inputDisabled from "true" to editUserInfoActive WHEN: email is fixed in BE to be edited! -->
          <inputfield
            :input="email"
            input-type="email"
            field-label="E-post"
            :input-valid="emailValid"
            :input-disabled="true"
            @input="email = $event"
            @inputValid="emailValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content style="overflow: visible">
          <div v-if="!editUserInfoActive">
            <v-list-item-title>Telefon</v-list-item-title>
            <v-list-item-subtitle>{{ phone }}</v-list-item-subtitle>
          </div>
          <phone-input
            v-else
            :phone="phone"
            @phone="phone = $event"
            @phoneNumberPrefixOutput="phoneNumberPrefixOutput = $event"
            @phoneNumberValid="phoneValid = $event"
          ></phone-input>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <inputfield
            :input="streetAddress"
            input-type="street"
            field-label="Adresse"
            :input-valid="streetAddressValid"
            :input-disabled="!editUserInfoActive"
            @input="streetAddress = $event"
            @inputValid="streetAddressValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <inputfield
            :input="postalCode"
            input-type="postalCode"
            field-label="Postnummer"
            :input-valid="postalCodeValid"
            :input-disabled="!editUserInfoActive"
            @input="postalCode = $event"
            @inputValid="postalCodeValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <inputfield
            :input="postalArea"
            input-type="postalArea"
            field-label="Sted"
            :input-valid="postalAreaValid"
            :input-disabled="!editUserInfoActive"
            @input="postalArea = $event"
            @inputValid="postalAreaValid = $event"
          ></inputfield>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-row style="margin-left: 10px">
      <FoodyButton
        class="edit-button"
        :on-click="editProfile"
        btn-color="primary"
        :disabled="!fieldsValid && editUserInfoActive"
        >{{
          editUserInfoActive ? "Lagre informasjon" : "Rediger informasjon"
        }}</FoodyButton
      >
      <FoodyButton
        v-if="editUserInfoActive"
        :on-click="cancelUserInfoEdit"
        class="cancel-button"
        btn-color="primary"
        ><v-icon>mdi-close-box</v-icon></FoodyButton
      >
    </v-row>
    <v-progress-circular
      v-show="isLoading"
      :size="40"
      :width="7"
      color="primary"
      indeterminate
    />

    <v-list-item
      v-if="editUserPasswordActive"
      two-line
      @keyup.enter="() => editPassword()"
    >
      <v-list-item-content>
        <inputfield
          :input="oldPassword"
          input-type="password"
          field-label="Gjeldene passord"
          :input-valid="oldPasswordValid"
          @input="oldPassword = $event"
          @inputValid="oldPasswordValid = $event"
        ></inputfield>
        <password-with-verify-password
          :password="newPassword"
          :repeat-password="newRepeatPassword"
          :both-passwords-valid="bothPasswordsValid"
          @password="newPassword = $event"
          @repeatPassword="newRepeatPassword = $event"
          @bothPasswordsValid="bothPasswordsValid = $event"
        ></password-with-verify-password>
      </v-list-item-content>
    </v-list-item>
    <v-row style="margin-left: 10px">
      <div>
        <FoodyButton
          class="edit-button"
          :on-click="editPassword"
          btn-color="primary"
          :disabled="!passwordsValid && editUserPasswordActive"
          >{{
            editUserPasswordActive ? "Lagre nytt passord" : "Endre passord"
          }}</FoodyButton
        >
        <FoodyButton
          v-if="editUserPasswordActive"
          :on-click="cancelPasswordEdit"
          class="cancel-button"
          btn-color="primary"
          ><v-icon>mdi-close-box</v-icon></FoodyButton
        >
      </div>
    </v-row>
    <ErrorComponent v-if="errorMessage !== ''" :message="errorMessage" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Codebehind from "@/components/sidebar/UserProfile";

export default Vue.extend({
  mixins: [Codebehind],
});
</script>

<style scoped>
.profile-heading {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(224, 224, 224);
  padding: 0.5em;
  align-items: center;
}
.profile-info {
  padding: 0 6px;
}
.profile-info div {
  font-size: 12px;
}
.edit-button {
  margin: 10px 5px 10px 0;
}
.cancel-button {
  margin: 10px 5px 10px 5px;
}
</style>
<style>
/* Scopeless due to scope issue */
.profile-info .vue-tel-input ul {
  padding-left: 0px;
  max-width: 250px;
}
</style>
