<template>
  <div class="button-type auto-align" @click="redirectTo(clickLink)">
    <object
      class="icon"
      type="image/svg+xml"
      :data="require(`@/assets/img/partner/${iconName}.svg`)"
    />
    <h4 class="button-text">{{ text }}</h4>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "PartnerType",
  props: {
    text: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    clickLink: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirectTo(val) {
      this.$router.push("/partner/" + val);
    },
  },
});
</script>
<style scoped>
.partner-type .header {
  margin: 40px;
}

.button-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: lightgrey;
  border-radius: 4px;
  padding: 1rem 1rem;
  width: 100%;
  height: 76px;
  max-height: 76px;
  overflow: hidden;
}

.button-type:hover {
  background: white;
  cursor: pointer;
}

.icon {
}

@media only screen and (min-width: 768px) {
  .auto-align {
    flex-direction: row;
  }
}

img {
  color: green;
}

.button-type .button-text {
  text-align: center;
  margin: 0 1.5rem;
}
</style>
