import CartItem from "@/components/shopping-cart/ShoppingCartItem.vue";
import ShoppingCartTableItem from "@/components/shopping-cart/ShoppingCartTableItem.vue";
import ShoppingCartDeliveryItem from "@/components/shopping-cart/ShoppingCartDeliveryItem.vue";
import ShoppingCartTipItem from "@/components/shopping-cart/ShoppingCartTipItem.vue";
import Tip from "@/components/shopping-cart/Tip.vue";
import { EatingMode } from "@/types/enums/eatingMode";
import {
  getDeliveryProps,
  getTipTotal,
} from "@/types/dto/eateries/eateryDtoFunctions";

const codeBehind = {
  components: {
    CartItem,
    ShoppingCartTableItem,
    ShoppingCartDeliveryItem,
    ShoppingCartTipItem,
    Tip,
  },
  data() {
    return {
      msgToKitchen: this.$store.getters.messageToKitchen ?? "",
      hasDeliveryInfo: false,
    };
  },
  mounted() {
    this.updateDeliveryInfo();

    // setup watchers
    this.$watch(
      (vm: any) => [vm.currentEatingMode, vm.shoppingCartSum],
      () => {
        this.updateDeliveryInfo();
      }
    );
  },
  computed: {
    currentEatingMode() {
      return this.$store.getters.selectedEatingMode ?? EatingMode.TakeAway;
    },
    vatType() {
      if (
        this.$store.getters.selectedEatingMode === EatingMode.TakeAway ||
        this.$store.getters.selectedEatingMode === EatingMode.Delivery
      )
        return 15;
      return 25;
    },
    shoppingcart() {
      return this.$store.getters.cart;
    },

    getTableReservation() {
      const dineInItem = this.$store.getters.dineInOrder;
      let tableReservation = null;

      if (dineInItem) {
        tableReservation = {
          name: "Bordreservasjon (" + dineInItem.Fullname + ")",
          totalPrice: 0,
          nrOfItems: dineInItem.NrOfPeople,
          choices: [] as any,
        };
      }
      return tableReservation;
    },
    getTable() {
      const table = this.$store.getters.tableObject;
      let tableObj = null;

      if (table) {
        tableObj = {
          name: "Bord",
          totalPrice: 0,
          nrOfItems: 1,
          choices: [] as any,
        };
      }
      return tableObj;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    inCheckoutAndEnabled() {
      if (this.currentEatery.tipsEnabled) {
        return true;
      }
      return false;
    },

    shoppingCartSum() {
      let orderSum = 0;
      this.shoppingcart.forEach(function (cartItem: any) {
        if (cartItem.nrOfItems > 1) {
          orderSum += cartItem.totalPrice * cartItem.nrOfItems;
        } else {
          orderSum += cartItem.totalPrice;
        }
      });
      return orderSum;
    },
    orderSum() {
      let orderSum = this.shoppingCartSum;
      const eatingMode = this.$store.getters.selectedEatingMode;
      if (eatingMode === EatingMode.Delivery) {
        const deliveryProps = getDeliveryProps(this.currentEatery);
        if (!deliveryProps) return orderSum;
        const deliveryObject = this.$store.getters.deliveryObject;
        if (!deliveryObject) return orderSum;
        if (this.shoppingCartSum < deliveryProps.freeDeliveryFromPrice) {
          orderSum += deliveryObject.totalPrice;
        }
      }
      const tipOption = this.$store.getters.selectedTip ?? 0;
      const tipSum = getTipTotal(tipOption, this.shoppingCartSum);
      if (tipSum) {
        orderSum += tipSum;
      }
      return orderSum;
    },
    hasDineInOnly() {
      const eateryInfo = this.$store.getters.eateryInfo;
      if (!eateryInfo) return false;
      const hasDineIn = eateryInfo.dineIn;
      const hasDelivery = eateryInfo.delivery;
      const takeAway = eateryInfo.takeAway;
      return hasDineIn && !hasDelivery && !takeAway;
    },
  },
  methods: {
    async updateDeliveryInfo() {
      const eatingMode = this.$store.getters.selectedEatingMode;
      const deliveryProps = getDeliveryProps(this.currentEatery);
      if (eatingMode !== EatingMode.Delivery || !deliveryProps) {
        this.hasDeliveryInfo = false;
        return;
      }
      let deliveryPayload = null;
      if (
        deliveryProps.deliveryPrice > 0 &&
        deliveryProps.freeDeliveryFromPrice != null &&
        this.shoppingCartSum >= deliveryProps.freeDeliveryFromPrice
      ) {
        deliveryPayload = {
          name: "Gratis levering",
          totalPrice: 0,
          nrOfItems: 1,
          choices: [] as any,
        };
      } else {
        deliveryPayload = {
          name: "Levering",
          totalPrice: deliveryProps.deliveryPrice,
          nrOfItems: 1,
          choices: [] as any,
        };
      }
      await this.$store.dispatch("initDeliveryObject", deliveryPayload);
      this.hasDeliveryInfo = true;
    },
    updateKichenMsgStore() {
      if (this.msgToKitchen) {
        this.$store.dispatch("updateMessageToKitchen", this.msgToKitchen);
      }
    },
    removeCartItem(index: any) {
      this.$store.dispatch("removeCartItem", index);
    },
    calculateVatInKr(vat: number) {
      const vatNumber = vat / 100 + 1;
      const vatValue = this.orderSum - this.orderSum / vatNumber;
      const vatValueInKr = vatValue / 100;
      return vatValueInKr.toFixed(2);
    }
  }
};
export default codeBehind;
