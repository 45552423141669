<template>
  <v-textarea
    v-model="inputValue"
    :label="label"
    :rules="validateRules"
    validate-on-blur
    :error-messages="externalErrors"
    clearable
    auto-grow
    solo
    clear-icon="mdi-close"
    color="blue darken-2"
    @input="$v.input.$touch()"
    @blur="$v.input.$touch()"
    @click:append="show = !show"
  >
  </v-textarea>
</template>
<script>
import Vue from "vue";
import Codebehind from "./TextAreaField";

export default Vue.extend({
  name: "TextAreaField",
  mixins: [Codebehind],
});
</script>

<style scoped></style>
