<template>
  <section class="partner-type">
    <h2 class="header">Hva beskriver din bedrift best?</h2>
    <div class="partner-type-buttons auto-resize">
      <PartnerType
        text="Spisesteder"
        iconName="Eateries"
        clickLink="spisesteder"
      ></PartnerType>
      <PartnerType
        text="Kjeder"
        iconName="Franchise"
        clickLink="kjeder"
      ></PartnerType>
      <PartnerType
        text="Hoteller"
        iconName="Hotels"
        clickLink="hotell"
      ></PartnerType>
      <PartnerType
        text="Innkjøpssamarbeid"
        iconName="Coops"
        clickLink="kjeder"
      ></PartnerType>
      <PartnerType
        text="Bar og Kafé"
        iconName="BarCafe"
        clickLink="cafebar"
      ></PartnerType>
      <PartnerType
        text="Andre grupperinger"
        iconName="Other"
        clickLink="kjeder"
      ></PartnerType>
    </div>
  </section>
</template>

<script>
import PartnerType from "./PartnerType.vue";
export default {
  components: { PartnerType: PartnerType },
  methods: {
    redirectTo(val) {
      this.$router.push("/partner/" + val);
    },
  },
};
</script>
<style scoped>
.header {
  margin: 0;
  padding: 0;
  margin-bottom: 4rem;
  text-align: center;
}

.partner-type-buttons {
  max-width: 100%;
  display: grid;
  grid-gap: 2rem 2rem;
  justify-items: center;
  margin: 0 auto;
  padding: 0 1rem;
  width: max-content;
}
.partner-type {
  padding: 10rem 0;
}

.auto-resize {
  grid-template-columns: repeat(1, minmax(250px, 2fr));
}
.partner-type-buttons button {
  width: 300px;
}

@media only screen and (min-width: 768px) {
  .auto-resize {
    grid-template-columns: repeat(3, minmax(2px, 2fr));
  }
  .header {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .partner-type {
    padding: 4rem 0;
  }
}
</style>
