import { api, baseURL, getConfig } from "../api";
import EateryDto from "@/types/dto/eateries/eateryDto";

export default {
  /* Eatery getters */
  async getEateryInfo(companyName: string, token: string): Promise<EateryDto> {
    const endpointUrl = baseURL + "/api/Eateries/GetInfoByName/" + companyName;
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
  // eslint-disable-next-line prettier/prettier
  async getEateriesByFilters(query: string, token: string): Promise<EateryDto[]> {
    const endpointUrl = baseURL + "/api/Eateries/" + query;

    const response = await api.get(endpointUrl, getConfig(token));
    return response.data;
  },
  async getEateriesOverview(token: string): Promise<EateryDto[]> {
    const endpointUrl = baseURL + "/api/Eateries/";
    const response = await api.get(endpointUrl, getConfig(token));
    return response.data;
  },
  // eslint-disable-next-line prettier/prettier
  async getEateryInfoById(companyId: number, token: string): Promise<EateryDto> {
    const endpointUrl = baseURL + "/api/Eateries/GetById/" + companyId;
    const response = await api.get(endpointUrl, getConfig(token));
    return response.data;
  },
};
