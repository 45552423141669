<template>
  <v-dialog v-model="show" width="500">
    <FoodyCard>
      <v-card-title>
        <b>{{ eateryInfo.displayName }}</b>
      </v-card-title>

      <v-card-text>
        <v-icon color="primary">mdi-map-marker-outline</v-icon>
        <a
          target="_blank"
          :href="
            'https://maps.google.com/?q=' +
            eateryInfo.address.streetAddress +
            ',' +
            eateryInfo.address.postalCode +
            ',' +
            eateryInfo.address.postalArea
          "
          >{{ eateryInfo.address.streetAddress }},
          {{ eateryInfo.address.postalCode }}
          {{ eateryInfo.address.postalArea }},
          {{ eateryInfo.companyCountry }}
        </a>
      </v-card-text>

      <v-card-text v-if="eateryInfo.phone">
        <v-icon color="primary">mdi-phone-outline</v-icon>
        {{ eateryInfo.phone }}
      </v-card-text>

      <v-card-text v-if="eateryInfo.aboutMessage">
        {{ eateryInfo.aboutMessage }}
      </v-card-text>

      <v-card-text>
        <b><center>Åpningstider</center></b>
        <hr />
        <div v-if="openingHoursList.length > 0">
          <v-tabs v-model="tab" centered class="mb-2">
            <v-tab v-for="item in openingHoursList" :key="item.tab">{{
              item.tab
            }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in openingHoursList" :key="item.tab">
              <table>
                <tbody>
                  <tr v-for="time in item.openingHours" :key="time.dayId">
                    <td>{{ time.day }}</td>
                    <td class="align-right">
                      {{ prettyTimeString(time.opens) }} -
                      {{ prettyTimeString(time.closing) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <span v-else>Ingen åpningstider funnet for spisestedet</span>
      </v-card-text>

      <v-divider />

      <div class="flex-container">
        <div></div>

        <div>
          <v-card-text>
            <p class="bold-text-24px">Org.nr {{ eateryInfo.companyOrgNr }}</p>
          </v-card-text>
        </div>

        <div>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click.stop="show = false">
              Lukk
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </FoodyCard>
  </v-dialog>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
import timeHelper from "@/helpers/timeHelper";

export default {
  name: "CompanyInfoModal",
  components: {
    FoodyCard: FoodyCard,
  },
  props: {
    eateryInfo: {
      type: Object,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      tab: null,
      openingHoursList: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.addEateryOpeningTime();
  },
  methods: {
    prettyTimeString(timeString) {
      return timeHelper.prettyHoursAndMinutes(timeString);
    },
    addEateryOpeningTime() {
      if (this.eateryInfo.dineIn && this.eateryInfo.dineIn.openingHours) {
        this.openingHoursList.push({
          tab: "spis inne",
          openingHours: this.sortDays(this.eateryInfo.dineIn.openingHours),
        });
      }
      if (this.eateryInfo.takeAway && this.eateryInfo.takeAway.openingHours) {
        this.openingHoursList.push({
          tab: "henting",
          openingHours: this.sortDays(this.eateryInfo.takeAway.openingHours),
        });
      }
      if (this.eateryInfo.delivery && this.eateryInfo.delivery.openingHours) {
        this.openingHoursList.push({
          tab: "levering",
          openingHours: this.sortDays(this.eateryInfo.delivery.openingHours),
        });
      }
    },
    sortDays(openingHours) {
      if (!openingHours) return null;
      return openingHours.slice().sort(function (a, b) {
        return a.dayId - b.dayId;
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border: none;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-container > *:first-child {
  width: 80px;
}

.bold-text-24px {
  font-size: 14px;
  color: black;
  font-weight: bolder;
  margin-bottom: 0px;
}
</style>
