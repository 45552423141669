//view imports
import FoodCourt from "@/views/FoodcourtMain.vue";
import store from "../store/index";
import { RouteConfig } from "vue-router";
import { initTableObject, createStartUrl } from "./guards/order-guards";

const FoodcourtRoutes: RouteConfig[] = [
  {
    path: "/foodcourt/:foodcourtName?",
    component: FoodCourt,
    props: true,
    beforeEnter: async (to, _, next) => {
      const startUrl = createStartUrl(to);
      await store.dispatch("clearTableObject");
      initTableObject(to, startUrl);
      next();
    },
  },
];

export default FoodcourtRoutes;
