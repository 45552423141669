import { getSeverity, messageType } from "@/types/enums/messageType";
import { FoodyError } from "@/types/error/error";
import { getMininumDeliveryPrice } from "@/types/dto/eateries/eateryDtoFunctions";
import Moment from "moment";
import {
  currentEatingModeIsOpenNow,
  getFirstAvailableOpeningTimeForCurrentEatingMode
} from "@/helpers/currentEateryHelper";

const codebehind = {
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    selectedDate: {
      type: String,
      required: true
    },
    selectedTime: {
      type: String,
      required: true
    }
  },

  computed: {
    minimumDeliverySumInKr() {
      return (
        getMininumDeliveryPrice(this.$store.getters.eateryInfo) / 100
      ).toFixed(2);
    },
    addArray(): Array<FoodyError> {
      const currentDate = Moment(this.selectedDate, "YYYY-MM-DD", true);
      if (this.selectedTime) {
        const currentTime = Moment(this.selectedTime, "HH:mm", true);
        currentDate.hour(currentTime.hour());
        currentDate.minute(currentTime.minute());
      }

      const momentNextOpeningDate = Moment(
        getFirstAvailableOpeningTimeForCurrentEatingMode(currentDate.toDate()), "YYYY-MM-DD HH:mm", true);
      return [
        {
          id: messageType.eatingModeClosed,
          isActive:
            momentNextOpeningDate.format("YYYY-MM-DD") != this.selectedDate,
          type: messageType.eatingModeClosed,
          severity: getSeverity(messageType.eatingModeClosed),
          message: `Spisestedet er stengt, men du kan forhåndsbestille allerede nå. Neste åpningstid er ${momentNextOpeningDate.format(
            "dddd"
          )} kl. ${momentNextOpeningDate.format("HH:mm")}`
        },
        {
          id: messageType.qrDineInIsClosed,
          isActive: this.$store.getters.isErrorQrDineInClosed,
          type: messageType.qrDineInIsClosed,
          severity: getSeverity(messageType.qrDineInIsClosed),
          message: `Spisested er stengt. Neste åpningstid er ${momentNextOpeningDate.format(
            "dddd"
          )} kl. ${momentNextOpeningDate.format("HH:mm")}`
        },
        {
          id: messageType.noCartItems,
          isActive: this.$store.getters.isErrorNoCartItem,
          type: messageType.noCartItems,
          severity: getSeverity(messageType.noCartItems),
          message: "Du må legge noe i handlekurven for å fortsette."
        },
        {
          id: messageType.notAvailabelForDelivery,
          isActive: this.$store.getters.isErrorNotAvailableForDelivery,
          type: messageType.notAvailabelForDelivery,
          severity: getSeverity(messageType.notAvailabelForDelivery),
          message:
            "Du har produkter i handlekurven som kun kan nytes på spisestedet, og ikke er tilgjengelig for utkjøring/levering."
        },
        {
          id: messageType.notAvailabelForTakeaway,
          isActive: this.$store.getters.isErrorNotAvailableForTakeAway,
          type: messageType.notAvailabelForTakeaway,
          severity: getSeverity(messageType.notAvailabelForTakeaway),
          message:
            "Du har produkter i handlekurven som kun kan nytes på spisestedet, og ikke er tilgjengelig for Take-away."
        },
        {
          id: messageType.belowMinimumDeliverySum,
          isActive: this.$store.getters.isErrorBelowMinimumDeliverySum,
          type: messageType.belowMinimumDeliverySum,
          severity: getSeverity(messageType.belowMinimumDeliverySum),
          message: `Du må bestille mat for minst ${this.minimumDeliverySumInKr} NOK for å bestille med levering.`
        }
      ];
    }
  }
};

export default codebehind;
