import { render, staticRenderFns } from "./PartnerSchema.vue?vue&type=template&id=ae3b4176&scoped=true"
import script from "./PartnerSchema.vue?vue&type=script&lang=js"
export * from "./PartnerSchema.vue?vue&type=script&lang=js"
import style0 from "./PartnerSchema.vue?vue&type=style&index=0&id=ae3b4176&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae3b4176",
  null
  
)

export default component.exports