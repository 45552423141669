<template>
  <v-dialog v-model="show" width="500">
    <FoodyCard>
      <v-card-title style="word-break: normal">
        Tilpasset melding til {{ item.name }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="CustomerMessage"
          outlined
          height="200px"
          label="Tilpasning av matrett(ene).."
          @blur="updateCartItemWithMessage"
        ></v-textarea>
      </v-card-text>
      <div class="bottom-container">
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn large text color="primary" @click.stop="show = false">
            Bekreft
          </v-btn>
        </v-card-actions>
      </div>
    </FoodyCard>
  </v-dialog>
</template>
<script>
import FoodyCard from "@/components/common/FoodyCard.vue";
export default {
  name: "CartItemMessageModal",
  components: { FoodyCard },
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      CustomerMessage: this.item.CustomerMessage ?? "",
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    updateCartItemWithMessage() {
      if (this.CustomerMessage) {
        const payload = {
          item: this.item,
          CustomerMessage: this.CustomerMessage,
        };
        this.$store.dispatch("updateCartItemWithMessage", payload);
      }
    },
  },
};
</script>
