<template>
  <div>
    <div v-if="!isOnlyDineInAndQR" class="delivery-option-switch-container">
      <DeliveryOptionSwitch
        v-if="!isQrTableOrder && !isSingleEatingMode"
        :estimationOnly="isSingleEatingMode"
        :eatery-info="currentEatery"
      ></DeliveryOptionSwitch>
      <DeliveryOptionInfo :estimationOnly="isSingleEatingMode" v-if="!eatingModeOnPauseMsg" />
    </div>

    <div class="messagesWrapper">
      <EateryMessage
        v-if="false"
        :msg="krisemelding"
        bg-color="#FFFFFF"
        icon="mdi-information-outline"
        icon-color="#FF8B81"
      ></EateryMessage>
      <EateryMessage
        v-if="eatingModeClosed"
        style="display: none"
        :msg="eatingModeClosedMsg"
        bg-color="#FFFFFF"
        icon="mdi-information-outline"
        icon-color="#DA8B81"
      ></EateryMessage>
      <EateryMessage
        v-if="!isQrTableOrder && eatingModeOnPauseMsg && !eatingModeClosed"
        :msg="eatingModeOnPauseMsg"
        bg-color="#FFFFFF"
        icon="mdi-information-outline"
        icon-color="#DA8B81"
      >
      </EateryMessage>
      <EateryMessage
        v-if="showDeliveryInfoExtra"
        :msg="deliveryInfoExtraMessage"
        bg-color="#FFFFFF"
        icon="mdi-truck-delivery-outline"
        icon-color="#818ADA"
      >
      </EateryMessage>
      <CustomerInfoMessage />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import DeliveryOptionSwitch from "@/components/common/DeliveryOptionSwitch.vue";
import DeliveryOptionInfo from "@/components/common/DeliveryOptionInfo/DeliveryOptionInfo.vue";
import EateryMessage from "@/components/EateryMessage.vue";
import CustomerInfoMessage from "@/components/common/CustomerInfoMessage.vue";
import {
  getPausedUntilUtcMsg,
  getEatingModeClosedMessage,
  showExtraDeliveryInfo,
  deliveryMessage,
  setAvailableEatingMode
} from "@/types/dto/eateries/eateryDtoFunctions";
import { currentEatingModeIsOpenNow } from "@/helpers/currentEateryHelper";
export default Vue.extend({
  name: "EatingModeSwitch",
  components: {
    DeliveryOptionSwitch,
    DeliveryOptionInfo,
    EateryMessage,
    CustomerInfoMessage,
  },
  props: {
    currentEatery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deliveryInfoExtraMessage: "",
    };
  },
  computed: {
    isOnlyDineInAndQR() {
      const eatery = this.currentEatery;
      return eatery.dineInIsQrOnly && !eatery.takeAway && !eatery.delivery;
    },
    isSingleEatingMode() {
      const eatery = this.currentEatery;
      const eatingModes = [eatery.dineIn, eatery.delivery, eatery.takeAway].filter(x => !!x);
      return eatingModes.length === 1;
    },
    showDeliveryInfoExtra() {
      return showExtraDeliveryInfo(this.currentEatery);
    },
    currentEatingMode() {
      if (!this.$store.getters.selectedEatingMode)
        setAvailableEatingMode(this.currentEatery);
      return this.$store.getters.selectedEatingMode;
    },
    eatingModeClosed() {
      return !currentEatingModeIsOpenNow();
    },
    eatingModeOnPauseMsg() {
      return getPausedUntilUtcMsg(this.currentEatery);
    },
    eatingModeClosedMsg() {
      return getEatingModeClosedMessage();
    },
    isQrTableOrder() {
      return this.$store.getters.tableObject;
    },
    krisemelding() {
      return "VIKTIG MELDING: Vår betalingsleverandør har problemer, og bestillinger kommer ikke igjennom. Vennligst bestill på en annen måte, så er vi snart tilbake.";
    },
  },

  watch: {
    async showDeliveryInfoExtra(newValue) {
      if (newValue === true) {
        this.deliveryInfoExtraMessage =
          await this.getDeliveryInfoExtraMessage();
      }
    },
  },
  async mounted() {
    this.deliveryInfoExtraMessage = await this.getDeliveryInfoExtraMessage();
  },
  methods: {
    async getDeliveryInfoExtraMessage() {
      return await deliveryMessage(this.currentEatery);
    },
  },
});
</script>
<style scoped>
.delivery-option-switch-container {
  flex-grow: 0;
  max-width: fit-content;
  margin: 0 auto;
  background-color: white;
  position: relative;
  border-radius: 25px;
  margin-top: -65px;
}
.messagesWrapper {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 8px;
}
@media only screen and (max-width: 520px) {
  .delivery-option-switch-container {
    margin-top: -15px;
  }
}
</style>
