import { getCurrentEatery } from "./currentEateryHelper";
import store from "../store/index";
import Menu from "../types/dto/menues/menu";
import { getValidEatingModes } from "./eatingModesValidator";

export function getValidEatingModesForCurrentMenu(isQr: boolean) {
  const eatery = getCurrentEatery();
  const menu = getCurrentMenu();

  return getValidEatingModes(isQr, eatery, menu);
}

function getCurrentMenu() {
  const menu = store.getters.menu;
  if (!menu) return null;

  return menu as Menu;
}
