import { functionsApi as api, functionsBaseURL, getConfig } from "../api";
import CityDto from "@/types/dto/cities/cityDto";

export default {
  /* Getters */
  async getCities(token: string): Promise<CityDto[]> {
    const endpointUrl = functionsBaseURL + "/api/cities";
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
};
