<template>
  <div>
    <header class="foodcard-header">
      <span
        >{{ item.name }}
        <span v-if="isNearlyOutOfStock" class="nearly-out-of-stock"
          >( kun {{ item.stock }} igjen )</span
        ></span
      >
      <v-icon class="info-icon" @click.stop="showAllergensInfoModal = true"
        >mdi-information-outline</v-icon
      >
    </header>
    <AllergyList v-model="showAllergensInfoModal" :item="item" />
  </div>
</template>
<script>
import AllergyList from "@/components/menues/menu-items/AllergyList/AllergyList.vue";

export default {
  components: {
    AllergyList,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAllergensInfoModal: false,
    };
  },
  computed: {
    isNearlyOutOfStock() {
      const stockCount = this.item.stock;
      return stockCount && stockCount <= 2 && stockCount > 0;
    },
  },
};
</script>
<style scoped>
.foodcard-header {
  font-weight: bold;
  font-size: 12pt;
  color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
}

.nearly-out-of-stock {
  font-size: 0.5em;
  color: red;
}

.info-icon {
  margin-bottom: 5px;
  align-self: end;
}
</style>
