<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <div v-on="on">
        <v-text-field
          v-model="computedDateFormatted"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
        ></v-text-field>
      </div>
    </template>
    <v-date-picker
      v-model="dateString"
      class="mt-4"
      first-day-of-week="1"
      locale="NB-no"
      no-title
      :min="getMinDate()"
      :max="getMaxDate()"
      :allowed-dates="allowedDates"
      @input="onChange()"
    />
  </v-menu>
</template>
<script>
import Vue from "vue";
import codeBehind from "./DatePicker";

export default Vue.extend({
  name: "DatePicker",
  mixins: [codeBehind],
});
</script>
<style scoped>
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  transform: scaleX(1);
  color: #a50a79;
}
</style>
