<template>
  <div
    v-if="deliveryObject"
    class="foody-flex-row foody-flex-align-center foody-flex-center foody-space-betweeen"
  >
    <div>
      <h3 style="margin-left: 5px">
        {{ deliveryObject.name }}
      </h3>
    </div>
    <div v-if="deliveryObject.totalPrice > 0">
      {{ deliveryObject.totalPrice / 100 }} NOK
    </div>
  </div>
</template>

<script lang="ts">
import { EatingMode } from "@/types/enums/eatingMode";
import { getMininumDeliveryPrice } from "@/types/dto/eateries/eateryDtoFunctions";

import Vue from "vue";
export default Vue.extend({
  name: "ShoppingCartDeliveryItem",
  props: {
    shoppingcartSum: {
      type: Number,
      required: true,
    },
  },
  computed: {
    minimumDeliveryPrice() {
      return getMininumDeliveryPrice(this.currentEatery);
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    eatingMode() {
      return this.$store.getters.selectedEatingMode;
    },
    deliveryObject() {
      if (this.eatingMode !== EatingMode.Delivery) return null;
      return this.$store.getters.deliveryObject;
    },
  },
});
</script>
<style scoped>
.shoppingCartError {
  font-size: 12px;
  color: red;
  margin-left: 5px;
}
</style>
