import MenuItem from "@/types/dto/menues/menuItem";
import store from "../store/index";

export function getItemCountInCart(item: MenuItem) {
  const cart = store.getters.cart;
  if (!cart || cart == null || cart.length === 0) return 0;
  return cart.reduce(
    (count: any, cartItem: any) =>
      item.menuItemId === cartItem.menuItemId
        ? count + cartItem.nrOfItems
        : count,
    0
  );
}

export function itemOutOfStock(item: MenuItem) {
  if (!item || !item.stock == null || item.stock == undefined) return false;
  if (item.stock == 0) return true;
  const currentAmount = getItemCountInCart(item);
  return currentAmount >= item.stock;
}
