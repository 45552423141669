<template>
  <div class="partner-schema">
    <p class="header">Takk for din henvendelse!</p>
    <p>
      <img src="../../assets/img/success_large.png" class="successicon" />
    </p>
    <p>
      Vi har mottatt din kontaktinformasjon og kommer tilbake til deg så fort
      som mulig.<br /><br />
      Ved hastesaker, kontakt oss på telefon 944 51 155 eller e-post
      thor.holum@getfood.no.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media only screen and (max-width: 1200px) {
  .successicon {
    height: 120px;
  }
}
</style>
