import Moment from "moment";

export default {
  /* Conversion */
  convertUtcToLocal(pausedUntilUtc: Date): Date {
    const pausedUntilDate = Moment.utc(pausedUntilUtc);
    const returnDate = Moment(pausedUntilDate).local().toDate();
    return returnDate;
  },

  /* Getters */
  getDateTimeFromDateAndTimeStrings(dateString: string, timeString: string) {
    const date = new Date();

    const splitDate = dateString.split("-");
    date.setFullYear(Number.parseInt(splitDate[0]));
    date.setMonth(Number.parseInt(splitDate[1]) - 1);
    date.setDate(Number.parseInt(splitDate[2]));

    const splitTime = timeString.split(":");
    date.setHours(Number.parseInt(splitTime[0]));
    date.setMinutes(Number.parseInt(splitTime[1]));

    return date;
  },

  /* Helpers */
  prettyHoursAndMinutes(timeString: string): string {
    if (!timeString) return null;
    const prettyTime = Moment(timeString, "HH:mm:ss").format("HH:mm");
    return prettyTime.toString();
  },

  getDateFromTimeString(timeString: string, date?: Date): Date {
    const setDate = date ? new Date(date) : new Date();
    const timeArray = timeString.split(":");
    const hours = parseInt(timeArray[0]);
    const minutes = parseInt(timeArray[1]);
    setDate.setHours(hours, minutes, 0);
    return setDate;
  },

  getTimeStringFromDate(date: Date) {
    return [
      date.getHours().toString().padStart(2, "0"),
      date.getMinutes().toString().padStart(2, "0"),
    ].join(":");
  },
};
