<template>
  <v-bottom-sheet v-model="GDPRsnackbar" :timeout="-1">
    <v-sheet min-height="200px" style="padding: 25px; text-align: center">
      <div class="gdpr-header">
        <h3>Informasjonskapsler/Cookies</h3>
      </div>
      <div class="gdpr-content">
        Vi bruker cookies for å forbedre din opplevelse på nettsiden. Ved å
        klikke på “godta alle”, samtykker du til alle våre cookies. Les mer om
        hva dette innebærer og juster dine preferanser under vår
        <router-link to="/cookiepolicy">cookie policy</router-link>.
      </div>
      <FoodyButton :btn-color="'primary'" :on-click="acceptGDPR"
        >Godta alle
      </FoodyButton>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script lang="ts">
import Vue from "vue";
import Codebehind from "./gdprCookiePopup";
export default Vue.extend({
  mixins: [Codebehind],
});
</script>
<style scoped>
.gdpr-header {
  margin-bottom: 20px;
}
.gdpr-content {
  margin-bottom: 20px;
}
</style>
