<template>
  <section class="partner-featuresection">
    <div class="features-highlightedfeatures-wrapper">
      <div class="features-highlightedfeatures">
        <div class="highlightedfeatureitem">
          <span>QR-kode ved bordet</span>
        </div>
        <div class="highlightedfeatureitem">
          <span>Takeaway</span>
        </div>
        <div class="highlightedfeatureitem">
          <span>Smittesporing</span>
        </div>
      </div>
      <div class="feature-spacing"></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style scoped>
.partner-featuresection {
  background-color: var(--partner-dark-background-color);
  color: white;
  display: flex;
  font-size: 1.2rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 100%;
}
.features-highlightedfeatures-wrapper {
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
}

.features-highlightedfeatures {
  display: flex;
  width: 85%;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.feature-spacing {
  width: 33%;
}
.highlightedfeatureitem:not(:last-child) {
  border-right: 3px solid white;
  padding-right: 1em;
}
.highlightedfeatureitem {
  padding-left: 1em;
}

.highlightedfeatureitem span {
  display: flex;
}

.highlightedfeatureitem-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 3px;
}
@media only screen and (max-width: 1200px) {
  .features-featurelist {
    justify-items: left;
    margin: 0 auto;
    width: max-content;
  }
  .features-highlightedfeatures {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
  .feature-spacing {
    display: none;
  }
}
</style>
