<template>
  <v-bottom-sheet
    v-if="item"
    v-model="showSheet"
    inset
    content-class="narrow"
    @click:outside="onSheetClose"
  >
    <v-sheet min-height="200px">
      <div class="sheet-head">
        <h3>{{ item.name }}</h3>
        <v-btn
          class="mr-4 sheet-head-icon"
          fab
          dark
          x-small
          color="#6D6D6D"
          @click="onSheetClose"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </div>
      <div class="sheet-desc">
        <span>{{ item.description }}</span>
      </div>
      <div class="sheet-body">
        <div
          v-if="item.menuItemVariants && item.menuItemVariants.length > 0"
          class="foody-flex-row sheet-props"
        >
          <MenuItemVariant
            :key="menuItemVariantsKey"
            :options="item.menuItemVariants"
            class="foodcard-properties-box"
            style="margin-top: 10px"
          ></MenuItemVariant>
        </div>
        <div
          v-if="item.foodProperties.length > 0 && showSheet"
          class="foody-flex-row"
        >
          <div style="width: 100%">
            <div
              v-for="fp in item.foodProperties"
              :key="fp.id"
              class="sheet-props"
            >
              <FoodOptionRadio
                v-if="!fp.multiSelect"
                :foodProp="fp"
                class="foodcard-properties-box"
              />
              <FoodOptionMulti
                v-if="fp.multiSelect"
                :foodProp="fp"
                class="foodcard-properties-box"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.allowCustomerMessage" class="pa-2">
        <v-textarea
          v-model="customerMessage"
          label="Meldingsfelt for rett"
          auto-grow
          outlined
          rows="2"
          row-height="30"
        ></v-textarea>
      </div>
      <div class="sheet-footer">
        <v-card class="sheet-footer-content">
          <h3 style="margin-right: 15px; margin-left: 0">
            {{
              ((variant ? variant.price : item.singlePrice) + optionPrice) / 100
            }}
            NOK
          </h3>
          <FoodyButton
            v-if="!hasOnlyQrAndDineIn || TableObject"
            :btn-color="'primary'"
            :parentclasses="['btn-full-width']"
            :on-click="() => addToCart(item)"
          >
            LEGG TIL I HANDLEKURV
          </FoodyButton>
        </v-card>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import FoodyButton from "@/components/common/FoodyButton.vue";
import MenuItemVariant from "@/components/menues/menu-items/menu-item-properties/MenuItemVariant.vue";
import FoodOptionRadio from "@/components/menues/menu-items/menu-item-properties/FoodOptionRadio.vue";
import FoodOptionMulti from "@/components/menues/menu-items/menu-item-properties/FoodOptionMulti.vue";
import { getItemCountInCart } from "@/helpers/cartHelper.ts";
import { resetFoodPropertyOptions, resetMenuItemVariants } from "./utils";
export default {
  name: "MenuItemBottomSheet",
  components: {
    MenuItemVariant,
    FoodOptionRadio,
    FoodOptionMulti,
    FoodyButton,
  },
  props: {
    showSheet: {
      type: Boolean,
      required: true,
    },
    activeMenuItem: {
      type: Object,
      default: null,
    },
    hasOnlyQrAndDineIn: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      customerMessage: "",
      menuItemVariantsKey: 1,
    };
  },
  computed: {
    TableObject() {
      return this.$store.getters.tableObject;
    },
    item() {
      return this.activeMenuItem;
    },
    optionPrice() {
      const selectedFoodProps = this.getSelectedFoodProperties(this.item);

      const selectedOptions =
        this.getSelectedFoodPropertiesOptions(selectedFoodProps);
      const sum = this.getOptionsSum(selectedOptions);
      return sum;
    },
    variant() {
      const foodVariant = this.getSelectedFoodVariant(this.item);
      return foodVariant;
    },
  },
  methods: {
    onSheetClose() {
      this.$emit("sheetUpdated", !this.showSheet);
    },
    addToCart() {
      const itemCountInCart = getItemCountInCart(this.item);
      if (this.item.stock && itemCountInCart >= this.item.stock) return;
      this.item.customerMessage = this.customerMessage
        ? this.customerMessage
        : undefined;
      this.$store.dispatch("addCartItem", this.item);
      this.$store.dispatch("setSnackbarText", `Lagt i handlekurv`);

      resetMenuItemVariants(this.item);
      resetFoodPropertyOptions(this.item);
      this.menuItemVariantsKey++;
      this.customerMessage = "";

      this.onSheetClose();
    },
    getSelectedFoodVariant(item) {
      if (!item.menuItemVariants || item.menuItemVariants.length === 0)
        return null;
      let selectedItem = item.menuItemVariants.find((x) => x.selected);
      if (selectedItem === undefined) {
        selectedItem = item.menuItemVariants.find((x) => x.isDefault);
        selectedItem.selected = true;
      }
      return selectedItem;
    },
    getSelectedFoodProperties(item) {
      return item.foodProperties.filter((x) =>
        x.foodPropertyOptions.some(
          (foodPropertyOption) =>
            foodPropertyOption && foodPropertyOption.selected
        )
      );
    },
    getSelectedFoodPropertiesOptions(foodProperties) {
      if (foodProperties && foodProperties.length > 0) {
        const res = foodProperties.map((x) => {
          const options = x.foodPropertyOptions.filter((option) => {
            if (option.selected) {
              return option;
            }
          });
          return options;
        });
        return res.flat();
      } else return [];
    },
    getOptionsSum(options) {
      let sum = 0;
      for (let index = 0; index < options.length; index++) {
        const option = options[index];
        sum += option.price;
      }
      return sum;
    },
  },
};
</script>
<style scoped>
::v-deep .v-bottom-sheet.v-dialog {
  overflow-y: auto;
}
.sheet-head {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-left: 15px;
}
.sheet-head-icon {
  margin-top: 0;
}
.sheet-desc {
  margin: 0 15px;
  border-bottom: 2px solid #f6f6f6;
  padding-bottom: 10px;
}
.sheet-body {
  padding: 0px 15px;
  max-height: auto;
}
.sheet-props {
  border-bottom: 2px solid #f6f6f6;
}
.sheet-props:last-child {
  border-bottom: none;
}
.sheet-footer-content {
  padding: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.sheet-footer {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
}
</style>
