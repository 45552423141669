<template>
  <div>
    <p class="closed-text">STENGT</p>
    <span>{{ eateryOpensText }}</span>
    <span style="font-weight: bold;">{{ nextOpeningTimeText }}</span>
    <p v-if="showSubText">
      Du kan forhåndsbestille allerede nå.
    </p>
  </div>
</template>
<script>

import { EatingMode } from "@/types/enums/eatingMode";
import Moment from "moment";

export default{
  props: {
    nextOpeningDate: {
      type: Date,
      required: true,
    },
    currentEatingMode: {
      type: Number,
      required: true,
    },
  },
  computed: {
    eateryOpensText() {
      switch (this.currentEatingMode) {
        case EatingMode.DineIn:
          return "Restauranten åpner ";
        case EatingMode.TakeAway:
          return "Henting åpner ";
        case EatingMode.Delivery:
          return "Levering åpner ";
        default:
          return "Åpent ";
      }
    },
    nextOpeningTimeText(){
      const opensToday = this.nextOpeningDate.getDate() === new Date().getDate();

      const opensTime = Moment(this.nextOpeningDate).format("HH:mm");

      if (opensToday) {
        return `kl ${opensTime}`;
      }

      const nextOpensDay = Moment(this.nextOpeningDate).format("dddd");

      return `${nextOpensDay} kl ${opensTime}`;
    },
    showSubText(){
      return this.currentEatingMode !== EatingMode.DineIn;
    },
  }
}
</script>
<style scoped>
  span {
    font-size: 1rem;
  }
  .closed-text {
    font-weight: bold;
    color: red;
  }
</style>