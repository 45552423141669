import { Route } from "vue-router";
import store from "../../store/index";

async function getResetToken(route: Route | string) {
  await store.dispatch("setResetToken", (route as Route).query);
}

export async function ResetTokenGuard(
  to: Route | string,
  from: Route | string,
  next: Function
): Promise<void> {
  await getResetToken(to);
  next();
}
