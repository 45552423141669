import { EatingMode } from "../types/enums";
import EateryDto from "../types/dto/eateries/eateryDto";
import Menu from "../types/dto/menues/menu";

export function getValidEatingModes(
  isQr: boolean,
  eatery?: EateryDto,
  menu?: Menu
) {
  if (isQr) return [EatingMode.DineIn];

  const options: EatingMode[] = [];

  if (!eatery) return [];

  const dineInAndQrOnly = eatery?.dineIn?.isQrOnly ?? false;

  if (!menu) {
    //Applies to menu list page
    if (eatery.dineIn && !dineInAndQrOnly) options.push(EatingMode.DineIn);
    if (eatery.takeAway) options.push(EatingMode.TakeAway);
    if (eatery.delivery) options.push(EatingMode.Delivery);
  } else {
    // Applies to menu page
    if (menu.allowDineIn && eatery.dineIn && !dineInAndQrOnly)
      options.push(EatingMode.DineIn);
    if (menu.allowTakeAway && eatery.takeAway)
      options.push(EatingMode.TakeAway);
    if (menu.allowDelivery && eatery.delivery)
      options.push(EatingMode.Delivery);
  }
  return options;
}
