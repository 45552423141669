<template>
  <div class="banner-container">
    <v-img :src="eateryInfo.bannerUrl" class="banner-bg">
      <div class="banner-header">
        <div class="nav-back">
          <div
            v-if="eateryMenus.length > 1 && showNavigation"
            class="clickable"
            @click="navBack"
          >
            <v-icon class="nav-back-icon" x-large>mdi-chevron-left</v-icon>
          </div>
        </div>
        <div class="company-header">
          <h1>{{ eateryInfo.displayName }}</h1>
          <div v-if="eateryInfo.kitchenTypes" class="kitchentype-container">
            <span v-for="ktype in eateryInfo.kitchenTypes" :key="ktype.id">
              {{ ktype.name }}
            </span>
          </div>
          <div class="address-text">
            <v-icon color="white">mdi-map-marker</v-icon>
            {{ eateryInfo.address.streetAddress }},
            {{ eateryInfo.address.postalCode }}
            {{ eateryInfo.address.postalArea }}
          </div>
        </div>
        <div
          class="nav-info clickable"
          @click.stop="showEateryInfoModal = true"
        >
          <v-icon class="nav-info-icon" x-large>mdi-information-outline</v-icon>
        </div>
      </div>

      <div>
        <!-- <img :src="eateryInfo.bannerUrl" class="banner-img" /> -->
      </div>
    </v-img>
    <div class="eatingmode-switch">
      <EatingModeSwitch :currentEatery="currentEatery"></EatingModeSwitch>
    </div>
    <EateryInfoModal v-model="showEateryInfoModal" :eateryInfo="eateryInfo" />

    <div class="border-line"></div>

    <div v-if="displayTableReservationSection" class="reservation-container">
      <div class="reservation-text-box">
        <p>
          Ønsker du å vente med bestillingen og kun reservere et bord i
          restauranten?
        </p>
      </div>
      <FoodyButton
        :btn-color="'primary'"
        :onClick="goToEaterieReservation"
        :parentclasses="['reservation-button']"
        :outlined="true"
      >
        RESERVER BORD
      </FoodyButton>
    </div>
  </div>
</template>

<script>
import EateryInfoModal from "@/components/eatery/EateryInfoModal.vue";
import EatingModeSwitch from "@/components/eatery/EatingModeSwitch.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import {} from "@/types/dto/eateries/eateryDtoFunctions";
export default {
  components: {
    EateryInfoModal,
    EatingModeSwitch,
    FoodyButton,
  },
  props: {
    eateryInfo: {
      type: Object,
      required: true,
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEateryInfoModal: false,
    };
  },
  computed: {
    TableObject() {
      return this.$store.getters.tableObject;
    },
    currentEatingMode() {
      return this.$store.getters.selectedEatingMode;
    },

    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    currentEateryName() {
      return this.$store.getters.eateryName;
    },
    eateryMenus() {
      return this.$store.getters.menus;
    },
    currentMenu() {
      return this.$store.getters.menu;
    },
    displayTableReservationSection() {
      const isDineInEatingMode = this.currentEatingMode === 1;
      return (
        this.eateryInfo.tableReservationEnabled &&
        isDineInEatingMode &&
        !this.eateryInfo.dineInIsQrOnly
      );
    },
  },
  methods: {
    goToEaterieReservation() {
      this.$router.push("/" + this.currentEatery.urlName + "/bord");
    },
    navBack() {
      this.$router.push("/" + this.currentEateryName);
    },
  },
};
</script>

<style scoped>
.reservation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px 15px 15px;
}

.reservation-button {
  padding: 10px;
  min-width: 15em; /* 20 */
  max-width: 20em; /* 30 */
  border-width: 2px;
  font-weight: 500;
}

.reservation-text-box {
  padding: 5px;
}

.border-line {
  border-bottom: 4px solid;
  border-color: #f5f8fa;
}

.messagesWrapper {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.kitchentype-container {
  display: block;
  margin-top: -5px;
}
.kitchentype-container span {
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px 5px black;
}

.banner-bg {
  padding-bottom: 65px;
  background-position: top center;
}
.banner-header {
  display: flex;
}
.banner-header .nav-back {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-width: 50px;
}
.nav-back-icon {
  color: white;
}
.address-text {
  padding-top: 10px;
  color: white;
}
.banner-header .company-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 5px black;
}
.banner-header .company-header h1 {
  margin-left: 0;
  font-size: 2em;
  color: #ffffff;
}
.banner-header .kitchentype-container span {
  padding: 0 10px;
  border-right: 2px solid white;
}
.banner-header .kitchentype-container span:last-of-type {
  border-right: none;
}
.banner-header .nav-info {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 50px;
  align-items: flex-start;
}
.nav-info-icon {
  padding-top: 5px;
  padding-right: 25px;
  color: white;
}
.banner-container {
  text-align: center;
  margin-top: 1px;
  padding-top: 1px;
}
.banner-content {
  display: flex;
  flex-direction: row;
}
.banner-dropdown {
  margin-left: 100px;
  margin-right: 100px;
}
.banner-img {
  max-height: 150px;
  width: auto;
  max-width: 250px;
  border-radius: 20px;
}
.banner-text {
  flex-grow: 1;
  vertical-align: text-top;
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 15px;
}
.banner-text span {
  font-size: 1.4em;
  line-height: 1.5em;
}
@media only screen and (max-width: 520px) {
  .nav-info-icon {
    padding-right: 10px;
  }
  .banner-bg {
    padding-bottom: 15px;
  }
  .banner-header {
    margin-top: 0;
  }
  .banner-content {
    display: flex;
    flex-direction: column;
  }
  .banner-text {
    flex-grow: 1;
    vertical-align: text-top;
    font-size: 0.8em;
  }
  .banner-img {
    max-height: 100px;
    max-width: 60%;
  }
  .banner-text span {
    font-size: 1.1em;
    line-height: 1.2em;
  }
}
@media only screen and (min-width: 1000px) {
  .banner-bg {
    max-height: 400px;
  }
}
</style>
