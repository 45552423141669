import {
  getShoppingCartLocalStorageId,
  checkIfDineInObject,
  checkIfTableObject,
  findCartItemIndex,
} from "../helpers";
import Vue from "vue";
import moment from "moment";

const tableObjectName = "tableObject";
//const submittedOrderObject = "submittedOrder";

export default {
  UPDATE_LAST_NAVIGATION_PAGE(state, lastPage) {
    state.lastNavigationPage = lastPage;
  },

  SET_START_URL(state, payload) {
    localStorage.setItem("startUrl", JSON.stringify(payload));
  },
  UPDATE_MENUS(state, menus) {
    state.menus = menus;
  },
  UPDATE_MENU(state, menu) {
    state.menu = menu;
  },
  SET_LAST_MENU_ID(state, id) {
    state.lastMenuId = id;
  },
  CLEAR_MENU(state) {
    state.menu = null;
  },
  SET_ACTIVE_MENUITEM(state, activeMenuItem) {
    state.activeMenuItem = activeMenuItem;
  },
  INIT_SHOPPING_CART(state) {
    const cart = localStorage.getItem(getShoppingCartLocalStorageId(state));
    if (cart) {
      state.cart = JSON.parse(cart);
    }
  },
  INIT_TABLE_OBJECT(state, tableValues) {
    const expiry = moment(new Date()).add(
      process.env.VUE_APP_TABLE_TIMEOUT_MINUTES || 20,
      "m"
    );

    state.tableObject = {
      TableId: tableValues.tableNumber,
      eateryName: tableValues.eateryName,
      expiry: expiry.format("YYYY/MM/DD HH:mm:ss"),
    };
  },
  REMOVE_EXPIRED_TABLE_OBJECT(state) {
    const now = moment(new Date());
    const tableExpiry = state.tableObject
      ? moment(state.tableObject.expiry)
      : null;

    if (tableExpiry && now.isAfter(tableExpiry)) {
      state.tableObject = null;
    }
  },

  INIT_DELIVERY_OBJECT(state, payload) {
    state.deliveryObject = payload;
  },
  SET_TABLE_OBJECT_COMPLETE(state, tableObject) {
    tableObject.completed = true;
    state.tableObject = tableObject;
    const jsonData = JSON.stringify(state.tableObject);
    Vue.$cookies.set(
      tableObjectName,
      jsonData,
      "2h",
      null,
      null,
      true,
      "Strict"
    );
  },
  ADD_TO_CART(state, cartItem) {
    state.cart.push(cartItem);
    localStorage.setItem(
      getShoppingCartLocalStorageId(state),
      JSON.stringify(state.cart)
    );
  },
  INCREMENT_CART_ITEM(state, item) {
    if (checkIfDineInObject(item)) {
      state.dineInOrder.NrOfPeople++;
    } else {
      item.nrOfItems++;
    }
    localStorage.setItem(
      getShoppingCartLocalStorageId(state),
      JSON.stringify(state.cart)
    );
  },
  DECREMENT_CART_ITEM(state, item) {
    if (checkIfTableObject(item)) {
      state.tableObject = null;
    } else if (checkIfDineInObject(item)) {
      state.dineInOrder.NrOfPeople--;
    } else {
      if (item) item.nrOfItems--;
    }
    if (state.cart.length === 0)
      localStorage.removeItem(getShoppingCartLocalStorageId(state));
    else
      localStorage.setItem(
        getShoppingCartLocalStorageId(state),
        JSON.stringify(state.cart)
      );
  },
  REMOVE_FROM_CART(state, cartItem) {
    if (checkIfDineInObject(cartItem)) {
      state.dineInOrder = null;
    } else if (checkIfTableObject(cartItem)) {
      state.tableObject = null;
      const redirectUrl = JSON.parse(localStorage.getItem("startUrl"));
      redirectUrl.path.query = {};
      redirectUrl.path.fullPath = `/${redirectUrl.path.params.cname}`;

      localStorage.setItem("startUrl", JSON.stringify(redirectUrl));
    } else {
      const cartIndex = findCartItemIndex(state, cartItem);
      state.cart.splice(cartIndex, 1);
      localStorage.setItem(
        getShoppingCartLocalStorageId(state),
        JSON.stringify(state.cart)
      );
    }

    if (state.cart.length === 0)
      localStorage.removeItem(getShoppingCartLocalStorageId(state));
  },
  CLEAR_CART(state) {
    state.cart = [];
    state.messageToKitchen = "";
  },
  CLEAR_LOCAL_STORAGE_CART(state) {
    localStorage.removeItem(getShoppingCartLocalStorageId(state));
  },
  UPDATE_CART_ITEM_MESSAGE(state, payload) {
    const item = payload.item;
    item.CustomerMessage = payload.CustomerMessage;
  },
  UPDATE_MSG_TO_KITCHEN(state, msgToKitchen) {
    state.messageToKitchen = msgToKitchen;
  },
  ORDER_COMPLETION_STATUS(state, result) {
    Vue.$cookies.set(
      "submittedOrder",
      JSON.stringify(result),
      "3min",
      null,
      null,
      true,
      "Strict"
    );
    state.submittedOrder = result;
  },
  // CLEAR_SUBMITTED_ORDER_COOKIE() {
  //   state.submittedOrder = null;
  //   Vue.$cookies.remove(submittedOrderObject);
  // },
  LOGGED_IN_USER(state, loggedInUser) {
    state.loggedInUser = loggedInUser;
  },
  UPDATE_LOADER(state, status) {
    state.loading = status;
  },
  UPDATE_PAYMENT_ORDER(state, paymentOrder) {
    state.paymentOrder = paymentOrder;
  },
  UPDATE_SNACKBAR_TEXT(state, snackbarText) {
    state.snackbarText = snackbarText;
  },
  UPDATE_SHOW_SNACKBAR(state, show) {
    state.showSnackbar = show;
  },
  SET_PAYMENT_ID(state, result) {
    state.paymentId = result;
  },
  SET_CHECKOUT_KEY(state, result) {
    state.checkoutKey = result;
  },
  SET_TABLE_RESERVATION(state, tableReservationObject) {
    state.tableReservationObject = tableReservationObject;
  },
  UPDATE_SHOW_SHOPPING_CART(state, show) {
    state.showShoppingCart = show;
  },
  CLEAR_DINEIN(state) {
    state.dineInOrder = null;
  },
  CLEAR_TABLE_OBJECT(state) {
    state.tableObject = null;
    Vue.$cookies.remove(tableObjectName);
  },
  SET_DELIVERY_OPTION(state, deliveryOption) {
    state.deliveryOption = deliveryOption;
  },
  CLEAR_DELIVERY_OPTION(state) {
    state.deliveryOption = null;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LAST_SELECTED_EATERY(state, eateryId) {
    state.lastSelectedEatery = eateryId;
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
  },
  SET_RESET_TOKEN(state, token) {
    state.resetToken = token;
  },
  SET_GDPRCOOKIE(state, cookie) {
    state.GDPRCookie = cookie;
  },
  LOCK_TO_EATERY(state, eateryId) {
    state.lockedToEatery = eateryId;
  },
};
