<template>
  <div>
    <ErrorComponent v-if="error !== ''" :message="error" />
    <div v-if="!sendtRecipt && !user">
      <h5>Ønsker du kvittering på E-post?</h5>
      <div class="foody-flex-row" style="justify-content: space-between">
        <div style="flex: 1">
          <PersonalInformationForm
            :want-firstname="false"
            :want-lastname="false"
            :want-phone="false"
            :email="email"
            :personal-information-form-valid="personalInformationFormValid"
            @email="email = $event"
            @keyup.enter.native="sendRecipt"
            @personalInformationFormValid="
              personalInformationFormValid = $event
            "
          />
        </div>
        <div class="send-button-column">
          <FoodyButton
            :btn-color="'primary'"
            :on-click="sendRecipt"
            :disabled="!emailValid"
          >
            Send
          </FoodyButton>
        </div>
      </div>
    </div>
    <div v-if="sendtRecipt || user">
      <ConfirmationMessage
        :message="`E-post med kvittering sendt til`"
        :userMail="`${user ? user.email : email}`"
      ></ConfirmationMessage>
    </div>
  </div>
</template>

<script>
import FoodyButton from "@/components/common/FoodyButton";
import ErrorComponent from "@/components/common/ErrorComponent";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";
import ConfirmationMessage from "@/components/ConfirmationMessage.vue";

export default {
  name: "WantToRecipt",
  components: {
    FoodyButton,
    ErrorComponent,
    PersonalInformationForm,
    ConfirmationMessage,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    isReciptSendt: {
      type: Boolean,
      required: true,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sendtRecipt: false,
      email: "",
      registeredUser: false,
      error: "",
      personalInformationFormValid: false,
    };
  },
  computed: {
    emailValid() {
      return this.personalInformationFormValid;
    },
  },
  methods: {
    async sendRecipt() {
      if (!this.emailValid) {
        return;
      }
      const sendReciptInfo = {
        Email: this.email,
        OrderGUID: this.order.orderGuid,
      };
      try {
        const emailSendt = await this.$store.dispatch(
          "sendtReciptToEmail",
          sendReciptInfo
        );
        if (emailSendt) {
          this.$emit("isReciptSendt", true);
          this.sendtRecipt = true;
        }
      } catch (error) {
        this.$emit("isReciptSendt", false);
        this.email = "";
        this.sendtRecipt = false;
        this.error = "Noe gikk feil med sendingen prøv igjen!";
      }
    },
    async registerUserWithEmail() {
      const newUserInfo = {
        Email: this.email,
        OrderGUID: this.order.orderGuid,
      };
      const registeredUser = await this.$store
        .dispatch("registerMinimalUser", newUserInfo)
        .catch((error) => {
          this.errorhandel(error);
        });
      if (registeredUser) {
        this.registeredUser = true;
      }
    },
    errorhandel(err) {
      if (err.response.data == "Brukernavn allerede i bruk") {
        this.error = "Bruker er alt registrert på e-posten!";
      } else if (err.response.data == "Order already has user set!") {
        this.error =
          "Orderen har alerede en bruker registrert på seg, men bruker har blitt registrert på e-posten!";
      }
    },
  },
};
</script>
<style scoped>
.send-button-column {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
</style>
