export interface AllergenItemString {
  longForm: string;
  shortForm: string;
}

export interface AllergenItem {
  id: string;
  groupID: string;
  isGroupLeader: boolean;
}

export interface AllergenGroup {
  groupLeaderName: string;
  allergens: AllergenItem[];
}

export interface AllergenCollection {
  grouped: AllergenGroup[];
  ungrouped: AllergenItem[];
}

export const createAllergyList = (allergen: AllergenCollection): string[] => {
  const allergyList: string[] = [];
  allergen.grouped.forEach((group) => {
    if (group.allergens.length === 1) {
      allergyList.push(allergens[group.allergens[0].id].longForm);
      return;
    }
    const groupWithoutLeader = group.allergens
      .filter((item) => !item.isGroupLeader)
      .map((item) => allergens[item.id].longForm);

    allergyList.push(
      `${group.groupLeaderName}: ${groupWithoutLeader.join(", ")}`
    );
  });
  const ungrouped = allergen.ungrouped.map(
    (item) => allergens[item.id].longForm
  );
  allergyList.push(...ungrouped);
  return allergyList;
};

export const sortAllergensBetweenGroupedAndUngrouped = (
  items: AllergenItem[]
) => {
  const grouped = new Map<string, AllergenGroup>();

  const groupLeaders = getGroupLeaders(items);
  groupLeaders.forEach((groupLeader) => {
    const group: AllergenGroup = {
      groupLeaderName: allergens[groupLeader.id].longForm,
      allergens: items.filter((item) => item.groupID === groupLeader.groupID),
    };

    grouped.set(groupLeader.id, group);
  });

  const ungrouped = items.filter((item) => !item.groupID);

  return { grouped, ungrouped };
};

const getGroupLeaders = (items: AllergenItem[]) => {
  return items.filter((a) => a.isGroupLeader);
};

export const allergens: { [key: string]: AllergenItemString } = {
  nuts: { longForm: "nøtter", shortForm: "n" },
  almonds: { longForm: "mandler", shortForm: "a" },
  hazelnuts: { longForm: "haselnøtter", shortForm: "h" },
  walnuts: { longForm: "valnøtter", shortForm: "w" },
  cashews: { longForm: "kasjunøtter", shortForm: "c" },
  pecans: { longForm: "pekannøtter", shortForm: "p" },
  pistachios: { longForm: "pistasienøtter", shortForm: "pi" },
  brazilnuts: { longForm: "paranøtter", shortForm: "b" },
  macadamia: { longForm: "macadamianøtter", shortForm: "m" },
  gluten: { longForm: "gluten", shortForm: "g" },
  wheat: { longForm: "hvete", shortForm: "wh" },
  rye: { longForm: "rug", shortForm: "r" },
  barley: { longForm: "bygg", shortForm: "ba" },
  oats: { longForm: "havre", shortForm: "o" },
  spelt: { longForm: "spelt", shortForm: "s" },
  khorasanwheat: { longForm: "korasanhvete", shortForm: "k" },
  shellfish: { longForm: "skalldyr", shortForm: "sh" },
  egg: { longForm: "egg", shortForm: "e" },
  fish: { longForm: "fisk", shortForm: "f" },
  peanuts: { longForm: "peanøtter", shortForm: "pe" },
  soy: { longForm: "soya", shortForm: "so" },
  milk: { longForm: "melk", shortForm: "mi" },
  celery: { longForm: "selleri", shortForm: "ce" },
  mustard: { longForm: "sennep", shortForm: "mu" },
  sesame: { longForm: "sesamfrø", shortForm: "se" },
  sulfurdioxidesulphite: { longForm: "sulfitt", shortForm: "su" },
  lupine: { longForm: "lupin", shortForm: "lu" },
  mollusks: { longForm: "bløtdyr", shortForm: "mo" },
};
