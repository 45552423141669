const codebehindData = {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneNumberPrefixOutput: "",
      streetAddress: "",
      postalCode: "",
      postalArea: "",
      newPassword: "",
      newRepeatPassword: "",
      oldPassword: "",
      editUserInfoActive: false,
      emailValid: false,
      firstNameValid: true,
      lastNameValid: true,
      phoneValid: true,
      streetAddressValid: true,
      postalCodeValid: true,
      postalAreaValid: true,
      bothPasswordsValid: false,
      oldPasswordValid: false,
      editUserPasswordActive: false,
      errorMessage: "",
      isLoading: false,
    };
  },
};
export default codebehindData;
