import { MutationTree } from "vuex";
import shoppingContainerErrorsState from "./state";

export const mutations: MutationTree<shoppingContainerErrorsState> = {
  SET_NO_CART_ITEM(state, status) {
    state.noCartItem = status;
  },

  SET_BELOW_MINIMUM_DELIVERY_SUM(state, status) {
    state.belowMinimumDeliverySum = status;
  },

  SET_EATING_MODE_CLOSED(state, status) {
    state.eatingModeClosed = status;
  },

  SET_NOT_AVAILABLE_FOR_DELIVERY(state, status) {
    state.notAvailableForDelivery = status;
  },

  SET_NOT_AVAILABLE_FOR_TAKEAWAY(state, status) {
    state.notAvailableForTakeAway = status;
  },

  SET_QR_DINE_IN_IS_CLOSED(state, status) {
    state.qrDineInIsClosed = status;
  },
};
