enum MenuItemSingleEnum {
  SideDish = 1,
  Food = 2,
  Dessert = 3,
  Drink = 4,
  Addon = 5
}

enum GroupedCategoryEnum {
  Food = 1 || 2 || 3,
  Drinks = 4,
  Addons = 5
}

enum MenuItemCatEnum {
  Food = "1",
  Drinks = "2",
  Addons = "3"
}

export { GroupedCategoryEnum, MenuItemCatEnum, MenuItemSingleEnum };
