<template>
  <section class="partner-head">
    <div class="partner-content">
      <span class="partner-header">
        Fremtidens løsning for mobil bestilling
      </span>
      <div>
        <span>Få økt omsetning og flere fornøyde kunder med getFOOD.</span>
      </div>
      <div class="partner-buttons">
        <v-btn x-large color="primary" dark @click="navToSignUp">
          Registrer deg her
        </v-btn>
        <v-btn x-large color="primary" dark @click="navToContact">
          Kontakt oss
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    navToContact() {
      this.$router.push("/partner/contact");
    },
    navToSignUp() {
      this.$router.push("/partner/signup");
    },
  },
};
</script>
<style scoped>
.partner-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-buttons button {
  width: fit-content;
  margin-top: 1rem;
}
.partner-head {
  background-color: var(--partner-dark-background-color);
  font-weight: bold;
  width: 100%;
  padding: 4rem 5px;
  color: #ffffff;
  display: flex;
}
.partner-header {
  font-size: 2rem;
}
.partner-content {
  display: flex;
  flex-direction: column;
  widows: min-content;
}
@media only screen and (max-width: 1200px) {
  .partner-header {
    font-size: 1.5em;
  }
}
</style>
