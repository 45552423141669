<template>
  <div
    v-if="tipAmount > 0"
    class="foody-flex-row foody-flex-align-center foody-flex-center foody-space-betweeen"
  >
    <h3 style="margin-left: 5px">Tips</h3>
    <div>{{ tipAmount / 100 }} NOK</div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { getTipTotal } from "@/types/dto/eateries/eateryDtoFunctions";
export default Vue.extend({
  name: "ShoppingCartTipItem",
  props: {
    orderSum: {
      type: Number,
      required: true,
    },
  },
  computed: {
    tipAmount() {
      let selectedTip = this.$store.getters.selectedTip;
      if (selectedTip === 4) selectedTip = 0;
      return getTipTotal(selectedTip ?? 0, this.orderSum);
    },
  },
});
</script>
