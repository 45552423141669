import { EatingMode } from "@/types/enums/eatingMode";
import RootState from "@/types/state/rootState";
import { GetterTree } from "vuex";
import UserOptionState from "./state";

export const getters: GetterTree<UserOptionState, RootState> = {
  selectedEatingMode(state: UserOptionState): EatingMode {
    return state.selectedEatingMode;
  },
  selectedTip(state: UserOptionState): number {
    return state.selectedTip;
  }
};
