const codebehind = {
  data() {
    return {
      translation: {
        countrySelectorLabel: "Prefiks",
        countrySelectorError: "Error",
        phoneNumberLabel: "Telefon nr.",
        example: "Eks: ",
      },
      valid: false,
      htmlValidShow: true,
    };
  },
  props: {
    phone: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    phoneInput: {
      get() {
        return this.phone;
      },
      set(val: string) {
        this.$emit("phone", val);
      },
    },
  },
  methods: {
    validatePhone(val: any) {
      // Uncoment when national code is wanted, make sure emit sendt to something.
      // this.$emit("phoneNumberPrefixOutput", val.country.dialCode);
      this.$emit("phoneNumberOutput", val.nationalNumber);
      this.$emit("phoneNumberValid", val.isValid);
    },
  },
};
export default codebehind;
