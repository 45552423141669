<template>
  <div class="navToPartnerContainer" @click="navToPartner">
    <v-icon
      class="navigate-back-icon"
      :class="variant === 'white' ? 'whitetext' : ''"
    >
      mdi-arrow-left-bold-circle
    </v-icon>
    <span
      class="navigate-back-text"
      :class="variant === 'white' ? 'whitetext' : ''"
      >Partnerside</span
    >
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
    },
    methods: {
      variant() {
        return this.variant;
      },
    },
  },
  methods: {
    navToPartner() {
      this.$router.push("/partner");
    },
  },
};
</script>

<style scoped>
.whitetext.navigate-back-icon,
.whitetext.navigate-back-text {
  color: white;
}
.navToPartnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  padding: 3px 5px;
  background: rgba(0, 0, 0, 0.1);
  max-width: max-content;
}
.navigate-back-icon {
  height: 30px;
  color: #000000;
  align-self: center;
}
.navigate-back-icon.white {
  color: #000000;
}
.navigate-back-text {
  line-height: 2em;
  margin-left: 10px;
}
.navigate-back-text.white {
  color: #000000;
}
@media only screen and (max-width: 1200px) {
  .navigate-back-icon {
    height: 15px;
  }
}
</style>
