import RootState from "@/types/state/rootState";
import api from "@/util/api/cityApi";
import { ActionTree } from "vuex";
import CityState from "./state";

export const actions: ActionTree<CityState, RootState> = {
  /*=====Gets=====*/
  async getAllCities(context) {
    const cities = await api.getCities(null);
    context.commit("SET_CITIES", cities);
    return cities;
  },
};
