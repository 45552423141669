import OpeningHoursDto from "../types/dto/eateries/openingHoursDto";
import { DateRange } from "../types/time/dateRange";
import Moment from "moment";

function getPreviousDayOpeningHoursDateRange(
  date: Date,
  eateryOpeningHours: OpeningHoursDto[]
) {
  const currentWeekDay = Moment(date).isoWeekday();
  const isMonday = currentWeekDay === 1;

  const previousDayOpeningHours = isMonday
    ? eateryOpeningHours.find((openingHours) => openingHours.dayId === 7)
    : eateryOpeningHours.find(
        (openingHours) => openingHours.dayId === currentWeekDay - 1
      );
  if (!previousDayOpeningHours || !previousDayOpeningHours.openPastMidnight)
    return null;

  const from = new Date(date);
  from.setDate(from.getDate() - 1);
  const to = new Date(date);

  const [openHour, openMinute] = previousDayOpeningHours.opens.split(":");
  const [closeHour, closeMinute] = previousDayOpeningHours.closing.split(":");

  from.setHours(Number(openHour), Number(openMinute), 0, 0);
  to.setHours(Number(closeHour), Number(closeMinute), 0, 0);

  return new DateRange(from, to);
}

function getCurrentDayOpeningHoursDateRange(
  date: Date,
  eateryOpeningHours: OpeningHoursDto[]
) {
  const currentWeekDay = Moment(date).isoWeekday();

  const currentDayOpeningHours = eateryOpeningHours.find(
    (openingHours) => openingHours.dayId === currentWeekDay
  );

  if (!currentDayOpeningHours) return null;

  const from = new Date(date);
  const to = new Date(date);

  const [openHour, openMinute] = currentDayOpeningHours.opens.split(":");
  const [closeHour, closeMinute] = currentDayOpeningHours.closing.split(":");

  from.setHours(Number(openHour), Number(openMinute), 0, 0);
  to.setHours(Number(closeHour), Number(closeMinute), 0, 0);

  if (currentDayOpeningHours.openPastMidnight) {
    to.setDate(to.getDate() + 1);
    to.setHours(0, 0, 0, 0);
  }

  if (from > to) return null;

  return new DateRange(from, to);
}

function dateRangeFilterRemoveTimeBeforeDate(
  dateRange: DateRange,
  date: Date
): DateRange {
  const from = dateRange.from;
  const to = dateRange.to;

  if (to < date) return null;

  if (from < date) {
    from.setHours(date.getHours(), date.getMinutes(), 0, 0);
    from.setDate(date.getDate());
  }

  if (from > to) return null;

  return new DateRange(from, to);
}

export function getValidOpeningDateRangesForDate(
  date: Date,
  eateryOpeningHours: OpeningHoursDto[],
  buffer = 30,
  pausedUntilDate?: Date
): DateRange[] {
  if (!eateryOpeningHours) return [];

  if (buffer < 0) {
    throw new Error("Buffer must be greater than or equal to 0");
  }

  const previousDayOpeningHoursDateRange = getPreviousDayOpeningHoursDateRange(
    date,
    eateryOpeningHours
  );

  const currentDayOpeningHoursDateRange = getCurrentDayOpeningHoursDateRange(
    date,
    eateryOpeningHours
  );

  let dateRanges: DateRange[] = [];
  if (previousDayOpeningHoursDateRange)
    dateRanges.push(previousDayOpeningHoursDateRange);
  if (currentDayOpeningHoursDateRange)
    dateRanges.push(currentDayOpeningHoursDateRange);

  if (dateRanges.length === 0) return [];

  dateRanges = dateRanges.map((dateRange) => {
    const openingTimePlusBuffer = Moment(dateRange.from)
      .add(buffer, "minutes")
      .toDate();
    return dateRangeFilterRemoveTimeBeforeDate(
      dateRange,
      openingTimePlusBuffer
    );
  });

  dateRanges = dateRanges.filter((dateRange) => dateRange);

  const nowPlusBuffer = Moment().add(buffer, "minutes").toDate();
  dateRanges = dateRanges.map((dateRange) =>
    dateRangeFilterRemoveTimeBeforeDate(dateRange, nowPlusBuffer)
  );
  dateRanges = dateRanges.filter((dateRange) => dateRange);

  if (pausedUntilDate) {
    const pausedUntilDatePlusBuffer = Moment(pausedUntilDate)
      .add(buffer, "minutes")
      .toDate();
    dateRanges = dateRanges.map((dateRange) =>
      dateRangeFilterRemoveTimeBeforeDate(dateRange, pausedUntilDatePlusBuffer)
    );
    dateRanges = dateRanges.filter((dateRange) => dateRange);
  }

  // Remove any date ranges that are one the previous day
  dateRanges = dateRanges.map((dateRange) => {
    if (dateRange.from.getDate() < date.getDate()) {
      const newFrom = new Date(date);
      newFrom.setHours(0, 0, 0, 0);
      return new DateRange(newFrom, dateRange.to);
    }
    return dateRange;
  });

  return dateRanges;
}

export function timeIsValidForDate(
  timeString: string,
  dateString: string,
  eateryOpeningHours: OpeningHoursDto[],
  buffer = 30,
  pausedUntilDate?: Date
) {
  const date = new Date(dateString);
  const [hour, minute] = timeString.split(":");
  date.setHours(Number(hour), Number(minute), 0, 0);

  const dateRanges = getValidOpeningDateRangesForDate(
    date,
    eateryOpeningHours,
    buffer,
    pausedUntilDate
  );

  for (let i = 0; i < dateRanges.length; i++) {
    const dateRange = dateRanges[i];
    if (date >= dateRange.from && date <= dateRange.to) return true;
  }
  return false;
}

export function getLastPossibleReservationTimeForDate(
  date: Date,
  eateryOpeningHour: OpeningHoursDto,
  buffer = 45
) {
  const closingTime = eateryOpeningHour.closing;
  const timeSplit = closingTime.split(":");
  const dateWithClosingTime = new Date(date);
  // Account for if eatery is open past midnight to make the closing time date correct
  if (eateryOpeningHour.openPastMidnight) {
    dateWithClosingTime.setDate(dateWithClosingTime.getDate() + 1);
  }
  dateWithClosingTime.setHours(timeSplit[0], timeSplit[1]);
  const closingMoment = Moment(dateWithClosingTime);
  return closingMoment.subtract(buffer, "minutes");
}

export function getFirstAvailableOpeningTimeForDate(
  date: Date,
  eateryOpeningHours: OpeningHoursDto[],
  buffer = 30,
  pausedUntilDate?: Date
) {
  for (let i = 0; i < 8; i++) {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + i);
    const dateRange = getValidOpeningDateRangesForDate(
      currentDate,
      eateryOpeningHours,
      buffer,
      pausedUntilDate
    );

    if (dateRange.length === 0) continue;

    const fromDatesTime = dateRange.map((x) => x.from.getTime());
    const minDateTime = Math.min(...fromDatesTime);

    return new Date(minDateTime);
  }
  return null;
}
