<template>
  <section class="container container--fluid card-container">
    <div class="cityheader-container">
      <div class="cityheader">
        <div class="cityheader__title">
          Utvalgte <span class="cityheader__city"> byer </span>
        </div>
      </div>
    </div>
    <p
      v-if="!loading && (!allCities || allCities.length === 0)"
      class="foody-city-notfound"
    >
      Ingen steder funnet i nærheten
    </p>
    <div class="spinner-container">
      <Loader :isLoading="loading" />
    </div>
    <div class="city-container">
      <Cities v-for="c in allCities" :key="c.id" :city-item="c" />
    </div>
  </section>
</template>
<script>
import Cities from "@/components/Cities.vue";
import Loader from "@/components/common/Loader.vue";

export default {
  components: {
    Cities,
    Loader,
  },

  data() {
    return {
      allCities: [],
      loading: true,
    };
  },

  async mounted() {
    const allCities = await this.$store.dispatch("getAllCities");

    this.allCities = allCities.data;
    this.loading = false;
  },
  methods: {
    search: function (searchText) {
      this.$router.push(`/search/${encodeURIComponent(searchText)}`);
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Poppins";
  color: #222222;
}

.spinner-container {
  text-align: center;
  text-align: -webkit-center;
}

.cityheader-container {
  display: flex;
  justify-content: center;
}

.cityheader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 92%;
  padding-bottom: 20px;
}

.cityheader__title {
  flex: 1;
  text-align: center;
  font-size: 25px;
  font-weight: 900;
}

.cityheader__city {
  font-weight: 100;
  padding: 1px;
}

.city-container {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
}

@media (min-width: 426px) {
  .container {
    padding: 50px 3vw;
  }
}
</style>
