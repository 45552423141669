import store from "../../store/index";
import router from "../index";
import { EatingMode } from "@/types/enums/eatingMode";
import { Route } from "vue-router";

export type StartUrl = {
  path: Route;
  query: {
    source: string | string[];
    origin: string | string[];
    tablenr: string | string[];
  };
};

export function createStartUrl(to: Route | string): StartUrl {
  return {
    path: to as Route,
    query: {
      source: "",
      origin: "",
      tablenr: "",
    },
  };
}

async function menuesCheck(eateryName: string) {
  await store.dispatch("getMenus", eateryName);
  const menus = store.getters.menus;
  return menus;
}
async function shoppingCartCheck() {
  if (
    !store.getters.cart ||
    (store.getters.cart && store.getters.cart.length < 1)
  ) {
    await store.dispatch("initShoppingCart");
  }
}

async function initEatery(eateryName: string) {
  await store.dispatch("setEateryName", eateryName);
  await store.dispatch("getEateryInfo", eateryName);
  await store.dispatch("setLastSelectedEatery", eateryName);
}

async function redirectToTableRestaurant(routeObj: Route) {
  const tableObject = store.getters.tableObject;
  if (!tableObject) {
    return false;
  }
  if (
    tableObject.eateryName &&
    tableObject.eateryName !== routeObj.params.cname
  ) {
    const reply = window.confirm(
      "Du har et bord registrert på en annen restaurant enn den du er på vei inn i. Om du fortsetter vil ordren din nullstilles, og du vil måtte scanne QR-koden på nytt for å gjøre flere bestillinger til bordet. Ønsker du å fortsette?"
    );
    return !reply;
  }
}

async function eateryCheck(routeObj: Route) {
  let eateryName = store.getters.eateryName;

  if (!eateryName || (eateryName && eateryName !== routeObj.params.cname))
    eateryName = routeObj.params.cname;

  if (eateryName != localStorage.getItem("eateryName")) {
    await store.dispatch("clearShoppingCart");
    await store.dispatch("clearLocalShoppingCart");
    await store.dispatch("clearTableObject");
    await store.dispatch("clearDeliveryOption");
  }

  await initEatery(eateryName);
  return eateryName;
}

export async function initTableObject(to: Route | string, startUrl: StartUrl) {
  startUrl.query.source = (to as Route).query.source;
  startUrl.query.origin = (to as Route).query.origin;
  const eateryName = store.getters.eateryName;

  // If coming from qrcode and poster (non-table QR-link) then clear the table object
  if (
    startUrl.query.source === "qrcode" &&
    startUrl.query.origin === "poster"
  ) {
    store.dispatch("clearTableObject");
    return;
  }

  if ((to as Route).query.tablenr) {
    startUrl.query.tablenr = (to as Route).query.tablenr;
    const tableValues = {
      tableNumber: (to as Route).query.tablenr,
      eateryName: eateryName,
    };
    await store.dispatch("initTableObject", tableValues);
    store.dispatch("setEatingMode", EatingMode.DineIn);
  } else if (
    startUrl.query.origin === "table" &&
    startUrl.query.source === "qrcode"
  ) {
    await store.dispatch("initTableObject", {
      tableNumber: undefined,
      eateryName: eateryName,
    });
  }
}

export async function CheckoutRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  await eateryCheck(to as Route);
  await shoppingCartCheck();
  (to as Route).params.prevRoute = (from as Route).fullPath;
  next();
}

export async function SuccessRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  await eateryCheck(to as Route);
  next();
}

export async function SmittesporingRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  await eateryCheck(to as Route);
  next();
}

export async function TableReservationRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  await eateryCheck(to as Route);
  next();
}

export async function MenuRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  const startUrl = createStartUrl(to);
  await eateryCheck(to as Route);
  const menu = store.getters.menu;

  if (!menu || (menu && Number.parseInt((to as Route).params.id) !== menu.id)) {
    await store.dispatch("getMenu", (to as Route).params.id);
  }

  await shoppingCartCheck();
  await initTableObject(to, startUrl);
  next();
}

export async function MenusRouteGuard(
  to: Route | string,
  from: Route | string,
  next: Function
) {
  const startUrl = createStartUrl(to);
  await store.dispatch("removeExpiredTableObject");

  if (await redirectToTableRestaurant(to as Route)) {
    router.push(`/${store.getters.tableObject.eateryName}`);
    return;
  }

  const eateryName = await eateryCheck(to as Route);

  if (eateryName) {
    const menus = await menuesCheck(eateryName);

    await shoppingCartCheck();
    if ((to as Route).query.source && (to as Route).query.origin) {
      await initTableObject(to, startUrl);

      const redirectUrl = JSON.parse(localStorage.getItem("startUrl"));
      if (
        redirectUrl &&
        redirectUrl.path &&
        redirectUrl.path.fullPath !== startUrl.path.fullPath
      ) {
        await store.dispatch("clearShoppingCart");
        await store.dispatch("clearLocalShoppingCart");
      }
    }

    await store.dispatch("setStartUrl", startUrl);

    if (menus && menus.length === 1) {
      const menu = menus[0];

      const newRoute = `/${eateryName}/menu/` + menu.id;

      // @ts-ignore --> seems like history in router.history is private api and doesn't appear on the typings https://github.com/vuejs/vue-router/issues/2157
      const oldRoute = router.history.current.fullPath;
      if (newRoute !== oldRoute) router.push(newRoute);
    } else {
      next();
    }
  }
}
