<template>
  <div
    class="foody-flex-row foody-flex-align-center foody-flex-center foody-space-betweeen"
  >
    <h3 style="margin-left: 5px">
      {{ getTable.name }}
    </h3>
    <v-btn v-if="showDeleteButton" icon @click="() => dialogActivator()">
      <v-icon>mdi-trash-can-outline</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="490">
      <v-card>
        <v-card-title
          style="
            white-space: nowrap;
            font-weight: bold;
            font-size: 1rem !important;
            padding: 34px 44px;
          "
          class="headline"
        >
          Fjerne {{ getTable.name }}
        </v-card-title>
        <v-card-text style="padding: 0 44px 24px">
          Er du sikker på at du ikke lenger vil bestille for
          {{ getTable.name }}?
        </v-card-text>
        <v-card-actions
          style="justify-content: space-between; padding: 34px 44px 44px"
        >
          <FoodyButton
            :btn-color="'secondary'"
            :on-click="dialogActivator"
            style="padding: 20px 30px"
          >
            Avbryt
          </FoodyButton>
          <FoodyButton
            :btn-color="'primary'"
            :on-click="removeTable"
            style="
              padding: 20px 20px;
              background-color: #c30f0f !important;
              margin-right: 5px;
            "
          >
            Fjern Bord
          </FoodyButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import FoodyButton from "@/components/common/FoodyButton.vue";

export default Vue.extend({
  name: "ShoppingCartTableItem",
  components: {
    FoodyButton,
  },
  props: {
    showDeleteButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getTable() {
      const table = this.$store.getters.tableObject;
      let tableObj = null;
      if (table) {
        let tableName;
        if (table.TableId) {
          const tablenr = table.TableId.replace(/^"(.*)"$/, "$1");
          tableName = `Bord #${tablenr}`;
        } else {
          tableName = "Bord";
        }
        tableObj = {
          name: tableName,
        };
      }
      return tableObj;
    },
  },
  methods: {
    dialogActivator() {
      this.dialog = !this.dialog;
    },
    removeTable() {
      this.$store.dispatch("removeCartItem", this.getTable);
    },
  },
});
</script>
