import store from "@/store";
import axios from "axios";
import router from "@/router";
import HttpHeaders from "./httpHeaders";
import { HttpContentType } from "./httpContentTypes";
import { User, UserPasswordInfo, Visitor } from "@/types/api/user";
import { Credentials } from "@/types/api/credentials";

export const baseURL = process.env.VUE_APP_API_URL;
export const functionsBaseURL = process.env.VUE_APP_FUNCTIONS_API_URL;
export const refreshTokenRoute = "/api/Auth/RefreshToken";
export const api = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  params: {}, // do not remove this, its added to add params later in the config
});
export const functionsApi = axios.create({
  baseURL: functionsBaseURL,
  timeout: 20000,
  params: {}, // do not remove this, its added to add params later in the config
});
const logoutUser = () => {
  // We perfom logout which contain revoke function
  store.dispatch("logOutUser");
  // Navigate to login page as user should perfom login to finish action
  if (router.currentRoute.path != "/login") {
    router.push({ path: "/login" });
  }
};

api.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response) {
      // client received an error response (5xx, 4xx)
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled or smth like that
      if (error.config.url == refreshTokenRoute) {
        logoutUser();
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      const token = store.getters.token;
      const refreshToken = store.getters.refreshToken;

      if (error.response?.data?.description === "401-wrong-credentials") {
        return "401-wrong-credentials";
      }

      if (token == null) {
        if (router.currentRoute.path != "/login") {
          router.push({ path: "/login" });
        }
      }

      const refreshTokenUrl = baseURL + refreshTokenRoute;

      const data = {
        Token: token,
        RefreshToken: refreshToken,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(refreshTokenUrl, data)
          .then((response) => {
            if (response.data?.token == null) {
              // Couldn't refresh token => logout user
              // Token expired = Forbid, or other reasons
              logoutUser();
              reject("refresh token expired");
            } else {
              store.dispatch("updateTokens", response.data);
              resolve(response.data.token);
            }
          })
          .catch((error) => {
            // Couldn't refresh token => logout user
            logoutUser();
            reject(error);
          });
      })
        .then((token) => {
          // New request with new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${token}`;
          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          Promise.reject(error);
        });
    } else if (error.request) {
      // client never received a response, or request never left
      Promise.reject(error);
    } else {
      Promise.reject(error);
    }
  }
);

export const getConfig = (token?: string) => {
  const headers: HttpHeaders = {
    "Content-Type": HttpContentType.ApplicationJson,
  };
  if (token) {
    headers.Authorization = "Bearer " + token;
  }
  return { headers: headers };
};

const getFileConfig = (
  token?: string
): { headers: HttpHeaders; responseType: "arraybuffer" } => {
  const headers: HttpHeaders = {
    "Response-Type": HttpContentType.ApplicationPdf,
    Accept: HttpContentType.ApplicationOctetStream,
  };
  if (token) {
    headers.Authorization = "Bearer " + token;
  }
  return { headers: headers, responseType: "arraybuffer" };
};

export default {
  async updateOrderAsReserved(paymendId: string, token: string) {
    const endpointUrl = baseURL + "/api/Order/checkoutCompleted/" + paymendId;

    const res = await api.get(endpointUrl, getConfig(token));
    return res;
  },
  async registerUser(newUser: User, guid: string) {
    const endOfUrl = !guid
      ? `/api/Auth/Register/`
      : `/api/Auth/Register/?orderGuid=${guid}`;
    const endpointUrl = baseURL + endOfUrl;
    const response = await api.post(endpointUrl, newUser, getConfig());
    return response.data;
  },
  async registerMinimalUser(newUser: User, guid: string) {
    const endpointUrl =
      baseURL + `/api/Auth/RegisterMinimalUser/?orderGuid=${guid}`;

    const response = await api.post(endpointUrl, newUser, getConfig());
    return response;
  },
  async logInUser(credentials: Credentials) {
    const endpointUrl = baseURL + "/api/Auth/GetLoginToken";
    const response = await api.post(endpointUrl, credentials, getConfig());
    return response.data;
  },
  async refreshTokens(tokenData: Credentials) {
    const endpointUrl = baseURL + "/api/Auth/RefreshToken";
    const response = await api.post(endpointUrl, tokenData, getConfig());
    return response.data;
  },
  async revokeToken(credentials: Credentials) {
    const endpointUrl = baseURL + "/api/Auth/Revoke";
    return await api.post(endpointUrl, credentials);
  },
  async getUserInfo(token: string) {
    const endpointUrl = baseURL + "/api/User/Info";
    const response = await api.get(endpointUrl, getConfig(token));
    return response.data;
  },
  async getUserAddress(token: string) {
    const endpointUrl = baseURL + "/api/User/Address";
    const response = await api.get(endpointUrl, getConfig(token));
    return response.data;
  },
  async updateUserProfile(userinfo: User, token: string) {
    const endpointUrl = baseURL + "/api/User/Info";
    const response = await api.post(endpointUrl, userinfo, getConfig(token));
    return response;
  },
  async updateUserPassoword(userPasswordInfo: UserPasswordInfo, token: string) {
    const endpointUrl = baseURL + "/api/auth/Password";
    const response = await api.put(
      endpointUrl,
      userPasswordInfo,
      getConfig(token)
    );
    return response;
  },

  completeReservation(newResObject: object, token: string) {
    const endpointUrl = baseURL + "/api/DineIn/CompleteReservation";

    return api
      .post(endpointUrl, newResObject, getConfig(token))
      .then((response) => response.data);
  },
  getReservationById(id: string, token: string) {
    const endpointUrl = baseURL + "/api/User/GetReservationById/" + id;

    return api
      .get(endpointUrl, getConfig(token))
      .then((response) => response.data);
  },
  async getMenus(cname: string, token: string) {
    const endpointUrl = baseURL + "/api/Menu/GetMenus/" + cname;
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
  async getMenu(id: string, token: string) {
    const endpointUrl = baseURL + "/api/Menu/GetMenuById/" + id;
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
  async verifyUserAccount(userguid: string, token: string) {
    const endpointUrl = baseURL + `/api/User/VerifyUser/${userguid}`;
    const res = await api.get(endpointUrl, getConfig(token));

    return res.data;
  },
  async checkVerifyUserAccount(userguid: string, token: string) {
    const endpointUrl = baseURL + `/api/User/CheckVerifyUser/${userguid}`;
    const res = await api.get(endpointUrl, getConfig(token));

    return res.data;
  },
  async sendNewVerifyUserAccount(userguid: string, token: string) {
    const endpointUrl = baseURL + `/api/User/NewVerifyUserAccount/${userguid}`;
    const res = await api.get(endpointUrl, getConfig(token));

    return res.data;
  },
  async saveOrder(model: object, token: string) {
    const endpointUrl = baseURL + "/api/Order/";
    const res = await api.post(endpointUrl, model, getConfig(token));
    return res.data;
  },
  async getOrderStatus(orderGuid: string) {
    const endpointUrl = baseURL + `/api/Order/status/${orderGuid}`;
    const res = await api.get(endpointUrl);
    return res.data;
  },
  async getOrderReceipt(orderId: string, token: string) {
    const endpointUrl = baseURL + `/api/Order/receipt/${orderId}`;

    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },
  async getOrderReceiptPdf(orderId: string, token: string) {
    const endpointUrl = baseURL + `/api/Order/receipt-pdf/${orderId}`;
    const res = await api.get(endpointUrl, getFileConfig(token));
    return res.data;
  },
  async getOrders(
    token: string,
    pageNumber: number,
    pageSize: number,
    sort: {
      [key: string]: boolean;
    }
  ) {
    const arr = [];
    for (const key in sort) {
      if (Object.keys(sort).some((x) => x === key)) {
        arr.push(key + "." + (sort[key] === true ? "desc" : "asc"));
      }
    }
    const sortString = arr.join(",");
    const endpointUrl =
      baseURL +
      `/api/order/?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortString}`;
    return await api
      .get(endpointUrl, getConfig(token))
      .then((response) => response.data);
  },

  async sendtReciptToEmail(email: string, orderGUID: string, token: string) {
    const endpoint =
      baseURL + `/api/Order/SendReceipt/${orderGUID}/?email=${email}`;
    const endpointUrl = encodeURI(endpoint);
    const res = await api.get(endpointUrl, getConfig(token));
    return res.data;
  },

  async addInfectionTracking(visitors: Visitor[], token: string) {
    const endpointUrl =
      baseURL +
      "/api/Infection/AddInfectionTracking/" +
      store.getters.eateryInfo?.eateryId;
    const res = await api.post(endpointUrl, visitors, getConfig(token));
    return res;
  },

  async verifyAndGetPaymentId(model: object, token: string) {
    const endpointUrl = baseURL + "/api/Payment/GetPaymentID";
    const data = await api
      .post(endpointUrl, model, getConfig(token))
      .then((response) => response.data);
    return data;
  },

  // TODO : Rename this stuff...
  async sendTableReservation(model: object) {
    const endpointUrl = baseURL + "/api/TableReservation";
    const data = await api.post(endpointUrl, model, getConfig());
    return data;
  },
  getDineInById(Id: string, token: string) {
    const endpointUrl = baseURL + "/api/dinein/getDineInById/" + Id;

    return api
      .get(endpointUrl, getConfig(token))
      .then((response) => response.data);
  },

  async sendResetPasswordToken(email: string) {
    const endpoint =
      baseURL + `/api/Auth/SendResetPasswordToken?email=${email}`;
    const res = await api.post(endpoint, getConfig());
    return res;
  },

  async resetPassword(user: User) {
    const endpoint = baseURL + `/api/Auth/ResetPassword`;
    const res = await api.post(endpoint, user, getConfig());
    return res.data;
  },
  async sendRequestMessage(msgObject: object) {
    const endpointUrl = baseURL + "/api/foody/registerinterest";
    const res = await api.post(endpointUrl, msgObject);
    return res;
  },
};
