<template>
  <FoodyCard :parentclasses="['customerLogin-view']">
    <v-list>
      <v-card-title
        class="heading font-weight-bold align-center"
        style="font-size: 30px"
      >
        Logg inn
      </v-card-title>
      <form
        v-disable-tab-on-class="['.mdi-close', '.mdi-eye-off']"
        class="login-form"
        @submit.prevent="doLogin"
        @keyup.enter="doLogin"
      >
        <v-container fluid>
          <LoginForm
            :email="loginEmail"
            :password="loginPassword"
            :login-form-valid="loginFormValid"
            @email="loginEmail = $event"
            @password="loginPassword = $event"
            @loginFormValid="loginFormValid = $event"
          />
          <ErrorComponent v-if="errorMessage !== ''" :message="errorMessage" />

          <v-row>
            <v-col cols="12" sm="12">
              <FoodyButton
                :parentclasses="['button-width']"
                title="Customer Login"
                btn-color="primary"
                :on-click="doLogin"
                :disabled="!formIsValid"
              >
                Logg inn
              </FoodyButton>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="6">
              Ny?
              <a @click="goToRegistration">Registrer her</a>
            </v-col>
            <v-col cols="6" sm="6" class="align-right">
              Glemt
              <a @click="goToForgotPassword">passord?</a>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-list>
  </FoodyCard>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import FoodyCard from "@/components/common/FoodyCard.vue";
import ErrorComponent from "@/components/common/ErrorComponent";

export default {
  name: "Login",
  components: {
    LoginForm: LoginForm,
    FoodyButton: FoodyButton,
    FoodyCard: FoodyCard,
    ErrorComponent: ErrorComponent,
  },
  data() {
    return {
      // FACEBOOKSTUFF
      isConnected: false,
      loginName: "",
      thirdPartyToken: "",
      userProfileImg: "",
      // OTHER STUFF
      loginEmail: "",
      loginPassword: "",
      errorMessage: "",
      loading: false,
      loginFormValid: false,
    };
  },
  computed: {
    formIsValid() {
      return this.loginFormValid;
    },
  },
  methods: {
    // logInWithFacebook() {
    //   const url = "/me?fields=id,name,email";
    //   FB.login(
    //     (response) => {
    //       FB.api(
    //         url,
    //         (fbLogin) => {
    //           this.loginName = fbLogin.name;
    //           this.loginEmail = fbLogin.email;
    //           this.thirdPartyToken = fbLogin.id;
    //           this.userProfileImg = `https://www.facebook.com/photo?fbid=${fbLogin.id}`;

    //           this.doLogin();
    //         },
    //         { scope: "email" }
    //       );
    //     },
    //     { scope: "email", return_scopes: true },
    //     { auth_type: "  " }
    //   );
    // },
    goToRegistration() {
      this.$router.push("/register");
    },
    goToForgotPassword() {
      this.$router.push("/forgot");
    },
    // OTHER STUFF
    async doLogin() {
      this.errorMessage = "";
      this.loading = true;
      const userCredentials = {
        Email: this.loginEmail,
        password: this.loginPassword,
        UserName:
          this.loginName && this.loginName !== "" ? this.loginName : null,
        userProfileImg: this.userProfileImg,
        thirdPartyToken: this.thirdPartyToken,
      };

      let user = null;
      try {
        const token = await this.$store.dispatch("logInUser", userCredentials);
        if (token) {
          user = await this.$store.dispatch("getUserInfo");
        }
      } catch (error) {
        this.errorMessage = "Ugyldig brukernavn eller passord";
      }
      if (user) {
        await this.$store.dispatch("getUserAddress");
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
.fb-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #4267b2;
  color: #fff;
}
.information {
  margin-top: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.well {
  background-color: rgb(191, 238, 229);
  margin: auto;
  padding: 50px 50px;
  border-radius: 20px;
  /* display:inline-block; */
}
.login {
  width: 200px;
  margin: auto;
}
.list-item:first-child {
  margin: 0;
}
.list-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.button {
  margin: auto;
}
.login-form {
  min-width: 25em;
  max-width: 30em;
}
.align-right {
  text-align: right;
}
.align-center {
  justify-content: center;
  align-items: center;
}
.button-width {
  width: 100%;
}
@media only screen and (max-width: 520px) {
  .login-form {
    min-width: 100%;
    max-width: 100%;
  }
}
</style>
