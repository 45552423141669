import RootState from "@/types/state/rootState";
import api from "@/util/api/eateryApi";
import { ActionTree } from "vuex";
import EateryState from "./state";
import EateryDto from "@/types/dto/eateries/eateryDto";
import locationHelper from "@/helpers/locationHelper";

export const actions: ActionTree<EateryState, RootState> = {
  /*=====Gets=====*/
  async getEateryInfo(context, eateryName): Promise<EateryDto> {
    const eateryInfo = context.state.eateryInfo;
    if (
      (eateryName && !eateryInfo) ||
      (eateryInfo &&
        eateryName.toLowerCase() !==
          (eateryInfo.displayName ?? "").toLowerCase())
    ) {
      const res = await api.getEateryInfo(eateryName, null);
      context.commit("UPDATE_EATERY_INFO", res);
      return res;
    }
  },
  async getEateryInfoById(context, eateryId: number): Promise<EateryDto> {
    if (!eateryId) return null;
    const eateryInfo = await api.getEateryInfoById(eateryId, null);
    context.commit("UPDATE_EATERY_INFO", eateryInfo);
    return eateryInfo;
  },
  async getEateriesByQuery(context, input) {
    let query = "?";
    const filters: { [key: string]: any } = input;
    const address = filters.addressString || "";

    // If we are using coordinates, set first parameter to Latitude
    if (locationHelper.isCoords(address)) {
      const coords = address.split(",");
      const lat = Number.parseFloat(coords[0]);
      const lng = Number.parseFloat(coords[1]);
      filters.Longitude = lng;
      delete filters.name;
      delete filters.addressString;
      query = `?Latitude=${lat}&`;
    }

    // Create GET parameters for each filter
    const ret: Array<string> = [];
    for (const d in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, d)) {
        // If value is not empty (we don't want null GET parameters)
        if (filters[d]) {
          const key = encodeURIComponent(d);
          const value = filters[d];

          if (Array.isArray(value)) {
            value.forEach((currentValue: string | number | boolean) => {
              ret.push(key + "=" + encodeURIComponent(currentValue));
            });
          } else {
            ret.push(key + "=" + encodeURIComponent(value));
          }
        }
      }
    }

    query += ret.join("&");

    const eateries = await api.getEateriesByFilters(query, null);
    context.commit("UPDATE_EATERIES", eateries);
    return eateries;
  },
  /*=====Updates=====*/
  async setEateryName(context, eateryName) {
    context.commit("SET_EATERY_NAME", eateryName);
    localStorage.setItem("eateryName", eateryName);
    return true;
  },
  clearEateryInfo(context) {
    context.commit("UPDATE_EATERY_INFO", null);
  },
};
