<template>
  <v-bottom-sheet
    v-model="showSheet"
    content-class="narrow"
    inset
    @click:outside="onSheetClose"
  >
    <v-sheet class="loginSheet">
      <div class="sheet-head">
        <h3 class="ml-5">Innlogging</h3>
        <v-btn
          class="mr-4 sheet-head-icon"
          fab
          dark
          x-small
          color="#6D6D6D"
          @click="onSheetClose"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </div>
      <div class="sheet-body">
        <form
          v-disable-tab-on-class="['.mdi-close', '.mdi-eye-off']"
          style="max-width: 500px; margin: 0 auto"
          @submit.prevent="doLogin"
          @keyup.enter="doLogin"
        >
          <LoginForm
            :email="loginEmail"
            :password="loginPassword"
            :login-form-valid="loginFormValid"
            @email="loginEmail = $event"
            @password="loginPassword = $event"
            @loginFormValid="loginFormValid = $event"
          />
          <ErrorComponent v-if="errorMessage !== ''" :message="errorMessage" />
        </form>
      </div>
      <div class="sheet-footer">
        <v-row>
          <v-col cols="12" sm="12">
            <FoodyButton
              :parentclasses="['button-width']"
              title="Customer Login"
              btn-color="primary"
              :on-click="doLogin"
              :disabled="!formIsValid"
            >
              Logg inn
            </FoodyButton>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6">
            <FoodyButton btn-color="secondary" :on-click="goToRegistration"
              >Registrer deg</FoodyButton
            >
          </v-col>
          <v-col cols="6" sm="6" class="align-right">
            <FoodyButton btn-color="secondary" :on-click="goToForgotPassword"
              >Glemt passord?</FoodyButton
            >
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import ErrorComponent from "@/components/common/ErrorComponent";

export default {
  name: "LoginBottomSheet",
  components: {
    LoginForm: LoginForm,
    FoodyButton: FoodyButton,
    ErrorComponent: ErrorComponent,
  },
  props: {
    showSheet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      errorMessage: "",
      loading: false,
      loginFormValid: false,
    };
  },
  computed: {
    formIsValid() {
      return this.loginFormValid;
    },
  },
  methods: {
    onSheetClose() {
      this.$emit("sheetUpdated", !this.showSheet);
    },
    goToRegistration() {
      this.$router.push("/register");
    },
    goToForgotPassword() {
      this.$router.push("/forgot");
    },
    async doLogin() {
      if (!this.formIsValid) {
        return;
      }
      this.errorMessage = "";
      this.loading = true;
      const userCredentials = {
        Email: this.loginEmail,
        password: this.loginPassword,
      };

      let user = null;
      try {
        const token = await this.$store.dispatch("logInUser", userCredentials);
        if (token) {
          user = await this.$store.dispatch("getUserInfo");
        }
      } catch (error) {
        this.errorMessage = "Ugyldig brukernavn eller passord";
      }
      if (user) {
        await this.$store.dispatch("getUserAddress");
        this.$emit("sheetUpdated", false);
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-bottom-sheet.v-dialog {
  overflow-y: auto;
}
.loginSheet {
  padding: 15px;
  min-height: 300px;
}
.sheet-head {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.sheet-head-icon {
  margin-top: 0;
}
.sheet-body {
  padding: 0px 15px;
  max-height: auto;
}
.sheet-props {
  border-bottom: 2px solid #f6f6f6;
}
.sheet-props:last-child {
  border-bottom: none;
}
.sheet-footer {
  padding: 20px;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
}
</style>
