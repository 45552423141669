import { MutationTree } from "vuex";
import UserOptionState from "./state";
import { EatingMode } from "@/types/enums/eatingMode";

export const mutations: MutationTree<UserOptionState> = {
  SET_EATING_MODE(state, eatingMode: EatingMode) {
    state.selectedEatingMode = eatingMode;
  },
  SET_SELECTED_TIP(state, selectedTip: number) {
    state.selectedTip = selectedTip;
  }
};
