<template>
  <FoodyCard
    :parentclasses="['customerLogin-view']"
    class="reset-password-form"
  >
    <v-card-title
      class="heading font-weight-bold align-center"
      style="font-size: 30px"
    >
      Glemt Passord?
    </v-card-title>
    <v-progress-circular
      v-show="isLoading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
    <div v-if="!isLoading && !hasData && !showError">
      <v-card-subtitle>
        Skriv inn din e-post for å få tilsendt nytt passord
      </v-card-subtitle>
      <PersonalInformationForm
        :want-firstname="false"
        :want-lastname="false"
        :want-phone="false"
        :email="email"
        :personal-information-form-valid="personalInformationFormValid"
        @email="email = $event"
        @personalInformationFormValid="personalInformationFormValid = $event"
      />

      <FoodyButton
        style="min-width: 25em; max-width: 30em"
        title="Reset password"
        btn-color="primary"
        :on-click="sendResetPasswordToken"
      >
        Reset passord
      </FoodyButton>
      <br />
    </div>
    <div v-else-if="hasData">
      <v-alert
        v-if="!showError"
        style="text-align: left"
        dense
        text
        type="success"
      >
        E-post med tilbakestilling av passord har blitt sendt til {{ email }}
      </v-alert>
    </div>
    <div v-else>
      <ErrorComponent
        v-if="showError"
        message="Noe gikk galt, prøv igjen senere"
      />
      <FoodyButton
        v-if="showError"
        style="min-width: 25em; max-width: 30em"
        title="Reset password"
        btn-color="primary"
        :on-click="login"
      >
        Tilbake til logg inn
      </FoodyButton>
    </div>
  </FoodyCard>
</template>

<script lang="ts">
import Vue from "vue";
import FoodyCard from "../components/common/FoodyCard.vue";
import FoodyButton from "../components/common/FoodyButton.vue";
import ErrorComponent from "../components/common/ErrorComponent.vue";
import PersonalInformationForm from "@/components/forms/personal/PersonalInformationForm.vue";

export default Vue.extend({
  name: "ForgotPassword",
  components: {
    FoodyCard,
    FoodyButton,
    ErrorComponent,
    PersonalInformationForm,
  },
  data() {
    return {
      email: "",
      showError: false,
      isLoading: false,
      hasData: false,
    };
  },
  methods: {
    login(): void {
      this.$router.push("/login");
    },
    async sendResetPasswordToken(): Promise<void> {
      this.isLoading = true;
      try {
        await this.$store.dispatch("sendResetPasswordToken", this.email);
        this.hasData = true;
      } catch (err) {
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped>
.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.reset-password-width {
  min-width: 20em;
  max-width: 30em;
}
</style>
