import EateryDto from "@/types/dto/eateries/eateryDto";
import RootState from "@/types/state/rootState";
import { GetterTree } from "vuex";
import EateryState from "./state";

export const getters: GetterTree<EateryState, RootState> = {
  eateryName(state): string {
    return state.eateryName;
  },
  eateryInfo(state): EateryDto {
    return state.eateryInfo;
  },
  eateries(state): EateryDto[] {
    return state.eateries;
  },
};
