<template>
  <div>
    <div class="partner-chains-bg-head">
      <PartnerNavBack variant="white"></PartnerNavBack>
      <AlphaBox
        header="Kjeder, grupper og innkjøpssamarbeid"
        show-features="false"
      ></AlphaBox>
    </div>
    <div class="partner-chains">
      <FeaturesFlex></FeaturesFlex>
      <div class="partner-benefits">
        <div class="left text">
          <p class="header">Full kontroll med alt i et system</p>
          <span>
            QR, Takeaway, Bordreservasjon og smittesporing håndteres enkelt i et
            og samme system. Smittesporing spanderer vi også.
          </span>
        </div>
        <img class="right" src="../../src/assets/img/eatery_1.png" />
        <div class="right text">
          <p class="header">Høyere omsetning, lavere kostnader</p>
          <span>
            Når kunden kan bestille når de vil, bestiller de gjerne mer. I
            tillegg kan du enkelt bemanne mer effektiv med getFOOD systemet.
          </span>
        </div>
        <img class="left" src="../../src/assets/img/eatery_2.png" />
        <div class="left text">
          <p class="header">Skaper den gode kundeopplevelsen</p>
          <span>
            Med en helhetlig tjeneste for mobil bestilling og betaling av mat og
            drikke, gjør getFOOD hverdagen enklere for spisesteder og ikke minst
            - kunden.
          </span>
        </div>
        <img class="right" src="../../src/assets/img/eatery_3.png" />
      </div>
      <PricesChains style="margin-top: 40px"></PricesChains>
      <CatchLines style="margin-top: 40px"></CatchLines>
      <PartnerReviews></PartnerReviews>
      <PartnerFooter></PartnerFooter>
    </div>
  </div>
</template>

<script>
import CatchLines from "@/components/partner/Catchlines.vue";
import PartnerReviews from "@/components/partner/PartnerReviews.vue";
import PricesChains from "@/components/partner/PricesChains.vue";
import AlphaBox from "@/components/partner/partner_subpages/PartnerAlphabox.vue";
import PartnerNavBack from "@/components/partner/PartnerNavBack.vue";
import FeaturesFlex from "@/components/partner/FeaturesFlex.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";

export default {
  components: {
    CatchLines: CatchLines,
    PartnerReviews: PartnerReviews,
    PricesChains: PricesChains,
    AlphaBox: AlphaBox,
    PartnerNavBack: PartnerNavBack,
    FeaturesFlex: FeaturesFlex,
    PartnerFooter: PartnerFooter,
  },
};
</script>
<style scoped>
.partner-chains {
  text-align: center;
  margin: 0 auto;
  max-width: 1250px;
}

.partner-chains-bg-head {
  padding: 40px;
  background-image: url("../../src/assets/img/chains_main.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  min-height: 600px;
}

.partner-chains-bg-head {
  padding: 40px;
  background-image: url("../../src/assets/img/chains_main.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  min-height: 600px;
}

.features {
  margin: 25px 25px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
}

.features span {
  color: #ffffff;
  padding-right: 32px;
  padding-left: 32px;
}

.features span:first-child {
  padding-left: 0;
}

.features span:last-child {
  border-right: none;
}

.featureitem {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.featureitem img {
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .partner-eateries-bg-head {
    font-size: 0.8em;
  }

  .features {
    grid-template-columns: 1fr;
  }
  .partner-benefits img {
    width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .partner-eateries {
    min-height: 400px;
  }

  .alphabox {
    min-height: 100px;
    margin-top: 180px;
  }

  .alphabox .header {
    font-size: 1.3em;
  }
  .features {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1201px) {
  .features span:not(:first-child) {
    border-left: 1px solid white;
  }
}
</style>
