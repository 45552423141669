<template>
  <v-row justify="end" no-gutters>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="675px"
      transition="dialog-bottom-transition"
    >
      <template #activator="{ on, attrs }">
        <v-btn color="primary" rounded v-bind="attrs" v-on="on">
          <v-icon style="padding-right: 5px">mdi-filter-variant</v-icon>
          Filter
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer> </v-spacer>

          <v-toolbar-items>
            <v-btn dark text @click="resetFilters()"> Tilbakestill </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="container--fluid">
          <v-col lg="8">
            <h3 v-if="showPriceRangeFilter" class="prisklasse">Prisklasse</h3>

            <div v-if="showPriceRangeFilter" class="alleknapper">
              <div class="r1-button">
                <v-btn
                  :color="selectedPriceRange === 0 ? '#9F0073' : 'white'"
                  class="v-button"
                  rounded
                  @click="buttonClick(0)"
                >
                  <h2
                    :style="
                      selectedPriceRange === 0 ? { color: 'white' } : null
                    "
                    class="dollartegn"
                  >
                    $
                  </h2>
                </v-btn>
              </div>
              <div class="r-button">
                <v-btn
                  :color="selectedPriceRange === 1 ? '#9F0073' : 'white'"
                  class="v-button"
                  rounded
                  @click="buttonClick(1)"
                >
                  <h2
                    :style="
                      selectedPriceRange === 1 ? { color: 'white' } : null
                    "
                    class="dollartegn"
                  >
                    $$
                  </h2>
                </v-btn>
              </div>
              <div class="r-button">
                <v-btn
                  :color="selectedPriceRange === 2 ? '#9F0073' : 'white'"
                  class="v-button"
                  rounded
                  @click="buttonClick(2)"
                >
                  <h2
                    :style="
                      selectedPriceRange === 2 ? { color: 'white' } : null
                    "
                    class="dollartegn"
                  >
                    $$$
                  </h2>
                </v-btn>
              </div>
            </div>

            <h3 class="distanse">Distanse</h3>
            <div class="slideralt">
              <section class="grid1">
                <div class="item-1">
                  <p class="venstreforslider">1km</p>
                </div>
                <div class="item-2">
                  <div class="">
                    <v-slider
                      v-model="slider"
                      thumb-label="always"
                      thumb-size="40"
                      class="slider"
                      max="30"
                      min="1"
                      @change="sliderChange"
                    ></v-slider>
                  </div>
                </div>
                <div class="item-3">
                  <p class="høyreforslider">30km</p>
                </div>
              </section>
            </div>

            <div class="hoved">
              <h3 class="filter">Filter</h3>

              <label
                v-for="eatingType in eatingTypes"
                :key="eatingType.name"
                class="sjekkmerketekst"
              >
                <span v-text="eatingType.name"></span>
                <input
                  v-model="selectedEatingModes"
                  type="checkbox"
                  :value="eatingType.id"
                  @change="eatingModeType"
                />
                <span class="checkmark"></span>
              </label>
              <h3 v-if="kitchenTypes.length > 0" class="overskrift-checkmark">
                Kjøkkentype
              </h3>
              <label
                v-for="c in kitchenTypes"
                :key="c.id"
                class="sjekkmerketekst"
              >
                <span v-text="c.name"></span>
                <input
                  v-model="selectedKitchenTypes"
                  type="checkbox"
                  :value="c.id"
                  @change="kitchenTypeClick"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </v-col>
        </div>
        <div class="sticky-button-container">
          <FoodyButton :onClick="saveFilter" :btnColor="'primary'"
            >Vis resultater</FoodyButton
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { EatingMode } from "@/types/enums/eatingMode";
import FoodyButton from "@/components/common/FoodyButton.vue";

// We put initial state in a function
// This is so we can reset the data when clicking on "Reset filter"
function initialState() {
  return {
    // The price range feature is not implemented
    showPriceRangeFilter: false,
    selectedPriceRange: false,
    // List of selected Eating mode types.
    selectedEatingModes: [],
    // List of selected kitchen types
    selectedKitchenTypes: [],
    // Default radius value
    slider: 10,
    // Whether the filter dialog is shown or not
    dialog: false,
    // A list of active filters (this is sent to server)
    activeFilters: [],
  };
}
export default {
  components: {
    FoodyButton,
  },

  props: {
    eateries: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      required: false,
      default: "store",
    },
  },

  data() {
    return initialState();
  },
  computed: {
    kitchenTypes: function kitchenTypes() {
      const foundKitchenTypeIds = [];
      const foundKitchenTypes = [];
      // Go through each eatery
      for (const key in this.eateries) {
        if (Object.prototype.hasOwnProperty.call(this.eateries, key)) {
          const eatery = this.eateries[key];
          // Go through each kitchen type
          eatery.kitchenTypes.forEach((kitchenType) => {
            if (kitchenType) {
              // If the kitchen type is not already in our list
              if (foundKitchenTypeIds.indexOf(kitchenType.id) == -1) {
                // Add the kitchen type ID to an array
                foundKitchenTypeIds.push(kitchenType.id);
                // Add the kitchen type data to an array
                foundKitchenTypes.push(kitchenType);
              }
            }
          });
        }
      }
      return foundKitchenTypes;
    },
    eatingTypes: function eatingTypes() {
      const eatingArray = [
        { id: EatingMode.DineIn, name: "Dine-in" },
        { id: EatingMode.TakeAway, name: "Take-away" },
        { id: EatingMode.Delivery, name: "Levering" },
      ];
      return eatingArray;
    },
  },
  methods: {
    buttonClick: function buttonClick(i) {
      // If clicking on the active price range, unselect it
      if (this.selectedPriceRange === i) {
        this.selectedPriceRange = -1;
      } else {
        this.selectedPriceRange = i;
      }
      this.activeFilters["PriceRange"] = this.selectedPriceRange;
    },
    eatingModeType: function eatingModeType() {
      // // Create a comma-separated list of selected EatingModeTypes
      this.activeFilters["EatingModes"] = this.selectedEatingModes.join(",");
    },
    kitchenTypeClick: function kitchenTypeClick() {
      // Create a comma-separated list of selected KitchesTYpes
      this.activeFilters["KitchenTypes"] = this.selectedKitchenTypes.join(",");
    },
    sliderChange: function sliderChange() {
      // Set search radius in km based on slider
      if (this.slider) {
        this.activeFilters["SearchRadiusKm"] = this.slider;
      }
    },
    emitFilterChanged: function emitFilterChanged() {
      // Emit a filter so that we can update the results
      this.$emit("filter-changed", this.activeFilters);
    },
    saveFilter: function saveFilter() {
      this.emitFilterChanged();
      this.dialog = false;
    },
    resetFilters: function resetFilers() {
      // Reset to default initialState
      Object.assign(this.$data, initialState());
      this.dialog = true;
      this.emitFilterChanged();
    },
  },
};
</script>

<style scoped>
.prisklasse {
  padding-top: 15px;
  padding-bottom: 15px;
}

.alleknapper {
  max-width: 675px;
  height: 60px;
}

.r1-button {
  width: 70px;
  float: left;
  margin-left: 15px;
  padding-bottom: 18px;
}

.r-button {
  width: 70px;
  float: left;
  margin-left: 25px;
  padding-bottom: 18px;
}

.dollartegn {
  color: #9f0073;
  margin: auto 0;
}

.v-button {
  color: #9f0073 !important;
}

.v-button:hover:before {
  opacity: 1 !important;
}

.v-button:hover {
  color: #9f0073;
}

.v-button:hover .dollartegn {
  color: white;
}

.distanse {
  height: 35px;
  padding-top: 10px;
}

.slideralt {
  margin-top: 30px;
}

.grid1 {
  padding-top: 25px;
  padding-left: 1px;
  display: grid;
  grid-template-columns: 60px 240px 60px;
  height: 70px;
  grid-template-rows: auto auto auto;
  max-width: 625px;
}

.høyreforslider {
  padding-top: 3px;
  padding-left: 3px;
}

.venstreforslider {
  padding-left: 15px;
  padding-top: 3px;
}

.slider {
  padding-bottom: 0px;
}

.filter {
  padding-top: 15px;
  padding-bottom: 15px;
}
.overskrift-checkmark {
  padding-top: 15px;
  padding-bottom: 15px;
}
.hoved {
  /* Create some padding for the checkboxes */
  padding-right: 30px;
  height: 100%;
}
/* The sjekkmerketekst */
.sjekkmerketekst {
  display: block;
  position: relative;
  margin-left: 15px;
  padding-bottom: 25px;
  cursor: pointer;
  font-size: 16px;
}

/* Hide the browser's default checkbox */
.sjekkmerketekst input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  float: right;
  top: 0%;
  left: 100%;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.sjekkmerketekst:hover input ~ .checkmark {
  background-color: rgb(248, 144, 193);
}

/* When the checkbox is checked, add a blue background */
.sjekkmerketekst input:checked ~ .checkmark {
  background-color: #9f0073;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.sjekkmerketekst input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.sjekkmerketekst .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*save sticky button container */
.sticky-button-container {
  text-align: center;
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  padding-bottom: 20px;
}
</style>
