import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const codebehind = {
  mixins: [validationMixin],
  validations() {
    return {
      input: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(250),
      },
    };
  },
  data() {
    return {
      show: false,
    };
  },
  props: {
    input: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputValid: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    externalErrors: {
      type: Array,
      required: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.input;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },

    validateRules(): string[] {
      const errors: string[] = [];
      if (!this.$v.input.required && this.isRequired) {
        errors.push("Dette feltet er påkrevd");
      }
      return errors;
    },
  },
  mounted() {
    this.$watch(
      (vm: any) => [vm.validateRules],
      () => {
        const checked = this.validateRules.length === 0;
        this.$emit("inputValid", checked);
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },

  methods: {
    clear() {
      this.$v.$reset();
    },
  },
};

export default codebehind;
