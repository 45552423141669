import { currentEatingModeHasValidOpeningHoursOnDate } from "@/helpers/currentEateryHelper";
import Moment from "moment";

const codeBehind = {
  data() {
    const data: {
      availableDates: string[];
      open: boolean;
      dateString: string;
    } = {
      availableDates: [],
      open: false,
      dateString: "",
    };
    return data;
  },
  props: {
    dateProp: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.dateString = this.dateProp;
  },
  computed: {
    dateInput: {
      get() {
        return this.dateProp;
      },
      set(val: string) {
        this.$emit("dateProp", val);
      },
    },
    computedDateFormatted() {
      if (!this.dateString) return null;
      return Moment(this.dateString).format("DD.MM.YYYY");
    },
  },
  methods: {
    onChange() {
      this.$emit("dateProp", this.dateString);
      this.open = false;
    },
    getMinDate() {
      return Moment().format("YYYY-MM-DD");
    },
    getMaxDate() {
      return Moment().add(2, "months").format("YYYY-MM-DD");
    },
    allowedDates(val: string) {
      const date = new Date(val);
      return currentEatingModeHasValidOpeningHoursOnDate(date);
    },
  },
};
export default codeBehind;
