import { EatingMode } from "@/types/enums/eatingMode";
import {
  getEatingModeDto,
  setAvailableEatingMode
} from "@/types/dto/eateries/eateryDtoFunctions";
import {
  currentEatingModeIsOpenNow,
  getFirstAvailableOpeningTimeForCurrentEatingMode
} from "@/helpers/currentEateryHelper";

export default {
  name: "DeliveryOptionInfo",
  props: {
    estimationOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    tableObject() {
      return this.$store.getters.tableObject;
    },
    currentEatingMode() {
      if (!this.$store.getters.selectedEatingMode)
        setAvailableEatingMode(this.currentEatery);
      return this.$store.getters.selectedEatingMode;
    },
    currentEatingModeDto() {
      const eatingDto = this.currentEatery;
      const eatingMode = getEatingModeDto(
        eatingDto,
        this.currentEatingMode as EatingMode
      );
      return eatingMode;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    nextOpeningDate() {
      return getFirstAvailableOpeningTimeForCurrentEatingMode();
    },
    isOpenNow() {
      return currentEatingModeIsOpenNow();
    }
  },
  methods: {
    getContainerCssClassName() {
      return this.tableObject
        ? "container-for-table-reservation"
        : "container-for-ordering";
    }
  }
};
