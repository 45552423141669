<template>
  <section class="section">
    <img src="../../../src/assets/img/benefits_3.png" />
    <div class="call-to-action">
      <div>
        <h3>Driver du et spisested? Bli partner hos getFOOD</h3>
        <span
          >Nå får du samlet all mobil bestilling i ett system. Vi har tilpassede
          løsninger for alle typer spisesteder.</span
        >
        <div>
          <FoodyButton
            class="navigate-button"
            :btn-color="'primary'"
            :on-click="navigateToPartnerPage"
            >getFOOD for spisesteder</FoodyButton
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import FoodyButton from "@/components/common/FoodyButton.vue";

export default {
  components: {
    FoodyButton,
  },
  methods: {
    navigateToPartnerPage() {
      this.$router.push("/partner");
    },
  },
};
</script>
<style scoped>
.section {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "call-to-action"
    "img";
}
img {
  grid-area: img;
  justify-self: center;
  height: auto;
  max-width: 100%;
  padding: 15px;
}
h3 {
  font-size: 20px;
  margin-bottom: 16px;
}

.call-to-action {
  padding: 15px;
  grid-area: call-to-action;
}

.navigate-button {
  margin-top: 20px;
}

@media (min-width: 426px) {
  .section {
    padding: 50px 3vw;
  }
}

@media (min-width: 900px) {
  .section {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "img call-to-action";
  }
}
</style>
