<template>
  <div class="features-flex">
    <div class="featureitem">
      <img src="../../assets/img/icon_qr.png" />
      <span class="featureitem-header">QR Bestilling</span>
      <span
        >Med vår løsning vil kundene bestille selv uten å stå i kø eller vente
        på personalet.</span
      >
    </div>
    <div class="featureitem">
      <img src="../../assets/img/icon_food.png" />
      <span class="featureitem-header">Take Away</span>
      <span
        >Spisestedene får egen nettbutikk hvor kundene kan bestille Take Away
        hjemmefra.</span
      >
    </div>
    <div class="featureitem">
      <img src="../../assets/img/icon_tableres.png" />
      <span class="featureitem-header">Bordreservasjon</span>
      <span>Motta bordreservasjoner rett i ordresystemet.</span>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.features-flex {
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.featureitem {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 15px 5px;
}

.featureitem-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 3px;
}

.featureitem img {
  align-self: center;
}

@media only screen and (max-width: 600px) {
  .features-flex {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .features-flex {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
