<template>
  <div>
    <h4 v-if="shoppingcart.length === 0">Din handlekurv er tom.</h4>
    <div
      v-if="getTable"
      style="
        border-bottom: 1px solid #b0c4de;
        padding-bottom: 10px;
        padding-top: 10px;
      "
    >
      <ShoppingCartTableItem :show-delete-button="false" />
    </div>
    <cart-item
      v-if="getTableReservation"
      :key="getTableReservation.name"
      :item="getTableReservation"
      :remove-item="() => removeCartItem(getTableReservation)"
    />
    <cart-item
      v-for="(item, index) in shoppingcart"
      :key="item.menuItemId + '' + index"
      :item="item"
      :remove-item="() => removeCartItem(item)"
    />
    <ShoppingCartDeliveryItem
      v-if="hasDeliveryInfo"
      :shoppingcartSum="shoppingCartSum"
    />
    <ShoppingCartTipItem
      v-if="inCheckoutAndEnabled"
      :orderSum="shoppingCartSum"
    />
    <div style="padding-top: 10px" class="foody-flex-row shopping-cart-sum">
      <h3 style="margin-left: 5px">Sum bestilling:</h3>
      <h3>{{ orderSum / 100 }} NOK</h3>
    </div>
    <div class="foody-flex-row mva-text">
      <div>herav mva {{ vatType }}%</div>
      <div>{{ calculateVatInKr(vatType) }} NOK</div>
    </div>
    <div>
      <Tip v-if="inCheckoutAndEnabled" />
    </div>
    <div>
      <v-textarea
        v-model="msgToKitchen"
        outlined
        height="80px"
        Auto
        grow
        no-resize
        label="Melding til kjøkken, eller ditt navn"
        @blur="updateKichenMsgStore"
      ></v-textarea>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import codeBehind from "./ShoppingCart";

export default Vue.extend({
  name: "ShoppingCart",
  mixins: [codeBehind],
});
</script>
<style scoped>
.mva-text {
  margin-left: 5px;
  justify-content: space-between;
  padding-bottom: 20px;
  font-size: 80%;
}
</style>
