interface TimeObject {
  days: number;
  hours: number;
  minutes: number;
}

const remainingTime = (hours: number): TimeObject => {
  const time: TimeObject = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  let remainingDays = 0;
  let remainingHours = 0;
  let remainingMinutes = 0;
  if (hours >= 24) {
    remainingDays++;
    remainingTime(hours - 24);
  } else if (hours < 1) {
    remainingMinutes = Math.round(hours * 60);
  } else {
    remainingHours = Math.round(hours);
  }

  time.days = remainingDays;
  time.hours = remainingHours;
  time.minutes = remainingMinutes;

  return time;
};

const toString = (timeObject: TimeObject): string => {
  if (
    timeObject.days === 0 &&
    timeObject.hours === 0 &&
    timeObject.minutes > 0
  ) {
    return `${timeObject.minutes} minutter`;
  } else if (timeObject.days === 0 && timeObject.hours > 0) {
    return timeObject.hours === 1
      ? `${timeObject.hours} time`
      : `${timeObject.hours} timer`;
  } else {
    const t =
      timeObject.hours === 1
        ? `${timeObject.hours} time`
        : `${timeObject.hours} timer`;
    return `${timeObject.days} dager og ${t}`;
  }
};

export { remainingTime, toString };
