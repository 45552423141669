import FoodyButton from "@/components/common/FoodyButton.vue";
import Moment from "moment";

const codebehind = {
  components: {
    FoodyButton,
  },
  computed: {
    tableReservation() {
      return this.$store.getters.tableReservationObject;
    },
    currentEatery() {
      return this.$store.getters.eateryInfo;
    },
    formattedReservationDateTime() {
      return Moment(this.tableReservation.ReservationDateTime).format(
        "HH:mm DD.MM.YYYY"
      );
    },
    reservationInfo() {
      return (
        "Kontakt " +
        this.currentEatery.displayName +
        " på telefon " +
        this.currentEatery.phone +
        ", eller send epost til " +
        this.currentEatery.email +
        " ved spørsmål/endringer."
      );
    },
  },
};
export default codebehind;
