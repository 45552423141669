<template>
  <v-text-field
    v-model="inputValue"
    :autocomplete="autoCompleteType"
    :rules="validateRules"
    :label="fieldLabel"
    :type="inputFieldType"
    color="blue darken-2"
    :disabled="inputDisabled"
    validate-on-blur
    clearable
    :error-messages="externalErrors"
    :append-icon="
      inputType === 'password' ? (show ? 'mdi-eye' : 'mdi-eye-off') : null
    "
    :hint="
      inputType === 'password'
        ? 'Minst 8 karakterer, små og store bokstaver'
        : null
    "
    @input="$v.input.$touch()"
    @blur="$v.input.$touch()"
    @click:append="show = !show"
  />
</template>
<script lang="ts">
import Codebehind from "./Inputfield";
import Vue from "vue";
export default Vue.extend({
  name: "Inputfield",
  mixins: [Codebehind],
});
</script>
