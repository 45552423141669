<template>
  <section>
    <div
      v-for="{ title, body, imageUrl, bgColor, textRight } in partnerBenefits"
      :key="title"
      class="partner-benefits"
      :class="bgColor"
    >
      <div class="partner-benefits-content" :class="{ textRight }">
        <div class="partner-benefits-text">
          <span slot="title" class="partner-benefits-title" v-html="title">
            {{ title }}
          </span>
          <span>
            {{ body }}
          </span>
        </div>
        <v-img class="partner-benefits-image" :src="imageUrl" />
        <div class="partner-dot-wrapper">
          <img
            class="partner-page-dots"
            src="../../assets/img/partner_page_dots.png"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import partnerBenefits from "./PartnerBenefits.ts";
export default {
  data() {
    return {
      partnerBenefits,
    };
  },
};
</script>
<style>
.partner-benefits {
  background-color: var(--partner-dark-background-color);
  color: white;
}
.partner-benefits-content.textRight .partner-dot-wrapper {
  left: 0px;
  top: 15px;
}
.partner-dot-wrapper {
  height: fit-content;
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: -55px;
}
.purple-text {
  color: var(--foody-shopping-cart-primary-color);
}

.partner-benefits.white {
  color: black;
}
.partner-page-dots {
  height: min-content;
}
.partner-benefits-content.textRight {
  display: flex;
  flex-direction: row-reverse;
  text-align: left;
}
.partner-benefits-content {
  max-width: 1140px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  margin: 0 auto;
  padding: 4em 0;
  position: relative;
}
.partner-benefits-title {
  font-size: 2.5rem;
  padding-bottom: 1em;
}
.partner-benefits-text {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.partner-benefits-image {
  width: inherit;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .partner-benefits-content {
    grid-template-columns: 1fr;
    margin: 0 1rem;
  }
  .partner-benefits-content.textRight {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .partner-benefits-content.textRight .partner-dot-wrapper {
    left: 100px;
    top: 15px;
  }
  .partner-dot-wrapper {
    bottom: 15px;
    right: 65px;
  }
  .partner-benefits-title {
    font-size: 1.5rem;
  }
}
</style>
