<template>
  <section>
    <v-container class="container">
      <v-row justify="center" style="padding-bottom: 20px">
        <h1>Utvalgte spisesteder</h1>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="eatery in eateries"
          :key="eatery.eateryId"
          sm="12"
          md="6"
          lg="4"
        >
          <EateryCardMinimal :eatery-item="eatery" />
        </v-col>
      </v-row>
      <v-row v-if="loading" justify="center">
        <v-col sm="auto">
          <Loader :isLoading="true" />
        </v-col>
      </v-row>
      <v-row v-if="!allPagesLoaded && !loading" justify="center">
        <v-col sm="auto">
          <FoodyButton
            :disabled="loading"
            :onClick="fetchNextPage"
            btn-color=""
          >
            Vis flere
          </FoodyButton>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import EateryCardMinimal from "@/components/eatery/EateryCardMinimal.vue";
import Loader from "@/components/common/Loader.vue";
import FoodyButton from "@/components/common/FoodyButton.vue";
import { EatingMode } from "@/types/enums";

const PAGE_SIZE = 12;

export default {
  components: {
    EateryCardMinimal,
    Loader,
    FoodyButton,
  },
  data() {
    return {
      eateries: [],
      loading: false,
      page: 1,
      allPagesLoaded: false,
    };
  },
  async mounted() {
    await this.fetchNextPage();
  },
  methods: {
    async fetchNextPage() {
      if (this.loading) return;
      this.loading = true;

      const result = await this.$store.dispatch("getEateriesByQuery", {
        page: this.page,
        pageSize: PAGE_SIZE,
        eatingModes: `${EatingMode.TakeAway},${EatingMode.Delivery}`,
        getQrOnlyDineIn: false,
      });
      this.eateries = [...this.eateries, ...result];
      this.loading = false;
      this.page++;

      if (result.length < PAGE_SIZE) this.allPagesLoaded = true;
    },
  },
};
</script>
<style scoped>
h3 {
  color: #222222;
}

@media (min-width: 426px) {
  .container {
    padding: 50px 3vw;
  }
}
</style>
